angular.module("prisma")
    .controller("AssortmentRulesCategoryCtrl", function ($rootScope, $scope, $state, $timeout, $interval, ngDialog, $filter, assortmentService, companyJson, $translate) {

        var self = this;
        var numberColWidth = 100;
        var editableColWidth = 140;
        var lastAdded = null;
        var columnDefs = null;

        self.gridOptions = null;
        self.summary = null;
        self.categoryRoleStrategyCoverages = [];
        self.brandCategoryRoleStrategyCoverages = [];
        self.roles = [];
        self.strategies = [];
        self.rsBrands = [];
        self.selectedBrand = 1;
        self.categoryLevel = 3;
        self.hideStrategy = null;

        self.customCategoryMonths = [];
        self.newCustomCategoryMonths = { toDelete: false, categoryId: 0, jan: true, feb: true, mar: true, apr: true, may: true, jun: true, jul: true, aug: true, sep: true, oct: true, nov: true, dic: true };

        self.goToRules = function (newTab, id, categoryGroup, category, subcategory) {

            categoryGroup = categoryGroup.replace(/ /g, "-").replace(/\//g, "_");
            category = category.replace(/ /g, "-").replace(/\//g, "_");
            subcategory = subcategory.replace(/ /g, "-").replace(/\//g, "_");

            var encodedcategoryGroup = categoryGroup != 'null' ? encodeURIComponent(categoryGroup) : '';
            var encodedcategory = category != 'null' ? encodeURIComponent(category) : '';
            var encodedsubcategory = subcategory != 'null' ? encodeURIComponent(subcategory) : '';

            if (newTab)
                $state.goNewTab('assortment.rules', { categoryId: id, categoryGroup: encodedcategoryGroup, category: encodedcategory, subcategory: encodedsubcategory });
            else
                $state.go('assortment.rules', { categoryId: id, categoryGroup: encodedcategoryGroup, category: encodedcategory, subcategory: encodedsubcategory });

        }

        self.save = function () {
            swal({
                title: translations.DiscardConfirmTitle,
                //text: 'Esta seguro que desea guardar los cambios realizados y las estrategias, roles y cobertura para ' + _.find(self.rsBrands, function (b) { return b.brandId == self.selectedBrand }).name + '?',
                text: translations.AreYouSureSwalSubtitle,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function () {
                    var itemsToSave = [];
                    var allIndexes100 = true;
                    self.gridOptions.api.forEachNode(function (node) {
                        if (node.data.dirty) {
                            itemsToSave.push(node.data);
                        }
                        if ((node.data.indexSalesWeight || node.data.indexUnitsWeight || node.data.indexMarginWeight || node.data.indexMarketSalesWeight || node.data.indexMarketUnitsWeight) && //Que haya al menos uno no nulo
                            (node.data.indexSalesWeight > 0 && node.data.indexUnitsWeight > 0 && node.data.indexMarginWeight > 0 && node.data.indexMarketSalesWeight > 0 && node.data.indexMarketUnitsWeight > 0) && //Que no haya numeros negativos
                            ((node.data.indexSalesWeight + node.data.indexUnitsWeight + node.data.indexMarginWeight + node.data.indexMarketSalesWeight + node.data.indexMarketUnitsWeight) < 1 ||  //Que la suma este entre 1 y 0.9999 (WorkArround, la suma daba 0.9999999999999)
                                (node.data.indexSalesWeight + node.data.indexUnitsWeight + node.data.indexMarginWeight + node.data.indexMarketSalesWeight + node.data.indexMarketUnitsWeight) < 0.9999))
                            allIndexes100 = false;
                    });

                    if (allIndexes100) {
                        if (itemsToSave.length > 0) {
                            var categories = JSON.stringify(itemsToSave);
                            assortmentService.rules.saveCategoryRules(categories, self.selectedBrand).then(function () {
                                loadStrategyAndRoles(false);

                                assortmentService.rules.saveCustomCategoryMonths(self.customCategoryMonths).then(function () {
                                    self.customCategoryMonths = [];
                                    loadData();
                                    swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success");
                                });

                            }).catch(function (fallback) {
                                console.log(fallback);
                                swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error");
                            });
                        }
                        else {
                            $timeout(function () { swal(translations.NoChangesWereRecorded, "", "info") }, 200);
                        }
                    }
                    else {
                        $timeout(function () {
                            swal(translations.ErrorTitleSwal, translations.SumErrorOfWeightedIndexes, "error");
                        }, 200);
                    }
                });
        }

        self.changeBrandClick = function (brandId) {
            var hasChanges = false;
            self.gridOptions.api.forEachNode(function (node) {
                if (node.data.dirty) {
                    hasChanges = true;
                }
            });

            if (hasChanges) {
                swal({
                    title: translations.PendingChangesWithoutSaving,
                    text: translations.TheChangesYouHaveMadeInRoleWillBeLost + _.find(self.rsBrands, function (b) { return b.brandId == self.selectedBrand }).name + '.',
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: translations.Discard,
                    cancelButtonText: translations.CancelSwal,
                    showLoaderOnConfirm: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                    function () {
                        self.changeBrand(brandId);
                    });
            }
            else
                self.changeBrand(brandId);
        }

        self.changeBrand = function (brandId) {

            self.selectedBrand = brandId;

            self.brandCategoryRoleStrategyCoverages = _.filter(self.categoryRoleStrategyCoverages, function (o) {
                return o.brandId == self.selectedBrand;
            });

            //Clear de los campos y seteo dirty = false en todos
            self.gridOptions.api.forEachNode(function (node) {
                node.data.categoryRole = null;
                node.data.categoryRoleId = null;
                node.data.categoryStrategy = null;
                node.data.categoryStrategyId = null;
                node.data.targetCoverage = null;
                node.data.dirty = false;
                self.gridOptions.api.refreshRows([node]);
            });

            //Recorro la grilla seteando los valores del brand seleccionado
            self.gridOptions.api.forEachNode(function (node) {

                angular.forEach(self.brandCategoryRoleStrategyCoverages, function (bc) {
                    if (bc.id == node.data.id) {
                        node.data.categoryRole = bc.categoryRole;
                        node.data.categoryRoleId = bc.categoryRoleId;
                        node.data.categoryStrategy = bc.categoryStrategy;
                        node.data.categoryStrategyId = bc.categoryStrategyId;
                        node.data.targetCoverage = bc.targetCoverage;
                        //break;
                        self.gridOptions.api.refreshRows([node]);
                    }

                });
            });

            self.gridOptions.api.refreshView();

        }

        self.openCustomMonthsDialog = function (categoryIds, monthsValue) {
            var newScope = $scope.$new();
            newScope.customMonths = angular.copy(self.newCustomCategoryMonths);

            //Si es Custom, agrego los meses asociados a la categor�a
            if (monthsValue == 0) {

                newScope.saveCustomMonths = function () {

                    angular.forEach(categoryIds, function (sc) {

                        for (var i = 0; i < self.customCategoryMonths.length; i++) {
                            var indexToRemove = null;
                            if (self.customCategoryMonths[i].categoryId == sc)
                                indexToRemove = i;

                            if (indexToRemove != null)
                                self.customCategoryMonths.splice(indexToRemove, 1);
                        }

                        newScope.customMonths.categoryId = sc;
                        self.customCategoryMonths.push(angular.copy(newScope.customMonths));
                    });

                    ngDialog.close();
                }

                ngDialog.open({
                    template: 'customCategoryMonthsDialog',
                    className: 'ngdialog-theme-default ngdialog-theme-custom',
                    closeByDocument : false,
                    scope: newScope
                });
            }
            else { //Si no es Custom, pongo toDelete a la categor�a para eliminar previas asociaciones.

                angular.forEach(categoryIds, function (sc) {

                    for (var i = 0; i < self.customCategoryMonths.length; i++) {
                        var indexToRemove = null;
                        if (self.customCategoryMonths[i].categoryId == sc)
                            indexToRemove = i;

                        if (indexToRemove != null)
                            self.customCategoryMonths.splice(indexToRemove, 1);
                    }

                    newScope.customMonths.categoryId = sc;
                    self.customCategoryMonths.push(angular.copy(newScope.customMonths));
                });
            }
        };

        self.externalFilterChanged = function () {
            self.gridOptions.api.onFilterChanged();
        }

        //grid config
        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: translations.Category,
                    width: numberColWidth + 200,
                    pinned: 'left',
                    field: 'name',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                    cellRenderer: {
                        renderer: 'group',
                        checkbox: false,
                        innerRenderer: function (params) {
                            var name = params.value + (params.node.group && params.node.children != null ? ' (' + params.node.children.length + ')' : '');

                            if ((self.categoryLevel == 3 && params.node.data.level != 3) || (self.categoryLevel == 4 && params.node.data.level > 3)) {
                                var anchor = document.createElement('a');
                                anchor.title = translations.ClickToEnterCategory;
                                anchor.style = 'padding: 5px';
                                anchor.innerHTML = name;

                                anchor.addEventListener('click', function (e) {
                                    var categoryGroup = '';
                                    var category = '';
                                    var subcategory = '';
                                    if (!params.node.parent || params.node.parent == null) {
                                        categoryGroup = params.value;
                                    }
                                    else if (params.node.parent != null && params.node.children != null) {
                                        categoryGroup = params.node.parent.data.name;
                                        category = params.value
                                    }
                                    else if (params.node.parent != null && params.node.children == null) {
                                        categoryGroup = params.node.parent.parent.data.name;
                                        category = params.node.parent.data.name
                                        subcategory = params.value
                                    }
                                    self.goToRules(e.ctrlKey, params.data.id, categoryGroup, category, subcategory);
                                });

                                return anchor;
                            }
                            else
                                return name;
                        }
                    }
                },
                {
                    headerName: translations.RoleAndStrategy,

                    children: [
                        {
                            headerName: translations.Role,
                            field: "categoryRole",
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: dropDownDecisionEditor
                        },
                        {
                            headerName: translations.Strategy,
                            field: "categoryStrategy",
                            headerTooltip: "",
                            columnGroupShow: self.hideStrategy,
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: dropDownDecisionEditor
                        },
                        {
                            headerName: translations.Coverage,
                            field: "targetCoverage",
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: function (param) {
                                if (param.data.level == 3) {
                                    param.value = param.data.subcategories
                                        .reduce(function (maxOfCat4, cat4) {
                                        return cat4.subcategories
                                            .map(function (x) { return x.targetCoverage; })
                                            .reduce(function (maxOfCat5, targetCoverage) {
                                                return targetCoverage > maxOfCat5 ? targetCoverage : maxOfCat5;
                                            }, 0);
                                    }, 0);
                                }
                                else if (param.data.level == 4) {
                                    param.value = param.data.subcategories
                                        .map(function (x) { return x.targetCoverage; })
                                        .reduce(function (maxOfCat5, targetCoverage) {
                                            return targetCoverage > maxOfCat5 ? targetCoverage : maxOfCat5;         
                                        }, 0);
                                }

                                return $rootScope.WeightedIndexPercentageEditor(param);
                            }
                        }]
                },
                {
                    headerName: translations.WeightedIndex,

                    children: [
                        {
                            headerName: translations.Sales,
                            field: "indexSalesWeight",
                            filter: 'number',
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth - 25,
                            cellClass: $rootScope.getClassForWeightedIndexCell,
                            cellRenderer: $rootScope.WeightedIndexPercentageEditor
                        },
                        {
                            headerName: translations.Units,
                            field: "indexUnitsWeight",
                            filter: 'number',
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth - 25,
                            cellClass: $rootScope.getClassForWeightedIndexCell,
                            cellRenderer: $rootScope.WeightedIndexPercentageEditor
                        },
                        {
                            headerName: translations.MarginInDollars,
                            field: "indexMarginWeight",
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth - 10,
                            cellClass: $rootScope.getClassForWeightedIndexCell,
                            cellRenderer: $rootScope.WeightedIndexPercentageEditor
                        },
                        {
                            headerName: translations.MarketSales,
                            field: "indexMarketSalesWeight",
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth + 10,
                            cellClass: $rootScope.getClassForWeightedIndexCell,
                            cellRenderer: $rootScope.WeightedIndexPercentageEditor
                        },
                        {
                            headerName: translations.MarketUnits,
                            field: "indexMarketUnitsWeight",
                            headerTooltip: "",
                            filter: 'number',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth + 25,
                            cellClass: $rootScope.getClassForWeightedIndexCell,
                            cellRenderer: $rootScope.WeightedIndexPercentageEditor
                        },
                        {
                            headerName: translations.MonthsForCalculation,
                            field: "monthsForCalculations",
                            width: 190,
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: dropDownDecisionEditorMonthsForCalculations
                        }
                    ]
                },
                {
                    headerName: translations.AnalysisSchedule,

                    children: [
                        {
                            headerName: translations.StartDate,
                            field: "rangeReviewStartDate",
                            headerTooltip: "",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth + 40,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'text',
                            cellRenderer: self.dateEditor
                        },
                        {
                            headerName: translations.Frequency,
                            headerTooltip: translations.InDays,
                            field: "rangeReviewFrequencyInDays",
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth - 10,
                            cellClass: $rootScope.getClassForCell,
                            filter: 'number',
                            cellRenderer: $rootScope.integerEditor
                        },
                        {
                            headerName: translations.LastRevision,
                            field: "rangeReviewLastRevision",
                            headerTooltip: "",
                            filter: 'text',
                            suppressMenu: true,
                            suppressSorting: true,
                            width: numberColWidth + 20,
                            cellClass: $rootScope.getClassForCell,
                            cellRenderer: $rootScope.dateRenderer
                        }]
                },
            ]
        }

        function setGridOptions() {

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: false,
                enableFilter: false,
                groupUseEntireRow: false,
                rowSelection: "multiple",
                groupSelectsChildren: true,
                suppressCellSelection: true,
                groupSuppressAutoColumn: false, // or undefined
                showToolPanel: false,// window.innerWidth > 1500,
                groupColumnDef: null, // or undefined
                singleClickEdit: true,
                rowHeight: 35,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                enableRangeSelection: false,
                angularCompileRows: true,
                getNodeChildDetails: getNodeChildDetails,
                enableFilter: true,
                doesExternalFilterPass: doesExternalFilterPass,
                isExternalFilterPresent: isExternalFilterPresent
            }
        }

        function changeStrategyOnColumnGroupShow() {
            if (companyJson.Erp && companyJson.Erp.Implementation && companyJson.Erp.Implementation == "CencosudColombia") {
                self.hideStrategy = 'open';
            }
        }

        function getNodeChildDetails(rowItem) {
            if (rowItem.subcategories && rowItem.subcategories.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.subcategories,
                    // the key is used by the default group cellRenderer
                    key: rowItem.id
                };
            } else {
                return null;
            }
        }

        function isExternalFilterPresent() {
            return self.search != null && self.search != '';
        }

        function doesExternalFilterPass(node) {
            if (node.data.cat1.toLowerCase().indexOf(self.search.toLowerCase()) != -1
                || node.data.cat2 && node.data.cat2.toLowerCase().indexOf(self.search.toLowerCase()) != -1
                || node.data.cat3 && node.data.cat3.toLowerCase().indexOf(self.search.toLowerCase()) != -1
                || (self.categoryLevel > 3
                && node.data.cat4 && node.data.cat4.toLowerCase().indexOf(self.search.toLowerCase()) != -1)
                )
                return true;

            return false;
        }

        function dropDownDecisionEditorMonthsForCalculations(params) {

            var editing = false;

            var eCell = document.createElement('div');

            var eLabel = null

            if (params.value)
                eLabel = document.createTextNode('Ultimos ' + params.value + ' meses');
            else {
                if (params.data.customMonths && params.data.customMonths != '')
                    eLabel = document.createTextNode(params.data.customMonths);
                else
                    eLabel = document.createTextNode("Personalizado");
            }

            eCell.appendChild(eLabel);

            var eSelect = document.createElement("select");
            eSelect.className = "form-control";
            eSelect.style = "margin:0px !important; border: 0px; line-height: 100%; font-size:12px !important";

            for (var i = 0; i < self.monthsForCalculations.length; i++) {
                var eOption = document.createElement("option");
                eOption.setAttribute("value", self.monthsForCalculations[i].value);
                eOption.innerHTML = self.monthsForCalculations[i].text;
                eSelect.appendChild(eOption);
            }

            eSelect.value = params.data.monthsForCalculations;

            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eSelect);
                    eSelect.focus();
                    editing = true;
                }
            });

            eSelect.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eSelect);
                    eCell.appendChild(eLabel);
                }
            });

            eSelect.addEventListener('change', function () {
                if (editing) {

                    var colIds = [];
                    colIds.push(params.column.colId);
                    var changedRows = [];
                    changedRows.push(params.node);

                    editing = false;
                    var newValue = '';

                    var categoryIds = [];
                    for (var i = 0; i < self.monthsForCalculations.length; i++) {
                        if (self.monthsForCalculations[i].value == eSelect.value) {
                            newValue = self.monthsForCalculations[i].value;
                            params.data['monthsForCalculations'] = newValue;

                            if (params.data.level == 5) //Si elegi sub-categor�a la agrego
                                categoryIds.push(params.data.id);
                            else if (params.data.level == 4)
                                categoryIds.push(params.data.id);

                            //Children
                            if (params.node != null && params.node.children != null) {
                                params.node.children.forEach(function (node) {
                                    if (node.children) {
                                        node.children.forEach(function (node) {
                                            node.data['monthsForCalculations'] = newValue;
                                            node.data.dirty = true;
                                            changedRows.push(node);
                                            if (node.data.level == 5) //Si elegi Grupo de Categorias, agrego a los hijos de las categorias (LVL5)
                                                categoryIds.push(node.data.id);
                                        });
                                    }
                                    node.data['monthsForCalculations'] = newValue;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                    if (node.data.level == 5) //Si elegi Categoria, agrego a sus hijos (LVL5)
                                        categoryIds.push(node.data.id);
                                    else if (node.data.level == 4)
                                        categoryIds.push(node.data.id);
                                });
                            }
                            break;
                        }
                    }

                    params.data.dirty = true;

                    params.api.refreshCells(changedRows, colIds);

                    eLabel.nodeValue = newValue;
                    eCell.removeChild(eSelect);
                    eCell.appendChild(eLabel);

                    self.openCustomMonthsDialog(categoryIds, newValue);
                }
            });

            return eCell;
        }

        function dropDownDecisionEditor(params) {

            var editing = false;

            var eCell = document.createElement('div');

            var eLabel = null

            if (params.value != null && params.value != "")
                eLabel = document.createTextNode(params.value);
            else
                eLabel = document.createTextNode("------------");

            eCell.appendChild(eLabel);

            var eSelect = document.createElement("select");
            eSelect.className = "form-control";
            eSelect.style = "margin:0px !important; border: 0px; line-height: 100%; font-size:12px !important";

            if (params.colDef.field == 'categoryRole') {
                for (var i = 0; i < self.roles.length; i++) {
                    var eOption = document.createElement("option");
                    eOption.setAttribute("value", self.roles[i].key);
                    eOption.innerHTML = self.roles[i].value;
                    eSelect.appendChild(eOption);
                }

                eSelect.value = params.data.categoryRoleId;
            }
            else if (params.colDef.field == 'categoryStrategy') {
                for (var i = 0; i < self.strategies.length; i++) {
                    var eOption = document.createElement("option");
                    eOption.setAttribute("value", self.strategies[i].key);
                    eOption.innerHTML = self.strategies[i].value;
                    eSelect.appendChild(eOption);
                }

                eSelect.value = params.data.categoryStrategyId;
            }

            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eSelect);
                    eSelect.focus();
                    editing = true;
                }
            });

            eSelect.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eSelect);
                    eCell.appendChild(eLabel);
                }
            });

            eSelect.addEventListener('change', function () {
                if (editing) {

                    var colIds = [];
                    colIds.push(params.column.colId);
                    var changedRows = [];
                    changedRows.push(params.node);

                    editing = false;
                    var newValue = '';
                    if (params.colDef.field == 'categoryRole') {
                        for (var i = 0; i < self.roles.length; i++) {
                            if (self.roles[i].key == eSelect.value) {
                                newValue = self.roles[i].value;
                                params.data['categoryRole'] = newValue;
                                params.data['categoryRoleId'] = eSelect.value;
                                //Children
                                if (params.node != null && params.node.children != null) {
                                    params.node.children.forEach(function (node) {
                                        if (node.children) {
                                            node.children.forEach(function (node) {
                                                node.data['categoryRole'] = newValue;
                                                node.data['categoryRoleId'] = eSelect.value;
                                                node.data.dirty = true;
                                                changedRows.push(node);
                                            });
                                        }
                                        node.data['categoryRole'] = newValue;
                                        node.data['categoryRoleId'] = eSelect.value;
                                        node.data.dirty = true;
                                        changedRows.push(node);
                                    });
                                }
                                break;
                            }
                        }

                    }
                    else if (params.colDef.field == 'categoryStrategy') {
                        for (var i = 0; i < self.strategies.length; i++) {
                            if (self.strategies[i].key == eSelect.value) {
                                newValue = self.strategies[i].value;
                                params.data['categoryStrategy'] = newValue;
                                params.data['categoryStrategyId'] = eSelect.value;

                                //Children
                                if (params.node != null && params.node.children != null) {
                                    params.node.children.forEach(function (node) {
                                        if (node.children) {
                                            node.children.forEach(function (node) {
                                                node.data['categoryStrategy'] = newValue;
                                                node.data['categoryStrategyId'] = eSelect.value;
                                                node.data.dirty = true;
                                                changedRows.push(node);
                                            });
                                        }
                                        node.data['categoryStrategy'] = newValue;
                                        node.data['categoryStrategyId'] = eSelect.value;
                                        node.data.dirty = true;
                                        changedRows.push(node);
                                    });
                                }
                                break;
                            }
                        }
                    }

                    params.data.dirty = true;
                    for (var i = 0; i < self.categoryRoleStrategyCoverages.length; i++) {
                        if (params.data['categoryRoleId'] == self.categoryRoleStrategyCoverages[i].categoryRoleId
                            && params.data['categoryStrategyId'] == self.categoryRoleStrategyCoverages[i].categoryStrategyId) {
                            params.data['targetCoverage'] = self.categoryRoleStrategyCoverages[i].targetCoverage;
                            //Children
                            if (params.node != null && params.node.children != null) {
                                params.node.children.forEach(function (node) {
                                    if (node.children) {
                                        node.children.forEach(function (node) {
                                            node.data['targetCoverage'] = self.categoryRoleStrategyCoverages[i].targetCoverage;
                                        });
                                    }
                                    node.data['targetCoverage'] = self.categoryRoleStrategyCoverages[i].targetCoverage;
                                });
                            }

                            colIds.push('targetCoverage');
                            break;
                        }
                    }

                    params.api.refreshCells(changedRows, colIds);

                    eLabel.nodeValue = newValue;
                    eCell.removeChild(eSelect);
                    eCell.appendChild(eLabel);
                    self.gridOptions.api.refreshView();
                }
                self.gridOptions.api.refreshView();
            });

            return eCell;
        }

        var isFirstLoad = true;
        function loadData() {
            self.categoryLevel = companyJson.Category.Levels;

            assortmentService.rules.getCategories(self.categoryLevel)
                .then(function (summary) {

                    self.summary = summary;

                    //seteo los nombres de las jerarquias a cada nodo (facilita el filtro de busqueda)
                    setHierarchiesNames(self.categoryLevel);

                    self.gridOptions.api.setRowData(summary);

                    loadStrategyAndRoles(isFirstLoad);
                    isFirstLoad = false;
                });
        }

        //agrega campos de nombre de categoria de todos los niveles a cada nodo para facilitar la busqueda por texto libre
        function setHierarchiesNames() {
            angular.forEach(self.summary, function (cat1) {
                cat1.cat1 = cat1.name;
                angular.forEach(cat1.subcategories, function (cat2) {
                    cat2.cat1 = cat1.name;
                    cat2.cat2 = cat2.name;
                    angular.forEach(cat2.subcategories, function (cat3) {
                        cat3.cat1 = cat1.name;
                        cat3.cat2 = cat2.name;
                        cat3.cat3 = cat3.name;
                        if (self.categoryLevel > 3) {
                            angular.forEach(cat3.subcategories, function (cat4) {
                                cat4.cat1 = cat1.name;
                                cat4.cat2 = cat2.name;
                                cat4.cat3 = cat3.name;
                                cat4.cat4 = cat4.name;

                            });
                        }
                    });
                });
            });
        }

        function loadStrategyAndRoles(isFirstLoad) {

            //Cargo la cobertura
            assortmentService.categoryRoleStrategyCoverages.getCoverages()
                .then(function (roleStrategyCoverages) {
                    if (roleStrategyCoverages && roleStrategyCoverages.length > 0) {
                        self.categoryRoleStrategyCoverages = roleStrategyCoverages;
                    }

                    if (isFirstLoad) {
                        //Get distinct BrandId, BrandName, BranIcon
                        self.rsBrands = _.uniqBy(_.uniq(_.map(_.flatten(roleStrategyCoverages), function (e) {
                            return { brandId: e.brandId, name: e.brand, brandIcon: e.brandIcon }
                        })), 'brandId');

                        //Ordenamiento del issue #854 
                        if (self.rsBrands && companyJson.AssortmentRules && companyJson.AssortmentRules.CustomOrderBrands) {
                            angular.forEach(self.rsBrands, function (b) {
                                switch (b.name) {
                                    case "Jumbo":
                                        b.name = '1-' + b.name;
                                        break;
                                    case "Metro":
                                        b.name = '2-' + b.name;
                                        break;
                                    case "Metro Express":
                                        b.name = '3-' + b.name;
                                        break;
                                    case "Metro Express Caf\u00E9":
                                        b.name = '4-' + b.name;
                                        break;
                                    case "Metro Discount":
                                        b.name = '5-' + b.name;
                                        break;
                                    case "Jumbo E-Commerce":
                                        b.name = '6-' + b.name;
                                        break;
                                    case "Jumbo E-Commerce":
                                        b.name = '7-' + b.name;
                                        break;
                                }
                            });
                        }

                        //Cargo los Roles y Estrategias para el combo
                        assortmentService.categoryRoleStrategyCoverages.getStrategyAndRoles()
                            .then(function (rolesAndStrategies) {
                                self.roles = [];
                                self.strategies = [];

                                angular.forEach(rolesAndStrategies, function (rs) {
                                    if (rs.isRole)
                                        self.roles.push({ key: rs.key, value: rs.value });
                                    else
                                        self.strategies.push({ key: rs.key, value: rs.value });
                                });
                            });
                    }

                    self.changeBrand(self.selectedBrand);
                });



        }

        var translations = null;

        self.monthsForCalculations = [
            { value: 3, text: 'Ultimos 3 meses' }
            , { value: 6, text: 'Ultimos 6 meses' }
            , { value: 12, text: 'Ultimos 12 meses' }
            , { value: 0, text: 'Personalizado' }
        ];

        self.dateEditor = function (params) {

            var editing = false;

            var eCell = document.createElement('div');
            eCell.style = "width:100%;height:100%; cursor: pointer;"
            var eLabel = document.createTextNode(self.dateRenderer(params));
            eCell.appendChild(eLabel);

            var eInput = document.createElement("input");
            eInput.style = "width:135px;"
            eInput.type = "date";

            eCell.addEventListener('click', function () {
                if (!editing) {
                    eCell.removeChild(eLabel);
                    eCell.appendChild(eInput);
                    eInput.focus();
                    eInput.value = params.value;
                    editing = true;
                    
                }
            });

            eInput.addEventListener('blur', function () {
                if (editing) {
                    editing = false;
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);
                }
            });

            eInput.addEventListener('change', function () {
                if (editing) {
                    editing = false;
                    var newValue = eInput.value;
                    params.data[params.colDef.field] = newValue;
                    params.data["dirty"] = true;
                    params.value = newValue;
                    eLabel.nodeValue = $rootScope.dateRenderer(params);
                    eCell.removeChild(eInput);
                    eCell.appendChild(eLabel);

                    if (params.node != null && params.node.children != null) {
                        var changedRows = [];
                        var colIds = [];
                        colIds.push(params.column.colId);
                        params.node.children.forEach(function (node) {
                            if (node.children) {
                                node.children.forEach(function (node) {
                                    node.data[params.column.colId] = params.value;
                                    node.data.dirty = true;
                                    changedRows.push(node);
                                });
                            }
                            node.data[params.column.colId] = params.value;
                            node.data.dirty = true;
                            changedRows.push(node);
                        });
                        params.api.refreshCells(changedRows, colIds);
                    }
                }
            });

            return eCell;
        }

        self.dateRenderer = function (params) {
            if (params.value == null)
                return "_______________________"; //'<i class="fa fa-calendar"/>'

            return new Date(params.value).toISOString().slice(0, 10);
        }

        function init() {
            changeStrategyOnColumnGroupShow();
            setGridOptions();

            $translate(["DiscardConfirmTitle", "AreYouSureSwalSubtitle", "ContinueSwal", "CancelSwal", "ConfirmedSwal", "ConfirmedSwalSubtitle"
                , "ErrorTitleSwal", "ErrorSwalSubtitle", "NoChangesWereRecorded", "SumErrorOfWeightedIndexes", "PendingChangesWithoutSaving"
                , "TheChangesYouHaveMadeInRoleWillBeLost", "Discard", "Category", "ClickToEnterCategory", "RoleAndStrategy", "Role"
                , "Strategy", "Coverage", "WeightedIndex", "Sales", "Units", "MarginInDollars", "MarketSales", "MarketUnits", "AnalysisSchedule"
                , "StartDate", "Frequency", "LastRevision", "InDays", "MonthsForCalculation", "AssortmentStrategyAndRulesSubtitle"])
                .then(function (all) {
                    translations = all;

                    self.subtitle = translations.AssortmentStrategyAndRulesSubtitle;

                    setColumnDefinitions();

                    self.gridOptions.api.setColumnDefs(columnDefs);
                });

            //init columnDefs and gridOptions

            loadData();
        }

        init();
    });

