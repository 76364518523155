angular.module("prisma")
 .controller("AbTestingCtrl", function ($scope, $translate, $resource, $rootScope, $window, $timeout, $state, priceService, adminService, authService) {
     var self = this;

     self.stores = [];
     self.storeZoning = [];
     //self.items = undefined;
     self.categories = [];
     self.expectedActionsArray = [];

     self.selectedAction;
     self.selectedActionType;
     self.selectedItem;
     self.selectedStoreBase;
     self.selectedStoreMirror;
     self.selectedCategory;
     self.expectedAction;
     self.companyName = $rootScope.erp.Implementation;

     self.actionTypesArray = [{
         id: 1, text: '$', hyphotesisText: '$'
     }, {
         id: 2, text: '%', hyphotesisText: '%'
     }]


     
     function calculateNewPrice(oldPrice, changeType, changeAmount, changeAction) {
         //console.log('oldPrice', oldPrice);
         //console.log('changeType', changeType);
         //console.log('changeAmount', changeAmount);
         //console.log('changeAction', changeAction);
         if(changeType == 1) { //$
             return changeAction == 1 ? oldPrice - changeAmount : oldPrice + changeAmount ;
         }

         return changeAction == 1 ? oldPrice - (oldPrice * changeAmount / 100) : oldPrice + (oldPrice * changeAmount / 100);

     }


     self.itemText = '';
     self.getItems = function (val) {
         self.itemText = val;
         if (self.selectedCategory == undefined) return;
         if (val.length < 4) return;

         var lookupParameter = { searchTerm: val, categoryIds: self.categoryIds };
         return priceService.abTesting.itemsLookupByCategory(lookupParameter, self.selectedCategory.id)
         .then(function (items) {
             return items;
         });
     };
     self.hypothesis = '';
     self.formHyphotesis = function () {
         try {

             var ret = translations.IfHyphotesis + self.selectedAction.hyphotesisText + translations.ThePriceOfHyphotesis + self.selectedItem.name + translations.InHyphotesis;

             if (self.selectedActionType.id === 1)
                 ret += "$";

             ret += self.actionValue;

             if (self.selectedActionType.id === 2)
                 ret += "%";

             ret += translations.TheDemandIsExpectedHyphotesis;
             if (self.expectedAction.id == 2)
                 ret += self.expectedAction.hyphotesisText;
             else
                 ret += self.expectedAction.hyphotesisText + translations.AHyphotesis + (self.expectedValue == undefined ? '' : self.expectedValue) + "%";

             self.hypothesis = ret;
             return ret;

         } catch (e) {
             return translations.SelectFieldsToElaborateAnHypothesis;
         }
     }

     self.categoryChange = function () {

         self.selectedItem = undefined;
         //self.items = [];
     }

     self.canSaveTesting = function () {
         //console.log("selectedItem", self.selectedItem);
         if (self.selectedItem == undefined)
             return false;
         //console.log(self.selectedItem);

         //console.log("selectedAction", self.selectedAction);
         if (self.selectedAction == undefined)
             return false;

         //console.log("selectedActionType", self.selectedActionType);
         if (self.selectedActionType == undefined)
             return false;

         //console.log("selectedStoreBase", self.selectedStoreBase);
         if (self.selectedStoreBase == undefined)
             return false;

         //console.log("selectedStoreMirror", self.selectedStoreMirror);
         if (self.selectedStoreMirror == undefined)
             return false;

         //console.log("selectedCategory", self.selectedCategory);
         if (self.selectedCategory == undefined)
             return false;

         //console.log("validFrom", self.validFrom);
         if (self.validFrom == undefined)
             return false;

         //console.log("validTo", self.validTo);
         if (self.validTo == undefined)
             return false;

         //console.log("actionValue", self.actionValue);
         if (self.actionValue == undefined || self.actionValue == '')
             return false;

         //console.log("expectedAction", self.expectedAction);
         if (self.expectedAction == undefined)
             return false;

         //console.log("expectedValue", self.expectedValue);
         if ((self.expectedValue == undefined || self.expectedValue == '') && self.expectedAction.id != 2)
             return false;

         if (self.description == undefined || self.description == '')
             return false;

         return true;
     }


     function validForm() {
         if (self.selectedStoreBase.id === self.selectedStoreMirror.id) {
             swal(translations.IncorrectData, translations.ValidationMirrorStoreAndBaseStore, "info");
             return false;
         }

         if (self.validTo < self.validFrom) {
             swal(translations.IncorrectData, translations.InvalidDateRange, "info");
             return false;
         }

                 
         if (self.currentPrice == undefined) {
             swal(translations.Priceless, translations.ValidationSelectedStoreNotHaveAPrice, "error");
             return false;
         }


         return true;
     }


     self.saveTesting = function () {
         if (!validForm()) return;


         swal({
             title: translations.DiscardConfirmTitle,
             text: translations.AreYouSureSwalSubtitle,
             type: "info",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: translations.ContinueSwal,
             cancelButtonText: translations.CancelSwal,
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
            function () {
                //function calculateNewPrice(oldPrice,changeType,changeAmount,changeAction) {

                var data = {
                    id: 0,
                    hypothesis: self.hypothesis,
                    description: self.description,
                    storeBaseId: self.selectedStoreBase.id,
                    storeBaseCode: self.selectedStoreBase.code,
                    storeMirrorId: self.selectedStoreMirror.id,
                    storeMirrorCode: self.selectedStoreMirror.code,
                    itemId: self.selectedItem.id,
                    itemCode: self.selectedItem.code,
                    itemUomId: self.selectedItem.saleUnitOfMeasureId,
                    itemUomCode: self.selectedItem.saleUnitOfMeasureCode,
                    changeInPriceAction: self.selectedAction.id,
                    changeInPriceType: self.selectedActionType.id,
                    changeInPrice: self.actionValue,
                    newPrice: calculateNewPrice(self.currentPrice, self.selectedActionType.id, self.actionValue, self.selectedAction),
                    validFrom: self.validFrom,
                    validTo: self.validTo,
                    expectedChangeInDemand: self.expectedValue,
                    expectedChangeInDemandAction: self.expectedAction.id,
                    observations: self.observations
                }
                console.log(data);
                priceService.abTesting.saveAbTesting(data).then(function (response) {
                    //console.log(response);
                    swal(translations.ConfirmedSwal, translations.ConfirmedSwalSubtitle, "success");
                    $state.go('prices.abTestingIndex');

                }).catch(function (fallback) {
                    console.log(fallback);
                    setTimeout(function () { swal(translations.ErrorTitleSwal, translations.ErrorSwalSubtitle, "error"); }, 100);
                });;



            });

     }

     function getPrice() {
         priceService.abTesting.getCurrentPrice(self.selectedStoreBase.id, self.selectedItem.id).then(function (res) {
             console.log('res', res);
             if (res == undefined || res == null || res == '') {
                 swal(translations.Priceless, translations.ValidationSelectedStoreNotHaveAPrice, "error");
                 self.currentPrice = undefined;
             } else {
                 self.currentPrice = res;
             }
             console.log(res);
         }, function (err) {
             console.log(err);
         })
     }

     self.storeBaseChange = function () {
         console.log("self.selectedItem", self.selectedItem);

         if (self.selectedItem === null || typeof self.selectedItem !== 'object')
             return;

         getPrice();
     }

     self.onItemSelect = function () {
         console.log("selectedStoreBase", self.selectedStoreBase);

         if (self.selectedStoreBase == undefined)
             return;

         getPrice();
     }

     self.selectStore = function (store, type) {
         //de-seleccion de otra tienda base o espejo
         self.storeZoning.forEach(function (d) {
             d.children.forEach(function (c) {
                 c.children.forEach(function (s) {
                     if (s.id != store.id) {
                         s.isBase = type == 'base' ? false : s.isBase;
                         s.isMirror = type == 'mirror' ? false : s.isMirror;
                     }
                 });
             });
         });

         //seleccion o de-seleccion de tienda base o espejo
         if (type == 'base') {
             self.selectedStoreBase = store.isBase ? store : null;
         }
         else if (type == 'mirror') {
             self.selectedStoreMirror = store.isMirror ? store : null;
         }

         self.storeBaseChange();         
     }

     var translations = null;

     function init() {

         $translate(["ValidationMirrorStoreAndBaseStore", "ValidationSelectedStoreNotHaveAPrice", "SelectFieldsToElaborateAnHypothesis"
             , "IncorrectData", "Priceless", "DiscardConfirmTitle", "AreYouSureSwalSubtitle", "ContinueSwal", "CancelSwal", "ConfirmedSwal"
             , "ConfirmedSwalSubtitle", "ErrorTitleSwal", "ErrorSwalSubtitle", "LowerPrice", "IncreasePrice", "LowM", "Rise", "RiseA"
             , "StaysTheSame", "Drops", "IfHyphotesis", "ThePriceOfHyphotesis", "InHyphotesis", "TheDemandIsExpectedHyphotesis", "AHyphotesis"])
             
             .then(function (all) {
                 translations = all;

                 self.actionsArray = [{
                     id: 1, text: translations.LowerPrice, hyphotesisText: translations.LowM
                 }, {
                         id: 3, text: translations.IncreasePrice, hyphotesisText: translations.Rise
                 }];

                 self.expectedActionsArray = [{
                     id: 3, text: translations.RiseA, hyphotesisText: translations.RiseA
                 }, {
                         id: 2, text: translations.StaysTheSame, hyphotesisText: translations.StaysTheSame
                 }, {
                         id: 1, text: translations.Drops, hyphotesisText: translations.Drops
                 }];

             });

         if (self.companyName == 'LaRebaja') {
             //arbol
             adminService.stores.getStoresZoning().then(function (values) {
                 self.storeZoning = values;
             });
         }

        priceService.abTesting.getStoresLookup(authService.authentication.companyId).then(function (values) {
            self.stores = values;
        });
         

         priceService.abTesting.getCategories().then(function (values) {
             self.categories = values;

         });
     }

     init();
 });
