
angular.module("prisma")
    .controller("ImporterCtrl", function ($scope, $log, $rootScope, $state, authService, adminService, serverService, priceService, Upload, ngDialog, $translate) {
        var self = this;

        self.importerType = null;
        self.importerName = null;
        self.importerServiceName = null;
        self.currentStep = 1;
        self.gridVisible = false;
        self.filesUploaded = false;
        self.isBusy = false;
        self.isBusy2 = false;
        self.isBusy3 = false;
        self.isBusy4 = false;
        self.error = false;
        self.files = null;
        self.gridOptions = null;
        self.showCategorySwal = false;
        self.allCategories = [];
        self.allSubCategories = [];
        self.mustShowCategorySwal = false;
        var columnDefs = null;
        self.gridOptions = null;

        self.finished1 = false;
        self.finished2 = false;
        self.finished3 = false;
        self.finished4 = false;

        self.runStep = function (args) {
            self.gridVisible = false;
            switch (self.currentStep) {
                case 1: { self.uploadFiles(); break; }
                case 2: { self.processFiles(); break; }
                case 3: { self.validateDataQuality(); break; }
                case 4: { self.importData(); break; }
            }
        }

        self.uploadFiles = function () {
            var files = self.files;
            if (files && files.length) {
                self.isBusy = true;
                var url = ian.urlCombine(serverService.getApiUrl(), '/admin/importer/uploadFile', authService.authentication.companyId, self.importerType);
                Upload.upload({
                    url: url,
                    data: { files: files }
                }).then(function (response) {
                    var importerResults = response.data;
                    processImporterResults(importerResults);
                    if (!anyError(importerResults))
                        self.filesUploaded = true;
                });
            }
        }

        self.processFiles = function () {
            self.isBusy2 = true;
            adminService.importers.processFiles(authService.authentication.companyId, self.importerType).then(function (importerResults) {
                processImporterResults(importerResults);
            });
        }

        self.validateDataQuality = function () {
            self.isBusy3 = true;
            adminService.importers.validateDataQuality(authService.authentication.companyId, self.importerType).then(function (importerResults) {
                processImporterResults(importerResults);
                if (ngDialog != null)
                    ngDialog.close();
            });
        }

        self.importData = function () {
            self.isBusy4 = true;
            if (self.importerType == "PriceSurveys") {
                swal("Subiendo Reporte", "Se está terminando de importar el relevamiento y generar las sugerencias, cuando finalice recibirá un Email notificandolo.", "success")
                self.isBusy4 = false;
                self.finished4 = true;
                self.currentStep = 1;
            }
            if (self.importerType == "MarketData") {
                swal("Subiendo Reporte", "Se está terminando de importar la información, cuando finalice recibirá un Email notificandolo.", "success")
                self.isBusy4 = false;
                self.finished4 = true;
                self.currentStep = 1;
            }
            if (self.importerType == "StoreClusterCategory") {

                swal("Reporte subido correctamente", "Estos cambios se verán reflejados mañana luego de que Prisma actualice los datos.", "success")
                self.isBusy4 = false;
                self.finished4 = true;
                self.currentStep = 1;
            }

            adminService.importers.importData(authService.authentication.companyId, self.importerType).then(function (importerResults) {
                processImporterResults(importerResults);
                //if (self.importerType == "PriceSurveys") //Se está haciendo en el paso 4 automaticamente, al hacerlo aca si no vuelve a la pantalla, no se generan las sugerencias. 
                //    priceService.suggestion.generateSuggestionsForAllCategories();
            });

        }

        self.finish = function () {

            if (self.importerType == "OxxoCosts")
                $state.go('prices.costs');


            self.currentStep = 1;
            loadResults([]);
            self.files = null;
            self.filesUploaded = false;
            self.finished1 = false;
            self.finished2 = false;
            self.finished3 = false;
            self.finished4 = false;
        }

        self.exportToExcel = function () {
            var params = {
                skipHeader: false,
                skipFooters: false,
                skipGroups: false,
                allColumns: true,
                onlySelected: false,
                suppressQuotes: false,
                fileName: self.categoryGroup + '.xls',
                columnSeparator: ','
            };

            self.gridOptions.api.exportDataAsCsv(params);
        }

        self.showNextStep = function () {
            if (self.currentStep > 0 && self.currentStep < 4) {
                self.currentStep++;
                loadResults([]);
            }
        }

        self.addCategoryConfirm = function () {
            swal({
                title: translations.NoCategoriesFoundForAllSKUs,
                text: translations.YouWantToAddThem,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        swal.close();
                        openCategoriesDialog();
                    }
                });
        }


        function openCategoriesDialog() {
            var newScope = $scope.$new();
            newScope.showLoader = true;

            adminService.categories.getCategories().then(function (allCategories) {
                self.allCategories = allCategories;


                adminService.categories.getSubCategories().then(function (allSubCategories) {
                    self.allSubCategories = allSubCategories;
                    adminService.items.getNoCategoryItems()
                        .then(function (items) {
                            newScope.showLoader = false;
                            newScope.items = items;
                            newScope.allCategories = angular.copy(self.allCategories);
                            newScope.allSubCategories = angular.copy(self.allSubCategories);
                            newScope.filteredSubcategories = angular.copy(self.allSubCategories);
                            newScope.selectedCategory = null;
                            newScope.selectedSubCategory = null;
                            newScope.saving = false;
                            newScope.filterText = '';

                            newScope.filterSubCategories = function (category) {
                                if (category != null) {
                                    newScope.filteredSubcategories = [];
                                    angular.forEach(newScope.allSubCategories, function (sc) {
                                        if (sc.parentId == category.id)
                                            newScope.filteredSubcategories.push(angular.copy(sc));
                                    });
                                }
                            }

                            newScope.saveCategories = function () {

                                if (this.selectedSubCategory != null) {
                                    newScope.saving = true;
                                    var itemEansToAplyCategory = []

                                    angular.forEach(newScope.items, function (i) {
                                        if (i.selected)
                                            itemEansToAplyCategory.push(angular.copy(i.ean));
                                    });

                                    adminService.items.saveItemCategories(this.selectedSubCategory.categoryId, this.selectedSubCategory.id, itemEansToAplyCategory)
                                        .then(function (pendingItemsWithNoCategory) {
                                            //Si no queda ninguno vuelvo a correr el paso 3 y cierro el dialog.
                                            if (pendingItemsWithNoCategory.length == 0) {
                                                swal(translations.ValidationWillRunAgain, "", "success");
                                                self.showCategorySwal = false;
                                                self.validateDataQuality();
                                            }
                                            else {
                                                newScope.items = pendingItemsWithNoCategory;
                                            }
                                            newScope.saving = false;
                                        },
                                            function () {
                                                newScope.saving = false;
                                            });
                                }
                            }

                            newScope.selectFilteredItems = function (filteredItems) {
                                angular.forEach(filteredItems, function (fi) {
                                    fi.selected = true;
                                });
                            }
                        });
                });
            });

            ngDialog.open({
                template: 'categoriesDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom custom-height-500 custom-width-1124 categoriesDialog',
                backdrop: 'static',
                keyboard: false,
                closeByDocument: false,
                scope: newScope
            });
        }

        function loadResults(results) {
            self.gridOptions.api.setRowData(results);
            self.gridOptions.api.refreshView();

            self.gridVisible = results.length > 0 ? true : false;
        }

        function processImporterResults(importerResults) {
            self.isBusy = false;
            self.isBusy2 = false;
            self.isBusy3 = false;
            self.isBusy4 = false;

            self["finished" + self.currentStep] = true;

            if (importerResults.length > 0) {
                //self.gridOptions.groupDefaultExpanded = importerResults.length < 10 ? 1 : 0;
                loadResults(importerResults);
            }
            if (anyError(importerResults)) {
                self.error = true;
            }
            else
                self.error = false;
        }

        function anyError(importerResults) {
            var error = false;
            for (var i = 0; i < importerResults.length; i++) {
                if (importerResults[i].severity === 'Error')
                    error = true;
            }

            return error;
        }

        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: translations.Severity,
                    width: 100,
                    field: 'severity',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                    rowGroupIndex: 0,
                    cellRenderer: function (params) {
                        var severityName = '';
                        switch (params.value) {
                            case 'Information': { severityName = '<i style="color: #6aa4ff;" class="fa fa-info-circle" aria-hidden="true"></i> Info'; break; }
                            case 'Error': { severityName = '<i style="color: #ea2323;" class="fa fa-stop-circle" aria-hidden="true"></i> Error'; break; }
                            case 'Warning':
                                {
                                    if (params.node.data != null && params.node.data.phase == 'ValidateDataQuality' && params.node.data.category == 'VDQ_CategoryNotFound' && !self.showCategorySwal && self.mustShowCategorySwal) {
                                        self.addCategoryConfirm();
                                        self.showCategorySwal = true;
                                    }
                                    severityName = '<i style="color: #e4e400;" class="fa fa-exclamation-circle" aria-hidden="true"></i> Warn'; break;
                                }
                        }
                        return severityName;
                    }
                },
                {
                    headerName: translations.Phase,
                    width: 100,
                    field: 'phase',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false
                },
                {
                    headerName: translations.File,
                    width: 50,
                    field: 'row',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                },
                {
                    headerName: translations.Category,
                    width: 200,
                    rowGroupIndex: 1,
                    field: 'category',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                },
                {
                    headerName: translations.Description,
                    width: 500,
                    field: 'description',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                },
            ]
        }

        function setGridOptions() {

            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                rowHeight: 35,
                suppressCellSelection: true,
                groupUseEntireRow: true,
                groupSuppressAutoColumn: false,
                groupDefaultExpanded: 0,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
            }
        }

        self.clearAllSensitivities = function () {
            swal({
                title: translations.WarningM,
                text: translations.AllSensitivityRecordsWillBeDeleted,
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: translations.ContinueSwal,
                cancelButtonText: translations.CancelSwal,
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true,
                imageUrl: "../../../assets/images/icons/icon_warning.png",
            }, function (isConfirm) {
                if (isConfirm) {
                    adminService.importers.removeAllSensitivities()
                        .then(function (ok) {
                            swal(translations.Success, translations.NoArticleInAnyAreaHasSensitivity, "success");
                        });
                }
            });
        }

        // Segun el importerType consulta en base de datos si existen datos pendientes previamente cargados
        // Si es el caso presenta un popup anunciando lo mismo y habilita el procesp para terminar de agregar los datos especificados.
        function hasPendingData(importerType) {
            const methodName = 'ImporterCtrl::hasPendingData';
            //$log.debug(`${methodName} importerType %o`, importerType);

            var fileName = "";

            // Consulta en base de datos si existe un archivo precargado y no finalizado segun su importerType.
            adminService.importers.getLastUploadedFile(importerType).then(function (lastFileName) {
                //$log.debug(`${methodName} (then) lastFileName`, lastFileName);

                fileName = lastFileName;

                // Evalua si el resultado de la busqueda posee contenido, de ser asi renderiza el popup con el warning.
                if (fileName !== null && fileName != "") {
                    const pendingFilesAlert = function () {
                        swal({
                            title: translations.WarningM,
                            text: translations.ItHasPendingFiles,
                            showCancelButton: true,
                            confirmButtonColor: "#1AB394",
                            confirmButtonText: translations.Accept,
                            cancelButtonText: translations.Cancel,
                            showLoaderOnConfirm: true,
                            closeOnConfirm: true,
                            closeOnCancel: true,
                            imageUrl: "../../../assets/images/icons/icon_warning.png",
                        }, function (isConfirm) {
                            if (isConfirm) {
                                //$log.debug(`${methodName} (confirm) %o`, isConfirm);

                                // Setea el currentstep al nivel 3.
                                self.currentStep = 3;

                                // Ejecuta el proceso que corre los steps.
                                self.runStep();
                            }
                        });
                    }

                    pendingFilesAlert();
                }
            })
                .catch(function (ex) {
                    //$log.debug(`${methodName} (catch) %o`, ex);
                });
        }

        var translations = null;

        function init() {

            $translate(["StepOneOp1Importers", "StepOneOp2Importers", "IntroductionToTheStepsImporters", "RemainingStepsImporters", "NoCategoriesFoundForAllSKUs", "YouWantToAddThem"
                , "ContinueSwal", "CancelSwal", "ValidationWillRunAgain", "Severity", "Phase", "File", "Category", "Description", "WarningM", "AllSensitivityRecordsWillBeDeleted", "Success"
                , "NoArticleInAnyAreaHasSensitivity", "ItHasPendingFiles", "Accept", "TheImporterTypeParameterHasNotBeenReceived", "MarketData", "CompetitivePricesSurvey", "Spaces"
                , "NewPrices", "MassiveLoadOfCosts", "Sensitivity", "BulkLoadingOfLabels", "ManualLiquidations", "LiquidationsByMaturity", "CancelLiquidations", "UpdateFieldsOfMeasurementUnits"])
                .then(function (all) {
                    translations = all;

                    var stepOne = $state.params.importerType == "ItemSensitivityPriceLists" ?
                        translations.StepOneOp1Importers
                        : translations.StepOneOp2Importers;

                    var steps = translations.IntroductionToTheStepsImporters + stepOne + translations.RemainingStepsImporters;

                    $('#steps').html(steps);

                    //defino titulo
                    switch (self.importerType) {
                        case "MarketData": { self.importerName = translations.MarketData; self.mustShowCategorySwal = true; break; }
                        case "PriceSurveys": self.importerName = translations.CompetitivePricesSurvey; break;
                        case "Spaces": self.importerName = translations.Spaces; break;
                        case "NewPrices": self.importerName = translations.NewPrices; break;
                        case "OxxoCosts": self.importerName = translations.MassiveLoadOfCosts; break;
                        case "ItemSensitivityPriceLists": self.importerName = translations.Sensitivity; break;
                        case "ItemTags": self.importerName = translations.BulkLoadingOfLabels; break;
                        case "HandMadeMarkdowns": self.importerName = translations.ManualLiquidations; break;
                        case "ExpirationMarkdowns": self.importerName = translations.LiquidationsByMaturity; break;
                        case "UndoMarkdowns": self.importerName = translations.CancelLiquidations; break;
                        case "EditUnitOfMeasures": self.importerName = translations.UpdateFieldsOfMeasurementUnits; break;
                    }

                    setColumnDefinitions();

                    self.gridOptions.api.setColumnDefs(columnDefs);

                });

            $log.debug('ImporterCtrl::init')

            //validaciones
            if (!$state.params.importerType) {
                alert(translations.TheImporterTypeParameterHasNotBeenReceived);
                $rootScope.goBack();
            }

            //defino el tipo de importador
            self.importerType = $state.params.importerType;

            //inicializo la grilla
            setGridOptions();

            // Valida si existen datos previamente cargados por completar.
            hasPendingData(self.importerType);
        }

        init();
    });