
'use strinct';
(function () {
    // Identificador unico para las directivas draggeables.
    var uid = 0;

    angular.module('prisma')
        .directive('planogramItemGroup2', ['$log', planogramItemGroup2Directive])
        .controller('planogramItemGroup2Controller', ['$log', '$element', 'DnDService', 'SvgService', planogramItemGroup2Controller]);

    function planogramItemGroup2Directive($log) {
        // $log.debug('planogramItemGroup2Directive::ctor');
        return {
            restrict: 'A',
            selector: '[planogram-item-group2]',
            templateUrl: '/app/spaces/planogram/planogram-item-group2.view.html',
            scope: {
                model: '<'
            },
            require: {
                planogram: '^^planogram'
            },
            controller: 'planogramItemGroup2Controller',
            controllerAs: '$ctrl',
            bindToController: true
        }
    }

    function planogramItemGroup2Controller($log, $element, dnDService, svgService) {
        // $log.debug('planogramItemGroup2Controller::ctor');
        var self = this;

        self.$onInit = function () {
            // $log.debug('planogramItemGroup2Controller::$onInit');
        }
        self.$onChanges = function $onChanges(changesObj) {
            if (changesObj.model) {
                self.model = changesObj.model.currentValue;
                self.model.itemSketchText = self.getItemSketchText();
                self.model.groupSketchText = self.getGroupSketchText();
                self.model.groupSketchTextY = self.getGroupSketchTextY();
                self.model.groupSketchTextLength = self.getGroupSketchTextLength();
                self.model.groupSketchTextTransform = self.getGroupSketchTextTransform();
                self.model.hitAreaY = self.getHitAreaY();
                self.model.hitAreaWidth = self.getHitAreaWidth();
                self.model.hitAreaHeight = self.getHitAreaHeight();
                self.model.labelInfo = self.getLabelInfo();
                self.model.label1 = self.getLabel1();
                self.model.label2 = self.getLabel2();
                self.model.opacity = self.getOpacity();

                angular.forEach(self.model.items,
                    function (item) {
                        item.itemSketchTextLength = self.getItemSketchTextLength(item);
                        item.sketchTextY = self.getItemSketchTextY(item);
                        item.itemSketchTextTransform = self.getItemSketchTextTransform(item);
                    });
            }
        }

        self.$onDestroy = function () {
            // $log.debug('planogramItemGroup2Controller::$onDestroy');
            self.planogram = null;
        }

        self.getModel = function getModel() {
            return self.model.$$original;
        };

        self.showSketch = function showSketch() {
            var ret = self.planogram.showSketch();
            return ret;
        };

        self.getLabelInfo = function getLabelInfo() {
            var aux = self.planogram.labelInfoGetter(self.model.$$original);
            var ret = self.fixLabel(aux);
            return ret;
        };

        /**
         * Devuelve el texto del label 1 del item group evaluando la expresi�n que se configuro.
         * @returns El texto del label 1 del item group.
         */
        self.getLabel1 = function getLabel1() {
            var aux = self.planogram.label1Getter(self.model.$$original);
            var ret = self.fixLabel(aux);
            return ret;
        };

        /**
         * Devuelve el texto del label 2 del item group evaluando la expresi�n que se configuro.
         * @returns El texto del label 2 del item group.
         */
        self.getLabel2 = function getLabel2() {
            var aux = self.planogram.label2Getter(self.model);
            var ret = self.fixLabel(aux);
            return ret;
        };

        self.fixLabel = function fixLabel(value) {
            var ret = value;
            // Fix precios en null.
            if (angular.isString(ret) && ret.trim() === '$ null') {
                ret = '';
            }

            return ret;
        }

        self.getItemSketchTextTransform = function getItemSketchTextTransform(item) {
            var ret;
            if (self.model.itemWidth > self.model.itemHeight) {
                ret = 'translate( ' +
                    String(self.model.itemWidth / 2) +
                    ' ' +
                    String(self.model.itemHeight / 2) +
                    ' ) rotate( 360 )';
            } else {
                ret = 'rotate( 270 ) translate( -' + String(self.model.itemHeight / 2) + ' 0 )';
            }

            return ret;
        };

        self.getItemSketchTextY = function getItemSketchTextY(item) {
            var ret;
            if (self.model.itemWidth > self.model.itemHeight) {
                ret = 0;
            } else {
                ret = String(self.model.itemWidth / 2);
            }

            return ret;
        };

        self.getItemSketchTextLength = function getItemSketchTextLength(item) {
            var ret = Math.max(self.model.itemWidth, self.model.itemHeight);
            return ret;
        };

        self.getGroupSketchTextLength = function getGroupSketchTextLength() {
            var ret = Math.min(self.model.width, self.model.height);
            return ret;
        };

        self.getGroupSketchTextTransform = function getGroupSketchTextTransform() {
            var ret;
            if (self.model.width > self.model.height) {
                ret = 'translate( ' +
                    String(self.model.width / 2) +
                    ' ' +
                    String(self.model.height / 2) +
                    ' ) rotate( 360 )';
            } else {
                ret = 'rotate( 270 ) translate( -' + String(self.model.height / 2) + ' 0 )';
            }

            return ret;
        };

        self.getGroupSketchTextY = function getGroupSketchTextY() {
            var ret;
            if (self.model.width > self.model.height) {
                ret = 0;
            } else {
                ret = String(self.model.width / 2);
            }

            return ret;
        };

        self.getItemSketchText = function getItemSketchText() {
            var ret = self.getLabel1();
            return ret;
        };

        self.getGroupSketchText = function getGroupSketchText() {
            var ret = self.getLabel1();
            return ret;
        };

        self.getItemSketchTextWidth = function getItemSketchTextWidth() {
            return Math.max(self.model.itemWidth, self.model.itemHeight);
        }

        self.getGroupSketchTextWidth = function getGroupSketchTextWidth() {
            return Math.max(self.model.width, self.model.height);
        }

        /**
         * Devuelve el valor de la opacidad.
         */
        self.getOpacity = function getOpacity() {
            var ret;
            var filtered = self.planogram.hasFiltered() &&
                angular.isDefined(self.model.filtered) &&
                self.model.filtered;
            var selected = self.planogram.hasSelected() &&
                angular.isDefined(self.model.selected) &&
                self.model.selected;
            var allowEditStructure = self.planogram.allowEditStructure;
            if ((filtered || selected) && !allowEditStructure) {
                ret = 1.0;
            } else if (self.planogram.hasFiltered() && !allowEditStructure) {
                ret = 0.14;
            } else if (self.planogram.hasSelected() && !allowEditStructure) {
                ret = 0.75;
            } else if (allowEditStructure) {
                ret = 0.14;
            } else {
                ret = 1.0;
            }

            return ret;
        };

        self.getLabelInfoClass = function getLabelInfoClass() {
            // Workaround: queda prendida la clase para dar el color rojo.
            planogramItemGroup2Controller.labelInfoClasses = planogramItemGroup2Controller.labelInfoClasses || {};
            //var ret = angular.merge({}, planogramItemGroup2Controller.labelInfoClasses);
            var ret = angular.copy(planogramItemGroup2Controller.labelInfoClasses);
            var aux = self.planogram.labelInfoClassGetter(self.model.$$original);
            if (angular.isString(aux) && aux !== '') {
                ret[aux] = true;
                planogramItemGroup2Controller.labelInfoClasses[aux] = false;
            }

            return ret;
        };

        self.getHitAreaY = function getHitAreaY() {
            var ret;
            var type = self.getModuleType();
            if (type === 'Shelf') {
                ret = -Math.abs(self.model.y);
            } else if (type === 'Peg') {
                ret = 0;
            } else {
                ret = -Math.abs(self.model.y);
            }

            return ret;
        }

        /**
         * Devuelve verdadero si esta habilitado el Drag and Drop en el planograma, falso en caso contrario.
         * @returns Verdadero si esta habilitado el Drag and Drop en el planograma, falso en caso contrario.
         */
        self.getAllowDrag = function getAllowDrag() {
            var ret = self.planogram.allowDrag
                && !self.planogram.allowEditStructure;
            return ret;
        }

        /**
         * Devuelve el ancho para el area de hit del item.
         * @return El ancho para el area de hit del item.
         */
        self.getHitAreaWidth = function getHitAreaWidth() {
            var ret = self.model.width;
            return ret;
        }

        /*
         * Devuelve el alto para el area de hit del item seg�n el tipo de m�dulo donde se encuentre el item.
         * @return El alto para el area de hit del item seg�n el tipo de m�dulo donde se encuentre el item.
         */
        self.getHitAreaHeight = function getHitAreaHeight() {
            var ret;
            var type = self.getModuleType();
            if (type === 'Shelf') {
                ret = self.model.level.height;
            } else if (type === 'Peg') {
                ret = self.model.height;
            } else {
                ret = self.model.height;
            }

            return ret;
        }

        self.onDblClickHandler = function onDblClick($event) {
            // Fix issue performance planograma.
            // $log.debug('planogramItemGroup2Controller::onDblClickHandler %o', $event);
            self.planogram.onDblClick($event);
        };

        self.onHoldHandler = function onHoldHandler($event) {
            $event.preventDefault();
            $event.stopPropagation();

            // Fix issue performance planograma.
            // $log.debug('planogramItemGroup2Controller::onClickHandler %o', $event);
            //if (!dnDService.isOnDrag()) {
            var $svg = self.planogram.getSvg();
            var point = svgService.eventToPoint($svg, $element, $event);
            var place = svgService.getPlace(point);
            self.planogram.raiseOnClick({
                $event: $event,
                planogram: self.planogram.getModel(),
                column: self.model.column.$$original,
                module: self.model.module.$$original,
                level: self.model.level.$$original,
                item: self.getModel(),
                point: point,
                place: place
            });
            //}
        };

        self.onClickHandler = function onClickHandler($event) {

            // console.time('onClickHandler');

            // Fix issue performance planograma.
            // $log.debug('planogramItemGroup2Controller::onClickHandler %o', $event);

            //Dispara el cambio de seleccion si el item esta seleccionado o ya hay otro seleccionado.
            if (self.model.selected || self.planogram.hasSelected()) {
                var $svg = self.planogram.getSvg();
                var point = svgService.eventToPoint($svg, $element, $event);
                var place = svgService.getPlace(point);
                self.planogram.raiseOnClick({
                    $event: $event,
                    planogram: self.planogram.getModel(),
                    column: self.model.column.$$original,
                    module: self.model.module.$$original,
                    level: self.model.level.$$original,
                    item: self.getModel(),
                    point: point,
                    place: place
                });
            }

            // console.timeEnd('onClickHandler');
        };

        function onDragEnterAndOverHandler($event) {
            // Fix issue performance planograma.
            // $log.debug('planogramItemGroup2Controller::onDragEnterAndOverHandler %o', $event);
            if (dnDService.isOnDrag()) {
                var $svg = self.planogram.getSvg();
                var point = svgService.eventToPoint($svg, $element, $event);
                var place = svgService.getPlace(point);
                self.planogram.setOver({
                    $event: $event,
                    model: self.model,
                    point: point,
                    place: place
                });
            }
        };

        self.onDragEnterHandler = onDragEnterAndOverHandler;
        self.onDragOverHandler = onDragEnterAndOverHandler;

        self.onDragLeaveHandler = function onDragLeaveHandler($event) {
            // Fix issue performance planograma.
            // $log.debug('planogramItemGroup2Controller::onDragLeaveHandler %o', $event);
            if (dnDService.isOnDrag()) {
                self.planogram.removeOver();
            }
        };

        self.onDragEndHandler = function onDragEndHandler($event) {
            // Fix issue performance planograma.
            // $log.debug('planogramItemGroup2Controller::onDragLeaveHandler %o', $event);
            if (dnDService.isOnDrag()) {
                var $target = $element.find('.planogram-item-group.item.image:first');
                if ($target.length) {
                    svgService.removeClass($target, 'drag');

                }

                $element.removeClass('drag').css('opacity', 1);
            }
        };

        self.onDropHandler = function onDropHandler($event) {
            // Fix issue performance planograma.
            //$log.debug('planogramItemGroup2Controller::onDropHandler %o', $event);
            if (dnDService.isOnDrag()) {
                var $svg = self.planogram.getSvg();
                var point = svgService.eventToPoint($svg, $element, $event);
                var place = svgService.getPlace(point);
                self.planogram.raiseOnDrop({
                    $event: $event,
                    planogram: self.planogram.getModel(),
                    column: self.model.column.$$original,
                    module: self.model.module.$$original,
                    level: self.model.level.$$original,
                    item: self.getModel(),
                    point: point,
                    place: place
                });
                dnDService.setData(null);
                dnDService.setDirective(null);
                dnDService.setOffset(null);
                self.planogram.removeOver();
            }
        };

        self.getDraggableTarget = function getDraggableTarget($event) {
            // Link: http://www.petercollingridge.co.uk/tutorials/svg/interactive/dragging/
            var $svg = self.planogram.getSvg();
            var $target = angular.element($event.target).parent('g.planogram-item-group.draggable');
            var point = svgService.eventToPoint($svg, $target, $event);
            var $clone = $target.clone();
            var idAux = ++uid;
            var id = 'ian-draggable-target-' + String(idAux);
            $target.data('draggable', id);
            var selector = '#' + id;
            $target.addClass('ian-active-drag');
            $element.css('opacity', 0.5);
            var offset = self.getMousePosition($event, $svg);
            $clone.prop('id', id)
                .data('point', point)
                .data('dragAndDropRemoveAfterUse', false);
            svgService.addClass($clone, 'ian-dragging');
            $svg.append($clone);
            var cloneDom = $clone[0];

            // Get all the transforms currently on this element
            var transforms = cloneDom.transform.baseVal;

            // Ensure the first transform is a translate transform
            if (transforms.length === 0 ||
                transforms.getItem(0).type !== window.SVGTransform.SVG_TRANSFORM_TRANSLATE) {

                // Create an transform that translates by (0, 0)
                var translate = svg.createSVGTransform();
                translate.setTranslate(0, 0);

                // Add the translation to the front of the transforms list
                cloneDom.transform.baseVal.insertItemBefore(translate, 0);
            }

            // Get initial translation amount
            var transform = transforms.getItem(0);
            offset.x -= transform.matrix.e;
            offset.y -= transform.matrix.f;
            $element.data('offset', offset);
            dnDService.setOffset(offset);
            $clone.data('offset', offset)
                .data('transform', transform);

            return selector;
        }

        self.daggableMoveAt = function daggableMoveAt($event, x, y) {
            // Link: http://www.petercollingridge.co.uk/tutorials/svg/interactive/dragging/
            var $svg = self.planogram.getSvg();
            var $target = angular.element('.ian-active-drag');
            var id = $target.data('draggable');
            var selector = '#' + id;

            // var $draggable = angular.element(selector);
            var $draggable = angular.element('.ian-dragging');
            //$log.debug('planogramItemGroup2Controller::daggableMoveAt\n\t$event: %o\n\tx: %o\n\ty: %o\n\tselector: %o\n\t$target: %o',
            //    $event,
            //    x,
            //    y, selector, 
            //    $target);
            if ($draggable.length) {
                var coord = self.getMousePosition($event, $svg);
                var offset = $draggable.data('offset');
                var transforms = $draggable[0].transform.baseVal;
                var transform = transforms.getItem(0);
                transform.setTranslate(coord.x - offset.x, coord.y - offset.y);

                //$log.debug('planogramItemGroup2Controller::daggableMoveAt\n\t$event: %o\n\tx: %o\n\ty: %o\n\tcoord: %o, \n\toffset: %o',
                //    $event,
                //    x,
                //    y,
                //    coord,
                //    offset);
            }
        }

        /**
         * Devuelve la configuraci�n de la grilla para el snap en el drag and drop.
         */
        self.getSnapToGridConfig = function getSnapToGridConfig() {
            //: (Math.abs(module.gridWidth) || 1) * self.scaleFactor,
            //    gridHeight: (Math.abs(module.gridHeight) || 1) * self.scaleFactor,
            var ret;
            var type = self.getModuleType();

            if (type === 'Shelf') {
                ret = {
                    x: 1,
                    y: 1
                };
            } else if (type === 'Peg') {
                ret = {
                    x: self.model.module.gridWidth,
                    y: self.model.module.gridHeight
                };
            } else {
                ret = {
                    x: 1,
                    y: 1
                };
            }

            return ret;
        }

        /**
         * Devuelve el tipo de m�dulo que contiene el item.
         */
        self.getModuleType = function getModuleType() {
            var ret = self.model.module.type;
            return ret;
        }

        self.getMousePosition = function getMousePosition($event, $svg) {
            var screenCtm = $svg[0].getScreenCTM();
            if ($event.touches) {
                $event = $event.touches[0];
            }

            return {
                x: ($event.clientX - screenCtm.e) / screenCtm.a,
                y: ($event.clientY - screenCtm.f) / screenCtm.d
            };
        }

    }
})();