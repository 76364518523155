'use strinct';
(function () {
    angular.module('prisma').component('planogram', {
        templateUrl: '/app/spaces/planogram/planogram.view.html',
        bindings: {
            columnSpaceBetween: '<',
            data: '<model',
            allowDrag: '<',
            allowDrop: '<',
            allowEditStructure: '<',
            showDoor: '<',
            showItemSketch: '<',
            showGroupSketch: '<',
            showLabelInfo: '<',
            showLabel1: '<',
            showLabel2: '<',
            showOverlay: '<',
            showColumnId: '<',
            showDimensions: '<',
            moduleSpaceBetween: '<',
            levelSpaceBetween: '<',
            itemSpaceBetween: '<',
            shelfHeight: '<',
            scaleFactor: '<',
            label1: '<',
            label2: '<',
            labelInfo: '<',
            labelInfoClass: '<',
            labelHeight: '<',
            raiseOnClick: '&onClick',
            raiseOnDrop: '&onDrop',
            raiseOnRenderReady: '&onRenderReady',
            raiseOnChangeLevelHeight: '&onChangeLevelHeight',
            raiseOnDeleteLevel: '&onDeleteLevel',
            width: '<',
            showDropzone: '<',
            showModulesOrder: '<'

        },
        controller: 'planogramController',
        controllerAs: '$ctrl',
        bindToController: true
    }).controller('planogramController', ['$log', '$scope', '$q', '$element', '$timeout', '$filter', '$parse', '$animate', 'imageService', 'DnDService', 'SvgService', planogramController]);

    /**
     * Controllador del planograma.
     * @param {*} $log Servicio de log de angular.
     * @param {*} $q Servicio de promesas de angular 
     * @param {*} $element
     * @param {*} $timeout
     * @param {*} $filter
     * @param {*} $parse
     * @param {*} imageService
     * @param {*} dnDService
     * @param {*} svgService
     */
    function planogramController($log, $scope, $q, $element, $timeout, $filter, $parse, $animate, imageService, dnDService, svgService) {
        // Fix issue performance planograma.
        // $log.debug('planogramController::ctor');
        var self = this;
        var uid = 0;
        self.columnSpaceBetween = 5;
        self.moduleSpaceBetween = 1;
        self.levelSpaceBetween = 0;
        self.itemSpaceBetween = 0;
        self.shelfHeight = 16;
        self.planogramSpaceBetween = 50;
        self.allowDrag = false;
        self.allowDrop = false;
        self.scaleFactor = 10;
        self.moduleShelfSide = 25;
        self.moduleSpaceBetween = 0;
        self.label1Getter = angular.noop;
        self.label2Getter = angular.noop;
        self.labelInfoGetter = angular.noop;
        self.labelInfoClassGetter = angular.noop;
        self.labelHeight = 50;
        self.calculateCancel = null;
        self.showOverlay = false;
        self.width = '100%';
        self.showColumnId = true;
        self.renderTimes = 0;
        self.hash = 0;
        self.hasItemsSelected = false;
        self.hasItemsFiltered = false;
        self.showDropzone = false;
        self.showModulesOrder = false;

        // Nùmero magico de los svg originales.
        self.moduleShelfRefrigeratorTop = 186;
        self.moduleShelfRefrigeratorBottom = 300;
        self.drag = {
            selectedElement: null,
            offset: null,
            transform: null
        };
        self.isOver = false;
        self.dropIndicatorX = 0;
        self.dropIndicatorY = 0;
        self.overTarget = null;
        self.dropIndicatorWidth = 0;
        self.dropIndicatorHeight = 0;
        self.allowEditStructure = false;

        self.$onInit = function () {
            // Fix issue performance planograma.
            // $log.debug('planogramController::$onInit');
        };

        self.$onDestroy = function () {
            // Fix issue performance planograma.
            // $log.debug('planogramController::$onDestroy');
            delete self.columns;
        };

        self.register = function registerColumn(column) {
            // Fix issue performance planograma.
            // $log.debug('planogramController::register %o', column);
            self.columns.push(column);
            return function unregisterColumn() {
                // Fix issue performance planograma.
                // $log.debug('planogramController::unregister %o', column);
                var id = column.getId();
                self.columns = self.columns.filter(function (item) {
                    return item.getId() !== id;
                });
            }
        };

        self.$onInit = function () {
            $animate.enabled($element, false);
        }

        self.$onChanges = function $onChanges(changesObj) {
            // console.time('$onChanges');
            // Fix issue performance planograma.
            // $log.debug('planogramController::$onChanges %o', changesObj);
            var recalculate = false;

            if (changesObj.width
                /* && angular.isNumber(changesObj.width.currentValue) */) {
                self.width = changesObj.width.currentValue;
            }

            if (changesObj.columnSpaceBetween
                && angular.isNumber(changesObj.columnSpaceBetween.currentValue)) {
                self.columnSpaceBetween = changesObj.columnSpaceBetween.currentValue;
                recalculate = true;
            }

            if (changesObj.itemSpaceBetween
                && angular.isNumber(changesObj.itemSpaceBetween.currentValue)) {
                self.itemSpaceBetween = changesObj.itemSpaceBetween.currentValue;
                recalculate = true;
            }

            if (changesObj.levelSpaceBetween
                && angular.isNumber(changesObj.levelSpaceBetween.currentValue)) {
                self.levelSpaceBetween = changesObj.levelSpaceBetween.currentValue;
                recalculate = true;
            }

            if (changesObj.moduleSpaceBetween
                && angular.isNumber(changesObj.moduleSpaceBetween.currentValue)) {
                self.moduleSpaceBetween = changesObj.moduleSpaceBetween.currentValue;
                recalculate = true;
            }

            if (changesObj.scaleFactor
                && angular.isNumber(changesObj.scaleFactor.currentValue)) {
                self.scaleFactor = changesObj.scaleFactor.currentValue;
                recalculate = true;
            }

            if (changesObj.shelfHeight
                && angular.isNumber(changesObj.shelfHeight.currentValue)) {
                self.shelfHeight = changesObj.shelfHeight.currentValue;
                recalculate = true;
            }

            if (changesObj.showDoor) {
                self.showDoor = Boolean(changesObj.showDoor.currentValue);
                recalculate = true;
            }

            if (changesObj.showItemSketch) {
                self.showItemSketch = Boolean(changesObj.showItemSketch.currentValue);
                recalculate = true;
            }

            if (changesObj.showGroupSketch) {
                self.showGroupSketch = Boolean(changesObj.showGroupSketch.currentValue);
                recalculate = true;
            }

            if (changesObj.showOverlay) {
                self.showOverlay = Boolean(changesObj.showOverlay.currentValue);
            }

            if (changesObj.allowDrag) {
                self.allowDrag = Boolean(changesObj.allowDrag.currentValue);
            }

            if (changesObj.allowDrop) {
                self.allowDrop = Boolean(changesObj.allowDrop.currentValue);
            }

            if (changesObj.allowEditStructure) {
                self.allowEditStructure = Boolean(changesObj.allowEditStructure.currentValue);
            }

            if (changesObj.label1) {
                self.label1Getter = $parse(changesObj.label1.currentValue);
            }

            if (changesObj.label2) {
                self.label2Getter = $parse(changesObj.label2.currentValue);
            }

            if (changesObj.labelInfo) {
                self.labelInfoGetter = $parse(changesObj.labelInfo.currentValue);
            }

            if (changesObj.labelInfoClass) {
                self.labelInfoClassGetter = $parse(changesObj.labelInfoClass.currentValue);
            }

            if (changesObj.showLabel1) {
                self.showLabel1 = Boolean(changesObj.showLabel1.currentValue);
            }

            if (changesObj.showLabel2) {
                self.showLabel2 = Boolean(changesObj.showLabel2.currentValue);
            }

            if (changesObj.showLabelInfo) {
                self.showLabelInfo = Boolean(changesObj.showLabelInfo.currentValue);
            }

            if (changesObj.data) {
                self.data = changesObj.data.currentValue;
                recalculate = true;
            }

            if (changesObj.showColumnId) {
                self.showColumnId = Boolean(changesObj.showColumnId.currentValue);
            }

            if (changesObj.showDimensions) {
                self.showDimensions = Boolean(changesObj.showDimensions.currentValue);
            }

            if (changesObj.showDropzone) {
                self.showDropzone = Boolean(changesObj.showDropzone.currentValue);
            }

            if (changesObj.showModulesOrder) {
                self.showModulesOrder = Boolean(changesObj.showModulesOrder.currentValue);
            }

            if (recalculate) {

                self.hasItemsSelected = false;
                self.hasItemsFiltered = false;
                self.model = self.calculate(self.data);
                self.itemsGroup = self.getItemsGroup(self.model);
                self.levels = self.getLevels(self.model);
                self.modules = self.getModules(self.model);
                self.renderTimes += 1;
                $timeout().then(function () {
                    // Fix camelCase property interpolation.
                    var $svg = $element.find('svg');
                    $svg[0].setAttribute('viewBox', self.model.viewBox);

                    //// Elimina los watchers de los scopes hijos.
                    //iterateScopes($scope.$$childHead.$$childHead,
                    //    function clean$$watchers(scope) {
                    //        scope.$$watchers = [];
                    //        scope.$$watchersCount = 0;
                    //        scope.$watch = mockScopeWatch(scope.$id);
                    //    });
                });
            }
            else {
                $timeout(function () { });
            }

            // console.timeEnd('$onChanges');
        }

        self.calculate = function calculate(data) {
            // console.time('calculate');
            // Fix issue performance planograma.
            //$log.debug('planogramController::calculate %o', data);
            var planogram = null;
            if (data) {
                planogram = {
                    columns: [],
                    width: data.width * self.scaleFactor,
                    height: data.height * self.scaleFactor,
                    $$original: data
                };

                // Ordena las columnas.                 
                var columns = $filter('orderBy')(data.columns || [], 'order');

                // Recorre todas las columnas del planograma.
                var prevColumn = null;
                angular.forEach(columns, function (column, idx) {
                    var newColumn = self.calculateColumn(planogram, prevColumn, column, idx);

                    // Agrega la columna al modelo.
                    planogram.columns.push(newColumn);
                    prevColumn = newColumn;
                }); // forEach columns

                // ReSharper disable once UnusedParameter
                var planogramStructure = planogram.columns.reduce(function columnsReduce(prevColumn, column, idx, array) {
                    var ret;
                    if (prevColumn) {
                        ret = {
                            width: prevColumn.width + column.width + self.columnSpaceBetween * self.scaleFactor,
                            height: Math.max(prevColumn.height, column.height)
                        }
                    } else {
                        ret = {
                            width: column.width,
                            height: column.height
                        }
                    }

                    return ret;
                }, null);

                planogram.width = planogramStructure.width;
                planogram.height = planogramStructure.height;

                // Fix posición de las columnas para los casos en que tienen distintas alturas.
                angular.forEach(planogram.columns,
                    function (column) {
                        column.y = planogramStructure.height - column.height;
                    });

                planogram.viewBox = '0 0 ' + String(planogram.width + self.planogramSpaceBetween * 2) + ' ' + String(planogram.height + self.planogramSpaceBetween * 2);
            }

            // console.timeEnd('calculate');
            return planogram;
        }

        self.calculateColumn = function calculateColumn(planogram, prevColumn, column, idx) {
            // Fix issue performance planograma.
            // $log.debug('planogramController::calculateColumn planogram: %o prevColumn: %o column: %o', planogram, prevColumn, column);
            var newColumn = {
                id: column.id,
                x: 0,
                y: 0,
                modules: [],
                $$original: column,
                // Toma el Id de columna que se recibio o lo asigna automaticamente,
                $$id: column.overwriteId || idx + 1
            };

            if (prevColumn !== null) {
                // ReSharper disable once QualifiedExpressionMaybeNull
                newColumn.x = prevColumn.x + prevColumn.width + self.columnSpaceBetween * self.scaleFactor;
            }

            // Ordena los modulos de la columna.
            var modules = $filter('orderBy')(column.modules || [], 'order');

            // Recorre todos los modulos de la columna.
            var prevModule = null;
            angular.forEach(modules, function (module) {
                var newModule;

                // Calcula el modulo
                switch (module.type) {
                    case 'Shelf':
                    case 'Peg':
                    default:
                        newModule = self.calculateModule(column, prevModule, module);
                        break;
                }

                // Agrega el modulo a la columna.
                newColumn.modules.push(newModule);
                prevModule = newModule;
            }); // forEach modules

            if (newColumn.modules.length) {

                // ReSharper disable once UnusedParameter
                var newColumnStructure = newColumn.modules.reduce(function moduleReduce(prevModule, module, idx, array) {
                    var ret;
                    if (prevModule) {
                        ret = {
                            width: Math.max(prevModule.width, module.width),
                            height: prevModule.height + module.height + self.moduleSpaceBetween
                        }
                    } else {
                        ret = {
                            width: module.width,
                            height: module.height
                        }
                    }

                    return ret;

                }, null);

                newColumn.width = newColumnStructure.width;
                newColumn.height = newColumnStructure.height;
            }
            else {
                newColumn.width = 0;
                newColumn.height = 0;
            }

            return newColumn;
        }

        self.calculateModule = function calculateModule(column, prevModule, module) {
            // Fix issue performance planograma.
            // $log.debug('planogramController::calculateModule column: %o prevModule: %o module: %o', column, prevModule, module);
            var newModule = {
                id: module.id,
                type: module.type,
                isRefrigerator: module.isRefrigerator,
                width: module.width * self.scaleFactor,
                height: module.height * self.scaleFactor,
                depth: module.depth * self.scaleFactor,
                order: module.order,
                divisions: [],
                x: 0,
                y: 0,
                gridWidth: (Math.abs(module.gridWidth) || 1) * self.scaleFactor,
                gridHeight: (Math.abs(module.gridHeight) || 1) * self.scaleFactor,
                levels: [],
                $$original: module
            };

            if (prevModule !== null) {
                // ReSharper disable once QualifiedExpressionMaybeNull
                newModule.y = prevModule.y + prevModule.height
                    + self.moduleSpaceBetween * self.scaleFactor;
            }

            if (module.divisions > 0) {
                var divisionWidth = newModule.width / module.divisions;
                for (var i = 1; i < module.divisions; i++) {
                    newModule.divisions.push({ x: divisionWidth * i });
                }
            }

            // Ordena los estantes del modulo.
            var levels = $filter('orderBy')(module.levels || [], 'levelNumber', true);

            // Recorre todos los estantes del modulo.
            var prevLevel = null;
            angular.forEach(levels, function (level) {
                var newLevel = self.calculateLevel(newModule, prevLevel, level);

                // Agrega el nivel al modulo.
                newModule.levels.push(newLevel);
                prevLevel = newLevel;
            }); // forEach levels

            if (newModule.levels.length > 0) {
                var newModuleStructure = newModule.levels.reduce(function levelsReduce(preLevel, level, idx, array) {
                    var ret;
                    if (preLevel) {
                        ret = {
                            width: Math.max(preLevel.width, level.width),
                            height: preLevel.height + level.height + self.levelSpaceBetween + self.shelfHeight
                        }
                    } else {
                        ret = {
                            width: level.width,
                            height: level.height + self.levelSpaceBetween
                        }
                    }

                    return ret;

                }, null);

                newModule.width = newModuleStructure.width + self.moduleShelfSide * 2;
                newModule.height = newModuleStructure.height + self.moduleShelfSide * 2;
            } else {
                newModule.width += self.moduleShelfSide * 2;
                newModule.height += self.moduleShelfSide * 2;
            }

            if (newModule.isRefrigerator) {
                newModule.height = newModule.height + self.moduleShelfRefrigeratorTop + self.moduleShelfRefrigeratorBottom;
            }

            return newModule;
        }

        self.calculateLevel = function calculateLevel(module, prevLevel, level) {
            // Fix issue performance planograma.
            // $log.debug('planogramController::calculateLevel module: %o prevLevel: %o level: %o', module, prevLevel, level);
            var newLevel = {
                height: level.height * self.scaleFactor,
                width: module.width,
                levelNumber: level.levelNumber,
                x: 0,
                y: 0,
                itemsGroup: [],
                categoryId: level.categoryId,
                categoryName: level.categoryName,
                id: level.id,
                moduleId: level.moduleId,
                itemPadding: 0,
                $$original: level
            };

            if (prevLevel !== null) {
                // ReSharper disable once QualifiedExpressionMaybeNull
                newLevel.y = prevLevel.y + prevLevel.height + self.levelSpaceBetween + self.shelfHeight;
            }

            var fixWidth = 0;
            var fixHeight = newLevel.height;

            // Ordena los items del nivel.
            var itemsGroup = $filter('orderBy')(level.items, 'order');

            // Calcula el espacio utilizado del estante.
            var usedWidth = 0;
            var itemsTotal = 0;
            angular.forEach(level.items, function (item) {
                var itemWidth = ([2, 4].indexOf(item.orientation) != -1 ? item.selectedUnitOfMeasure.height : item.selectedUnitOfMeasure.width) * self.scaleFactor;                
                usedWidth += itemWidth * item.facings;
                itemsTotal += item.facings;
            });
            var usedWidthPercentage = usedWidth * 100 / newLevel.width;

            // Si el porcentaje de ocupación horizontal de los ítems 
            // en el estante es mayor o igual al 80 % del espacio del estante. 
            // Se distribuye el espacio restante (no utilizado) en forma equitativa entre todos los ítems.
            if (usedWidthPercentage >= 80 && itemsTotal > 1) {
                newLevel.itemPadding = (newLevel.width - usedWidth) / (itemsTotal - 1);
            }

            // Recorre todos los items del nivel.
            var prevItemGroup = null;
            angular.forEach(itemsGroup, function forEachItemGroup(itemGroup) {
                var newItemGroup = self.calculateItemGroup(newLevel, prevItemGroup, itemGroup);
                newLevel.itemsGroup.push(newItemGroup);
                prevItemGroup = newItemGroup;
                fixWidth += newItemGroup.padding.width;
                fixHeight = Math.max(fixHeight, newItemGroup.height);

            }); // forEach itemsGroup

            newLevel.width += fixWidth;
            return newLevel;
        };

        self.calculateItemGroup = function calculateItemGroup(level, prevItemGroup, itemGroup) {
            // Fix issue performance planograma.
            // $log.debug('planogramController::calculateItemGroup level: %o prevItemGroup: %o itemGroup: %o', level, prevItemGroup, itemGroup);

            self.hasItemsSelected = self.hasItemsSelected || itemGroup.selected;
            self.hasItemsFiltered = self.hasItemsFiltered || itemGroup.filtered;
            var w = itemGroup.selectedUnitOfMeasure.width * 10;
            var h = itemGroup.selectedUnitOfMeasure.height * 10;
            var imageUrl = itemGroup.selectedUnitOfMeasure.imagePlanogramFront;
            var altImageUrl = '//placehold.it/' + w + 'x' + h + '/293846/a7b1c2&text=Prisma';
            var newItemGroup = {
                $$id: ++uid,
                fill: itemGroup.fill || 'none',
                // opacity: itemGroup.opacity || 1,
                get opacity() {
                    return this.$$original.opacity || 1;
                },
                facings: itemGroup.facings || 1,
                stackAbove: itemGroup.stackAbove || 1,
                itemHeight: itemGroup.selectedUnitOfMeasure.height * self.scaleFactor,
                itemWidth: itemGroup.selectedUnitOfMeasure.width * self.scaleFactor,
                itemDepth: itemGroup.selectedUnitOfMeasure.depth * self.scaleFactor,
                order: itemGroup.order,
                imageUrl: altImageUrl,
                height: 0,
                width: 0,
                items: [],
                imageResolved: false,
                orientation: itemGroup.orientation || 1 /* Rotación */,
                layDownItem: itemGroup.layDownItem || 0 /* Acostados */,
                padding: {
                    width: 0,
                    height: 0,
                    depth: 0
                },
                // selected: itemGroup.selected || false,
                get selected() {
                    return this.$$original.selected || false;
                },
                // filtered: itemGroup.filtered || false,
                get filtered() {
                    return this.$$original.filtered || false;
                },
                x: (itemGroup.positionX || 0) * self.scaleFactor,
                y: (itemGroup.positionY || 0) * self.scaleFactor,
                $$original: itemGroup
            }

            /*
                1 = Sin rotación 
                2 = Rotado 90 grados 
                3 = Rotado 180 grados
                4 = Rotado 270 grados.
            */

            if (!self.showSketch()) {
                // Se conecta a los eventos de carga de imagenes.
                var $$id = newItemGroup.$$id;
                imageService.load(imageUrl)
                    .then(function onThen() {
                        // Fix issue performance planograma.
                        //$log.debug('planogramController::calculateItemGroup (imageLoad:then) imageurl: %o $$id: %o', imageUrl, $$id);

                        var idx = self.itemsGroup.findIndex((localItem) => localItem.$$id === $$id);
                        if (idx > -1) {
                            self.itemsGroup[idx].imageUrl = imageUrl;
                        }

                    })
                    .catch(function onCatch() {
                        // Fix issue performance planograma.
                        //$log.debug('planogramController::calculateItemGroup (imageLoad:catch) imageurl: %o $$id: %o', imageUrl, $$id);
                    })
                    .finally(function onFinally() {
                        //$log.debug('planogramController::calculateItemGroup (imageLoad:finally) imageurl: %o $$id: %o', imageUrl, $$id);

                        var idx = self.itemsGroup.findIndex((localItem) => localItem.$$id === $$id);
                        if (idx > -1) {
                            self.itemsGroup[idx].imageResolved = true;
                        }

                    });
            }

            /*
            
               /|\
              / | \
             /  |  \
            /   /\  \
            |  /  \  \ 
            | /    \ |
            |/      \|
             \      /
              \    /
               \  / 
                \/
            */

            // Calcula los tamaños de los items según los tipos de rotaciones.

            // Items acostados.
            var layDownItem = {
                width: newItemGroup.itemWidth,
                height: newItemGroup.itemDepth,
                transform: 'scale( 1 )',
                position: {
                    width: newItemGroup.itemWidth,
                    height: newItemGroup.itemDepth
                }
            };

            // Items apilados
            var stackAboveItem = {
                width: newItemGroup.itemWidth,
                height: newItemGroup.itemHeight,
                transform: 'scale( 1 )',
                position: {
                    width: newItemGroup.itemWidth,
                    height: newItemGroup.itemHeight
                }
            };

            var width;
            var hasStack = newItemGroup.stackAbove > 1;
            var hasLayDown = newItemGroup.layDownItem > 0;

            var layDownItemAux;
            var stackAboveItemAux;

                            width = stackAboveItem.width;
          
            switch (newItemGroup.orientation) {
                case 1 /* Sin rotación */:
                    if (hasStack && hasLayDown) {
                        width = Math.max(layDownItem.position.width, stackAboveItem.position.width);
                    } else if (hasStack && !hasLayDown) {
                        width = stackAboveItem.width;
                    } else if (!hasStack && hasLayDown) {
                        width = layDownItem.width;
                    } else {
                        width = stackAboveItem.width;
                    }

                    break;

                case 2 /* Rotado 90 grados */:
                    // // Apilados
                    // stackAboveItemAux = stackAboveItem.width;
                    // stackAboveItem.width = stackAboveItem.height;
                    // stackAboveItem.height = stackAboveItemAux;

                    // stackAboveItem.transform = 'rotate( 90 ' + String(stackAboveItem.width / 2) + ' ' + String(stackAboveItem.height / 2) + ' ) '
                    stackAboveItem.transform = 'rotate( 90 ) translate( 0 ' + String(- newItemGroup.itemHeight) + ' )';
                    stackAboveItem.position = {
                        width: stackAboveItem.height,
                        height: stackAboveItem.width
                    };

                    // Acostados
                    //layDownItemAux = layDownItem.width;
                    // layDownItem.width = layDownItem.height;
                    // layDownItem.height = layDownItemAux;
                    layDownItem.position = {
                        width: layDownItem.height,
                        height: layDownItem.width
                    };

                    // layDownItem.transform = 'rotate( 90 ' + String(layDownItem.width / 2) + ' ' + String(layDownItem.height / 2) + ' ) '
                    layDownItem.transform = 'rotate( 90 ) translate( ' + String((- newItemGroup.itemHeight + newItemGroup.itemWidth) * newItemGroup.stackAbove) + ' ' + String(- layDownItem.height) + ' ) ';

                    break;

                case 3 /* Rotado 180 grados */:
                    // Apilados        
                    stackAboveItem.transform = 'rotate( 180 ' + String(stackAboveItem.width / 2) + ' ' + String(stackAboveItem.height / 2) + ' ) ';

                    // Acostados
                    layDownItem.transform = 'rotate( 180 ' + String(layDownItem.width / 2) + ' ' + String(layDownItem.height / 2) + ' ) ';

                    if (hasStack && hasLayDown) {
                        width = Math.max(layDownItem.width, stackAboveItem.width);
                    } else if (hasStack && !hasLayDown) {
                        width = stackAboveItem.width;
                    } else if (!hasStack && hasLayDown) {
                        width = layDownItem.width;
                    } else {
                        width = stackAboveItem.width;
                    }

                    break;
                case 4 /* Rotado 270 grados */:
                    // Apilados    
                    // stackAboveItemAux = stackAboveItem.width;
                    // stackAboveItem.width = stackAboveItem.height;
                    // stackAboveItem.height = stackAboveItemAux;
                    stackAboveItem.transform = 'rotate( 270 ' + String(stackAboveItem.width / 2) + ' ' + String(stackAboveItem.width / 2) + ' ) ';
                    stackAboveItem.position = {
                        width: stackAboveItem.height,
                        height: stackAboveItem.width
                    };

                    // Acostados
                    // layDownItemAux = layDownItem.width;
                    // layDownItem.width = layDownItem.height;
                    // layDownItem.height = layDownItemAux;
                    var translateLayDownItem = (layDownItem.width / 2) - (((newItemGroup.itemHeight - newItemGroup.itemWidth) * newItemGroup.stackAbove) / 2);
                    layDownItem.transform = 'rotate( 270 ' + String(translateLayDownItem) + ' ' + String(translateLayDownItem) + ' ) ';
                    layDownItem.position = {
                        width: layDownItem.height,
                        height: layDownItem.width
                    };

                    if (hasStack && hasLayDown) {
                        width = Math.max(layDownItem.position.width, stackAboveItem.position.width);
                    } else if (hasStack && !hasLayDown) {
                        width = stackAboveItem.height;
                    } else if (!hasStack && hasLayDown) {
                        width = layDownItem.height;
                    } else {
                        width = stackAboveItem.position.width;
                    }

                    break;
            }         

            if (hasStack && hasLayDown) {
                width = Math.max(layDownItem.position.width, stackAboveItem.position.width);
            } else if (hasStack && !hasLayDown) {
                width = stackAboveItem.position.width;
            } else if (!hasStack && hasLayDown) {
                width = layDownItem.position.width;
            } else {
                width = stackAboveItem.position.width;
            }

            var padding = (self.showSketch()) ? 2.5 : 0;

            // Calcula el tamaño del grupo de items.
            newItemGroup.width = width * newItemGroup.facings
                + padding * newItemGroup.facings
                + level.itemPadding * (newItemGroup.facings - 1);
            newItemGroup.height = stackAboveItem.position.height * newItemGroup.stackAbove
                + layDownItem.position.height * newItemGroup.layDownItem
                + padding * newItemGroup.stackAbove
                + padding * newItemGroup.layDownItem;

            newItemGroup.padding.width = padding * newItemGroup.facings;
            newItemGroup.padding.height = padding * newItemGroup.stackAbove
                + padding * newItemGroup.layDownItem;

            var stackHeight = stackAboveItem.height * newItemGroup.stackAbove;
            var paddingX = padding + level.itemPadding;

            // Recorre todos los frentes.
            for (var i = 0; i < newItemGroup.facings; i++) {
                // Recorre todos los items apilados.
                for (var j = 0; j < newItemGroup.stackAbove; j++) {
                    var itemStack = {
                        x: i * stackAboveItem.position.width + i * level.itemPadding + ((i > 0) ? padding : 0),
                        y: j * stackAboveItem.position.height + ((j > 0) ? padding : 0),
                        width: stackAboveItem.width,
                        height: stackAboveItem.height,
                        noLabel: false,
                        transform: stackAboveItem.transform
                    };
                    newItemGroup.items.push(itemStack);
                }

                // Recorre todos los items acostados.
                for (var k = 0; k < newItemGroup.layDownItem; k++) {
                    var itemLayDown = {
                        x: i * layDownItem.position.width + i * level.itemPadding + ((i > 0) ? padding : 0),
                        y: k * layDownItem.position.height + stackHeight + ((k > 0) ? padding : 0),
                        width: layDownItem.width,
                        height: layDownItem.height,
                        noLabel: true,
                        transform: layDownItem.transform
                    };
                    newItemGroup.items.push(itemLayDown);
                }
            }

            if (!self.hasPosition(itemGroup)) {
                if (prevItemGroup === null) {
                    newItemGroup.x = 0;
                } else {
                    newItemGroup.x = prevItemGroup.x + prevItemGroup.width + padding + level.itemPadding;
                }

                // Alinea el grupo de items en la base del estante,            
                newItemGroup.y =
                    level.height -
                    newItemGroup.stackAbove * stackAboveItem.position.height -
                    newItemGroup.layDownItem * layDownItem.position.height;
            }

            return newItemGroup;
        }

        self.hasPosition = function hasPosition(itemGroup) {
            var keys = Object.keys(itemGroup);
            var idxPropX = keys.indexOf('positionX');
            var idxPropY = keys.indexOf('positionY');
            var ret = idxPropX > -1 && angular.isNumber(itemGroup.positionX)
                && idxPropY > -1 && angular.isNumber(itemGroup.positionY);
            return ret;
        }

        self.showSketch = function showSketch() {
            var ret = self.showItemSketch
                || self.showGroupSketch;
            return ret;
        };

        self.hasModel = function hasModel() {
            return angular.isDefined(self.model);
        }

        self.hasSelected = function hasSelected() {
            var ret = self.hasItemsSelected;
            return ret;
        }

        self.hasFiltered = function hasFiltered() {
            var ret = self.hasItemsFiltered;
            return ret;
        }

        self.onClickHandler = function onClick($event) {
            // Fix issue performance planograma.
            // $log.debug('planogramController::onClickHandler %o', $event);
            if (!dnDService.isOnDrag()) {
                $event.stopPropagation();
                self.raiseOnClick({
                    $event: $event,
                    planogram: self.getModel()
                });
            }
        }

        self.onDblClick = function onDblClick($event) {
            // Zoom to element.
            // Link: https://makina-corpus.com/blog/metier/2015/zoom-and-pan-to-a-svg-element
            // the main SVG object and its current viewBox
            var svg = $element.find('svg')[0];

            // element is the element I want to zoom to
            var element = $event.target;
            var bbox = element.getBBox();
            var viewBox = svg.getAttribute('viewBox');
            var vbox = viewBox.split(' ');
            vbox[0] = parseFloat(vbox[0]);
            vbox[1] = parseFloat(vbox[1]);
            vbox[2] = parseFloat(vbox[2]);
            vbox[3] = parseFloat(vbox[3]);

            // the current center of the viewBox
            var cx = vbox[0] + vbox[2] / 2;
            var cy = vbox[1] + vbox[3] / 2;

            var matrix = element.getTransformToElement(svg);

            // the new center
            var newx = (bbox.x + bbox.width / 2) * matrix.a + matrix.e;
            var newy = (bbox.y + bbox.height / 2) * matrix.d + matrix.f;

            // the corresponding top left corner in the current scale
            var absoluteOffsetX = vbox[0] + newx - cx;
            var absoluteOffsetY = vbox[1] + newy - cy;

            // the new scale
            var scale = bbox.width * matrix.a / vbox[2] * 1.2;

            var scaledOffsetX = absoluteOffsetX + vbox[2] * (1 - scale) / 2;
            var scaledOffsetY = absoluteOffsetY + vbox[3] * (1 - scale) / 2;
            var scaledWidth = vbox[2] * scale;
            var scaledHeight = vbox[3] * scale;

            svg.setAttribute('viewBox', '' + scaledOffsetX + ' ' + scaledOffsetY + ' ' + scaledWidth + ' ' + scaledHeight);
        }

        self.getModel = function getModel() {
            // return angular.copy(self.model);
            return self.model.$$original;
        }

        /**
         * Devuelve el valor del ancho del SVG.
         */
        self.getWidth = function getWidth() {
            var ret;
            if (angular.isNumber(self.width)) {
                ret = self.width;
            } else {
                ret = '100%';
            }

            return ret;
        }

        self.getDraggable = function ($target) {
            return $target.hasClass('draggable') ? $target : $target.parents('.draggable');
        }

        self.isDraggable = function ($target) {
            return $target.is('.draggable') || $target.parents('.draggable').length > 0;
        }

        self.startDrag = function startDrag($event) {
            // Fix issue performance planograma.
            // $log.debug('planogramController::startDrag %o', $event);

            // var $target = self.getDraggable(angular.element($event.target));
            var $target = angular.element($event.target);
            // if ($target.hasClass('draggable')) {
            if (self.isDraggable($target)) {
                self.drag.selectedElement = $target[0];
                self.drag.offset = self.getMousePosition($event);
                // self.drag.offset.x -= parseFloat(self.drag.selectedElement.getAttributeNS(null, 'x'));
                // self.drag.offset.y -= parseFloat(self.drag.selectedElement.getAttributeNS(null, 'y'));

                // Get all the transforms currently on this element
                var transforms = self.drag.selectedElement.transform.baseVal;

                // Ensure the first transform is a translate transform
                if (transforms.length === 0
                    || transforms.getItem(0).type !== window.SVGTransform.SVG_TRANSFORM_TRANSLATE) {
                    // Create an transform that translates by (0, 0)
                    self.drag.translate = self.drag.$draggable.createSVGTransform();
                    self.drag.translate.setTranslate(0, 0);

                    // Add the translation to the front of the transforms list
                    self.drag.selectedElement.transform.baseVal.insertItemBefore(translate, 0);
                }

                // Get initial translation amount
                self.drag.transform = transforms.getItem(0);
                self.drag.offset.x -= self.drag.transform.matrix.e;
                self.drag.offset.y -= self.drag.transform.matrix.f;
            }
        }

        self.drag = function drag($event) {
            // Fix issue performance planograma.
            // $log.debug('planogramController::drag %o', $event);
            if (self.drag.selectedElement) {
                $event.preventDefault();
                var coord = self.getMousePosition($event);
                // self.drag.selectedElement.setAttributeNS(null, 'x', coord.x - self.drag.offset.x);
                // self.drag.selectedElement.setAttributeNS(null, 'y', coord.y - self.drag.offset.y);
                var x = coord.x - self.drag.offset.x;
                var y = coord.y - self.drag.offset.y;
                self.drag.transform.setTranslate(x, y);
            }
        };

        self.endDrag = function endDrag($event) {
            // Fix issue performance planograma.
            // $log.debug('planogramController::endDrag %o', $event);
            self.drag.selectedElement = null;
        }

        self.getMousePosition = function getMousePosition($event) {
            // Fix issue performance planograma.
            // $log.debug('planogramController::getMousePosition %o', $event);
            // var svg = $element.find('svg')[0];
            var screenCtm = self.drag.$draggable[0].getScreenCTM();

            if ($event.touches) {
                $event = $event.touches[0];
            }

            return {
                x: ($event.clientX - screenCtm.e) / screenCtm.a,
                y: ($event.clientY - screenCtm.f) / screenCtm.d
            };
        }

        /**
         * Devuelve la referencia del tag svg del planograma.
         * @return Referencia del tag svg del planograma.
         */
        self.getSvg = function getSvg() {
            var ret = $element.find('svg:first');
            return ret;
        }

        self.makeDraggable = function makeDraggable() {
            // Fix issue performance planograma.
            // $log.debug('planogramController::makeDraggable');
            self.drag.$draggable = $element.find('svg:first');
            self.drag.$draggable.off('mousedown touchstart')
                .off('mousemove touchmove')
                .off('mouseup mouseleave touchend touchleave touchcancel');

            if (self.allowDrag) {
                self.drag.$draggable.on('mousedown touchstart', angular.bind(self, self.startDrag))
                    .on('mousemove touchmove', angular.bind(self, self.drag))
                    .on('mouseup mouseleave touchend touchleave touchcancel', angular.bind(self, self.endDrag));
            } else {
                self.drag.$draggable = null;
            }
        };

        /**
         * Devuelve el hash de toda la configuración del componente.
         * @private
         * @returns El hash de toda la configuración del componente.
         */
        self.getHashCode = function getHashCode() {
            var flags = self.getFlags();
            var auxVars = [
                flags,
                self.label1,
                self.label2,
                self.labelInfo,
                self.labelHeight,
                self.columnSpaceBetween,
                self.moduleSpaceBetween,
                self.levelSpaceBetween,
                self.itemSpaceBetween,
                self.shelfHeight,
                self.scaleFactor,
                self.width];
            var aux = auxVars.join('|');
            var ret = self.calculateHash(aux);
            return ret;
        };

        /**
         * Devuelve el calculo de los flags activos del componente.
         * @private
         * @returns El calculo de los flags activos del componente.
         */
        self.getFlags = function getFlags() {
            var ret = 0;
            if (self.showDoor) {
                ret |= 1;
            }

            if (self.showItemSketch) {
                ret |= 2;
            }

            if (self.showGroupSketch) {
                ret |= 4;
            }

            if (self.showLabelInfo) {
                ret |= 8;
            }

            if (self.showLabel1) {
                ret |= 16;
            }

            if (self.showLabel2) {
                ret |= 32;
            }

            if (self.showOverlay) {
                ret |= 64;
            }

            if (self.showColumnId) {
                ret |= 128;
            }

            return ret;
        };

        /**
         * Devuelve el hash de la cadena.
         * - 0 si no es una cadena.
         * - 0 si la cadena esta vacia.
         * - hash numerico de la cadena en los otros casos.
         * @private 
         * @link Inspiración https://stackoverflow.com/a/7616484
         * @param {any} value Cadena.
         * @returns El hash de la cadena.
         */
        self.calculateHash = function calculateHash(value) {
            var hash = 0, i, chr;
            if (angular.isString(value)) {
                var len = value.length;
                if (len > 0) {
                    for (i = 0; i < len; i++) {
                        chr = value.charCodeAt(i);
                        hash = ((hash << 5) - hash) + chr;
                        hash |= 0; // Convert to 32bit integer
                    }
                }
            }

            return hash;
        };

        self.updateModel = function updateModel(model, newModel) {
            var ret = model;
            if (ret.columns.length === newModel.columns.length
                && ret.width === newModel.width
                && ret.height === newModel.height) {
                //var i = 0;
                //var cols = ret.columns.length;
                //while (i < cols) {
                //    var colOri = ret.columns[i];
                //    var colNew = newModel.columns[i];
                //    if (colOri.x === colNew.x &&
                //        colOri.y === colNew.y &&
                //        colOri.modules.length === colNew.modules.length &&
                //        colOri.$$id === colNew.$$id) {
                //        var j = 0;
                //        var modules = colOri.modules.length;
                //        while (j < modules) {


                //            j += 1;
                //        }
                //    } else {
                //        ret.columns[i] = angular.copy(colNew);
                //    }

                //    i += 1;
                //}

                angular.forEach(ret.columns,
                    function (column, colIdx) {
                        var colNew = newModel.columns[colIdx];
                        if (column.id === colNew.id
                            && column.x === colNew.x
                            && column.y === colNew.y
                            && column.modules.length === colNew.modules.length
                            && column.$$id === colNew.$$id) {
                            angular.forEach(column.modules,
                                function (module, modIdx) {
                                    var moduleNew = newModel.columns[colIdx].modules[modIdx];
                                    if (module.id === moduleNew.id &&
                                        module.type === moduleNew.type &&
                                        module.isRefrigerator === moduleNew.isRefrigerator &&
                                        module.width === moduleNew.width &&
                                        module.height === moduleNew.height &&
                                        module.depth === moduleNew.depth &&
                                        module.order === moduleNew.order &&
                                        module.divisions.length &&
                                        module.x === moduleNew.x &&
                                        module.y === moduleNew.y &&
                                        module.levels.length === moduleNew.levels.length) {
                                        angular.forEach(module.levels,
                                            function (level, lvlIdx) {
                                                if (true) {
                                                    angular.forEach(level.items,
                                                        function (item, itemIdx) {

                                                        });
                                                }
                                            });

                                    } else {
                                        ret.columns[colIdx].modules[modIdx] = angular.copy(moduleNew);
                                    }
                                });

                        } else {
                            ret.columns[colIdx] = angular.copy(colNew);
                        }
                    });
            } else {
                ret = angular.copy(newModel);
            }

            return ret;
        }

        self.getItemsGroup = function getItemsGroup(data) {
            // console.time('getItemsGroup');
            var ret = [];
            angular.forEach(data.columns,
                function (column) {
                    angular.forEach(column.modules,
                        function (module) {
                            angular.forEach(module.levels,
                                function (level) {
                                    angular.forEach(level.itemsGroup,
                                        function (item) {
                                            //var itemExt = angular.merge({},
                                            //    item,
                                            //    {
                                            //        column: column,
                                            //        module: module,
                                            //        level: level
                                            //    });
                                            var itemExt = angular.copy(item);

                                            itemExt.column = column;
                                            itemExt.module = module;
                                            itemExt.level = level;

                                            itemExt.positionX = self.getItemGroupX(itemExt);
                                            itemExt.positionY = self.getItemGroupY(itemExt);
                                            ret.push(itemExt);
                                        });
                                });
                        });
                });
            // console.timeEnd('getItemsGroup');

            return ret;
        }

        self.getLevels = function getLevels(data) {
            // console.time('getLevels');
            var ret = [];
            angular.forEach(data.columns,
                function (column) {
                    angular.forEach(column.modules,
                        function (module) {
                            angular.forEach(module.levels,
                                function (level) {
                                    var levelExt = angular.copy(level);

                                    levelExt.column = column;
                                    levelExt.module = module;

                                    levelExt.positionX = self.getLevelX(levelExt);
                                    levelExt.positionY = self.getLevelY(levelExt);
                                    levelExt.buttonsX = getLevelButtonsX(levelExt);
                                    levelExt.buttonsY = getLevelButtonsY(levelExt);
                                    levelExt.measureArrowX = getLevelMeasureArrowX(levelExt);
                                    levelExt.measureArrowY = getLevelMeasureArrowY(levelExt);
                                    ret.push(levelExt);
                                });
                        });
                });
            return ret;
        }

        self.getModules = function getModules(data) {
            var ret = [];
            angular.forEach(data.columns,
                function (column) {
                    angular.forEach(column.modules,
                        function (module) {
                            var moduleExt = angular.copy(module);
                            moduleExt.column = column;

                            moduleExt.positionX = self.getModuleX(moduleExt);
                            moduleExt.positionY = self.getModuleY(moduleExt);
                           
                            ret.push(moduleExt);

                        });
                });
            return ret;
        }

        self.getItemGroupX = function getItemGroupX(value) {
            try {
                var columnX = value.column.x;
                var moduleX = value.module.x;
                var levelX = value.level.x;
                var ret = self.planogramSpaceBetween
                    + columnX
                    + moduleX
                    + levelX + self.moduleShelfSide
                    + value.x;
                return ret;
            } catch (ex) {
                $log.error('planogramController::getItemGroupX data: %o, ex: %o', value, ex);
                throw ex;
            }
        }

        self.getItemGroupY = function getItemGroupY(value) {

            try {
                var columnY = value.column.y;
                var moduleY = value.module.y;
                var levelY = value.level.y;
                var ret = self.planogramSpaceBetween +
                    columnY +
                    moduleY +
                    ((value.module.isRefrigerator) ? self.moduleShelfRefrigeratorTop : 0) +
                    levelY + self.moduleShelfSide + value.y;
                return ret;
            } catch (ex) {
                $log.error('planogramController::getItemGroupY data: %o, ex: %o', value, ex);
                throw ex;
            }
        }

        self.getLevelX = function getLevelX(value) {
            try {
                var columnX = value.column.x;
                var moduleX = value.module.x;
                var ret = self.planogramSpaceBetween
                    + columnX
                    + moduleX
                    + self.moduleShelfSide
                    + value.x;
                return ret;
            } catch (ex) {
                $log.error('planogramController::getLevelX data: %o, ex: %o', value, ex);
                throw ex;
            }
        }

        self.getModuleX = function getModuleX(value) {
            try {
                var columnX = value.column.x;
                var ret = self.planogramSpaceBetween
                    + columnX
                    //+ self.moduleShelfSide
                    + value.x;
                return ret;
            } catch (ex) {
                $log.error('planogramController::getModuleX data: %o, ex: %o', value, ex);
                throw ex;
            }
        }

        self.getLevelY = function getLevelY(value) {

            try {
                var columnY = value.column.y;
                var moduleY = value.module.y;
                var ret = self.planogramSpaceBetween +
                    columnY +
                    moduleY +
                    self.moduleShelfSide + value.y;
                return ret;
            } catch (ex) {
                $log.error('planogramController::getLevelY data: %o, ex: %o', value, ex);
                throw ex;
            }
        }

        self.getModuleY = function getModuleY(value) {

            try {
                var columnY = value.column.y;
                var ret = self.planogramSpaceBetween +
                    columnY
                    //+ self.moduleShelfSide
                    + value.y;
                return ret;
            } catch (ex) {
                $log.error('planogramController::getModuleY data: %o, ex: %o', value, ex);
                throw ex;
            }
        }

        /**
        * Calcula la posicion x de los botones de edicion de nivel
        * @param {any} level nivel para posicionar los botones
        */
        function getLevelButtonsX(level) {

            try {
                var buttonsWidth = (self.labelHeight * 3) + (10 * 2);//ancho de la botonera

                var ret = ((level.width - buttonsWidth) / 2) + level.positionX;
                return ret;

            } catch (ex) {
                $log.error('planogramController::getLevelButtonsX data: %o, ex: %o', level, ex);
                throw ex;
            }
        }


        /**
        * Calcula la posicion y de los botones de edicion de nivel
        * @param {any} level nivel para posicionar los botones
        */
        function getLevelButtonsY(level) {

            try {
                var ret = ((level.height - self.labelHeight) / 2) + level.positionY
                    + (level.module.isRefrigerator ? 186 : 0);//top de refrigerdor 
                return ret;

            } catch (ex) {
                $log.error('planogramController::getLevelButtonsY data: %o, ex: %o', level, ex);
                throw ex;
            }
        }

        /**
        * Calcula la posicion x de los botones de edicion de nivel
        * @param {any} level nivel para posicionar los botones
        */
        function getLevelMeasureArrowX(level) {

            try {
                // var ret = ((level.width - self.labelHeight )) + level.positionX;
                var ret = (level.width - 130) + level.positionX;
                return ret;

            } catch (ex) {
                $log.error('planogramController::getLevelMeasureArrowX data: %o, ex: %o', level, ex);
                throw ex;
            }
        }

        /**
      * Calcula la posicion x de los botones de edicion de nivel
      * @param {any} level nivel para posicionar los botones
      */
        function getLevelMeasureArrowY(level) {

            try {
                var ret = ((level.height - self.labelHeight) / 2) + level.positionY;
                return ret;

            } catch (ex) {
                $log.error('planogramController::getLevelMeasureArrowY data: %o, ex: %o', level, ex);
                throw ex;
            }
        }

        /**
         * Se recive cuando se termino de cargar la imagen de un item.
         * @event 
         * @param {any} itemGroup
         */
        self.onLoadImage = function onLoadImage(itemGroup) {
            var item = self.findItemGroup(itemGroup.$$original.id);
            if (item) {
                item.imageResolved = itemGroup.imageResolved;
                item.imageUrl = itemGroup.imageUrl;
            }
        }

        self.findItemGroup = function findItemGroup(id) {
            var ret = null;
            var idx = self.itemsGroup.findIndex(function (item) {
                var ret = item.$$original.id === id;
                return ret;
            });

            if (idx > -1) {
                ret = self.itemsGroup[idx];
            }

            return ret;
        }

        /**
         * Devuelve verdadero si se tiene que mostrar el indicador de drop.
         * @public
         * @returns Verdadero si se tiene que mostrar el indicador de drop, falso en caso contrario.
         */
        self.showDropIndicator = function showDropIndicator() {
            return self.isOver;
        };

        /**
         * Configura el itemGroup sobre el cual se esta pasnado el mouse para mostrar el indicador de posición.
         * @param {any} data itemGroup
         */
        self.setOver = function setOver(data) {
            // $log.debug('planogramLevelShelfDirective::setOver\n\tdata: %o', data);
            self.isOver = true;
            if (angular.isObject(data)
                && angular.isObject(data.model)) {
                // self.dropIndicatorX = data.model.x;
                self.dropIndicatorX = self.getItemGroupX(data.model);
                self.dropIndicatorY = self.getItemGroupY(data.model);
                var type = data.model.module.type;
                if (type === 'Peg') {
                    //self.dropIndicatorWidth = data.model.width;
                    //self.dropIndicatorHeight = data.model.height;
                    self.dropIndicatorWidth = 0;
                    self.dropIndicatorHeight = 0;

                } else {
                    self.dropIndicatorY -= data.model.y;
                    self.dropIndicatorWidth = 20;
                    self.dropIndicatorHeight = data.model.level.height;
                    if (data.place === 'after') {
                        self.dropIndicatorX += data.model.width;
                    }
                }

            } else {
                // self.dropIndicatorX = 0;
                self.dropIndicatorX = self.planogramSpaceBetween;
                self.dropIndicatorY = self.planogramSpaceBetween;
            }

            self.overTarget = data.model;

            if (isNaN(self.dropIndicatorX) || isNaN(self.dropIndicatorY)) {
                $log.info('setOver self: %o, data: %o, x: %o, y: %o', self, data, self.dropIndicatorX, self.dropIndicatorY);
            }
        }

        self.removeOver = function removeOver() {
            // $log.debug('planogramLevelShelfDirective::removeOver');
            self.isOver = false;
            self.dropIndicatorX = 0;
            self.overTarget = null;
        }

        self.showSelectedItem = function showSelectedItem(item) {
            var ret = angular.isObject(item) && item.selected && !self.allowEditStructure;
            return ret;
        }

        self.itemOnClickHandler = function itemOnClickHandler($event, itemGroup) {
            $event.preventDefault();
            $event.stopPropagation();

            // Fix issue performance planograma.
            // $log.debug('planogramItemGroup2Controller::onClickHandler %o', $event);
            if (!dnDService.isOnDrag()) {
                var $svg = self.getSvg();
                var $el = angular.element($event.target);
                var point = svgService.eventToPoint($svg, $el, $event);
                var place = svgService.getPlace(point);
                var item = self.findItemGroup(itemGroup.$$original.id);
                self.raiseOnClick({
                    $event: $event,
                    planogram: self.getModel(),
                    column: itemGroup.column.$$original,
                    module: itemGroup.module.$$original,
                    level: itemGroup.level.$$original,
                    item: item.$$original,
                    point: point,
                    place: place
                });
            }
        }

        self.increaseLevel = function increaseLevel($event, level) {
            $log.info('increaseLevel $event: %o, level: %o', $event, level);
            self.raiseOnChangeLevelHeight({
                $event: $event,
                planogram: self.getModel(),
                column: level.column.$$original,
                module: level.module.$$original,
                level: level.$$original,
                action: 'increase'
            });
        }

        self.reduceLevel = function reduceLevel($event, level) {
            self.raiseOnChangeLevelHeight({
                $event: $event,
                planogram: self.getModel(),
                column: level.column.$$original,
                module: level.module.$$original,
                level: level.$$original,
                action: 'reduce'
            });
        }

        self.deleteLevel = function deleteLevel($event, level) {
            self.raiseOnDeleteLevel({
                $event: $event,
                planogram: self.getModel(),
                column: level.column.$$original,
                module: level.module.$$original,
                level: level.$$original
            });
        }

        self.getTransform = function getTransform(module) {
            var ret;
            if (module.isRefrigerator) {
                ret = 'translate(' + module.positionX + ' ' + (module.positionY + self.moduleShelfRefrigeratorTop) + ' )';
            } else {
                ret = 'translate( 0 0 )';
            }

            return ret;
        };

        self.getModuleHeight = function getModuleHeight(module) {
            var ret = module.height;
            if (module.isRefrigerator) {
                ret -= self.moduleShelfRefrigeratorTop;
            }

            return ret;
        };

        /*
        // Utils optimización performance.
        // Link: https://www.monterail.com/blog/2015/story-of-angular-watchers-toggler-directive
        // Link: https://github.com/kentcdodds/ng-stats/blob/master/src/index.js
        var scopeSelectors = '.ng-scope, .ng-isolate-scope';

        function getRootScope() {
            if ($rootScope) {
                return $rootScope;
            }
            var scopeEl = document.querySelector(scopeSelectors);
            if (!scopeEl) {
                return null;
            }
            $rootScope = angular.element(scopeEl).scope().$root;
            return $rootScope;
        }

        function iterateScopes(currentScope, fn) {
            if (typeof currentScope === 'function') {
                fn = currentScope;
                currentScope = null;
            }
            currentScope = currentScope || getRootScope();
            currentScope = _makeScopeReference(currentScope);
            if (!currentScope) {
                return;
            }
            var ret = fn(currentScope);
            if (ret === false) {
                return ret;
            }
            return iterateChildren(currentScope, fn);
        }

        function iterateSiblings(start, fn) {
            var ret;
            while (!!(start = start.$$nextSibling)) {
                ret = fn(start);
                if (ret === false) {
                    break;
                }

                ret = iterateChildren(start, fn);
                if (ret === false) {
                    break;
                }
            }
            return ret;
        }

        function iterateChildren(start, fn) {
            var ret;
            while (!!(start = start.$$childHead)) {
                ret = fn(start);
                if (ret === false) {
                    break;
                }

                ret = iterateSiblings(start, fn);
                if (ret === false) {
                    break;
                }
            }
            return ret;
        }


        function getScopeById(id) {
            var myScope = null;
            iterateScopes(function (scope) {
                if (scope.$id === id) {
                    myScope = scope;
                    return false;
                }
            });
            return myScope;
        }

        function _makeScopeReference(scope) {
            if (_isScopeId(scope)) {
                scope = getScopeById(scope);
            }
            return scope;
        }

        function _isScopeId(scope) {
            return typeof scope === 'string' || typeof scope === 'number';
        }

        function mockScopeWatch (scopeId) {
            return function (watchExp, listener, objectEquality, prettyPrintExpression) {
            }
        }
        */
    }
})();