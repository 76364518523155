var _controller = function ($scope, $rootScope, $element, $timeout, $attrs, $filter, $uibModal, priceService, homeService, $translate) {
    var self = this;

    var priceMap = null;
    var country = '';

    var priceId;

    var priceSurveyType = [];

    self.companyCode = $rootScope.erp.Implementation;

    self.priceSurveys = [];
    self.allPriceList = [{}];
    self.selectedPriceZone = null;
    self.selectedPriceZoneName = '';
    self.itemName = "";
    self.itemCode = "";
    self.selectedCompetitorGroups = [];
    self.priceSurveysFiltered = [];
    self.competitorsInit = [];
    self.competitorPriceList = [];
    self.competitorStates = [];
    self.competitorZones = [];
    self.selectedPriceLists = [];


    self.onlyMarkers = false;
    self.multizoneViewsCheckbox = false;


    self.getPriceSurveyType = function (id) {
        for (var i = 0; i < priceSurveyType.length; i++) {
            if (priceSurveyType[i].id == id)
                return priceSurveyType[i];
        }
    }

    var markers = [];

    self.$onChanges = function (changes) {
        if (_.isEmpty(changes) == false) {
            if (changes.price.currentValue && changes.price.currentValue != changes.price.previousValue) {
                //console.log(changes.price.currentValue.priceId);
                init();
            }
        }
    }


    self.getCompetitorGroupPrice = function (competitorGroup) {
        if (competitorGroup == undefined) return;
        if (competitorGroup.parentId) {
            var thisPrice = 0;
            angular.forEach(self.price.competitorsGroupPrices, function (price) {
                if (price.competitorGroupId == competitorGroup.id && price.onlyMarkers == self.onlyMarkers) {
                    thisPrice = price.price;

                }
            });

            return thisPrice;
        } else {
            var priceAcum = 0;
            var count = 0;
            angular.forEach(self.price.competitorGroupParents, function (parent) {
                angular.forEach(self.price.competitorsGroupPrices, function (price) {
                    if (parent.id == price.parentId && price.onlyMarkers == self.onlyMarkers) {
                        priceAcum += price.price;
                        count++;
                    }
                });

            });

            return (priceAcum / count).toFixed(3);
        }

    }

    self.getCompetitorGroupIC = function (competitorGroup) {
        if (competitorGroup == undefined) return;


        if (competitorGroup.parentId) {


            var thisPrice = 0;
            angular.forEach(self.price.competitorsGroupPrices, function (price) {
                if (price.competitorGroupId == competitorGroup.id && price.onlyMarkers == self.onlyMarkers) {
                    thisPrice = price.price;
                    //console.log('competitorGroup', competitorGroup);
                    //console.log('price.price', price.price);
                }
            });
            if (thisPrice == 0) return '';
            if (!self.price._Price) return ''

            return (self.price._Price / thisPrice - 1).toFixed(3);
        } else {
            //console.log('self.price.competitorsGroupPrices', self.price.competitorsGroupPrices);
            var priceAcum = 0;
            var count = 0;
            angular.forEach(self.price.competitorGroupParents, function (parent) {
                if (competitorGroup.id == parent.id) {
                    angular.forEach(self.price.competitorsGroupPrices, function (price) {
                        if (parent.id == price.parentId && price.onlyMarkers == self.onlyMarkers) {
                            priceAcum += price.price;
                            count++;
                        }
                    });
                }

            });
            var thisPrice = priceAcum / count;
            if (thisPrice == 0) return '';
            if (!self.price._Price) return ''

            return (self.price._Price / thisPrice - 1).toFixed(3);
        }


    }
    self.getCompetitorGroupSuggestedIC = function (competitorGroup) {
        if (competitorGroup == undefined) return;

        if (competitorGroup.parentId) {
            var thisPrice = 0;
            angular.forEach(self.price.competitorsGroupPrices, function (price) {
                if (price.competitorGroupId == competitorGroup.id && price.onlyMarkers == self.onlyMarkers)
                    thisPrice = price.price;
            });
            if (thisPrice == 0) return '';
            if (!self.price.suggestedPrice) return '';
            return (self.price.suggestedPrice / thisPrice - 1).toFixed(3);
        } else {
            var priceAcum = 0;
            var count = 0;
            angular.forEach(self.price.competitorGroupParents, function (parent) {
                angular.forEach(self.price.competitorsGroupPrices, function (price) {
                    if (parent.id == price.parentId && price.onlyMarkers == self.onlyMarkers) {
                        priceAcum += price.price;
                        count++;
                    }
                });
            });
            var thisPrice = priceAcum / count;

            if (thisPrice == 0) return '';
            if (!self.price.suggestedPrice) return '';
            return (self.price.suggestedPrice / thisPrice - 1).toFixed(3);

        }

    }

    self.getCompetitorGroupNewIC = function (competitorGroup) {
        if (competitorGroup == undefined) return;

        if (competitorGroup.parentId) {
            var thisPrice = 0;
            angular.forEach(self.price.competitorsGroupPrices, function (price) {
                if (price.competitorGroupId == competitorGroup.id
                    && price.onlyMarkers == self.onlyMarkers)
                    thisPrice = price.price;
            });
            if (thisPrice == 0) return '';
            if (!self.price.newPrice) return '';
            return (self.price.newPrice / thisPrice - 1).toFixed(3);
        } else {
            var priceAcum = 0;
            var count = 0;
            angular.forEach(self.price.competitorGroupParents, function (parent) {
                angular.forEach(self.price.competitorsGroupPrices, function (price) {
                    if (parent.id == price.parentId && price.onlyMarkers == self.onlyMarkers) {
                        priceAcum += price.price;
                        count++;
                    }
                });
            });
            var thisPrice = priceAcum / count;

            if (thisPrice == 0) return '';
            if (!self.price.newPrice) return '';
            return (self.price.newPrice / thisPrice - 1).toFixed(3);
        }

    }


    self.navigateToCompetitor = function (id) {
        for (var m in markers) {
            var marker = markers[m];
            if (marker && marker.options && id == marker.options.id) {

                priceMap.setView(marker._latlng, 14);
                marker.openPopup();
            };
        }
    }

    self.runFilters = function () {
        self.priceSurveysFiltered = [];

        angular.forEach(markers, function (marker) {
            priceMap.removeLayer(marker);
        });
        markers = [];

        var minLat = 999;
        var maxLat = -999;
        var minLng = 999;
        var maxLng = -999;
        var latArray = [];
        var lngArray = [];

        self.priceSurveys.forEach(function (priceSurvey) {
            var marker = addMarker(priceSurvey);
            if (marker && marker._latlng.lat && marker._latlng.lng) {
                latArray.push(marker._latlng.lat);
                lngArray.push(marker._latlng.lng);
            }
        });


        var averageLng;
        var averageLat;
        var sumLat = 0;
        var sumLng = 0;
        angular.forEach(markers, function (marker) {
            sumLat += marker._latlng.lat;
            sumLng += marker._latlng.lng;
        });
        averageLat = sumLat / markers.length;
        averageLng = sumLng / markers.length;
        sumLat = 0;
        sumLng = 0;


        var deviationLat = standardDeviation(latArray).deviation;
        var deviationLng = standardDeviation(lngArray).deviation;

        var i = 0;
        markers.forEach(function (marker) {

            if ((Math.abs(marker._latlng.lat - averageLat) < (deviationLat * 1)) &&
                (Math.abs(marker._latlng.lng - averageLng) < (deviationLng * 1))
            ) {
                i++;

                sumLat += marker._latlng.lat;
                sumLng += marker._latlng.lng;

            }
        });

        if (i > 0) {
            averageLat = sumLat / i;
            averageLng = sumLng / i;
        }


        var latlng = {
            lat: averageLat,
            lng: averageLng
        };

        if (latlng.lat && latlng.lng) {
            //var diffLat = maxLat - minLat;
            //var diffLng = maxLng - minLng;
            //var max = Math.abs(diffLat) > Math.abs(diffLng) ? Math.abs(diffLat) : Math.abs(diffLng);
            priceMap.setView(latlng, 13);

        }


    }


    var standardDeviation = function (arr) {
        var n = arr.length;
        var sum = 0;

        arr.map(function (data) {
            sum += data;
        });

        var mean = sum / n;

        var variance = 0.0;
        var v1 = 0.0;
        var v2 = 0.0;

        if (n != 1) {
            for (var i = 0; i < n; i++) {
                v1 = v1 + (arr[i] - mean) * (arr[i] - mean);
                v2 = v2 + (arr[i] - mean);
            }

            v2 = v2 * v2 / n;
            variance = (v1 - v2) / (n - 1);
            if (variance < 0) { variance = 0; }
            stddev = Math.sqrt(variance);
        }

        return {
            mean: Math.round(mean * 100) / 100,
            variance: variance,
            deviation: Math.round(stddev * 100) / 100
        };
    };

    self.showMapAndCompetitors = true;
    self.isMinimized = function (isMap) {
        if (self.showMapAndCompetitors)
            return 'col-md-6';
        else {
            if (isMap) {
                setTimeout(function () { priceMap.invalidateSize() }, 200);
                return 'col-md-12';
            }
            else
                return 'hidden';
        }
    }
    //function GetZoomLevel(maxDiff) {
    //    return 13;


    //    if (maxDiff > 5)
    //        return 4;
    //    if (maxDiff > 3)
    //        return 5;
    //    if (maxDiff > 2)
    //        return 6;
    //    if (maxDiff > 1)
    //        return 7;

    //    if (maxDiff > 0.7)
    //        return 9;

    //    if (maxDiff > 0.5)
    //        return 10;

    //    if (maxDiff > 0.4)
    //        return 11;

    //    if (maxDiff > 0.3)
    //        return 11;

    //    if (maxDiff > 0.2)
    //        return 12;

    //    return 12;
    //}
    //$ctrl.selectedCompetitorGroups
    self.setFilterPriceZone = function (priceZone) {
        if (self.selectedPriceZone == priceZone) return;
        self.selectedPriceZone = priceZone;

        if (priceZone != null) {
            self.selectedPriceZoneName = priceZone.name;
        }
        else
            self.selectedPriceZoneName = '';

        self.selectedCompetitorGroups = [];
        self.competitorGroupsParents = [];

        changePriceUsed();

        loadCompetitorGroupPrices();
        loadCompetitorGroupsParents();
        loadCompetitorGroups();

        //console.log('removiendo', priceZone);

        self.runFilters();
    }

    self.setCompetitorGroupFilter = function (priceSurvey) {

        self.runFilters();
    }

    self.onlyMarkerChanged = function () {
        //console.log('change', self.onlyMarkers);
        self.runFilters();
    }

    self.multizoneViews = function () {
        if (self.multizoneViewsCheckbox) {
            var priceListId = 0
            var states = [];
            if (self.selectedPriceZone != undefined)
                priceListId = self.selectedPriceZone.id;

            angular.forEach(self.competitorsInit, function (c) {
                angular.forEach(c.priceLists, function (pl) {
                    if (pl == priceListId) {
                        if (self.competitorPriceList.length > 0) {
                            states = self.competitorPriceList.map(function (cpl) { return cpl.state });
                            if (!states.includes(c.state)) {
                                self.competitorPriceList.push(c);
                            }
                        }
                        else {
                            self.competitorPriceList.push(c);
                        }
                    }
                });
            });
        }
        else {
            self.competitorPriceList = [];
            self.competitorZones = [];
        }
    }

    self.changeStates = function () {
        self.competitorZones = [];
        var zoneNames = [];
        var allZones = self.allPriceList;
        var zonesIds = self.competitorStates.map(function (s) { return s.priceLists });
        angular.forEach(allZones, function (z) {
            angular.forEach(zonesIds, function (zId) {
                if (zId.includes(z.id)) {
                    if (self.competitorZones.length > 0) {
                        zoneNames = self.competitorZones.map(function (cz) { return cz.name });
                        if (!zoneNames.includes(z.name)) {
                            self.competitorZones.push(z);
                        }
                    }
                    else {
                        self.competitorZones.push(z);
                    }
                }
            });
        });
    }

    self.applyStatesFilters = function () {     

        self.selectedCompetitorGroups = [];
        self.competitorGroupsParents = [];

        changePriceUsed();

        loadCompetitorGroupPricesByPriceListIds();
        loadCompetitorGroupsParentsByPriceListIds();
        loadCompetitorGroupsByPriceListIds();

        self.runFilters();
    }

    function mustShowPriceSurvey(priceSurvey) {
        //console.log('priceSurvey', priceSurvey);

        if (priceSurvey.competitorLat == null || priceSurvey.competitorLong == null) {
            return false;
        }
        if (!self.multizoneViewsCheckbox) {
            if (priceSurvey.priceListName != self.selectedPriceZoneName && self.selectedPriceZoneName != '')
                return false;
        }
        else {
            var rv = true;
            angular.forEach(self.selectedPriceLists, function (pl) {
                if (priceSurvey.priceListName != pl.name && self.selectedPriceLists.length == 1) {
                    rv = false;
                }
            });
            return rv;
        }
        if (self.selectedCompetitorGroups.length > 0) {
            var valid = false;
            if (priceSurvey.competitorGroupIdsList.length > 0 || priceSurvey.source == 'YPF') //TODO: Identificar Brand Propia
                angular.forEach(self.selectedCompetitorGroups, function (competitorGroupSelected) {
                    if (priceSurvey.competitorGroupIdsList.indexOf(competitorGroupSelected.id) != -1 || priceSurvey.source == 'YPF')
                        valid = true;
                });
            if (!valid)
                return false;
        }
        //console.log(self.onlyMarkers, !priceSurvey.isMarker)
        if (self.onlyMarkers && !priceSurvey.isMarker)
            return false;

        //if(priceSurvey.)



        return true
    }

    function addMarker(priceSurvey) {
        if (mustShowPriceSurvey(priceSurvey)) {
            self.priceSurveysFiltered.push(priceSurvey);

            if (priceSurvey.competitorLogo) {
                //var competitorIcon = L.icon({
                //    iconUrl: 'assets/images/competitors/' + priceSurvey.competitorLogo,

                //    iconSize: [24, 24], // size of the icon
                //    iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
                //    popupAnchor: [12, 6] // point from which the popup should open relative to the iconAnchor
                //});

                //console.log('self.price', priceSurvey);

                var imgStr = '<img src="assets/images/competitors/' + priceSurvey.competitorLogo + '" ' +
                    ' class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" ' +
                    'style="width: 20px; height: 20px;top:28px;left:14px;' +
                    //'margin-top: -60px;' +
                    //'margin-left: -24px;' +
                    '" />';
                //console.log('imgstr', imgStr);
                var arrowStr = '<div style="' +
                    'width: 0;' +
                    'height: 0;' +
                    'margin-top: 33px;                           ' +
                    'margin-left: 14px;                          ' +
                    'border-left: 7px solid transparent;         ' +
                    'border-right: 7px solid transparent;        ' +
                    'border-top: 7px solid black;                ' +
                    '"></div>'
                var color;

                //console.log('price ahooora', self.price);   
                if (self.price._Price == undefined) {
                    color = 'gray';
                }
                else if (self.price._Price > priceSurvey.price) {
                    color = 'green';
                }
                else if (self.price._Price < priceSurvey.price) {
                    color = 'red';
                }
                else {
                    color = 'gray';
                }

                var upperDiv = '<div ' +
                    'style="width:100%;font-size:13px; font-weight:bold; height:21px;top:0;background-color:' + color + ';opacity:0.8;color:white;text-align:center;">'
                    + ian.abbreviateNumberThousand(priceSurvey.price, 2, '') + '</div>';

                var mainDivStyles = 'style="height:55px; width:45px; background-color:white;border-radius:5px;border-color: black;' +
                    //'margin-top: -60px;' +
                    //'margin-left: -24px;' +
                    'border-style: solid;' +
                    'border-width: 1px;"';

                var competitorIcon = L.divIcon({
                    html: '<div  ' + mainDivStyles + ' >' +
                    upperDiv + ' ' +
                    imgStr +
                    arrowStr + '</div>',

                    iconSize: [45, 55], // size of the icon
                    className: 'transparent',
                    iconAnchor: [24, 60], // point of the icon which will correspond to marker's location
                    popupAnchor: [0, -54] // point from which the popup should open relative to the iconAnchor
                });
                //console.log('pricesurvey', priceSurvey);
                var popupStr = '<b>' + priceSurvey.competitorCode + ' ' + priceSurvey.competitor + '</b>' +
                    '<br>' +
                    $filter('currency')(priceSurvey.price) +
                    '<br>' +
                    $filter('date')(priceSurvey.observedDate, 'MMM d, y') +
                    '<br>' +
                    self.getPriceSurveyType(priceSurvey.priceSurveyType).name;

                var marker = L.marker([priceSurvey.competitorLat, priceSurvey.competitorLong], { id: priceSurvey.id, icon: competitorIcon }).addTo(priceMap)
                    .bindPopup(popupStr);

                markers.push(marker);
                return marker;
            }
        }
    }

    function loadPriceSurveys(itemId) {
        self.isLoadingSurveys = true;
        if (!self.multizoneViewsCheckbox) {
            var priceListId = 0
            if (self.selectedPriceZone != undefined)
                priceListId = self.selectedPriceZone.id;

            //Vacio el listado para que quede el loading cuando cargo otra zona o cambio filtros
            self.priceSurveys = [];

            priceService.pricesurveys.onlyActive(itemId, priceListId, true).then(function (priceSurveys) {
                self.priceSurveys = priceSurveys;
                console.log('priceSurveys', priceSurveys);

                if (self.priceSurveys.length > 0) {
                    self.itemName = self.priceSurveys[0].itemName;
                    self.itemCode = self.priceSurveys[0].itemCode;
                }

                self.runFilters();
                self.isLoadingSurveys = false;
            });
        }
        else {
            self.priceSurveys = [];
            var priceListIds = self.selectedPriceLists.map(function (cz) { return cz.id }); 

            priceService.pricesurveys.onlyActiveByPriceListIds({ itemId: itemId, priceListIds: priceListIds, includeOwnPrices: true}).then(function (priceSurveys) {
                self.priceSurveys = priceSurveys;
                console.log('priceSurveys', priceSurveys);

                if (self.priceSurveys.length > 0) {
                    self.itemName = self.priceSurveys[0].itemName;
                    self.itemCode = self.priceSurveys[0].itemCode;
                }

                self.runFilters();
                self.isLoadingSurveys = false;
            });
        }
    }

    function loadPriceLists() {

        priceService.pricelists.getPriceLists().then(function (priceList) {
            self.allPriceList = priceList;

            var selected = false;
            for (i = 0; i < self.allPriceList.length; i++) {
                if (self.allPriceList[i].id == self.price.priceListId) {
                    selected = true;
                    self.setFilterPriceZone(self.allPriceList[i]);
                }
            }

            if (selected == false) {
                self.setFilterPriceZone(self.allPriceList[0]);
            }

            priceService.costs.getItems().then(function (items) {
                //console.log('items', items);
                self.items = items;
                for (var i = 0; i < items.length; i++) {
                    if (items[i].id == self.price.itemId) {
                        self.selectedItem = items[i];
                    }
                }

                if (self.selectedItem == undefined) {
                    self.selectedItem = items[0];
                }
                getPrices(self.selectedItem.categoryId);


            });
        });
    }


    function getPrices(categoryId) {
        self.priceSurveys = [];
        self.priceSurveysFiltered = [];
        self.isLoadingSurveys = true;
        //Vacio el listado para que quede el loading cuando cargo otra zona o cambio filtros
        priceService.decisions.getPrices(categoryId, 0).then(function (prices) {
            self.prices = prices;
            //console.log('prices', prices);
            self.isLoadingSurveys = false;
            changePriceUsed();
            loadCompetitorGroupPrices();
        });

    }


    function changePriceUsed() {
        if (self.canChangePriceUsed && self.prices) {


            var selected = false;
            for (var i = 0; i < self.prices.length; i++) {
                //console.log('prices[i].priceListId', prices[i].priceListId, ' == ', 'self.selectedPriceZone.id', self.selectedPriceZone.id);
                if (self.selectedPriceZone == null || self.prices[i].priceListId == self.selectedPriceZone.id) {
                    //console.log('seleccionada', self.selectedPriceZone);
                    //console.log('precio', self.prices[i]);
                    selected = true;
                    self.price = self.prices[i];
                    self.price.priceId = 0;
                }
            }

            if (!selected) {
                self.price = {
                    priceId: 0
                };
            }

        }
        if (self.selectedItem) {

            $timeout(function () {
                loadPriceSurveys(self.selectedItem.id);
            })
        }

    }

    function loadCompetitorsInit() {
        priceService.competitors.competitors().then(function (competitors) {
            self.competitorsInit = competitors;
        });
    }


    function loadData() {

        //loadItems();
        //loadPriceSurveys();
        loadCompetitorsInit();
        loadPriceLists();

    }

    self.displayPhoto = function (price) {

        if (price.priceSurveyType != 2) // solo muestro fotos en extra
            return;
        var modalInstance = $uibModal.open({
            animation: true,
            component: 'displayPhoto',
            windowClass: 'app-modal-window',
            resolve: {
                params: function () {
                    return {
                        url: price.source
                    };
                }
            }
        });
    }


    function loadCompetitorGroupsParents() {
        var priceListId = 0
        if (self.selectedPriceZone != undefined)
            priceListId = self.selectedPriceZone.id;
        priceService.competitors.getCompetitorGroupsParentByPriceList(priceListId).then(function (compGroupsParents) {
            //console.log('compGroupsParents', compGroupsParents);
            self.competitorGroupsParents = compGroupsParents;
        })
    }

    function loadCompetitorGroupsParentsByPriceListIds() {
        var priceListIds = self.selectedPriceLists.map(function (cz) { return cz.id });
        priceService.competitors.getCompetitorGroupsParentByPriceListIds({ priceListIds: priceListIds }).then(function (compGroupsParents) {
            self.competitorGroupsParents = compGroupsParents;
        })
    }

    function loadCompetitorGroups() {

        var priceListId = 0
        if (self.selectedPriceZone != undefined)
            priceListId = self.selectedPriceZone.id;

        priceService.competitors.getCompetitorGroupsByPriceList(priceListId).then(function (compGroups) {
            self.competitorGroups = angular.copy(compGroups);

            if (self.priceSurveysFiltered.length > 0) {
                self.competitorGroupsFilteredByParentAndSurveys = [];
                var includedIds = [];
                angular.forEach(self.competitorGroups, function (cg) {
                    for (var i = 0, len = self.priceSurveysFiltered.length; i < len; i++) {
                        angular.forEach(self.priceSurveysFiltered[i].competitorGroupIdsList, function (groupId) {
                            if (groupId == cg.id && includedids.indexOf(cg.id) == -1) {
                                self.competitorGroupsFilteredByParentAndSurveys.push(cg);
                                includedids.push(cg.id);
                            }
                        });
                        
                    }
                });
            }
            else
                self.competitorGroupsFilteredByParent = angular.copy(compGroups);
        })
    }

    function loadCompetitorGroupsByPriceListIds() {
        var priceListIds = self.selectedPriceLists.map(function (cz) { return cz.id });

        priceService.competitors.getCompetitorGroupsByPriceListIds({ priceListIds: priceListIds }).then(function (compGroups) {
            self.competitorGroups = angular.copy(compGroups);

            if (self.priceSurveysFiltered.length > 0) {
                self.competitorGroupsFilteredByParentAndSurveys = [];
                var includedIds = [];
                angular.forEach(self.competitorGroups, function (cg) {
                    for (var i = 0, len = self.priceSurveysFiltered.length; i < len; i++) {
                        angular.forEach(self.priceSurveysFiltered[i].competitorGroupIdsList, function (groupId) {
                            if (groupId == cg.id && includedids.indexOf(cg.id) == -1) {
                                self.competitorGroupsFilteredByParentAndSurveys.push(cg);
                                includedids.push(cg.id);
                            }
                        });

                    }
                });
            }
            else
                self.competitorGroupsFilteredByParent = angular.copy(compGroups);
        })
    }

    function loadCompetitorGroupPrices() {

        var priceListId = 0
        //console.log('self.selectedPriceZone', self.selectedPriceZone);
        if (self.selectedPriceZone != undefined)
            priceListId = self.selectedPriceZone.id;

        priceService.decisions.getCompetitorsGroupPrices(self.price.itemId, priceListId).then(function (competitorPrices) {
            //console.log('competitorPrices', competitorPrices);
            self.price.competitorsGroupPrices = competitorPrices;

        });
    }

    function loadCompetitorGroupPricesByPriceListIds() {
        var priceListIds = self.selectedPriceLists.map(function (cz) { return cz.id });

        priceService.decisions.getCompetitorsGroupPricesByPriceListIds({ itemId: self.price.itemId, priceListIds: priceListIds }).then(function (competitorPrices) {
            self.price.competitorsGroupPrices = competitorPrices;
        });
    }


    function loadCompetitors(compGroupId) {
        priceService.competitors.getCompetitors(compGroupId).then(function (competitors) {
            //console.log('competitors', competitors);
            self.competitors = competitors;
        })
    }

    self.changeItemSelected = function () {
        //console.log('item changed', self.selectedItem);

        getPrices(self.selectedItem.categoryId);

        self.selectedCompetitorGroups = [];
        self.competitorGroupsParents = [];
        //loadPriceSurveys(self.selectedItem.id);
    }
    
    self.validatePriceSurvey = function (price) {
        swal({
            title: 'Marcar precio como valido?',
            text: 'El precio relevado esta marcado como invalido. Si considera que esto es un error puede marcarlo como valido haciendo clic en "Es Valido"',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1AB394",
            confirmButtonText: "Es Valido",
            cancelButtonText: "Cancelar",
            closeOnConfirm: false,
            closeOnCancel: true
        }, function (isValid) {
            if (isValid) {
                price.isValid = true;
                swal('Exito', 'El precio relevado fue marcado como valido', 'success');
            }
        });
    }

    self.validatePriceSurvey = function (price) {
        swal({
            title: 'Marcar precio como valido?',
            text: 'El precio relevado esta marcado como invalido. Si considera que esto es un error puede marcarlo como valido haciendo clic en "Es Valido"',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1AB394",
            confirmButtonText: "Es Valido",
            cancelButtonText: "Cancelar",
            closeOnConfirm: false,
            closeOnCancel: true
        }, function (isValid) {
            if (isValid) {
                price.isValid = true;
                swal('Exito', 'El precio relevado fue marcado como valido', 'success');
            }
        });
    }

    self.changeCompetitorGroupParents = function () {

        if (self.price.competitorGroupParents != null && self.price.competitorGroupParents.length > 0) {

            self.competitorGroupsFilteredByParent = [];
            angular.forEach(self.competitorGroups, function (competitorGroup) {
                angular.forEach(self.price.competitorGroupParents, function (competitorGroupParent) {
                    if (competitorGroup.parentId == competitorGroupParent.id)
                        self.competitorGroupsFilteredByParent.push(competitorGroup);
                });
            });


            //self.competitorGroupsFilteredByParent = ($filter('filter')(self.competitorGroups, { parentId: self.price.competitorGroupParents.id }));
        }
        else {
            self.selectedCompetitorGroups = [];
            self.competitorGroupsFilteredByParent = self.competitorGroups;
            self.price.competitorGroup = null;
        }

        self.competitorGroupsFilteredByParentAndSurveys = [];
        var includedIds = [];

        angular.forEach(self.competitorGroupsFilteredByParent, function (cg) {
            for (var i = 0, len = self.priceSurveysFiltered.length; i < len; i++) {
                angular.forEach(self.priceSurveysFiltered[i].competitorGroupIdsList, function (grid) {
                    if (groupId == cg.id && includedids.indexOf(cg.id) == -1) {
                        self.competitorGroupsFilteredByParentAndSurveys.push(cg);
                        includedids.push(cg.id);
                    }
                });
            }
        });

        self.competitorGroupsFilteredByParent = angular.copy(self.competitorGroupsFilteredByParentAndSurveys);
    }

    self.getICBoxes = function () {
        if (self.price.competitorGroupParents != undefined) {
            var arr = self.selectedCompetitorGroups.concat(self.price.competitorGroupParents);
            //console.log(arr);
            return arr;
        }
        else {
            return self.selectedCompetitorGroups;
        }

    }

    self.changeCompetitorGroup = function () {
        self.runFilters();
        //if (self.price.competitorGroup != null)
        //    self.selectedCompetitorGroups.push(self.price.competitorGroup);
        //    //loadCompetitors(self.price.competitorGroup.id);
        //else {
        //    self.selectedCompetitorGroups = [];
        //    self.price.competitor = null;
        //    self.competitors = null;
        //}
    }

    var translations = null;

    function init() {
        $translate(["Basket", "Extra", "PriceIndex", "Promotion", "Own", "Web"])
            .then(function (all) {
                translations = all;

                priceSurveyType = [
                    { id: 1, name: translations.Basket, className: "lazur-bg" },
                    { id: 2, name: translations.Extra, className: "yellow-bg" },
                    { id: 3, name: translations.PriceIndex, className: "" },
                    { id: 4, name: translations.Promotion, className: "" },
                    { id: 5, name: translations.Own, className: "navy-bg" },
                    { id: 6, name: translations.Web, className: "gray-bg" },
                ]

            });

        homeService.dashboards.getCountry()
            .then(function (c) {
                country = c;

                document.getElementById('priceMapid-' + self.price.priceId).innerHTML = "<div id='map-" + self.price.priceId + "' style='width: 100%; height: 100%;'></div>";

                if (country == "Peru") {
                    priceMap = L.map('map-' + self.price.priceId).setView([-8.8822898, -73.7699384], 5); //Per�
                    self.moneySymbol = '$';
                }
                if (country == "Colombia") {
                    priceMap = L.map('map-' + self.price.priceId).setView([1.8822898, -73.7699384], 5); //Colombia
                    self.moneySymbol = '$';
                }
                else if (country == "Argentina") {
                    priceMap = L.map('map-' + self.price.priceId).setView([-38.005, -61.0739], 4); //Argentina
                    self.moneySymbol = '$';
                }
                else if (country == "Mexico") {
                    priceMap = L.map('map-' + self.price.priceId).setView([21.695501, -101.666045], 5); //Mexico
                }
                else if (country == "UK") {
                    priceMap = L.map('map-' + self.price.priceId).setView([51.5287718, -0.119114], 5); //UK
                }
                else if (country == "Chile") {
                    priceMap = L.map('map-' + self.price.priceId).setView([-33.4718999, -70.9100212], 4); //Chile
                    self.moneySymbol = '$';
                }

                L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                    subdomains: ['a', 'b', 'c']
                }).addTo(priceMap);


                loadData();
            });
    }


    self.selectPrice = function (p) {
        //console.log('selecting p', p);
        if (self.selectedCompetitor && self.selectedCompetitor.competitorId === p.competitorId) {
            self.selectedCompetitor = null;
        }
        else if (!p.evolutionData) {
            p.evolutionData = {};
            self.selectedCompetitor = p;

            priceService.pricesurveys.getHistorical(p.competitorId, p.itemId, p.salesUnitOfMeasureId, self.price.priceType, p.priceSurveyType == 5).then(function (priceHistory) {
                //console.log('priceHistory', priceHistory);
                p.evolutionData.priceHistory = _.uniqBy(priceHistory, 'observedDate'); //el chart se rompe si hay mas de una fecha de observacion igual
            });

            priceService.competitors.getCompetitorUnitSales(p.competitorId, p.itemId, p.priceSurveyType == 5, 12).then(function (unitsData) {
                //console.log('unitsData', unitsData);
                p.evolutionData.unitsData = unitsData;
            });

        } else {
            self.selectedCompetitor = p;
        }

        self.navigateToCompetitor(p.id)

        //self.toggleTabsRow(p);
    }

    //self.toggleTabsRow = function (p) {

    //    var speed = 100;

    //    if (self.selectedPrice) {
    //        $('#' + p.priceId).attr('hidden', false).show();
    //        $('#' + p.priceId)
    //          .children('td')
    //            .animate({ paddingTop: 8, paddingBottom: 8 }, speed)
    //            .children()
    //            .slideDown(speed);
    //    }
    //    else {
    //        $('#' + p.priceId)
    //          .children('td')
    //            .animate({ paddingTop: 0, paddingBottom: 0 }, speed)
    //            .children()
    //            .slideUp(speed, function () { $('#' + p.priceId).attr('hidden', true).hide(); });
    //    }
    //}

}

angular.module('prisma').component('priceMap', {
    templateUrl: 'app/shared_logic/components/priceMap/priceMap.component.html',    
    bindings: {
        price: '<',
        canChangePriceUsed: '='
    },

    controller: _controller
});


