angular.module("prisma")
 .controller("editItemCtrl", function ($scope, $rootScope, $state, $timeout, $stateParams, promotionsService, homeService, authService, serverService, Upload, ngDialog) {
     var self = this;

     const TAX = 0.18;

     if ($stateParams.position == -2)//interna
         self.loadForInternalPromotion = true;

     self.hasPermissionToEditItem = authService.hasPermission('promotion_edit_promotionItem') || (self.loadForInternalPromotion && !authService.hasPermission('promotion_take_internalPromotion'));

     self.decimalFormat = function (value) {
         if (value) {
             return value.toFixed(2);
         }
     }

     self.integerFormat = function (value) {
         if (value) {
             return value.toFixed(0);
         }
     }

     self.isNew = false;
     self.promotionMediaPage = null;
     self.categoryIds = [];
     self.canLookupItems = false;
     self.isLoading = false;
     self.isLoadingSuggestedPrice = false;
     self.isLoadingMarketPrice = false;
     self.overlaps = [];
     self.variants = [];
     self.chat = '';
     self.inputVisibility = 1;
     self.files = [];
     self.savedFiles = [];
     self.selectedImage = {id: 0, imageUrl: ""};
     self.selectedIndex = 0;
     self.store = null;
     self.promoDetails = "";
     self.paymentTypes = [];
     self.viewVariantInfoReadOnly = false;//permite ver la info de un relacionado en modo solo lectura

     //ITEM LOOKUP
     self.item = null;
     self.itemLookupText = '';
     self.itemsInLookup = [];
     self.selectedItem = null;
     self.loadingLookupItems = false;
     self.lookupItemsNoResults = true;

     self.isBusy = false;

     //campos calculados 
     $scope.$watch('ct.item', function (newVal, oldVal) {
         if (newVal && (newVal.promotionalPrice || newVal.loyaltyPrice)) {
             let bonusOrPromotionalPrice = newVal.loyaltyPrice ? newVal.loyaltyPrice : newVal.promotionalPrice;
             self.item.varP = newVal.actualPrice ? (bonusOrPromotionalPrice / newVal.actualPrice) - 1 : null;
             if (newVal.forecast) {
                 self.item.forecastCash = newVal.forecast * bonusOrPromotionalPrice;
                 self.item.estimatedMarginP = newVal.newCost ? (self.item.forecastCash - (newVal.forecast * (newVal.newCost * (1 + TAX)))) / self.item.forecastCash : 0;
                 self.item.estimatedMarginCash = newVal.newCost ? ((bonusOrPromotionalPrice / (1 + TAX)) - newVal.newCost) * newVal.forecast : 0;
                 self.item.forecastMarginP = (self.item.forecastCash - (newVal.forecast * (newVal.newCost ? (newVal.newCost * (1 + TAX)) : (newVal.internalCost * (1 + TAX))))) / self.item.forecastCash;
                 self.item.forecastMarginCash = ((bonusOrPromotionalPrice / (1 + TAX)) - (newVal.newCost ? newVal.newCost : newVal.internalCost)) * newVal.forecast;
             }
             if (newVal.normalPrice) {
                 self.item.actualInternalIc = (bonusOrPromotionalPrice - newVal.normalPrice) / newVal.normalPrice;
                 self.item.strategyInternalIc = self.item.suggestedPrice? (self.item.suggestedPrice - newVal.normalPrice) / newVal.normalPrice : null;
             }
             if (newVal.marketPrice) {
                 self.item.actualExternalIc = (bonusOrPromotionalPrice - newVal.marketPrice) / newVal.marketPrice;
                 self.item.strategyExternalIc = self.item.suggestedPrice ? (self.item.suggestedPrice - newVal.marketPrice) / newVal.marketPrice : null;
             }

         }
     }, true);


     var previousLookupText = '';

     $scope.$watch('ct.itemLookupText', function (newVal, oldVal) {
         if (newVal) {

             if (newVal.id) {
                 self.selectedItem = self.itemLookupText;
                 self.lastSearchedItem = newVal;
                 getItemInfo($stateParams.promotionId, newVal.id, 0, newVal.saleUnitOfMeasureId);
                 if (self.item)
                     self.item.saleUnitOfMeasureId = self.selectedItem.saleUnitOfMeasureId;//aqui nunca entra self.item es null hasta que vuelva la info del servidor
             }


             if (newVal && newVal != oldVal && (newVal.length == 4 || (oldVal.length < 3 && newVal.length >= 4)) && newVal != previousLookupText) {
                 previousLookupText = newVal;

                 self.itemsInLookup = [{ saleUnitOfMeasureId: 0, id: 0, code: '', name: 'Cargando Items con el texto ' + newVal + '...' }];
                 self.loadingLookupItems = true;
                 var lookupParameter = { searchTerm: newVal, categoryIds: self.categoryIds };
                 homeService.lookups.itemsLookup(lookupParameter)
                 .then(function (items) {
                     self.itemsInLookup = items;
                     self.loadingLookupItems = false;
                     if (items.length > 0)
                         self.lookupItemsNoResults = false;
                     else
                         self.lookupItemsNoResults = true;
                 });
             }
         }

     }, true);

     //VENDORS LOOKUP
     self.vendorsInLookup = [];
     self.loadingLookupVendors = false;
     self.lookupVendorsNoResults = true;

     $scope.$watch('ct.vendorLookup', function (newVal, oldVal) {
         if (newVal) {

             if (newVal && newVal.vendorId != null) {
                 self.item.vendor = self.vendorLookup;
             }

             if (newVal) { // && newVal != oldVal && (newVal.length == 4 || (oldVal.length < 3 && newVal.length >= 4)) && newVal != previousLookupText
                 previousLookupText = newVal;

                 self.vendorsInLookup = [{ id: 0, code: '', name: 'Cargando proveedores con el texto ' + newVal + '...' }];
                 self.loadingLookupVendors = true;
                 self.vendorsInLookup = self.item.promotionItemVendors;
                 self.loadingLookupVendors = false;
                 if (self.item.promotionItemVendors.length > 0)
                     self.lookupVendorsNoResults = false;
                 else
                     self.lookupVendorsNoResults = true;

             }
         }

     }, true);

     self.changeNegotiationType = function (negotiationType) {
         if (negotiationType == 1) {
             self.item.sellIn = !self.item.sellIn;
             self.item.newCost = self.item.sellIn ? self.item.newCost : '';
         }
         else {
             self.item.sellOut = !self.item.sellOut;
             self.item.negotiatedAmount = self.item.sellOut ? self.item.negotiatedAmount : '';
         }
     };

     self.changeProceedToCharge = function (proceedToCharge) {
         self.item.proceedToCharge = proceedToCharge;
         if (proceedToCharge)
             self.getAnnounceFee();
         else { self.item.announceFee = null; self.item.negotiatdAnnounceFee = null; }
     }
 
     self.goToVariantView = function goToVariantView(item) {
         if ($stateParams.promotionItemId != 0) {                 
             if (item.id && item.saleUnitOfMeasureId) {
                 self.viewVariantInfoReadOnly = true;
                 getItemInfo($stateParams.promotionId, 0, item.id, item.saleUnitOfMeasureId);
             }
         }
         else {
             if (item.itemId && item.baseUmvId) {
                 self.viewVariantInfoReadOnly = true;
                 getItemInfo($stateParams.promotionId, item.itemId, 0, item.baseUmvId);
             }

         }

     }

     self.goToPublishedView = function goToPublishedView() {
         if ($stateParams.promotionItemId != 0) {
             self.viewVariantInfoReadOnly = false;
             getItemInfo($stateParams.promotionId, 0, $stateParams.promotionItemId, $stateParams.salesUnitOfMeasureId);
         }
         else {
             self.viewVariantInfoReadOnly = false;
             getItemInfo($stateParams.promotionId, self.lastSearchedItem.id, 0, self.lastSearchedItem.saleUnitOfMeasureId);
         }
     }

     function loadOverlapping(itemId, salesUnitOfMeasureId) {
         //Cargo el Overlapping
         promotionsService.items.getItemOverlaps($stateParams.promotionId, itemId, salesUnitOfMeasureId, $stateParams.promotionMediaPageId, $stateParams.position)
         .then(function (overlaps) {
             self.overlaps = overlaps;
             self.distinctPromotionsOveralps = [];
             _.forOwn(_.groupBy(self.overlaps, "promotionId"), function (value, key) {
                 self.distinctPromotionsOveralps.push(key);
             });
         });
     }

     function getItemInfo(promotionId, itemId, promotionItemId, saleUnitOfMeasureId) {
         self.isLoading = true;

         promotionsService.items.getItemInfo(promotionId, itemId, promotionItemId, saleUnitOfMeasureId)
                 .then(function (item) {
                     self.item = item;
                     self.item.promotionMediaPageId = $stateParams.promotionMediaPageId;
                     self.item.position = $stateParams.position;
                     self.item.isHighlighted = $stateParams.isHighlighted;
                     self.item.isSubstitute = $stateParams.isSubstitute;
                     self.item.promotionItemType = $stateParams.promotionItemType;
                     self.selectedImage.imageUrl = self.item.imageUrl;
                     self.item.marginP = self.item.actualPrice > 0 ? ((self.item.actualPrice / (1 + TAX)) - self.item.internalCost) / (self.item.actualPrice / (1 + TAX)) : 0;
                     if ($stateParams.promotionItemId == 0) {
                         self.item.proceedToCharge = true;
                         self.changeProceedToCharge(true);
                         self.item.sellOut = true;
                         self.item.chargeType = 2;
                         self.item.announceType = 1;
                         self.getAnnounceFee();
                         if (self.item) {
                             var storeId = self.item.promotionItemStores[0].storeId;
                             promotionsService.stores.getStore(storeId).then(function (s) {
                                 promotionsService.items.getLastPromotionItem(s.brandId, self.item.itemId)
                                 .then(function (response) {
                                     self.item.promotionalAction = response.promotionName;
                                     self.item.prevPromotionPrice = response.price;
                                     self.item.promotionalActionValidity = response.validity;
                                 });
                             });
                         }
                     }
                     else if (self.item.announceFee == null) { self.getAnnounceFee(); }

                     self.savedFiles = []; //Clear variable
                     if (self.item.imageUrl)
                         self.savedFiles = [{ id: 0, imageUrl: self.item.imageUrl}];

                     angular.forEach(item.files, function (img) {
                         if (img !== self.item.imageUrl)
                             self.savedFiles.push({ id: self.savedFiles.length, imageUrl: img});
                     })
                     if (($stateParams.position != 0) || ($stateParams.isSubstitute == 'true')) {

                         if ($stateParams.promotionItemId == 0) {//nuevo item, relaciones automaticas
                             promotionsService.items.getVariants(item.itemId)
                             .then(function (variants) {
                                 self.variants = variants;
                             });
                         }
                         else {//traer relaciones automaticas y manuales (si es que se cargaron)
                             promotionsService.items.getPromotionVariants(item.id)
                             .then(function (variants) {
                                 self.variants = variants;
                             });
                         }
                     }

                     //getinventory
                     promotionsService.items.getItemDailyInventory($stateParams.promotionId, item.itemId, $stateParams.promotionItemId)
                     .then(function (stock) {
                         self.item.stockCash = stock.stockCash;
                         self.item.storeStock = stock.storeStock;
                         self.item.cdStock = stock.cdStock;
                         self.item.storesUnderStockDays = Object.keys(stock.storesUnderStockDays).length;
                         self.storesUnderStockDaysDetail = "";
                         Object.keys(stock.storesUnderStockDays).forEach(function (k) {
                             self.storesUnderStockDaysDetail += stock.storesUnderStockDays[k] + " ";
                         });
                         if (self.item.dailyUnits != 0)
                             self.item.stockDays = parseInt((self.item.storeStock + self.item.cdStock)) / self.item.dailyUnits;                    
                     });

                     loadOverlapping(itemId != 0 ? itemId : item.itemId, saleUnitOfMeasureId);

                     self.vendorLookup = self.item.vendor;

                     self.isLoading = false;
                     //calculo suerido y precio mercado
                     self.isLoadingCompetitivePrices = true;
                     promotionsService.items.getCompetitivePrice($stateParams.promotionMediaPageId, $stateParams.promotionItemType, self.item.itemId, saleUnitOfMeasureId)
                         .then(function (response) {
                             self.suggestedForInternal = response.suggestedPriceForInternal;
                             self.suggestedForExternal = response.suggestedPriceForExternal;
                             if (self.suggestedForInternal && self.suggestedForExternal)
                                 self.item.suggestedPrice = Math.min(self.suggestedForInternal, self.suggestedForExternal);
                             else self.item.suggestedPrice = self.suggestedForInternal ? self.suggestedForInternal : self.suggestedForExternal;
                              
                             if (response.priceSurveys && response.priceSurveys.length > 0) {
                                 self.item.marketPrice = $rootScope.arrayAverage(response.priceSurveys.map(function (s) { return s.price; }));
                                 self.surveys = response.priceSurveys;
                                 
                             }
                             self.isLoadingCompetitivePrices = false;
                         });
                    
                 });
     }

     self.openSurveysDialog = function () {
         if (self.surveys && self.surveys.length > 0) {
             var newScope = $scope.$new();
             newScope.surveys = self.surveys;

             ngDialog.open({
                 template: 'surveysDialog',
                 className: 'ngdialog-theme-default ngdialog-theme-custom',
                 scope: newScope
             });
         }
        
     }
     self.openSuggestionDetailsDialog = function () {
         if (self.suggestedForExternal || self.suggestedForInternal) {
             ngDialog.open({
                 template: 'suggestionDetailsDialog',
                 className: 'ngdialog-theme-default ngdialog-theme-custom',
                 scope: $scope
             });
         }

     }
     
     self.getAnnounceFee = function () {

         if (self.item) {
             var storeId = self.item.promotionItemStores[0].storeId;
             promotionsService.stores.getStore(storeId).then(function (s) {
                 self.item.announceFee = promotionsService.calculateTariff(s.brand, self.item.announceType);
                 self.item.negotiatdAnnounceFee = self.item.announceFee;
             });
         }
     }

     // solution #2
     //$scope.$watch(function () {
     //    for (var i = 0; i < self.savedFiles.length; i++) {
     //        if (self.savedFiles[i].active) {
     //            return self.savedFiles[i];
     //        }
     //    }
     //}, function (currentSlide, previousSlide) {
     //    if (currentSlide !== previousSlide) {
     //        self.selectedImage = currentSlide.imageUrl;
     //    }
     //});
     // solution #3
     $scope.$watch('active', function (newIndex, oldIndex) {
         if (Number.isFinite(newIndex) && newIndex !== oldIndex) {
             self.selectedImage = self.savedFiles[newIndex];
         }
     });


     self.uploadFiles = function (files, errFiles) {
         if (errFiles && errFiles.length > 0) {
             var allowedSize = null;
             var largeFiles = '';

             angular.forEach(errFiles, function (file) {
                 if (file.$error == 'maxSize') {
                     if (allowedSize == null)
                         allowedSize = file.$errorParam;
                     if (largeFiles != '')
                         largeFiles += ', ';
                     largeFiles += ian.format(' {0} ({1}KB)', file.name, (file.size / 1024).toFixed(0));
                 }
             });

             swal('Peso de imagenes excedido', ian.format('El peso maximo para cada imagen es {0}. Las siguientes imagenes exceden el peso permitido: {1}', allowedSize, largeFiles), 'error');
         }
         else if (files && files.length && self.item.code != '') {
             self.isBusy = true;
             var url = ian.urlCombine(serverService.getApiUrl(), '/promotion/items/uploadFile/' + self.item.code);
             Upload.upload({
                 url: url,
                 data: { files: files }
             }).then(function (response) {
                 var uploadResults = response.data;
                 processResults(uploadResults);
                 if (!anyError(uploadResults))
                     self.filesUploaded = true;
             });
         }

     }

     function loadResults(results) {
         if ($.isArray(results)) {
             angular.forEach(results, function (imageUrl) {
                 self.savedFiles.push({id: self.savedFiles.length, imageUrl: imageUrl});
             });
         } else {
             self.savedFiles.push({id: self.savedFiles.length, imageUrl: results.parameter1});
         }
     }

     function processResults(uploadResults) {
         self.isBusy = false;
         if (uploadResults.length > 0) {
             loadResults(uploadResults);
         }
         if (anyError(uploadResults)) {
             self.error = true;
         }
         else {
             self.filesUploaded = true;
             self.error = false;
             self.files = [];
         }
     }

     function anyError(uploadResults) {
         var error = false;
         for (var i = 0; i < uploadResults.length; i++) {
             if (uploadResults[i].severity === 3)
                 error = true;
         }

         return error;
     }

     self.showOverlapping = function () {

         var newScope = $scope.$new();
         newScope.overlaps = self.overlaps;

         ngDialog.open({
             template: 'overlapDialog',
             className: 'ngdialog-theme-default ngdialog-theme-custom',
             scope: newScope
         });
     };

     self.goToLayout = function () {
         var state = 'promotions.addItemToLayout';
         if (self.isReview)
             state = 'promotions.2reviewTradeAgreements';
         else if (self.isPrintLayoutCompare)
             state = 'promotions.printLayoutCompare';
         else if (self.isLayoutImageUpload)
             state = 'promotions.layoutImageUpload';
         else if (self.isFinalValidation)
             state = 'promotions.finalValidation';

         $state.go(state, {
             promotionId: $stateParams.promotionId,
             taskId: $stateParams.taskId,
             promotionMediaPageId: $stateParams.promotionMediaPageId
         });
     }

     self.goToEditPromotion = function () {
         $state.go('promotions.editInternalPromotion', {
             promotionId: $stateParams.promotionId,
             promotionMediaPageId: $stateParams.promotionMediaPageId
         });
     }


     //valida precio promocional, y precio/pts bonus
     function validatePrices() {
         if (self.item.promotionalPrice != undefined || self.item.loyaltyPrice != undefined) {
             if ((self.item.loyaltyPrice == undefined && self.item.loyaltyPoints == undefined) || (self.item.loyaltyPrice == 0 && self.item.loyaltyPoints == 0) || (self.item.loyaltyPrice != undefined && self.item.loyaltyPoints != undefined)) {
                 if ((self.item.loyaltyPrice != undefined && self.item.promotionalPrice != undefined && self.item.loyaltyPrice <= self.item.promotionalPrice) || self.item.loyaltyPrice == undefined || self.item.promotionalPrice == undefined) {
                     return true;
                 }
                 else {
                     $timeout(function () {
                         swal("Error", "Precio bonus no debe superar al promocional", "error");
                     }, 100);
                 }
             }
             else {
                 $timeout(function () {
                     swal("Error", "Si existe 'precio bonus' 'puntos bonus' es obligatorio y viceversa", "error");
                 }, 100);
             }
         }
         else {
             $timeout(function () {
                 swal("Error", "Debe cargar el precio promocional o el precio bonus", "error");
             }, 100);
         }

     }

     function anyItemInPhoto() {
         //si no tiene variantes entonces es item en foto
         if (!self.variants.length)
             self.item.isItemInPhoto = true;

         var rv = self.item.isItemInPhoto ||
             self.variants.some(function (x) { return x.isItemInPhoto; });
         if (!rv) {
             $timeout(function () {
                 swal("Error", "Debe seleccionar al menos un articulo en foto.", "error");
             }, 100);
         }
         return rv;
     }

     self.saveComment = function () {
         if (self.chat != '' && $stateParams.promotionItemId != 0) {
             var newChat = {
                 id: 0,
                 promotionItemId: $stateParams.promotionItemId,
                 promotionMediaPageId: $stateParams.promotionMediaPageId,
                 message: self.chat.replace(/(\r\n|\n|\r)/gm, '')
             };
             promotionsService.mechanics.saveChat(newChat)
             .then(function (isOk) {
                 swal('Comentario guardado!', '', 'success');
             }, function (status) {
                 if (status == 502) {
                     swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                 }
                 else {
                     swal("Error", "Hubo un error al guardar", "error");
                 }
             });
         }
     }

     self.save = function () {
         if (self.item != null && validatePrices() && anyItemInPhoto()) {
             if (!self.isReview || $stateParams.position == 0) {
                 swal({
                     title: 'Esta seguro que desea asociar este Item a esta Página?',
                     type: "warning",
                     showCancelButton: true,
                     confirmButtonColor: "#1AB394",
                     confirmButtonText: "Continuar",
                     cancelButtonText: "Cancelar",
                     showLoaderOnConfirm: true,
                     closeOnConfirm: false,
                     closeOnCancel: true
                 },
             function (isConfirm) {
                 if (isConfirm) {

                     self.item.imageUrl = self.selectedImage.imageUrl;
                     if (self.chat != '') {
                         var newChat = {
                             promotionItemId: self.item.id,
                             promotionMediaPageId: self.item.promotionMediaPageId,
                             message: self.chat
                         };
                         self.item.chats.push(newChat);

                     }
                     ////si no comento nada y hay un nuevo chat se lo borro
                     //if (self.chat == '' && self.item.chats[self.item.chats.length - 1].username === undefined) {
                     //    self.item.chats.splice(self.item.chats.length - 1, 1);
                     //}                             
                     self.chat = '';

                     //info de relacionados (isItemInPhoto)
                     var variantItemsInPhoto = self.variants.filter(function (x) {
                         return x.isItemInPhoto;
                     });

                     self.item.variantItemsInPhoto = variantItemsInPhoto.map(function (x) {
                         return x.itemCode || x.code;
                     });

                     promotionsService.items.savePromotionItem(self.item)
                     .then(function (item) {
                         if (item.id != 0) {
                             swal('Item Asociado!', 'El item ' + self.item.name + ' se ha asociado correctamente', 'success');
                         }
                         else {
                             swal('Error', 'La posicion ' + self.item.position + ' se encuentra actualmente ocupada', 'error');
                         }
                         if (!self.loadForInternalPromotion)
                             self.goToLayout();
                         else self.goToEditPromotion();


                     }, function (status) {
                         if (status == 502) {
                             swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                         }
                         else {
                             swal("Error", "Hubo un error al guardar", "error");
                         }
                     });


                 }
             });
             }
             else
                 saveReview();
         }

     }

     function saveReview() {

         swal({
             title: 'Está seguro que desea guardar?',
             text: 'Solo se guardará precio promocional, precio bonus, puntos bonus, pronostico y comentarios',
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: "Continuar",
             cancelButtonText: "Cancelar",
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
      function (isConfirm) {
          if (isConfirm) {
              if (self.chat != '') {
                  var newChat = {
                      promotionItemId: self.item.id,
                      promotionMediaPageId: self.item.promotionMediaPageId,
                      message: self.chat
                  };
                  self.item.chats.push(newChat);
                  self.chat = '';
              }
              //info de relacionados (isItemInPhoto)
              var variantItemsInPhoto = self.variants.filter(function (x) {
                  return x.isItemInPhoto;
              });

              self.item.variantItemsInPhoto = variantItemsInPhoto.map(function (x) {
                  return x.itemCode || x.code;
              });
              promotionsService.items.saveReview(self.item)
              .then(function (item) {
                  swal('Edición exitosa!', 'El item ' + self.item.name + ' se ha modificado correctamente', 'success');
                  self.goToLayout();
              }, function (status) {
                  if (status == 502) {
                      swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                  }
                  else {
                      swal("Error", "Hubo un error al guardar", "error");
                  }
              });
          }
      });

     }


     self.deleteChat = function (chatId) {

         swal({
             title: 'Borrar el comentario ?',
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#1AB394",
             confirmButtonText: "Continuar",
             cancelButtonText: "Cancelar",
             showLoaderOnConfirm: true,
             closeOnConfirm: false,
             closeOnCancel: true
         },
      function (isConfirm) {
          if (isConfirm) {

              promotionsService.items.deleteItemChat(chatId)
                   .then(function (isOk) {
                       swal('Comentario Borrado!', 'se ha borrado correctamente', 'success');
                       promotionsService.items.getItemInfo($stateParams.promotionId, 0, $stateParams.promotionItemId, self.item.saleUnitOfMeasureId)
                       .then(function (item) {
                           self.item.chats = item.chats;
                       });

                   }, function (status) {
                       if (status == 502) {
                           swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                       }
                       else {
                           swal("Error", "Hubo un error al borrar el comentario", "error");
                       }
                   });


          }

      });
     }


     function init() {
         self.isNew = $stateParams.promotionItemId == 0 ? true : false;
         self.announcementTypes = promotionsService.promotionMechanicAnnouncementTypes;
         self.paymentTypes = promotionsService.promotionMechanicPaymentTypes;
         self.promotionExhibitionType = promotionsService.promotionExhibitionType;
         if ($stateParams.promotionItemId != 0) {
             getItemInfo($stateParams.promotionId, 0, $stateParams.promotionItemId, $stateParams.salesUnitOfMeasureId);
         }

         promotionsService.tasks.getTaskName($stateParams.taskId)
           .then(function (taskName) {
               switch (taskName) {
                   case "2° Revisión Comercial": self.isReview = true;
                       break;
                   case "1° Input para armado de Pieza": self.isPrintLayoutCompare = true;
                       break;
                   case "2° Input para armado de Pieza": self.isPrintLayoutCompare = true;
                       break;
                   case "3° Input para armado de Pieza": self.isPrintLayoutCompare = true;
                       break;
                   case "1° Subida de Pieza para revisión": self.isLayoutImageUpload = true;
                       break;
                   case "2° Subida de Pieza para revisión": self.isLayoutImageUpload = true;
                       break;
                   case "3° Subida de Pieza para revisión": self.isLayoutImageUpload = true;
                       break;
                   case "Validación final": self.isFinalValidation = true;
                       break;
               }

           });

         //Cargo los datos de la página con sus categorias asociadas
         if ($stateParams.promotionMediaPageId != 0) {
             promotionsService.promotions.getPromotionMediaPage($stateParams.promotionMediaPageId)
                 .then(function (promotionMediaPage) {
                     self.promotionMediaPage = promotionMediaPage;

                     self.categoryIds.push(promotionMediaPage.mainCategoryId);
                     for (var i = 0; i < promotionMediaPage.secondaryCategoriesIds.length; i++) {
                         self.categoryIds.push(promotionMediaPage.secondaryCategoriesIds[i]);
                     }

                     self.canLookupItems = true;
                 });
         }

         promotionsService.promotions.getPromotionData($stateParams.promotionId, $stateParams.promotionMediaPageId, $stateParams.position)
         .then(function (promotionInfo) {
             self.promoDetails = promotionInfo;
         });
     }


     init();
 });