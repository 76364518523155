"use strict";

angular.module("prisma")
    .service("adminService", function adminService($rootScope, httpService) {
        var self = this;

        self.importers = {
            uploadFile: function (file, importer) {

                var fd = new FormData();
                //Take the first selected file
                fd.append("file", file);

                httpService.post('/admin/importer/uploadFile', fd, {
                    headers: { 'Content-Type': undefined },
                    transformRequest: angular.identity,
                    data: { 'importerName': importer, 'username': '' }
                })
            },
            processFiles: function (companyId, importer) {
                return httpService.get('/admin/importer/processFiles/' + companyId + "/" + importer);
            },
            validateDataQuality: function (companyId, importer) {
                return httpService.get('/admin/importer/validateDataQuality/' + companyId + "/" + importer);
            },
            importData: function (companyId, importer) {
                return httpService.get('/admin/importer/importData/' + companyId + "/" + importer);
            },
            removeAllSensitivities: function () {
                return httpService.get('/admin/importer/removeAllSensitivities');
            },
            getLastUploadedFile: function (importerType) {
                return httpService.get('/admin/importer/getLastUploadedFile/' + importerType);
            }
        }

        self.reports = {
            getItemsToIgnore: function () {
                return httpService.get('/admin/reports/getItemsToIgnore');
            },
            getItemsToIgnoreLastReport: function () {
                return httpService.get('/admin/reports/getItemsToIgnoreLastReport');
            },
            exportItemsToIgnore: function () {
                return httpService.get('/admin/reports/exportItemsToIgnore');
            }
        }

        self.auditTrail = {
            getByObject: function (objectId, objectType, days) {
                return httpService.get('admin/auditTrail/getByObject/' + objectId + '/' + objectType + (days ? '/' + days : ''));
            },
        }

        self.items = {
            saveItemTags: function (tagIdsToSave, itemId) {
                return httpService.post('admin/items/save-tags/' + itemId, tagIdsToSave);
            },
            getNoCategoryItems: function () {
                return httpService.get('admin/items/getNoCategoryItems');
            },
            saveItemCategories: function (categoryId, subCategoryId, itemEansToAplyCategory) {
                return httpService.post('admin/items/saveItemCategories/' + subCategoryId, itemEansToAplyCategory);
            }, 
            saveManagementItems: function(itemsToSave) {
                return httpService.post('admin/items/saveManagementItems', itemsToSave);
            }, 
            getItemsByCategory: function (categoryId) {
                return httpService.get('admin/items/getItemsByCategory/' + categoryId);
            },
            getItemsByCategoryWithUnitOfMeasures: function (categoryId) {
                return httpService.get('admin/items/getItemsByCategoryWithUnitOfMeasures/' + categoryId);
            },
            getItemsForManagement: function (params) {
                return httpService.post('admin/items/getItemsForManagement' , params);
            },
            getManufacturers: function () {
                return httpService.get('admin/items/getManufacturers');
            },
            getItemBrands: function () {
                return httpService.get('admin/items/getItemBrands');
            }
        }

        self.tags = {
            getTags: function () {
                return httpService.get('admin/tags/getTags/' + $rootScope.companyId);
            },
            getTagGroups: function () {
                return httpService.get('admin/tags/getTagGroups/' + $rootScope.companyId);
            },
            saveTag: function (tag) {
                 return httpService.post('admin/tags/saveTag', tag);
            }
        }

        self.clusters = {

            getBrands: function () {
                return httpService.get('admin/cluster/getBrands');
            },
            getRegions: function () {
                return httpService.get('admin/cluster/getRegions');
            },
            getClusters: function () {
                return httpService.get('admin/cluster/getClusters');
            },
            getClustersByCategory: function (categoryId) {
                return httpService.get('admin/cluster/getClustersByCategory/' + categoryId);
            },
            deleteCluster: function (clusterId) {
                return httpService.delete('admin/cluster/delete-cluster/' + clusterId);
            },
            saveCluster: function (cluster) {
                return httpService.post('admin/cluster/save-cluster', cluster);
            },
            validateClusterCode: function (code) {
                return httpService.get('admin/cluster/validateClusterCode/' + code);
            }
        }

        self.missions = {
            getMissions: function () {
                return httpService.get('admin/mission/getMissions/');
            },
            getMission: function (id) {
                return httpService.get('admin/mission/getMission/' + id);
            },
            getMissionEnums: function () {
                return httpService.get('admin/mission/getMissionEnums');
            },
            getAgentCompetitors: function () {
                return httpService.get('admin/mission/GetAgentCompetitors');
            },
            saveMission: function (mission) {
                return httpService.post('admin/mission/saveMission', mission);
            },
            deleteMission: function (missionId) {
                return httpService.get('admin/mission/deleteMission/' + missionId);
            },
        }

        self.stores = {
            getStores: function (excluded) {
                return httpService.get('admin/store/getStores/' + excluded);
            },
            getDistributionCentersCombo: function () {
                return httpService.get('admin/store/GetDistributionCentersCombo');
            },
            getStoresCombo: function () {
                return httpService.get('admin/store/getStoresCombo');
            },
            getStore: function (storeId) {
                return httpService.get('admin/store/getStore/' + storeId);
            },
            doesStoreCodeExists: function (storeCode, storeId) {
                return httpService.get('admin/store/doesStoreCodeExists/' + storeCode + '/' + storeId);
            },
            deleteStore: function (storeId) {
                return httpService.get('admin/store/deleteStore/' + storeId);
            },
            saveStore: function (store) {
                return httpService.post('admin/store/saveStore', store);
            },
            getStoreFormats: function () {
                return httpService.get('admin/store/getStoreFormats/');
            },
            getCustomerTypes: function () {
                return httpService.get('admin/store/getCustomerTypes/');
            },
            getStoreByClusters: function (clusterIds) {
                return httpService.post('admin/store/getStoreByClusters', clusterIds)
            },
            getStoresZoning: function () {//LaRebaja necesita ver tiendas zonificadas
                return httpService.get('admin/store/getStoresZoning');
            }  
        }

        self.vendors = {
            getVendorsCombo: function () {
                return httpService.get('admin/store/GetVendorsCombo');
            },
        }

        self.brands = {
            getBrandsCombo: function (onlyOwn) {
                return httpService.get('admin/store/GetBrandsCombo/' + onlyOwn);
            },
            getBrands: function () {
                return httpService.get('admin/store/getBrands');
            },
             saveBrand: function (brand, isChangedIcon) {
                 return httpService.post('admin/store/saveBrand/' + isChangedIcon, brand);
            }

        }

        self.places = {
            getPlacesCombo: function () {
                return httpService.get('admin/store/GetPlacesCombo');
            }
        }

        self.priceLists = {
            getPriceLists: function () {
                return httpService.get('admin/pricelist/getPriceLists');
            },
            getStorePriceLists: function () {
                return httpService.get('admin/pricelist/getStorePriceLists');
            },            
            getMarkets: function() {
                return httpService.get('admin/pricelist/getMarkets');
            }
        }

        self.priceListsSubsidies = {
            getPriceListsSubsidies: function () {
                return httpService.get('admin/pricelist/getPriceListsSubsidies');
            },
            getStorePriceListsSubsidies: function () {
                return httpService.get('admin/pricelist/getStorePriceListsSubsidies');
			}
		}


        self.categories = {
            getCategorySector: function () {
                return httpService.get('admin/category/getCategorySector');
            },
            getCategoryGroups: function () {
                return httpService.get('admin/category/getCategoryGroups');
            },
            getCategories: function () {
                return httpService.get('admin/category/getCategories');
            },
            getCategoriesWithSubcategories: function () {
                return httpService.get('admin/category/getCategoriesWithSubcategories');
            },
            getSubCategories: function () {
                return httpService.get('admin/category/getSubCategories');
            },
            getCategoriesWithLevels: function (levels) {
                return httpService.post('admin/category/getCategoriesWithLevels', levels);
            }
        }

        self.interfaces = {
            getByBatch: function (batchIdentifier) {
                return httpService.get('admin/interfaces/getByBatch/' + batchIdentifier);
            },
            getByDate: function (date) {
                return httpService.post('admin/interfaces/getByDate', date);
            },
            getLast: function (batchIdentifier) {
                return httpService.get('admin/interfaces/getLast');
            },
            getDetails: function (interfaceLogId) {
                return httpService.get('admin/interfaces/getDetails/' + interfaceLogId);
            },
            getDetailSummary: function (interfaceLogId) {
                return httpService.get('admin/interfaces/getDetailSummary/' + interfaceLogId);
            },
            getFile: function (importerType, filename) {
                return httpService.get('admin/interfaces/getFile/' + importerType + '/' + filename);
            }
        }

        self.security = {
            getRoles: function () {
                return httpService.get('admin/security/roles');
            },
            getUsers: function () {
                return httpService.get('admin/security/users');
            },
            getStores: function () {
                return httpService.get('admin/security/stores');
            },
            getCategories: function () {
                return httpService.get('admin/security/categories');
            },
            getPermissions: function () {
                return httpService.get('admin/security/permissions');
            },
            changeUserRole: function (changeUserRoleDto) {
                return httpService.post('admin/security/changeUserRole', changeUserRoleDto);
            },
            resetUserPassword: function (resetUserPasswordDto) {
                return httpService.post('admin/security/resetUserPassword', resetUserPasswordDto);
            },
            assignStoresToUser: function (assignStoresToUserDto) {
                return httpService.post('admin/security/assignStoresToUser', assignStoresToUserDto);
            },
            assignCategoriesToUser: function (assignCategoriesToUserDto) {
                return httpService.post('admin/security/assignCategoriesToUser', assignCategoriesToUserDto);
            },
            assignPermissionsToRole: function (assignPermissionsToRoleDto) {
                return httpService.post('admin/security/assignPermissionsToRole', assignPermissionsToRoleDto);
            },
            saveUser: function (userDto) {
                return httpService.post('admin/security/saveUser', userDto);
            },
            disableUser: function (disableUserDto) {
                return httpService.post('admin/security/disableUser', disableUserDto);
            },
            createRole: function (roleName, observations) {
                return httpService.get('admin/security/createRole/' + roleName + '/' + observations);
            },
            getSecurityAuditTrails: function (fromDate, toDate) {
                return httpService.get('admin/security/auditTrails/' + fromDate + '/' + toDate);
            },
        }

        self.notifications = {
            getTop5: function () {
                return httpService.get('admin/notifications/getTop5');
            }
        }
    });

