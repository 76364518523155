angular.module("prisma")
    .controller("promotionSearchCtrl", function ($rootScope, $scope, $timeout, $stateParams, $state, promotionsService, authService, ngDialog) {
        var self = this;

        self.isLoadingPromotions = false;
        self.promotions = [];
        self.search;

        self.advancedSearch = function () {
            if (self.search.length >= 3) {
                self.isLoadingPromotions = true;
                promotionsService.promotions.getPromotionsByAdvancedSearch(self.search)
                    .then(function (promotions) {
                        self.promotions = promotions;
                        self.isLoadingPromotions = false;
                        console.log(self.promotions);
                    });
            }
            else {
                swal("Error", "se necesita una busqueda de 3 caracteres o mas", "error");
            }
        }

});