
angular.module("prisma")
    .config(function ($translateProvider, companyJson) {


        ////use preferred and fallback
        //$translateProvider
        //    .fallbackLanguage(companyJson.Translations.FallbackLanguage)
        //    .preferredLanguage(companyJson.Translations.PreferredLanguage);

        ////load preferred language
        //$.getScript("assets/translations/" + companyJson.Translations.PreferredLanguage + ".js")
        //    .done(function (translation) {
        //        $translateProvider.translations(companyJson.Translations.PreferredLanguage, getTranslation());
        //    });

        ////load fallback language
        //if (companyJson.Translations.FallbackLanguage != companyJson.Translations.PreferredLanguage) {
        //    $.getScript("assets/translations/" + companyJson.Translations.FallbackLanguage + ".js")
        //        .done(function (translation) {
        //            $translateProvider.translations(companyJson.Translations.FallbackLanguage, getTranslation());
        //        });
        //}

        $translateProvider
            .translations("es", {
                Points: "Puntos",
                UnitSales: "Venta Unitaria",
                Price: "Precio",
                Cost: "Costo",
                Benchmark: "Comparación",
                TotalUnits: "Unidades totales",
                YesterdayPrice: "Precio de ayer",
                Inventory: "Inventario",
                Prices: "Precios",
                Range: "Surtido",
                Language: "Idioma",
                English: "Inglés",
                Spanish: "Español",
                ProductCost: "Costo del producto",
                DiffInDollars: "Diferencia en pesos",
                NotificationTitle: "Cayo el margen bruto",
                NotificationSubtitle: "La Coca Cola 1.5L fue el producto con mayor caida en margen bruto en todo el negocio.",
                TheReasons: "Las Razones",
                Reason1: "Aumento el costo y no se actualizo el precio de venta",
                Iagree: "Estoy de acuerdo!",
                Idesagree: "No estoy de acuerdo...",
                Discard: "Descartar",
                Reason2: "Cayo el volumen de ventas",
                SubReason1: "Se detecto estacionalidad semanal, los domingos se vende mas que los lunes.",
                ChartTitle: "Gráfico de Evolución: ",
                ChartTitleStrong: "Coca Cola 1.5L",
                Week: "Semana",
                Month: "Mes",
                Year: "Año",
                SalesInDollars: "Venta ($)",
                MarginInDollars: "Margen ($)",
                ChartFooter: "Indicadores Disponibles: Unidades, Precio, Venta, Margen",
                PricesTableTitle: "Precios de Competidores",
                PricesTableTitleCol1: "Competidor",
                PricesTableTitleCol2: "Fecha",
                PricesTableTitleCol3: "Precio",
                PricesTableTitleCol4: "En Promoción",
                PricesTableTitleCol5: "Dirección",
                MapTitle: "Ubicación Competidor: ",
                Back: "Volver",
                WeatherTitle: "Clima de ayer",
                InSales: "En Ventas",
                WeatherDesc: "El último dia registrado con clima similar se vendieron 54 unidades más.",
                InventoryTitle: "Indicadores de Inventario",
                InventoryDays: "Dias de Inventario",
                InventoryComment: "Son los dias restantes de inventario.",
                GMROI: "GMROI",
                InventoryFooter: " de margen por cada $ invertido en inventario",
                RangeTitle: "Indicadores de Surtido",
                RangeLine1: " de la categoría Bebidas en la tienda 1",
                RangeLine1Sub: " subio 2 posiciones",
                RangeLine2: " de la categoría Bebidas en toda la red",
                RangeLine2Sub: " subio 5 posiciones",
                RangeLine3: " de la categoría Bebidas en el mercado",
                RangeLine3Sub: " bajo 1 posición",
                StoreManager: "Gerente de tienda",
                Profile: "Perfil",
                Contacts: "Contactos",
                Mailbox: "Mensajes",
                Logout: "Salir",
                Home: "Inicio",
                SmartDashboard: "Tablero Inteligente",
                Maps: "Mapas",
                DeviationAnalysis: "Análisis de desvios",
                WeatherAnalysis: "Análisis del clima",
                BcgMatrix: "Matriz BCG",
                Pivot: "Pivot",
                Notifications: "Notificaciones",
                NEW: "NUEVO",
                Decisions: "Decisiones",
                Elasticities: "Elasticidades",
                PromotionAnalysis: "Análisis de Promociones",
                Sensitivity: "Sensibilidades",
                RulesAndStrategies: "Estrategia y Reglas",
                Competitors: "Competidores",
                RangeAnalysis: "Análisis de Surtido",
                Segmentation: "Segmentación",
                TicketAnalysis: "Análisis de Tickets",
                BasketAnalysis: "Análisis de Canasta",
                Spaces: "Espacios",
                Planograms: "Planogramas",
                Layouts: "Planos",
                SpaceAnalysis: "Análisis de Espacios",
                StockAnalysis: "Análisis de Stocks",
                OrderSuggestions: "Sugerencias de Pedido",
                Suppliers: "Proveedores",
                Administration: "Administración",
                GeneralSettings: "Configuración General",
                Importers: "Importadores",
                Masters: "Maestros",
                DiscardConfirmTitle: "¿Está seguro?",
                DiscardConfirmSub: "La razón será descartada",
                YesDiscard: "Si, descartar",
                DiscardMessageTitle: "Descartada!",
                DiscardMessageComment: "No volverá a ver esta razón.",
                ThanksForFeedback: "Gracias por tu feedback!",
                ThanksForFeedbackComment: "Tus colaboración además de ayudarnos a mejorar, te permite sumar ",
                ThanksForFeedbackPoints: " puntos",
                ThanksForFeedbackTitle2: "Suma puntos con tu Feedback",
                DisagreeTitle: "No estoy de acuerdo",
                DisagreeSub: "Cuentanos cual crees que fue el motivo",
                Send: "Enviar",
                Costs: "Costos",
                CompetitorsPrices: "Precios de competidores",
                CompetitivePosition: "Posicion Competitiva",
                MarketAnalysis: "Análisis de Mercado",
                Clusters: "Clusters",
                StoresIncluded: "Tiendas Incluidas",
                Stores: "Tiendas",
                Store: "Tienda",
                StoresToBeIncludedByCategories: "Seleccione Tiendas a incluir por Categoría",
                TypeOfStore: "Tipo de Tienda",
                SelectTypeOfStore: "--Selecionar tipo de Tienda--",
                AddStore: "Agregar Tienda",
                StoreMasters: "Maestro de Tiendas",
                AssignStores: "Asignar Tiendas",
                Close: "Cerrar",
                AssignmentOfStores: " Asignacion de Tiendas",
                ByStore: "Por Tienda",
                ByStores: "Por Tiendas",
                TopNavbarSearch: "Buscar Productos, Categorías, Tiendas y Clusters",
                BenchmarksStepOne: "Seleccione al menos 2 Tiendas o Categorías o Cluster para comparar",
                StoresToCompare: "Tiendas a comparar",
                SelectStores: "Seleccione Tiendas",
                PerformanceByProvinceSubtitle: "El siguiente es un resumen de densidad de Tiendas por provincia y su performance",
                PerformanceByProvinceSubtitle2: "El siguiente es un resumen de densidad de Tiendas por provincia y su performance (con drill-down por Departamento)",
                TotalStores: "Total Tiendas:",
                BaseStore: "Tienda base",
                SelectStore: "Seleccione Tienda",
                MirrorStore: "Tienda espejo",
                ChooseComparableStores: "Elegir Tiendas comparables",
                MaintainThePriceOfMirrorStores: "Mantener el precio de las Tiendas espejo igual",
                PriceChangesReportSubtitle: "El siguiente reporte muestra un resumen de los cambios de precios informados al ERP por fecha de vigencia y Tiendas",
                AddPricesArea: "Agregar Zona de Precios",
                Name: "Nombre",
                National: "Nacional",
                Enabled: "Habilitada",
                Yes: "Si",
                ToDisable: "Deshabilitar",
                EnableTooltip: "Habilitar",
                Save: "Guardar",
                ItsBase: "Es Base",
                ItsNational: "Es Nacional",
                Excluded: "Está Excluido",
                ExplicationCompetitors: "Administre aqui los Grupos de Competidores y sus Puntos de Venta para poder establecer reglas de posicionamiento competitivo",
                NewGroup: "Nuevo Grupo",
                NewCompetitor: "Nuevo Competidor",
                CompetitorsGroup: "Grupo de Competidores",
                DragCompetitorsHere: "ARRASTRE COMPETIDORES AQUÍ",
                OnlyNew: "Solo nuevos",
                WithoutGroups: "Sin Grupos",
                Logo: "Logo",
                SonOf: "Hijo de",
                SelectFather: "--Seleccionar Padre--",
                SelectFamily: "--Selecione Familia--",
                ExternalCode: "Codigo Externo",
                Address: "Dirección",
                Brand: "Marca",
                CorporateName: "Nombre Corporativo",
                Latitude: "Latitud",
                Length: "Longitud",
                Marker: "Marcador",
                Observe: "Observar",
                SelectPriceZones: "Seleccione Zonas de Precios",
                CreateSensitivity: "Crear Sensibilidad",
                Order: "Orden",
                SurveyFrequency: "Frecuencia de Relevamiento",
                Expiration: "Expiracion",
                ValidityDays: "Dias de Vigencia",
                MondayAbbreviation: "L",
                TuesdayAbbreviation: "M",
                WednesdayAbbreviation: "M",
                ThursdayAbbreviation: "J",
                FridayAbbreviation: "V",
                SaturdayAbbreviation: "S",
                SundayAbbreviation: "D",
                SelectSurveyFrequency: "Seleccionar Frecuencia de Relevamiento",
                Monday: "Lunes",
                Tuesday: "Martes",
                Wednesday: "Miércoles",
                Thursday: "Jueves",
                Friday: "Viernes",
                Saturday: "Sabado",
                Sunday: "Domingo",
                CreateRoundingRule: "Crear Regla de Redondeo",
                LoadItemsToExclude: "Cargar Items a excluir",
                RoundingRules: "Reglas de Redondeo",
                EditRoundingRuleSubtitle: "En caso de tener más  de un redondeo para un mismo tipo de precio y rango, Prisma intentará cumplir con la regla más específica según su ámbito de aplicación (primero categoría y luego zona de precios)",
                TypeOfPrice: "Tipo de Precio",
                RoundingType: "Tipo de Redondeo",
                Decimals: "Decimales",
                Tolerance: "Tolerancia",
                DirectionRoundingRules: "Dir",
                TestPanel: "Panel de Pruebas",
                TestPanelSubtitle: "No toma en cuenta las reglas con ámbitos de aplicación",
                PriceToRound: "Precio a Redondear",
                EnterValue: "Ingrese Valor",
                ResultWithRoundingApplied: "Resultado con Redondeo aplicado",
                TryRounding: "Probar Redondeo",
                SelectPriceType: "Seleccionar tipo de precio",
                Terminations: "Terminaciones",
                SubcategoriesToInclude: "Subcategorías a incluir",
                SubcategoriesToExclude: "Subcategorías a excluir",
                AreasToInclude: "Zonas a incluir",
                AreasToBeExcluded: "Zonas a excluir",
                Select: "Seleccionar",
                AddNewRank: "Agregar Nuevo Rango",
                SelectDecimals: "Seleccionar Decimales",
                Insert: "Insertar",
                Add: "Agregar",
                EditTerminations: "Editar Terminaciones",
                New1: "Nueva",
                Cancel: "Cancelar",
                Accept: "Aceptar",
                StrategyAndPriceRules: "Estrategia y reglas de Precio",
                ExplicationStrategyAndPriceRules: "Cargue los lineamientos de margen bruto para cada una de las categorías / subcategorías, indicando el margen objetivo, el mínimo y el máximo. <br/> Indique también la <b> variación de precios mínima y máxima</b>\r\nPor último ingrese el <b>competidor principal</b> para cada una de las categorías / subcategorías.",
                DescriptionRule: "Descripcion Regla",
                Validity: "Vigencia",
                AreaOfApplication: "Ámbito de aplicación",
                Acceptance: "Aceptación",
                Description: "Descripción",
                Subcategories: "Subcategorías",
                Items: "Items",
                PlaceholderSearchItem: "Ingrese descripción o código de artículo",
                PriceTypes: "Tipos de precio",
                PriceType: "Tipo de precio",
                CompetitionAreas: "Zonas de Competencia",
                Labels: "Etiquetas",
                Properties: "Propiedades",
                RelationshipRule: "Regla de Parentesco",
                Areas: "Zonas",
                RulesOfKinshipOfZones: "Las reglas de Parentesco de zonas son evaluadas cada vez que se cambia un precio de una Zona Padre. \r\nLos filtros marcados con * no afectan la seleccion de items.",
                SelectParentPriceZone: "Seleccione Zona de Precios Padre",
                SelectKindOfType: "Seleccione Tipo de Parentesco",
                DifferenceIn: "Diferencia en",
                AllSubcategories: "Todas las Sub-Categorías",
                AllArticleGroups: "Todos los Grupos de Articulos",
                AllPricesZones: "Todas las Zonas de Precios ",
                ExceptZoneFather: "Excepto Zona Padre",
                AllSuppliers: "Todos los Proveedores",
                ApplySKUs: "Aplicar a SKUs que contengan la siguiente descripcion/marca",
                ScopeOfExclusion: "Ámbito de exclusión",
                ExcludeSKUs: "Excluir a SKUs que contengan la siguiente descripcion/marca",
                SelectSubcategories: "Seleccione Subcategorías",
                SelectArticleGroup: "Seleccione Grupo de articulos",
                SelectSuppliers: "Seleccione Proveedores",
                SelectSensibilitiesToInclude: "Seleccione Sensibilidades a Incluir",
                SelectLabelsToInclude: "Seleccione Etiquetas a Incluir",
                SelectSubcategoriesToExclude: "Seleccione Sub-Categorías a excluir",
                SelectItemGroupsToExclude: "Seleccione Grupos de articulos a excluir",
                SelectPriceAreasToExclude: "Seleccione Zonas de Precios a excluir",
                SelectSuppliersToExclude: "Seleccione Proveedores a excluir",
                SelectSensitivityToExclude: "Seleccione Sensibilidades a excluir",
                SelectTagsToExclude: "Seleccione Etiquetas a excluir",
                Products: "Productos",
                RulesOfRelationshipOfProducts: "Las reglas de Parentesco de productos son evaluadas cada vez que se cambia un precio de un producto Padre. \r\n Los filtros marcados con * no afectan la seleccion de items.",
                SelectFather: "Seleccione Padre",
                Generic: "Genérico:",
                ParentProductVariant: "Este producto padre es una variante del producto",
                GenericRuleVariant: "Es regla Genérico - Variante",
                SuggestionRule: "Regla de Sugerencia",
                Margin: "Margen",
                MarginRules: "Las reglas de margen generan sugerencias de precios en base al cambio de costos de proveedores. \r\n Los filtros marcados con * no afectan la seleccion de items.",
                TargetMargin: "Margen Objetivo",
                KeepMargin: "Mantener Margen",
                KeepSameMarginPercentage: "Mantener mismo margen %",
                KeepSameMargin$: "Mantener mismo margen $",
                Competitive1: "Competitiva",
                CompetitiveRules: "Las reglas de posicionamiento competitivo generan sugerencias de precios en base al cambio de un precio del mercado. \r\n Los filtros marcados con * no afectan la seleccion de items.",
                SelectPointOfSale: "Seleccione Punto de Venta",
                SelectTypesOfObservations: "Seleccione Fuentes de Monitoreo",
                ConsiderOnlyCompetitorsSamePriceArea: "Considerar solo competidores en la misma zona de precios",
                Use: "Usar",
                MoreThanOneFashionToUse: "Si hay más de una moda usar",
                LessOfaFashionToUse: "y si hay más de una moda con misma cantidad de repeticiones usar",
                ApplyToProducts: "Aplicar a Productos que contengan la siguiente descripcion/marca",
                CancelTheChangesMade: "Cancelar los cambios realizados",
                SaveCompetitionRule: "Guardar regla de Competencia",
                SaveMargenRule: "Guardar regla de Margen",
                SaveParentageRule: "Guardar regla de Parentesco",
                SaveZoneRule: "Guardar regla de Zonas",
                GenerateSuggestions: "Generar Sugerencias",
                CategoryReview: "Revision de Categoría",
                TooltipGenerateSuggestionsButton: "El proceso de generacion de sugerencias se ejecuta periodicamente de acuerdo a la frecuencia configurada en cada categoría y tambien cuando se incorpora nueva informacion de mercado. Desde aqui podra ser ejecutado en forma manual",
                EvaluateMarginRules: "Evaluar Reglas de Margen",
                EvaluateCompetitionRules: "Evaluar Reglas de Competencia",
                EvaluateRelationshipRules: "Evaluar Reglas de Parentesco",
                ExportToAnExcel: "Exportar a un Excel los datos de la grilla",
                IncludeAllItems: "Incluir todos los items de la grilla",
                ExcludeAllItems: "Excluir todos los items de la grilla",
                SaveAllChangesPriceDecisions: "Guardar todos los cambios realizados: sugerencias aceptadas, precios cambiados, sugerencias ignoradas, precios endosados y endosos rechazados.",
                AcceptSuggestions: "Aceptar Sugerencias",
                AcceptSuggestionsTooltip: "Aceptar todas las sugerencias según filtros aplicados",
                EndorseAll: "Endosar Todos",
                EndorseAllTooltip: "Endosa todos los precios pendientes según filtros aplicados",
                MarkAsRevised: "Marcar como Revisada",
                Normal: "Normales",
                MarkAsRevisedAllNormalPricesTooltip: "Marca como revisados todos los precios NORMALES",
                MarkAsRevisedAllPricesOfCompetitionTooltip: "Marca como revisados todos los precios de COMPETENCIA",
                MarkAsRevisedAllPricesTooltip: "Marca como revisados TODOS los precios",
                Status: "Estado",
                PreviousPrice: "Precio Anterior",
                ThePriceVariationLimitHasBeenExceededTooltip: "Se ha excedido el limite de Variacion de Precio. Los limites son: de",
                To: "a",
                TheMarginLimitHasBeenExceededTooltip: "Se ha excedido el limite de Margen. Los limites son: de",
                GeneralInformation: "Información General",
                Ean: "EAN",
                BaseUm: "UM Base",
                ProductInformation: "Informacion del Producto",
                Maker: "Fabricante",
                NewLabel: "Nueva etiqueta",
                Quality: "Calidad",
                Premium: "Premium",
                Apply: "Aplicar",
                Area: "Zona",
                Action: "Acción",
                Situation: "Situación",
                ChangePriceManually: "Cambiar Precio Manualmente",
                AcceptSuggestion: "Aceptar Sugerencia",
                Reverse: "Revertir",
                Endorse: "Endosar",
                ToRefuse: "Rechazar",
                MarkAsEndosada: "Marcar como Endosada",
                ModifyMargin: "Modificar Margen",
                PercentajeMargin: "Margen %",
                UpdateDates: "Actualizar Fechas",
                DateFrom: "Fecha Desde",
                DateUntil: "Fecha Hasta",
                ForEndorsement: "Para Endoso",
                Falls: "Bajas",
                Rises: "Subas",
                StrongFalls: "Fuertes Bajas",
                StrongRises: "Fuertes Subas",
                DepressedMargins: "Márgenes Deprimidos",
                HighMargins: "Márgenes Altos",
                NegativeMargins: "Márgenes Negativos",
                LittleSignificantChanges: "Cambios Poco Significativos",
                SuggestedNotRespected: "Sugerencia No Respetada",
                Overlaps: "Solapados",
                SeeMap: "Ver Mapa",
                Page: "Página",
                Position: "Posición",
                CurrentPrices: "Precios Vigentes",
                Motive: "Motivo",
                Reasons: "Motivos",
                CompetitorsReferenceSameZone: "Referencia de Competidores en la misma Zona",
                ReasonForSuggestion: "Motivo de la Sugerencia",
                LowForts: "Fuertes Bajas",
                LastPricesInformedERP: "Últimos Precios Informados al ERP",
                GenerationDate: "Fecha de Generación",
                GenerationTime: "Hora de Generación",
                Archive: "Archivo",
                PricesPendingToInform: "Precios Pendientes a Informar",
                Un_select: "De(seleccionar)",
                PricesToInform: "Precios a Informar",
                AllTypesOfPrice: "Todos los tipos de precio",
                AllStores: "Todas las tiendas",
                AllCategories: "Todas las categorías",
                SelectAllVisiblePricesTooltip: "Selecciona / Deselecciona todos los precios visibles (considerando filtros)",
                GenerateTheFileToInformTheERPTooltip: "Genera el archivo para informar al ERP los precios seleccionados. Dichos precios pasaran a estado Informado en Prisma.",
                Delete: "Eliminar",
                NewTest: "Nuevo Test",
                RemoveTheSelectedTestsTooltip: "Elimina los test seleccionados",
                CreateAnewTestTooltip: "Crea un nuevo test",
                PriceChangesReport: "Reporte de Cambios de Precios",
                PriceMapReport: "Reporte de mapa de precios",
                ReportInformed: "Reporte informados",
                OnlyThoseNotImplemented: "Solo los no implementados",
                DailyMarginsReport: "Reporte Márgenes Diarios",
                VolumeMeasurement: "Medición de Volumen",
                MixEvolution: "Evolución del MIX",
                ImportantInformation: "Informacion Importante",
                ExplanationNewCost: "Agregue los nuevos costos con el botón '+ Agregar Costo'. \r\n Estos aparecerán grisados en el listado, hasta que se haya apretado el botón de 'Guardar nuevos costos y Generar Sugerencias'. \r\n Una vez que se hayan guardado correctamente, volverá a ver el listado de manera normal.",
                AddCost: "Agregar Costo",
                SaveNewCosts: "Guardar Nuevos Costos y Generar Sugerencias",
                BulkLoad: "Carga Masiva",
                Supplier: "Proveedor",
                CenterOfDistribution: "Centro de Distribucion",
                ProductCode: "Codigo de Producto",
                NameOfProduct: "Nombre de Producto",
                StartDate: "Fecha de Inicio",
                NetCost: "Costo Neto",
                Taxes: "Impuestos",
                SelectDistributionCenter: "Seleccionar Centro de Distribucion",
                ItemCode: "Código de artículo",
                SelectItem: "Seleccionar Item",
                Tax: "Impuesto",
                TaxRate: "Tasa de impuesto",
                Subsidy: "Subsidio",
                FreightCost: "Costo flete",
                DateEffectiveFrom: "Fecha Vigencia Desde",
                FreightCostText: "Costo Teórico de Flete desde su centro de distribucion hacia la estacion es de",
                CreatePlanogram: "Crear Planograma",
                Dimension: "Dimensión",
                ModalTitleCreatePlanogram: "Nuevo Planograma",
                NewPlanogramLegend1: "Complete los siguientes campos",
                NewPlanogramLegend2: "Arrastre el elemento deseado hacia la 'Zona de Arrastre' y complete los campos",
                NewPlanogramLegend3: "Haga clic en mostrar elementos si desea arrastrar un nuevo módulo",
                ModalTitleEditPlanogram: "Editar Planograma",
                Width: 'Ancho',
                Height: 'Alto',
                Depth: 'Profundidad',
                AddShelfToPlanogram: 'Agregar Mueble al Planograma',
                RemoveShelfFromPlanogram: 'Eliminar Mueble del Planograma',
                SaveShelf: 'Guardar Mueble',
                LevelOfPlanogram: 'Nivel del Planograma',
                ExplanationAddLevels: 'Agrega el número de niveles al Planograma, la altura de cada uno de los niveles será la misma , salvo especificación posterior. Se puede editar manualmente la altura de cada nivel haciendo click en los "+" "-".',
                Optional: 'Opcional',
                ExplanationAddOneLevel: 'Se puede agregar un nuevo nivel con una altura definida, al inicio o al final del planograma.',
                NumberOfEqualLevels: 'N° de Niveles Iguales',
                CompleteAllFieldsToCreateANewPlanogram: 'Complete todos los campos para crear un nuevo Planograma.',
                AtTheEndPressTheNextButton: 'Al finalizar presione el botón siguiente.',
                Fornitures: 'Muebles',
                Print: 'Imprimir',
                PrintPlanogram: 'Imprimir el Planograma',
                ShowDimensions: 'Ver Dimensiones',
                HideDimensions: 'Ocultar Dimensiones',
                FromThePlanogram: "Mostrar u ocultar las dimensiones del Planograma",
                Clean: 'Limpiar',
                CleanPlanogram: 'Eliminar todos los items acomodados en el Planograma',
                EditDimensionsTooltip: "Edite las dimensiones del Planograma o agregue un nuevo Módulo",
                EditPlanogramDimensions: "Editar Dimensiones",
                GoToThePlanogramViewMode: 'Ir al modo Vista del Planograma',
                GoToThePlanogramEditMode: 'Ir al modo Editar Planograma',
                GoToTheLayoutViewMode: 'Ir al modo Vista del Layout',
                GoToTheLayoutEditMode: 'Ir al modo Editar Layout',
                AssignStoresLayout: "Asignar tiendas al Layout",
                SeeAuditPicture: 'Ver fotos de Auditoría del Planograma',
                RequestApproval: 'Solicitar Aprobación',
                RequestApprovalTooltip: 'Marca el Planograma como Terminado y Solicitar Aprobación',
                Approve: "Aprobar",
                ApproveTooltip: "Aprobar el Planograma",
                AssingFrontsTo: 'Asignar frentes para',
                Maximize: 'Maximizar',
                MinimumFronts: 'Mínimo de frentes',
                MaximumFronts: 'Máximo de frentes',
                PercentageOfOccupiedSpace: 'Porcentaje de espacio ocupado',
                Percentage: 'Porcentaje',
                AddRule: 'Agregar Regla',
                TopToBottom: 'Arriba hacia abajo',
                BottomToTop: 'Abajo hacia arriba',
                LeftToRight: 'Izquierda a Derecha',
                RightToLeft: 'Derecha a Izquierda',
                Upward: 'Ascendente',
                Falling: 'Descendente',
                Measure: 'Medida',
                AddOrder: 'Agregar Ordenamiento',
                AddRestriction: 'Agregar Restricción',
                GENERATE: 'GENERAR',
                WithoutCategoryAssigned: 'Sin Categoría Asignada',
                Delist: 'Delistados',
                SeeTickets: 'ver Tickets',
                DeleteAnItem: 'Eliminar un Item',
                AddItem: 'Agregar Item',
                Front: 'Frente',
                Side: 'Lateral',
                Top: 'Superior',
                Subcategory: 'Subcategoría',
                Manufacturer: 'Fabricante',
                Sales: 'Ventas',
                Units: 'Unidades',
                SalesInStore: 'Ventas en tienda',
                PriceInStore: 'Precio en Tienda',
                UnitsInStore: 'Unidades en Tienda',
                StockInStore: 'Stock en tienda',
                SalesInCluster: 'Ventas en Cluster',
                AveragePriceInCluster: 'Precio Promedio en Cluster',
                UnitsInCluster: 'Unidades en Cluster',
                StockInCluster: 'Stock en cluster',
                ExhibitionDays: 'Días de Exhibición',
                ShowProductInformation: 'Mostrar Información de Productos',
                WithoutRule: 'Sin Regla',
                RepositionFrequencyInDays: 'Frecuencia de reposición en días',
                Tag: 'Etiqueta',
                OrderBy: 'Ordenar por',
                WithoutOrder: 'Sin Orden',
                MarginInCluster: 'Margen en Cluster',
                Weight: 'Peso',
                Aream2: 'Area',
                WithoutFilters: 'Sin Filtros',
                Filters: 'Filtros',
                SelectTag: "Seleccionar Etiqueta",
                SelectBrand: "Seleccionar Marca",
                SelectManufacturer: "Seleccionar Fabricante",
                Select: 'Seleccionar',
                NewSingular: 'Nuevo',
                Edit: 'Editar',
                NewTypeOfTicket: "Nuevo Tipo de Ticket",
                SelectTypeTicket: "--Selecionar Tipo de Ticket--",
                NeedDescription: "Necesita Descripción",
                NewPlanogram: "Nuevo Planograma",
                Complete: "Completar",
                From1: "De",
                ChangeSenseOrientation: "Cambiar Sentido de Circulación",
                SenseCirculation: "Sentido de Circulación",
                AutoStack: "Apilar",
                ShowDoor: "Mostrar Puerta",
                Tags: "Etiquetas",
                ProductChat: "Chat del Producto",
                NewMessage: "Nuevo Mensaje",
                Write: "Escribir",
                DelistProduct: "Producto Delistado",
                LiquidarProduct: "LIQUIDAR PRODUCTO",
                AskForWithdrawal: "PEDIR RETIRO",
                ChooseCriteria: "Elegir criterio",
                HighPriority: "Prioridad Alta",
                MediumPriority: "Prioridad Media",
                LowPriority: "Prioridad Baja",
                StopFollowingTicket: "Dejar de seguir Ticket",
                FollowTicket: "Seguir Ticket",
                Elements: "Elementos",
                EditElement: "Editar Elementos",
                Container: "Contenedor",
                Columns: "Columnas",
                AddElements: "Agregar Elementos",
                AddLevelToThePlanegram: "Agregar Nivel al Planograma",
                AddLevel: "Agregar el Nivel indicado al Planograma",
                Measurements: "Medidas",
                Dropzone: "Zona de Arrastre",
                DragItemhere: "Arrastra un elemento aquí",
                NewFurniture: "Nuevo Mueble",
                IncreaseHeightPlanogram: "Aumentar la altura del Nivel del Planograma",
                ReduceHeightPlanogram: "Reducir la altura del Nivel del Planograma",
                RemovePlanogramLevel: "Eliminar el Nivel del Planograma",
                NewModuleTitle: "Nuevo Módulo",
                NumberLevels: "Numero de Niveles",
                DivisionsOrDoors: "Divisiones o puertas",
                PositionIn: "Posicionar en:",
                ShowElements: "Mostrar Elementos",
                EditElement: "Editar Elemento",
                InsertInTheSameColumn: "Insertar en la misma columna",
                InsertInNewColumnOnLeft: "Insertar en una nueva columna a la izquierda",
                InsertInNewColumnOnRight: "Insertar en una nueva columna a la derecha",
                Refrigerator: "Heladera",
                NewTags: "Nuevas Etiquetas",
                Quantity: "Cantidad",
                UnitOfMeasureAbbreviation: "UN de Medida",
                BaseUnitOfMeasure: "Unidad de Medida Base",
                ValidationEan1: "existe en la base de dato (campo no valido)",
                ValidationEan2: "Valido",
                UploadPhotosItem: "Subir fotos",
                UploadPhotosItemTooltip: "Subir fotos para esta Unidad de Medida",
                RemoveThisUnitOfMeasure: "Eliminar esta Unidad de Medida",
                UnitOfMeasureCode: "Código de Unidad de Medida",
                AddUnitOfMeasure: "Agregar Unidad de Medida",
                UploadItemPictures: "Cargar Fotos del Item",
                ItemInFront: "del item de frente",
                ProfileItem: "del item de perfil",
                ItemAbove: "del item de arriba",
                Distributor: "Distribuidor",
                DischargeDate: "Fecha de Alta",
                Conversion: "Conversión",
                Dimensions: "Dimensiones",
                Place: "Plaza",
                HeightText: "Representa la suma de los niveles",
                NoImage: "Sin Foto",
                NoImageSubtitle: "Agregue foto de auditoria",
                EditLayout: "Editar Layout",
                EditUnitOfMeasures: "Edición unidad de medida",
                ProductHigh: "Alta de Producto",
                DeleteItem: "Eliminar item o grupo de items",
                ChangeFront: "Cambiar el frente del item o del grupo de items",
                AddItemBehind: "Agregar item o grupo de items detrás",
                DeleteItemBehind: "Eliminar item o grupo de items detrás",
                AddItemToRight: "Agregar ítem a la derecha",
                DeleteItemOnRight: "Eliminar el ítem de la derecha",
                AddItemAbove: "Agregar Item o grupo de items arriba",
                DeleteItemAbove: "Eliminar Ítem o grupo de items de arriba",
                TooltipForStoreUnits: "No hay suficientes unidades para completar los frentes",
                TooltipForStoreUnits2: "No hay suficientes unidades para completar la carga total",
                MoreInformation: "Más Información",
                TooltipRemoveElement: "Eliminar el elemento seleccionado",
                MoreZoom: "Darle mas zoom al Planograma",
                LessZoom: "Darle menos zoom al Planograma",
                AveragePerformance: "Performance Promedio",
                SelectPriceType: "--Seleccione un tipo de precio--",
                InformToERPTooltip: "Genera el archivo para informar al ERP los precios filtrados. Dichos precios pasaran a estado Informado en Prisma.",
                YouMustSelectACategory: "Debe seleccionar una categoría",
                NoResultsFor: "Sin resultados para",
                AddFreightCost: "Agregar Costo Flete / Estímulo",
                CostOfFreightAndStimulus: "Costo de Fletes y Estímulos",
                ItemCode1: "Código de item",
                FreightCost: "Costo de flete",
                Stimulus: "Estímulo",
                SelectArea: "--Seleccionar zona--",
                Article: "Artículo",
                AddRegion: "Agregar Región",
                Region: "Región",
                SubcategoriesToInclude: "Subcategorías a incluir",
                SubcategoriesToExclude: "Subcategorías a excluir",
                PricingZonesToExclude: "Zonas de Precios a excluir",
                PriceZonesToInclude: "Zonas de Precios a incluir",
                AcceptSelectedSuggestions: "Aceptar las sugerencias seleccionadas",
                IgnoreSelectedSuggestions1: "Ignorar las sugerencias seleccionadas",
                EndorseSelectedItems: "Endosar los items seleccionados aceptando las modificaciones",
                RejectSelectedChanges: "Rechazar los cambios seleccionados (Permite ingresar un mensaje de rechazo)",
                ModifyMarginInStaggeredWay: "Modificar margen en forma escalonada indicando un Margen $ objetivo",
                MassiveDatesUpdate: "Actualización Masiva de Fechas de Vigencia para Nuevos Precios",
                MassiveLoad: "Carga Masiva",
                IndividualLoad: "Carga Individual",
                SaveABTesting: "Guardar AB Testing",
                PercentageOfTheRiseOrFall: "% de la suba o baja",
                ItMustMatchExactly: "Debe coincidir en forma exacta con las regiones informadas por la autoridad",
                FreightCostTooltip: "Ingresar aquí el costo del flete en $ por litro",
                StimulusTooltip: "Ingresar aquí el estimulo fiscal en $ por litro",
                FilterApplied: "Filtro aplicado",
                LastModification: "Ult. Modif.",
                EditPlanogramStructure: "Editar Estructura de Planograma",
                CopyingThisStructure: "Nuevo Planograma Copiando esta Estructura",         
                ClearPlanogram: "Borrar Planograma",
                SaveThePlanogram: "Guardar el Planograma",
                YouDoNotHavePermissionsToSave: "No tiene permisos para Guardar",
                Active: "Activos",
                ClickToFilterBy: "Clic para filtrar por",
                ClickToEdit: "Clic para editar",
                Copy: "Copiar",
                CopyTooltip: "Copiar último módulo e insertar a la derecha",
                DataImporter: "Importador de Datos",
                UploadFiles: "Subir Archivos",
                DragTheFileHere: "Arrastrar el archivo aquí ",
                DragTheFileHere1: "o hacer click para buscar en tu PC",
                VerifyFormat: "Verificar Formato",
                ValidateInformation: "Validar Información",
                UpdateTables: "Actualizar Tablas",
                UploadFile: "Subir Archivo",
                ValidateData: "Validar Datos",
                AddCategories: "Agregar Categorías",
                Finalize: "Finalizar",
                SelectSubcategory: "--Seleccione Subcategoría--",
                Subcategory2: "SubCategoria2",
                GoToStep2Tooltip: "Ir al paso 2",
                GoToStep3Tooltip: "Ir al paso 3",
                GoToStep4Tooltip: "Ir al paso 4",
                RestartTheImporter: "Reinicia el importador para subir nuevos archivos",
                ManualSettlementsOverwriteSuggestedSettlements: "Alerta: Las Liquidaciones Manuales sobreescriben las Liquidaciones Sugeridas",
                LiquidationsByExpirationOverwriteSuggestedLiquidations: "Alerta: Las Liquidaciones por Vencimiento sobreescriben las Liquidaciones Sugeridas.",
                BleachSensitivities: "Blanquear Sensibilidades",
                NewHighRule: "Nueva Regla Altas",
                NewRuleLows: "Nueva Regla Bajas",
                SaveProductRegistrationRule: "Guardar regla de Alta de Productos",
                SaveProductDropRule: "Guardar regla de Baja de Productos",
                ButtonGenerateSuggestionsAssortmentTooltip: "El proceso de generacion de sugerencias se ejecuta periodicamente de acuerdo a la frecuencia configurada en cada categoria y tambien cuando se incorpora nueva informacion de mercado. Desde aqui podra ser ejecutado en forma manual",
                NewRuleTitle: "NUEVA REGLA",
                NewHighRuleIntro: "Las reglas de altas serán utilizadas por el sistema para generar sugerencias de inclusión de nuevos artículos que esten performando bien en el mercado. Para definir este criterio pueden utilizarse diferentes variables y condicionales en cualquier combinación elegida.",
                CoverageInfo: "Tener en cuenta la cobertura objetivo de las distintas marcas al momento de crear la regla de cobertura.",
                ObjectiveFor: " objetivo para ",
                SelectAnOption: "Seleccione una Opción",
                AllClusters: "Todos los Clusters",
                AllTags: "Todas las Etiquetas",
                SelectSubcategoriesToInclude: "Seleccione Subcategorías a Incluir",
                SelectClustersToExclude: "Seleccione Clusters a excluir",
                NewLowRuleIntro: "Las reglas de baja serán utilizadas por el sistema para generar sugerencias de baja de artículos existentes que no esten performando bien. Para definir este criterio pueden utilizarse diferentes variables y condicionales en cualquier combinación elegida.",
                AssortmentMatrixStep1: "Seleccionar: Bandera , Formato y Cliente.",
                AssortmentMatrixStep2: "Seleccionar  Sector, Sección,  Familia, Sub-Familias y Cluster. ",
                AssortmentMatrixStep3: "Seleccionar si  se desea  apertura por Fabricante o Marca (o ambas).",
                AssortmentMatrixStep4: "Seleccionar 1 o más  etiquetas.",
                AssortmentMatrixStep5: "Seleccionar periodo.",
                SaveTemplate: "Guardar Template",
                OpenTemplate: "Abrir Template",
                AssortmentReport: "Reporte de Surtido",
                Flag: "Bandera",
                AllFlags: "Todas los banderas",
                Format: "Formato",
                AllFormats: "Todos los formatos",
                Client: "Cliente",
                AllClients: "Todos los clientes", 
                Scope: "Alcance",
                Section: "Sección",
                Family: "Familia",
                Subfamily: "Sub-familia",
                AllSubfamily: "Todas las Sub-Familias",
                Opening: "Apertura",
                UpTo5Labels: "Solo puede seleccionar hasta 5 etiquetas",
                Months3: "3 Meses",
                Months6: "6 Meses",
                Run: "Ejecutar",
                Export: "Exportar",
                ManagementOfItems: "Gestión de Items",
                ManagementOfItemsLegend1: "Búsqueda de productos: Elija categoría/s, subcategoría/s y filtre por texto libre, al terminar presione el boton 'Buscar'.",
                ManagementOfItemsLegend2: "Modificación de atributos: Modifica o agrega los atributos que quieres actualizar de forma masiva, al finalizar presione el boton 'Aplicar'.",
                ManagementOfItemsLegend3: "Listado de Items: En la siguiente tabla podrá visualizar los cambios aplicados.",
                ManageItems: "Gestionar Items",
                SearchManageItems: "Buscar por código, nombre, marca, fabricante",
                FilterTooltip: "Filtrar",
                SelectedTags: "Seleccionar Etiquetas",
                AddNewTagTooltip: "Agregar nueva etiqueta",
                AsignForPersist: "Ahora debes asignar el nuevo atributo al menos a un item",
                Group: "Grupo",
                SelectedGroup: "Seleccione Grupo",
                YouMustSelectAtLeastOneCategory: "Debe seleccionar al menos una Categoría",
                ManagementOfItemsTitle1: "Búsqueda de productos",
                ManagementOfItemsTitle2: "Modificación de atributos",
                ManagementOfItemsTitle3: "Listado de Items",
                NewTagGroup: "Nuevo Grupo de Etiquetas",
                NewBrand: "Nueva Marca",
                NewManufacturer: "Nuevo Fabricante",
                WithoutProducts: "Sin Productos",
                FullView: "Vista completa",
                UnitsStock: "Stock Unidades",
                TheItemWasAdded: "Se agregó el ítem",
                Aggregate: "Agregado",
                Actives: "Activos",
                EnterFurnitureToPrint: "Ingrese muebles a imprimir",
                EnterFurnitureToPrintSubtitle: "Si deja vacio se imprimirán todos los muebles. Puede usar rangos. Ej: 2-5",
                InvalidRank: "El rango ingresado es inválido",
                InvalidRankSubtitle: "Debe escribir una posición de mueble valida",
                ConfigurationFinished: "Configuración finalizada",
                AllReadyToPrintThePlanogram: "Todo listo para imprimir el planograma",
                Planogram: "Planograma",
                Seconds: " segundos",
                Since: "Hace ",
                YearAgo: " año",
                MonthAgo: " mes",
                DayAgo: " día",
                HourAgo: " hora",
                MinuteAgo: " minuto",
                Sp_generalKpis_Kpis: "KPIs Generales",
                Sp_generalKpis_Filters: "Filtros",
                Sp_generalKpis_Category: "Categoría",
                Sp_generalKpis_rentability: "Rentabilidad",
                Sp_generalKpis_averagePrice: "Precio Promedio",
                Sp_generalKpis_spacing: "Espaciado",
                Sp_generalKpis_tags: "Etiquetas",
                Sp_generalKpis_zones: "Zonas",
                Sp_generalKpis_applyButton: "Aplicar",
                Sp_editLayout_AddTicketTooltip: "Agregar Ticket al Planograma Seleccionado",
                Sp_editLayout_rotateTooltip: "Rotar Planograma seleccionado",
                Sp_editLayout_deleteTooltip: "Eliminar Planograma seleccionado",
                Sp_editPlanogram_Recommendation: "Recomendación",
                Pr_competitors_checkboxMarker: "Se considera los precios relevados de este competidor para la generación de sugerencias",
                Pr_competitors_checkboxObserve: "Se van relevar los  precios de este competidor",
                PriceEvolution: "Evolución de Precios",
                CompetitiveIndex: "Índice de Competitividad",
                CompetitiveByZone: "Competitividad x Zona",
                CompetitiveByCompetitor: "Competitividad x Competidor",
                CompetitiveByCategory: "Competitividad x Categoria",
                SelectPricingZone: "-- Seleccionar Zona de Precios --",
                Pr_competitiveIndex_subtitle: "Cambie el agrupador para ver de diferentes maneras el Índice de Competitividad",
                Pr_competitiveIndex_GroupBy: "Agrupar por:",
                Pr_competitiveIndex_CompetitivenessFor: "Competitividad por",
                Pr_competitiveIndex_Ci: "IC",
                Pr_competitiveIndex_Cheaper: "Más Barato",
                Pr_competitiveIndex_TheSame: "Iguales", 
                Pr_competitiveIndex_MoreExpensive: "Más Caro",
                Pr_competitiveIndex_TypeOfSurvey: "Tipo de Relevamiento",
                Pr_decisions_restore: "Restablecer",
                Sp_editLayout_validationArea: "El Area de Ventas no puede ser mayor al Area total",
                Pr_decisions_ExperimentalPrice: "Precio Simulado",
                Pr_decisions_Close: "Cerrar",
                Pr_decisions_CloseTooltip: "Deseleccionar todos los items",
                Pr_decisions_Experiment: "Simulación",
                Pr_decisions_ChangePrice: "Cambiar Precio",
                Pr_decisions_Selected: "Seleccionados",
                Pr_volumeMeasurement_legend1: "Seleccione un producto",
                Pr_volumeMeasurement_legend2: "Seleccione una zona de precios",
                Pr_volumeMeasurement_legend3: "Complete las fechas 'Desde' y 'Hasta' para visualizar el gráfico",
                Pr_volumeMeasurement_evolutionOfTheMIX: "Evolución del MIX",
                Pr_volumeMeasurement_validationDate: "Fecha Desde no puede ser mayor a la fecha Hasta",
                Sp_planograms_editStructure: "Editar Estructura",
                Sp_planograms_editStructureTooltip: "Editar la estructura del planograma o agregar un nuevo mueble",
                Sp_planograms_copyStructure: "Copiar Estructura",
                Sp_planograms_copyStructureAndProducts: "Copiar Estructura y Productos",
                Sp_editPlanogram_editLevelsTooltip: "Edite las dimensiones de los niveles del Planograma",
                Sp_createPlanogram_loadStoresIndividually: "Cargar tiendas de manera individual",
                Sp_createPlanogram_loadStoresMassively: "Cargar tiendas de manera masiva",
                Sp_editPlanogram_space: "Espacio",
                Sp_editPlanogram_spaced: "Espaciado: ",
                Sp_editPlanogram_new: "Nuevo",
                Sp_editPlanogram_pendingApproval: "Aprobación pendiente",
                Sp_editPlanogram_approved: "Aprobado",
                Sp_editPlanogram_informed: "Informado",
                Sp_editLayout_moreZoomTooltip: "Darle más zoom al Layout",
                Sp_editLayout_lessZoomTooltip: "Darle menos zoom al Layout",
                SP_editPlanogram_Autocomplete_Strategy: "Definir Estrategia",
                SP_editPlanogram_Autocomplete_ExhibitionDays: "Días de Exhibición Objetivo",
                SP_editPlanogram_Autocomplete_Results: "Resultado",
                SP_editPlanogram_Autocomplete_ResultsSummary: "Resumen",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part1: "Se incluyeron ",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part2: " items en el planograma",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part3: "de un total de ",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part4: " items ",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part5: "y no fueron incluidos ",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part6: " items del surtido activo.",
                SP_editPlanogram_Autocomplete_ResultsSummary: "Detalle de items no incluidos",
                SP_editPlanogram_Autocomplete_ResultsTableCode: "Código",
                SP_editPlanogram_Autocomplete_ResultsTableDesc: "Descripción",
                Pr_competitors_selectBrand: "--Seleccionar Bandera--",
                Ad_missions_newMissions: "Nueva Misión",
                Ad_missions_mission: "Misión",
                Ad_missions_missionType: "Tipo de Misión",
                Ad_missions_finishDate: "Fecha de Finalización",
                Ad_missions_users: "#Usuarios",
                Ad_missions_advance: "Avance",
                Ad_missions_daysToExpire: "Días por vencer",
                Ad_missions_programming: "Programación",
                Ad_missions_surveyMethod: "Método de relevamiento", 
                Ad_missions_selectMissionType: "--Seleccione tipo de misión--", 
                Ad_missions_selectsMethod: "--Seleccione Metodo--",
                Ad_missions_selectFrecuency: "--Seleccione Frecuencia--",
                Ad_missions_selectActionType: "--Seleccione Tipo de Accion--",
                Ad_missions_timeToCompleteIt: "Plazo para completarla",
                Ad_missions_inDays: "En días",
                Ad_missions_startsOn: "Empieza el:",
                Ad_missions_endsThe: "Finaliza el:",
                Ad_missions_brand: "Bandera / Marca",
                Ad_missions_selectBrand: "--Seleccione Marcas--",
                Ad_missions_region: "Estado / Región",
                Ad_missions_selectRegion: "--Seleccione Regiones--",
                Ad_missions_location: "Distrito / Localidad",
                Ad_missions_selectLocation: "--Seleccione Localidad--",
                Ad_missions_priceZone: "Zona de Precios",
                Ad_missions_selectpriceZone: "--Seleccione zona de precios--",
                Ad_missions_specificStores: "Tiendas Especificas",
                Ad_missions_selectSpecificStores: "--Seleccione tiendas--",
                Ad_missions_products: "Productos",
                Ad_missions_group: "Grupo",
                Ad_missions_selectGroup: "--Seleccione Grupos--",
                Ad_missions_subGroup: "Subgrupos",
                Ad_missions_selectSubGroup: "--Seleccione Subgrupos--",
                Ad_missions_Items: "Artículos",
                Ad_missions_selectItems: "--Seleccione Artículos--",
                Ad_missions_responsible: "Responsables",
                Ad_missions_selectShopper: "Seleccione Shopper",
                Ad_missions_shopper: "Shopper",
                Ad_missions_addShopper: "Agregar un nuevo responsable",
                Ad_missions_legendResponsable: "Asigne tiendas a un responsable específico para hacer el relevamiento",
                Pr_competitors_selectPlace: "--Seleccionar Plaza--",
                Adm_brands_brands: "Banderas",
                Adm_brands_addBrand: "Agregar Bandera",
                Adm_brands_brandName: "Nombre de la Marca",
                Adm_brands_searchBrand: "Buscar Bandera...",
                Adm_brands_newBrand: "Nueva Bandera",
                Adm_brands_editBrand: "Editar Bandera",


                //JS
                SaveSwalTitle: "Esta seguro que desea guardar la Tienda?",
                StoreSaved: "Tienda guardada",
                ErrorWhenSavingTheStore: "Ocurrio un error al guardar la Tienda",
                ValidationNameStore: "Debe elegir un nombre para la Tienda",
                ValidationTypeStore: "Debe elegir un tipo de Tienda",
                EnableDisableSwalTitle: "la Tienda",
                UpdatedStores: "Tiendas Actualizadas",
                AssignedStoresSwalTitle: "Tiendas Asignadas",
                AssignedStoresSwalSubTitle: "Se asignaron correctamente las Tiendas al usuario",
                ValidationMirrorStoreAndBaseStore: "La Tienda espejo debe ser diferente de la Tienda base",
                ValidationSelectedStoreNotHaveAPrice: "La Tienda seleccionada no tiene un precio para el item elegido",
                DstPopUpInformativeGraphic: "mas $ de venta",
                StoreAndCD: "Tienda y Centro de Distribución",
                StoreDescendant: "Desc. Tienda",
                CategoryTreeMap: "Arbol de Categorías",
                ByCategory: "Por categoría",
                Setup: "Configuración",
                PriceZones: "Zonas de Precios",
                Rounding: "Redondeo",
                Goals: "Objetivos",
                Reports: "Reportes",
                Welcome: "Bienvenido a Prisma!",
                InformToERP: "Informar al ERP",
                PriceElasticity: "Elasticidad de precios",
                Reports: "Reportes",
                PriceChanges: "Cambios de Precios",
                PriceMap: "Mapa de precios",
                InformedPrices: "Precios informados",
                DailyMargins: "Margenes Diarios",
                ReplenishmentCosts: "Costos de Reposicion",
                Promotions: "Promociones",
                Campaigns: "Campañas",
                Workflows: "Procesos",
                Rules: "Reglas",
                PromotionalActions: "Acciones Promocionales",
                Calendar: "Calendario",
                ControlPanel: "Tablero de Control",
                MyTasks: "Mis Tareas",
                Communicate: "Comunicación",
                BIReports: "Reportes BI",
                Markdowns: "Liquidaciones",
                SelectionRules: "Reglas de seleccion",
                Missions: "Misiones",
                MarketData: "Inf. de Mercado",
                NewPrices: "Nuevos Precios",
                Sensitivities: "Sensibilidades",
                Security: "Seguridad",
                Users: "Usuarios",
                PrismaDevelopedBy: "Prisma fue desarrollado por",
                InformationUpdatedUpTo: "Informacion actualizada a",
                SALES: "VENTAS",
                SalesYTD: "Ventas YTD",
                VsPlan: "vs Plan",
                PlanProgress: "Avance del plan anual",
                AnnualPlan: "Plan Anual",
                Forecasted: "Proyectado",
                VsPrevYear: "vs Año anterior",
                MARGIN: "MARGEN $",
                COST: "COSTO",
                UNITS: "UNIDADES",
                MarginYTD: "Margen YTD",
                UnitsYTD: "Unidades YTD",
                CostYTD: "Costo YTD",
                MarginDecreased: "Cayo el margen",
                PriceInS: 'Precio en $',
                HeatMap: 'Mapa de Calor',
                HeatMapSubtitle: "En este mapa podrá observar su performance por Pais, Estado, Ciudad o Tienda.",
                PreviousMonth: "Mes Anterior",
                SameMonthPrevYear: "Mismo mes año ant.",
                SelectCategory: "-- Seleccione Categoría --",
                SalesIn: "Venta en",
                Population: "Poblacion",
                Households: "Viviendas",
                GreaterThan: "mayor a",
                Between: "entre",
                And: " y ",
                LessThan: "menor a",
                SelectSector: "--Selecione Sector--",
                SelectState: "--Selecione Estado/Provincia--",
                Colors: "Colores",
                Size: "Tamaño",
                Period: "Periodo",
                START: "INICIAR",
                Analysis: "Análisis",
                SelectedPeriod: "Periodo seleccionado",
                DifferenceSamePeriodPrevYear: "Diferencia con mismo periodo, año anterior",
                State: "Estado/Provincia",
                ShowSalesDistributionBetween: "Muestra la distribucion de la venta entre las",
                Categories: "Categorías",
                Stores: "Tiendas",
                TheSizeOfTheCirclesCorresponds: "El tamaño de los círculos corresponde a las",
                Category: "Categoría",
                Store: "Tienda",
                Place: "Plaza",
                TheColorShowsTheVariation: "El color muestra la variacion entre el periodo actual y el periodo anterior.",
                DTSLegend1: "Aqui se puede ver como cada categoría o tienda performo versus el periodo anterior. El color de la burbuja indica la magnitud del cambio que tambien es referenciado en el eje Y.",
                PriceDecisionsCategory: "Decisiones de precios por categoría",
                PriceDecisionsCategorySubtitle1: "El siguiente es un resumen de las sugerencias y precios nuevos por categoría.",
                PriceDecisionsCategorySubtitle2: "Elija la categoría o subcategoría sobre la que desea trabajar haciendo un click sobre la misma.",
                Search: "Buscar...",
                ClickToEnterCategory: "Clic para ingresar a la categoría",
                RegularSuggestions: "Sugerencias Regulares",
                Suggestions: "Sugerencias",
                SuggestionsQuantity: "Cantidad de sugerencias",
                NumberCompetitiveSuggestions: "Cantidad de sugerencias competitivas",
                Impact: "Impacto",
                ShowsImpactInMargin: "Muestra el impacto en margen si se aceptan las sugerencias.",
                ShowsImpactInPesos: "Muestra el impacto en pesos de la sugerencia",
                CompetitiveSuggestions: "Sugerencias Competencia",
                NewRegular: "Nuevos Regulares",
                New: "Nuevos",
                BigDrop: "Fuerte Baja",
                BigDropToolTip: "Precios nuevos que bajaron mas de lo permitido para esta categoría.",
                BigIncrease: "Fuerte Suba",
                BigIncreaseToolTip: "Precios nuevos que subieron mas de lo permitido para esta categoría.",
                MarginMin: "< Margen min",
                MarginMintTooltip: "Precios nuevos que estan por debajo del margen minimo para esta categoría",
                MarginMax: "> Margen max",
                MarginMaxTooltip: "Precios nuevos que estan por arriba del margen maximo para esta categoría.",
                NewCompetitive: "Nuevos Competitivos",
                OverCategoryTotal: "sobre el total de la categoría",
                MarginDifPerMonth: "dif. margen x mes",
                Decisions: "Decisiones",
                NumberOfChanges: "# Cambios",
                OverTotalSuggestedChanges: "del total de cambios sugeridos",
                AcceptedSuggestions: "sug. aceptadas",
                PercentageChange: "Cambio %",
                VsSuggestedPrices: "vs. precios sugeridos",
                VsCurrentPrices: "vs. precios actuales",
                WeightedByUnitSales: "ponderado por venta",
                AssumingSameUnitSales: "asumiendo misma venta unitaria",
                Performance: "Evolución de Performance",
                Suggestion: "Sugerencia",
                Product: "Producto",
                ValidFromDate: "Fecha de Inicio de Vigencia",
                ValidFrom: "Fecha Desde",
                ValidTo: "Fecha Hasta",
                ValidFromUpdateTooltip: "Actualiza de forma masiva la fecha DESDE de los precios filtrados",
                ValidToUpdateTooltip: "Actualiza de forma masiva la fecha HASTA de los precios filtrados",
                CompetitorsMap: "Mapa de Competidores",
                CostsAndMargins: "Costos y Margenes",
                AuditTrails: "Auditoria",
                All: "Todos",
                Current: "Vigente",
                Exception: "Excepcion",
                Markdown: "Rebaja",
                Promotion: "Promoción",
                Bonus: "Bonus",
                Competitive: "Competencia",
                Regular: "Normal",
                PriceZone: "Zona de Precios",
                CompetitorType: "Tipo de Competidor",
                Competitor: "Competidor",
                OnlyMarkers: "Solo marcadores",
                MultizoneViews: "Vista multizonas",
                Allf: "Todas",
                Any:"Ninguna",
                SelectProduct: "-- Seleccione producto --",
                SelectCompetitorType: "-- Seleccione Tipo de Competidor --",
                SelectCompetitor: "-- Seleccione competidor --",
                Observed: "Observado",
                Type: "Tipo",
                Basket: "Canasta",
                Own: "Propio",
                PriceIndex: "Indice de Precios",
                Web: "Web",
                Extra: "Extra",
                Rule: "Regla",
                PricesEvaluatedByCompetitiveRule: "Precios evaluados por la Regla de Competencia",
                CurrentPrice: "Precio Actual",
                SuggestedPriceBasedOnTargetMargin: "Precio sugerido segun Margen Objetivo",
                Date: "Fecha",
                AssortmentStrategyAndRules: "Estrategia y reglas de Surtido",
                AssortmentStrategyAndRulesSubtitle: "Cargue los <b>roles y estrategias</b> para cada <b>marca</b> en cada una de las categorías / subcategorías,\r\nindicando la cobertura objetivo manualmente o utilizando la que propone el sistema.\r\n Indique también que peso le asignará a cada variable para la construccion del <b>indice ponderado \r\n Por último ingrese las <b>fechas y frecuencia de análisis</b> para cada una de las categorías / subcategorías.",
                GoalsSelectCategory: "Seleccione una categoría, zona de precios o tienda en la tabla de abajo para ver los datos graficados.",
                BenchmarksStepOne1: "Utilice los filtros habilitados para refinar su busqueda",
                BenchmarksStepOne2: "Explore el gráfico para ver los valores mes a mes",
                BenchmarksStepOne3: "Utilice la botonera azul para cambiar la visualizacion del gráfico.",
                Compare: "Comparar",
                SectorsToCompare: "Sectores/Categorías a comparar",
                SelectCategories: "Seleccione Categorías",
                ClustersToCompare: "Clusters a comparar",
                SelectClusters: "Seleccione Clusters",
                Filter: "Filtrar",
                SectorsCategories: "Sectores/Categorías",
                From: "Desde",
                To: "Hasta",
                Bars: "Barras",
                Spline: "Lineas",
                SalesComparison: "Comparativo de Ventas",
                Accumulated: "acumulado",
                MarginComparison: "Comparativo de Margen $",
                UnitsComparison: "Comparativo de Unidades",
                ComparePerformance: "Análisis de performance comparativo",
                AreYouSureSwalTitle: "Esta seguro que desea ",
                EnableSwal: "habilitar",
                ToDisableSwal: "deshabilitar",
                TheZoneSwal: " la zona",
                WarningStoresSwal: "ADVERTENCIA! las tiendas ",
                BelongZoneSwall: " pertenecen a la zona",
                CancelSwal: "Cancelar",
                ContinueSwal: "Continuar",
                UpdatedZonesSwal: "Zonas Actualizadas",
                TheProcessContinuesSwal: "El proceso Continúa…",
                ErrorTitleSwal: "Error!",
                ErrorWhenDisablingTheZoneSwal: "Hubo un error al deshabilitar la zona de precios.",
                SavePriceZoneSwalTitle: "Esta seguro que desea Guardar esta Zona de Precios?",
                SaveSwal: "Guardar",
                SavedZoneSwal: "Zona Guardada!",
                SavedZoneSwalSubtitle: "se ha guardado la zona de precios exitosamente",
                ErrorOccurredWhileSavingZoneSwal: "Ha ocurrido un error tratando de guardar la zona",
                ValidationSwalTitle: "Campos incompletos",
                ValidationSwalSubtitle: "Debe completar todos los campos",
                SaveCompetitorValidationSwalSubtitle: "Debe completar codigo y nombre",
                SaveCompetitorTreeRelationsSwal: "Relaciones guardadas!",
                TheProcessOfContinuousStorageSwal: "El proceso de guardado continua. Puede revisar el estado en unos minutos.",
                ThereWasAnErrorInSavingRelationshipsSwal: "Hubo un error al guardar las relaciones",
                DisableCompetitorSwalTitle: "Esta seguro que desea deshabilitar el competidor?",
                CompetitorDisabledSwalTitle: "Competidor Deshabilitado!",
                CompetitorDisabledSwalSubtitle: "se ha deshabilitado correctamente",
                TheProcessOfDisablingContinuesSwal: "El proceso de deshabilitar continua. Puede revisar el estado en unos minutos.",
                ThereWasAnErrorDisablingCompetitorSwal: "Hubo un error al deshabilitar el competidor",
                EditCompetitor: "Editar Competidor",
                TheCompetitorExistsInTheGroupSwalTitle: "El competidor ya existe en el grupo",
                TheCompetitorExistsInTheGroupSwalSubtitle: "No se ha agregado el competidor porque ya existia en el grupo",
                YouAreSureToRemoveTheCompetitorSwal: "Está seguro de quitar al competidor ",
                FromTheGroupSwal: " del grupo ",
                RemoveSwal: "Quitar",
                EditSensitivityTitle: "EDITAR SENSIBILIDAD",
                NewSensitivityTitle: "NUEVA SENSIBILIDAD",
                SaveSensitivitySwal: "Esta seguro que desea guardar la Sensibilidad?",
                SavedSensitivitySwal: "Sensibilidad Guardada!",
                TheSensibilitySwal: "La sensibilidad ",
                HasBeenSuccessfullySavedSwal: " se ha guardado exitosamente",
                ErrorSavingSensitivitySwal: "Hubo un error al guardar esta Sensibilidad",
                EditRoundingRuleTitle: "EDITAR REGLA DE REDONDEO",
                NewRoundingRuleTitle: "NUEVA REGLA DE REDONDEO",
                DeleteTheRuleSwal: "Esta seguro que desea eliminar la regla ?",
                ErasedRuleSwal: "Regla Borrada!",
                HasBeenErasedCorrectlySwal: "se ha borrado correctamente",
                TheProcessOfContinuousDeletionSwal: "El proceso de borrado continua. Puede revisar el estado en unos minutos.",
                ErrorWhenDeletingTheRule: "Hubo un error al borrar la regla",
                DeleteSwal: "Eliminar",
                ValidationNumberToRound: "Debe ingresar un numero valido",
                SaveTheRoundingRulesSwal: "Esta seguro que desea guardar las Reglas de Redondeo?",
                SavedRoundingRulesSwal: "Reglas de Redondeo Guardadas!",
                ErrorWhenSavingTheseRoundingRules: "Hubo un error al guardar estas Reglas de Redondeo",
                ValidationRangesSwal: "la lista de rangos debe comenzar en 0 y terminar en infinito (vacio)",
                ValidationRangesSwal1: "verifique que el 'hasta' de un rango coincida con el 'desde' del siguiente",
                AreYouSureSwal: "Está seguro?",
                AreYouSureSwalSubtitle: "Esta seguro que desea guardar los cambios?",
                WarningSwal: "Aviso",
                YouMustSaveSwal: "Debe guardar antes de agregar un nuevo rango.",
                NoChangeWasDetectedSwal: "No se detectó ningún cambio",
                ConfirmedSwal: "Confirmado",
                ConfirmedSwalSubtitle: "Se guardaron los cambios seleccionados",
                ErrorSwalSubtitle: "Hubo un error al querer guardar los cambios",
                AreYouSureYouWantToReviewTheCategorySwal: "Esta seguro que desea revisar la categoría?",
                RevisionOfCategorySwal: "Revisión de Categoría!",
                RevisionOfCategorySwalSubtitle: "Ha terminado el proceso de revision de categoría exitosamente. Se generaron ",
                RevisionOfCategorySwalSubtitle2: " sugerencias para esta categoría",
                TheContinuousCategoryReviewProcessSwal: "El proceso de revision de categoría continua. Puede revisar las sugerencias generadas dentro de unos momentos desde la pantalla de decision de precios.",
                ErrorWhenReviewingThisCategory: "Hubo un error al revisar esta categoría",
                GenerateSuggestionsSwal: "Esta seguro que desea generar las sugerencias?",
                GenerationOfSuggestionsSwal: "Generación de Sugerencias!",
                TheProcessEndedSuccessfullySwal: "El proceso termino exitosamente. Se generaron ",
                TheProcessEndedSuccessfullySwal2: " sugerencias para esta categoría",
                TheSuggestionsContinueToBeGeneratedSwal: "Las sugerencias siguen generandose. Puede revisar el estado de las mismas dentro de unos momentos desde la pantalla de decision de precios.",
                ErrorWhenGeneratingSuggestionsForThisCategorySwal: "Hubo un error al generar las sugerencias para esta categoría",
                RemoveRuleSwal: "Está seguro que desea Eliminar la regla y sus sugerencias asociadas?",
                RuleEliminatedSwal: "Regla eliminada!",
                TheRuleHasBeenSuccessfullyRemovedSwal: "La regla se ha eliminado exitosamente",
                ErrorWhenDeletingTheRule: "Hubo un error al eliminar la regla",
                TheRuleSwal: "La Regla ",
                AssociatedWithGenericSwal: " ya está asociada al Genérico ",
                ThereCanOnlyBe1RulePerGenericSwal: " Solo puede haber 1 regla por Generico",
                NoApplicationScopeOrItemsWereAddedSwal: "No se agregaron Ambitos de Aplicacion ni Items",
                NoApplicationScopeOrItemsWereAddedSwalSubtitle: "Para las reglas de Parentesco de Items, debe seleccionar al menos una sensibilidad, etiqueta, atributo o item.",
                AssortmentMatrix: "Matriz de Surtido",
                ExportDecisions: "Exportar Decisiones",
                ExportDecisionsPrisma: "Exportar Decisiones Prisma",
                TextTooShort: "Texto demasiado corto",
                Never: "Nunca",
                Daily: "Diario",
                Weekly: "Semanal",
                Biweekly: "Quincenal",
                Monthly: "Mensual",
                Bimonthly: "Bimestral",
                Quarterly: "Trimestral",
                Days1: " Días",
                PricePoints: "Puntas de Precio",
                StandardRounding: "Redondeo Tradicional",
                ToNearest: "hacia el mas cercano",
                ToUp: "hacia arriba",
                ToDown: "hacia abajo",
                RoundingEndingValidation1: "La terminacion debe estar entre 0 y 1. ",
                RoundingEndingValidation2: "La terminacion debe estar entre 0 y 100. ",
                RoundingEndingValidation3: "La terminacion debe estar entre 0 y 1000. ",
                RoundingEndingValidation4: "La terminacion debe estar entre 0 y 10000. ",
                RoundingEndingValidation5: "La terminacion debe estar entre 0 y 100000. ",
                RoundingEndingValidation6: "La terminacion debe tener igual cantidad de decimales o menos que los decimales indicados para este redondeo.",
                RoundingEndingValidation7: "La terminacion debe ser menor a",
                RoundingEndingValidation8: "Ya existe una terminacion para este valor.",
                RoundingEndingValidation9: "El valor de la terminacion es invalido",
                Direction: "Direccion",
                EditEndings: "Editar Terminaciones",
                Rounding3: "milesimas (56.36105 = 56.361)",
                Rounding2: "centesimas (56.36105 = 56.36)",
                Rounding1: "decimas (56.36105 = 56.4)",
                Rounding0: "enteros (56.36105 = 56)",
                RoundingM1: "decenas (56.36105 = 60)",
                RoundingM2: "centenas (56.36105 = 100)",
                RoundingM3: "miles (5.600 = 6.000)",
                RoundingM4: "diez miles (5.600 = 10.000)",
                RoundingM5: "cien miles (56.000 = 100.000)",
                RoundingM6: "millones (560.000 = 1.000.000)",
                NewSuggestionRule: "Nueva Regla de Sugerencia",
                NewParentRule: "Nuevo Regla de Parentesco",
                Minimum: "Minimo",
                Average: "Promedio",
                Maximum: "Maximo",
                Mode: "Moda",
                LowestModePrice: "La de Precio mas bajo",
                AverageModePrice: "El Precio promedio de las modas",
                HighestModePrice: "La de Precio mas alto",
                HighestRepetitions: "La que mas se repite",
                PriceVariation: "Variacion de precios",
                RULE: "REGLA: ",
                of: "de",
                CI: "IC",
                SuggestedCI: "IC Sugerido",
                NewCI: "Nuevo IC",
                PriceHistoryAndEstimatedUnitSales: "Historico de precios y Volumen Estimado",
                PriceHistory: "Historico de precios",
                UnitSalesHistory: "Historico de venta en unidades",
                Costs: "Costos",
                CurrentWOTaxes: "Neto Actual",
                NewWOTaxes: "Neto Nuevo",
                CurrentTaxes: "Impuesto Actual",
                NewTaxes: "Impuesto Nuevo",
                CurrentTotalCostWOVat: "Costo Total S/IVA Actual",
                NewTotalCostWOVat: "Costo Total S/IVA Nuevo",
                TotalCostVariation: "Variacion % Costo Total S/IVA",
                Margins: "Margenes",
                MarginHasChanged: "El margen ha cambiado",
                CostHasChanged: "El costo ha cambiado",
                TicketTypes: "Tipos de Tickets",
                Planograms: "Planogramas",
                CreatePlanogram: "Crear Planograma",
                AnErrorHasOccurred: "Ha ocurrido un error",
                AnErrorHasOccurredInfo: "Ha ocurrido un error inesperado.\r\n Si el problema persiste utilice el botón Descargar Info para bajar el error a un archivo txt y luego adjuntelo en un mail dirigido al equipo de soporte de Prisma.\r\n Muchas gracias.",
                ViewAdditionalInfo: "Ver información adicional",
                DownloadInfo: "Descargar Info",
                RequestedActivityProcessing: "El proceso solicitado esta demorando",
                SalesLegend: "ventas_$",
                UnitsLegend: "unidades",
                MarginLegend: "margen_$",
                PriceLegend: "precio",
                SundayS: "Dom",
                MondayS: "Lun",
                TuesdayS: "Mar",
                WednesdayS: "Mie",
                ThursdayS: "Jue",
                FridayS: "Vie",
                SaturdayS: "Sab",
                JanuaryS: "Ene",
                FebruaryS: "Feb",
                MarchS: "Mar",
                AprilS: "Abr",
                Mays: "May",
                JuneS: "Jun",
                JulyS: "Jul",
                AugustS: "Ago",
                SeptemberS: "Sep",
                OctoberS: "Oct",
                NovemberS: "Nov",
                DecemberS: "Dic",
                Sunday: "Domingo",
                Monday: "Lunes",
                Tuesday: "Martes",
                Wednesday: "Miércoles",
                Thursday: "Jueves",
                Friday: "Viernes",
                Saturday: "Sábado",
                January: "Enero",
                February: "Febrero",
                March: "Marzo",
                April: "Abril",
                May: "Mayo",
                June: "Juni",
                July: "Julio",
                August: "Agosto",
                September: "Septiembre",
                October: "Octubre",
                November: "Noviembre",
                December: "Diciembre",
                IsThe: " es la ",
                WithGreatestIncrease: " de mayor crecimiento ",
                WithGreatestDecrease: " con la mayor caída ",
                DTSLegend3: "Aqui se pueden ver las SubCategorías agrupadas en Categorías, el tamaño total de cada categoría y la variación contra el mismo período del año anterior.",
                DSTFinalMessage: "Explore el Grafico",
                DSTFinalMessageInfo: "Puede navegar haciendo un click en los botones de arriba. Si se posiciona sobre una burbuja podra obtener mayor informacion de la",
                OfThe: " de la ",
                DstPopUpInformativeGraphicLess: "menos $ de venta",
                RequestedActivityProcessingInfo: "Prisma continua procesando el pedido solicitado.\r\nLa información estará lista cuando el sistema termine de procesarla.\r\nPresione aceptar para cerrar este mensaje",
                SessionExpired: "La sesión ha expirado",
                SessionExpiredInfo: "La sesión actual ha superado el tiempo limite y ha expirado.\r\nPresione aceptar para cerrar este mensaje y vuelva a ingresar sus credenciales.",
                AccessDenied: "Permisos Insuficientes",
                AccessDeniedInfo: "El usuario no tiene los permisos necesarios para realizar la acción requerida.\r\n Para obtener los permisos puede solicitarlos a un administrador.",
                Suggested: "Sugeridos",
                Endorsed: "Endosados",
                Pending: "Pendientes",
                NewPriceSuggestion: "Hay una nueva sugerencia de precio.",
                ERPPrice: "Es el precio del ERP.",
                PriceNeedsEndorsement: "Este precio fue actualizado y esta pendiente de endoso.",
                ReadyToInformToERP: "Este precio fue actualizado y endosado. Esta listo para ser informado al ERP.",
                Ignored: "Ignorado",
                Ignore: "Ignorar",
                Days: "días",
                Event: "Evento",
                Observations: "Observaciones",
                Date: "Fecha",
                User: "Usuario",
                AddNewFloor: "Agregar nuevo piso",
                ChoosePicture: "Elegir una foto",
                NoSelectedFiles: "No hay archivos seleccionados",
                Width: "Ancho",
                Height: "Alto",
                HeightF: "Alta",
                SellingArea: "Area de ventas",
                TotalArea: "Area total",
                LayoutEdition: "Edición de Layout",
                LayoutEditionSubTitle: "Cree nuevos layouts o modifique los existentes",
                LayoutEditionLegend1: "Agregue uno o más pisos al Layout",
                LayoutEditionLegend2: "Arrastre los planogramas que desea ubicar en el Layout",
                LayoutEditionLegend3: "Haga clic en el Planograma que desee para accionar sobre el, o bien para ver su propia información",
                LayoutView: "Vista de Layout",
                AnalyzeLayoutPerformance: "Analice la performance de sus layouts",
                ViewMode: "Modo Vista",
                EditMode: "Modo Edición",
                Rotate: "Rotar",
                ViewPlanogram: "Ver Planograma",
                View: "Ver",
                AddTicket: "Agregar Ticket",
                SelectedStore: "Tienda Seleccionada",
                Off: "Apagado",
                StockOut: "Quiebre de Stock",
                Floor: "Piso",
                NewFloor: "Nuevo Piso",
                ShowInfo: "Mostrar información",
                HideInfo: "Ocultar información",
                ShowInformationPlanograms: "Mostrar Información de los Planogramas",
                HideInformationPlanograms: "Ocultar Información de los Planogramas",
                AddFloorToLayout: "Agregue un piso al Layout",
                LayoutWOFloor: "Layout sin piso",
                AddFloorToLayoutEditMode: "Agregue desde el modo de edición de Layout un piso",
                NoPlanograms: "Sin Planogramas",
                NoPlanogramsSubtitle: "No hay Planogramas asociados a este Cluster",
                InformationPanel: "Panel de Información",
                Facings: "Frentes",
                SquareMeters: "Metros Cuadrados",
                LinearMeters: "Metros Lineales",
                Legend: "Leyenda",
                AdditionalInformation: "Información Adicional",
                NewTicket: "Nuevo Ticket",
                SelectTicket: "--Seleccionar Ticket--",
                SelectPriority: "--Seleccionar Prioridad--",
                Code: "Código",
                CreateLayout: "Crear Layout",
                SelectCluster: "--Seleccionar Cluster--",
                PressNextWhenDone: "Al finalizar presione el botón siguiente.",
                UseCreatedFurniture: "Utilice los muebles ya creados haciendo click en el +, o crea un nuevo mueble.",
                First: "Primero",
                Last: "Último",
                EditPlanogram: "Edición de Planograma",
                PlanogramView: "Vista de Planograma",
                AnalyzePlanogramPerformance: "Analice la performance del planograma",
                Communicate1: "Comunicar",
                ExportToExcel: "Exportar a Excel",
                ExportToExcelInfoPlanogram: "Exportar a Excel la información del Planograma",
                AuditPicture: "Foto de Auditoría",
                CreateFurniture: "Crear Mueble",
                ViewDimensions: "Ver Dimensiones",
                ActiveInCluster: "Activos Cluster",
                WithoutExhibition: "Sin Exhibición",
                WithExhibition: "Con Exhibición",
                Autocomplete: "Autocompletar",
                PriceElasticity: "Elasticidad de precios",
                Hypothesis: "Hipótesis",
                Action: "Acción",
                SelectAction: "--Seleccionar Acción--",
                Value: "Valor",
                SelectValueType: "--Seleccionar Tipo de Valor--",
                Forecast: "Pronóstico",
                ExpectVolumeSales: "Se espera que el volumen",
                ExpectedValue: "Valor Esperado",
                AvoidChangingOtherThanPrice: "Evitar modificar otras variables que no sean el precio",
                AssumptionsAndObservations: "Supuestos y Observaciones",
                EditLevels: "Editar Niveles",
                Load: "Carga",
                DaysInDisplay: "Días de Exhibición",
                DaysInStock: "Días de Stock",
                ClusterPerformance: "Performance x Cluster",
                StorePerformance: "Performance x Tienda",
                LoadBehind: "Apilables detrás",
                LoadAbove: "Apilables arriba",
                ClearFilters: "Borrar Filtros",
                SelectedCluster: "Cluster Seleccionado",
                GroupBy: "Agrupar por",
                SavePlanogramSwalTitle: "Esta seguro que desea guardar el Planograma?",
                SavedPlanogramSwal: "Planograma Guardado!",
                SavedPlanogramaSwalSubtitle: "se ha guardado el planograma exitosamente",
                ClickHereViewCategory: "click para ingresar a la categoría",
                MarginGuidelines: "Lineamientos de Margen",
                GenerateSuggestionsVarTooltipMin: "Generar sugerencias con una variacion menor a la minima",
                AllowMin: "Perm. Min",
                AllowMax: "Perm. Max",
                GenerateSuggestionsVarTooltipMax: "Generar sugerencias con una variacion mayor a la maxima",
                SignVar: "Var. Sign.",
                SignVarTooltip: "Generar sugerencias y precios nuevos cuya variacion sea mayor al porcentaje indicado en esta columna (Regla de Significatividad). La cifra colocada aplicara tanto positiva como negativamente",
                SignVarMargin: "Var. Sign. en Margen",
                SignVarMarginTooltip: "Generar sugerencias y precios nuevos cuya variacion en margen (var. margen * venta en unidades) sea mayor al monto indicado en esta columna (Regla de Significatividad). La cifra colocada aplicara tanto positiva como negativamente",
                MainCompetitor: "Comp. Principal",
                TargetMarginP: "Objetivo %",
                TargetMargin: "Objetivo",
                AllowMinTooltip: "Generar sugerencias por debajo del margen minimo",
                AllowMaxTooltip: "Generar sugerencias por arriba del margen maximo",
                GoToTarget: "Ir a Obj.",
                GoToTargetTooltip: "Si se selecciona esta opcion, ante un cambio de costos en cualquier SKU de la categoría se va a generar una sugerencia tomando el margen objetivo de la categoría",
                KeepMargin: "Mant.",
                KeepMarginTooltip: "Si se selecciona esta opcion, entonces ante un cambio de costos en cualquier SKU de la categoría se va a generar una sugerencia para mantener el margen actual del SKU",
                PriceVariation: "Variación de Precios",
                LowestPrice: "Precio más bajo",
                AveragePrice: "Precio Promedio",
                HighestPrice: "Precio más alto",
                SwalReviewCategoryPriceRules: "Esta seguro que desea revisar la categoría?",
                RequestQueued: "Su pedido para Revision de Categoría ha sido encolado!",
                RequestQueuedInfo: "Puede realizar el seguimiento del mismo mediante el listado de tareas pendientes ubicado en la barra superior.",
                TheProcessContinues: "El proceso continua...",
                TheProcessContinuesReviewCategoryInfo: "El proceso de revision de categoría continua. Puede revisar las sugerencias generadas dentro de unos momentos desde la pantalla de decision de precios.",
                AnErrorOccurredReviewingCategory: "Hubo un error al revisar esta categoría",
                AreYouSureGeneratePriceSuggestions: "Esta seguro que desea generar las sugerencias?",
                TheProcessContinuesGeneratePriceSuggestionsInfo: "El proceso de generación de sugerencias continua. Puede revisar las sugerencias generadas dentro de unos momentos desde la pantalla de decision de precios.",
                AnErrorOccurredGeneratingSuggestions: "Hubo un error al generar las sugerencias para esta categoría",
                AreYouSureDeletePriceRule: "Está seguro que desea Eliminar la regla y sus sugerencias asociadas?",
                DeletedRule: "Regla eliminada",
                DeletedRuleInfo: "La regla se ha eliminado exitosamente",
                TheProcessContinuesInfo: "El proceso continua. Puede revisar el estado en unos minutos.",
                AnErrorDeletingRule: "Hubo un error al eliminar la regla",
                CompetitiveWrittenRule1: "REGLA: Precio",
                IfMoreThanOneMode: "Si hay mas de una moda usar: ",
                IfMoreThanOneModeWithSameReps: "y si hay mas de una moda con misma cantidad de repeticiones usar:",
                OnlyCompetitorsInSamePriceZone: "(solo competidores en la misma zona de precios)",
                SamePrice: "Mismo Precio",
                DifferenceOf: "Diferencia de",
                SubsidiesFreights: "Subsidios y Fletes",
                InsufficientSpaceSwal: 'Espacio insuficiente',
                InsufficientHeightSpaceSubtitleSwal: 'La altura del item supera la altura disponible',
                InsufficientWidthSpaceSubtitleSwal: 'El ancho del item supera lugar disponible',
                InsufficientDepthSpaceSubtitleSwal: 'La profundidad del item supera lugar disponible',
                IncorrectCategorySwal: 'Categoría incorrecta',
                IncorrectCategorySubtitleSwal: 'No puede agregar este item en un nivel de subcategoría',
                SureDeleteItemSwal: 'Esta seguro que quiere eliminar el producto',
                AddLayDownItemSwal: 'Desea agregar el producto posicion acostada al final del nivel?',
                Fields: 'Campos',
                SavedTicketSwal: "Ticket Guardado!",
                SavedTicketSwalSubtitle: "Se ha guardado el ticket exitosamente",
                SureCleanPlanogramSwal: 'Esta seguro que que desea limpiar el planograma?',
                SureGenerateAutocompletePlanogramSwal: 'Esta seguro que que desea generar el autocompletado?',
                WithoutLevelsSwal: 'Sin niveles',
                WithoutLevelsSubtitleSwal: 'El panograma no tiene niveles creados',
                PlanagramNotFoundSwal: 'No se encontro el planograma',
                PlanagramNotFoundSwalSubtitle1: 'No se encontro panograma que contenga con itemID: ',
                PlanagramNotFoundSwalSubtitle2: 'en el cluster con ID: ',
                HideProducts: 'Ocultar Panel de Productos',
                ShowProducts: 'Ver Panel Productos',
                HideInformation: 'Ocultar Panel de Informacion',
                ShowInformation: 'Ver Panel de Información',
                HidePlanograms: 'Ocultar Panel de Planogramas',
                ShowPlanograms: 'Ver Panel Planogramas',
                Subspaced: 'Subespaciado',
                Overpaced: 'Sobreespaciado',
                SalesShare: 'Share de Ventas',
                SpaceShare: 'Share de Espacio',
                SalesRestProducts: 'Venta - Resto de los Productos',
                SpaceRestProcucts: 'Espacio - Resto de los Productos',
                SalesRestPlanograms: 'Venta - Resto de los Planogramas',
                SpaceRestPlanograms: 'Espacio - Resto de los Planogramas',
                SureDeletePlanogramSwal: 'Esta seguro que quiere eliminar el Planograma ',
                SureDeletePlanogramSwalDesc: 'Al eliminar el planograma será removido automaticamente de todos los planos donde se encuentre actualmente.',
                SaveLayoutSwalTitle: "Esta seguro que desea guardar el Layout?",
                SavedLayoutSwal: "Layout Guardado!",
                SavedLayoutSwalSubtitle: "Se ha guardado el Layout exitosamente",
                ViewMore: "Ver Más",
                ViewLess: "Ver Menos",
                PlanogramCreatedIn: "Planograma creado el",
                In: "en",
                UploadPicture: "Subir Foto",
                TicketsSubtitle: "Puede cambiar la prioridad y el estado de los Tickets existentes o dejar comentarios haciendo clic en el nombre del Ticket",
                SaveEditTicketTitle: "Cambios guardados",
                SaveEditTicketTitleSubtitle: "Los cambios fueron guardados correctamente",
                ResolveTicket: "RESOLVER TICKET",
                CloseTicket: "CERRAR TICKET",
                CreatedTicketIn: "creo el ticket el",
                TicketType: "Tipo de Ticket",
                Priority: "Prioridad",
                Comments: "Comentarios",
                Comment: "Comentar",
                WriteComment: "Escribe un comentario...",
                Low: "Baja",
                LowPercentage: "Baja %",
                RisePercentage: "Suba %",
                Medium: "Media",
                High: "Alto",
                Resolved: "Resuelto",
                Closed: "Cerrado",
                AlertWillLoseChanges: "Si abandona esta página perdera los cambios pendientes de confirmar. Está seguro que desea salir de la página?",
                SwalEmptyFloors: "Pisos vacíos",
                SwalEmptyFloorsSubtitle: "El último piso agregado aún se encuentra vacío",
                ValidationSwalTitleAddLevel: "Datos incompletos",
                ValidationSwalSubtitleAddLevel: "Debe completar todos los campos con valores numéricos",
                FileName: "Nombre del archivo: ",
                FileSize: ", Tamaño del archivo:",
                NotValidFileType: ": No es un tipo de archivo válido. Actualiza tu selección.",
                ValidationNameTicket: "Debe ingresar un nombre",
                ValidationTypeTicket: "Debe seleccionar un tipo de ticket",
                WithoutSize: "Sin tamaño",
                WithoutSizeSubtitle: "sin tamaño alto o ancho o profundidad",
                BasicInformation: "Información Básica",
                UnitOfMeasure: "Unidad de Medida",
                SaveItemSwall: "Guardar Item",
                SaveItemSwallSubtitle: "Está seguro que desea guardar el item?",
                ItemSavedProperly: "Ítem guardado Correctamente",
                ShowProfileItem: "Cambiar Frente: Mostrar el item de perfil",
                ShowItemInfront: "Cambiar Frente: Mostrar el item de frente",
                ShowItemAbove: "Cambiar Frente: Mostrar el item de arriba",
                ValidationSwalUomRepeted: "No puede repetirse el código de Unidad de Medida",
                Regions: "Regiones",
                Target: "Objetivo",
                YouMustEnterValidNumber: "Debe ingresar un número válido",
                ActionsAgGrid: "Acciones",
                PricesOfThisCategoryAsRevised: "Esta seguro que desea Marcar todos los precios de esta Categoría como revisados (se ignoraran las sugerencias pendientes)?",
                CompetitionPricesOfThisCategoryAsRevised: "Esta seguro que desea Marcar todos los precios de Competencia de esta Categoria como revisados (se ignoraran las sugerencias pendientes)?",
                NormalPricesOfThisCategoryAsRevised: "Esta seguro que desea Marcar todos los precios Normales de esta Categoria como revisados (se ignoraran las sugerencias pendientes)?",
                PricesOfThisCategoryAsRevisedPartial: "Esta seguro que desea Marcar todos los precios de esta Categoría como revisados (No se ignoraran las sugerencias pendientes)?",
                CompetitionPricesOfThisCategoryAsRevisedPartial: "Esta seguro que desea Marcar todos los precios de Competencia de esta Categoria como revisados (No se ignoraran las sugerencias pendientes)?",
                NormalPricesOfThisCategoryAsRevisedPartial: "Esta seguro que desea Marcar todos los precios Normales de esta Categoria como revisados (No se ignoraran las sugerencias pendientes)?",
                WantToNotifyEndorsement: "Está seguro que desea notificar endoso?",
                TheEndorsementWasNotified: "Se notificó el endoso",
                ErrorWhenWantingNotifyEndorsement: "Hubo un error al querer notificar el endoso en esta categoría",
                IgnoreSelectedSuggestions: "Está seguro que desea ignorar las sugerencias seleccionadas y blanquear los nuevos precios?",
                ConfirmedIgnoreSelectedSuggestions: "Se ignoraron las sugerencias seleccionadas y se blanquearon los nuevos precios",
                ErrorWhenWantingIgnoreSuggestions: "Hubo un error al querer ignorar las sugerencias",
                Attention: "Atención",
                NoChangesWereRecorded: "No se registraron cambios",
                EndorseTheSelectedPrices: "Está seguro que desea endosar los precios seleccionados?",
                EndorseTheModifiedPrices: "Está seguro que desea endosar los precios modificados?",
                ConfirmedEndorseTheSelectedPrices: "Se endosaron los precios seleccionados",
                ErrorWhenWantingEndorsePrices: "Hubo un error al querer endosar los precios",
                RejectionComment: "Comentario de rechazo",
                RejectionCommentSubtitle: "Ingrese un comentario por el cual se rechazaron los valores sugeridos:",
                RejectedItems: "Items Rechazados",
                RejectedItemsSubtitle: "Se han rechazado los items seleccionados",
                ModifyMarginIn: "Modificar Margen en ",
                ModifyMarginSubtitle: "El margen actual de los items seleccionados es de ",
                EnterNewMarginValue: " Ingrese el nuevo valor de margen:",
                YouMustEnterValue: "Debes ingresar un valor",
                YouMustEnterNumericValue: "Debe ingresar un valor numérico",
                MarginUpdated: "Margen Actualizado",
                TheMarginWasUpdatedCorrectly1: "Se actualizo correctamente el margen objetivo a ",
                TheMarginWasUpdatedCorrectly2: " para los items seleccionados",
                SelectAtLeastOnePriceToContinue: "Debe seleccionar al menos un precio para continuar",
                EnterNewMarginValueforSelectedProducts: "Ingrese el nuevo valor de margen para cada uno de los productos seleccionados:",
                NewPrice: "Precio Nuevo",
                Present: "Actual",
                SuggestedS: "Sugerido",
                CurrentPrice1: "Precio Vigente",
                Original: "Original",
                OriginalAverage: "Original Promedio",
                LastCost: "Último Costo",
                CompetitivenessIndex: "Índice de Competitividad",
                Sale: "Venta",
                Volume: "Volumen",
                NewSale: "Venta Nueva ($)",
                CurrentAverage: "Actual Promedio",
                SuggestedAverage: "Sugerido Promedio",
                PercentajeNew: "Nuevo %",
                DolarNew: "Nuevo $",
                ValidityDates: "Fechas de Vigencia",
                FromN: "Desde N",
                ToN: "Hasta N",
                OverlapsAbbreviation: "Solap.",
                ErrorWhenWantingToMarkThisCategoryAsRevised: "Hubo un error al querer marcar esta categoria como revisada",
                TheFollowingChangesWillBeSaved: "Se van a guardar los siguientes cambios: ",
                PriceChangesOp: " cambios de precios, ",
                IgnoredSuggestionsOp: " sugerencias ignoradas, ",
                EndorsedPricesOp: " precios endosados, ",
                RejectedPricesOp: " precios rechazados, ",
                SuggestionTooltip: "SUGERENCIA: Hay una nueva sugerencia de precio del sistema.",
                CurrentTooltip: "ACTUAL: Es el precio del ERP.",
                NewTooltip: "NUEVO: Este precio fue actualizado y esta pendiente de endoso.",
                EndorsedTooltip: "ENDOSADO: Este precio fue actualizado y endosado. Esta listo para ser informado al ERP.",
                ReportFilteredPricesToTheERP: "Está seguro que desea Informar los precios filtrados al ERP?",
                YouMustSelectAPriceType: "Debe seleccionar un tipo de precio",
                YouMustSelectAPriceTypeSubtitle: " No se ha seleccionado ningun tipo de precio",
                YouMustSelectAReason: "Debe seleccionar un motivo para estos tipos de precio",
                YouMustSelectAReasonSubtitle: " En el caso de que no aparezca ninguno, no existen precios a informar del tipo seleccionado",
                GeneratedPriceReport: "Reporte de Precios Generado!",
                GeneratedPriceReportSubtitle: "Se generó el reporte de precios exitosamente para informar los cambios al ERP",
                DeleteSelectedTests: "Está seguro que desea eliminar los test seleccionados?",
                TestsWereDeleted: "Los tests fueron borrados",
                TestsWereDeletedSuccessfully: "Los tests fueron borrados exitosamente",
                ErrorInWantingToEraseTests: "Hubo un error al querer borrar los tests",
                Data: "Datos",
                VariationInPrices: "Variación en los precios",
                Change: "Cambio",
                Expected: "Esperado",
                SelectFieldsToElaborateAnHypothesis: "Seleccione los campos para elaborar una hipótesis",
                IncorrectData: "Datos incorrectos",
                InvalidDateRange: "Intervalo de fechas inválido",
                Priceless: "Sin precio",
                DateFrom: "Fecha de",
                DateFromTooltip: "Nueva fecha de vigencia de",
                StoreCode: "Código de tienda",
                ChangesSent: "Cambios enviados",
                ChangesSentTooltip: "Todos los cambios, incluidos los productos que no están activos en la tienda",
                NAtooltip: "Cambios que no producirán una impresión de etiqueta",
                Atooltip: "Solo los cambios que producirán una impresión de etiqueta",
                Limit: "Límite",
                LimitTooltip: "Límite de cambios por día permitido para la tienda",
                Detour: "Desvío %",
                Discount: "Descuento",
                Decrease: "Bajas",
                Increase: "Subas",
                SuggestionsGenerated: "Sugerencias Generadas!",
                SuggestionsGeneratedSubtitle: "Se han generado nuevas sugerencias",
                ValidationOfDates: "La fecha de inicio debe ser posterior a la fecha actual",
                YouWantToSaveThisNewCost: "Está seguro que desea Guardar este Nuevo costo?",
                YouWantToSaveThisNewCostSubtitle: "Está seguro que desea Guardar este Nuevo costo y Generar Sugerencias de Precios?",
                TheSubsidyCanNotBeGreaterThan100: "El subsidio no puede ser mayor al 100%",
                SavedCost: "Costo Guardado",
                SavedCostSubtitle: "se ha guardado el costo exitosamente ",
                SavedCostSubtitle1: "y se generaron ",
                SavedCostSubtitle2: "sugerencias ",
                TheSubsidy: " el subsidio",
                UpdatedSubsidies: "Subsidios Actualizados",
                ErrorDisablingSubsidy: "Hubo un error al deshabilitar el subsidio.",
                SaveSubsidy: "Está seguro que desea guardar el subsidio?",
                SavedSubsidy: "Subsidio guardado!",
                SavedSubsidySubtitle: "se ha guardado el subsidio exitosamente",
                ErrorToSaveSubsidy: "Ha ocurrido un error tratando de guardar el subsidio",
                SaveRegion: "¿Está seguro que desea guardar la Region?",
                SavedRegion: "Región guardada",
                SavedRegionSubtitle: "se ha guardado la region exitosamente",
                ErrorToSaveTheRegion: "Ha ocurrido un error tratando de guardar la region",
                NoFilesSelectedForUpload: "No hay archivos actualmente seleccionados para cargar",
                NewOrModified: "Nuevo o Modificado",
                RequestForApproval: "Solicitó Aprobación",
                Approved: "Aprobado",
                Communicated: "Comunicado",
                ErasedPlanogram: "Planograma Borrado",
                ErasedPlanogramSubtitle: "Se ha borrado el planograma con éxito",
                InsufficientPermissionsToEdit: "Permisos Insuficientes para Editar",
                InsufficientPermissionsToEditPlanogram: "Permisos Insuficientes para Editar el Planograma y Solicitar su Aprobación",
                FinishThePlanogram: "Está seguro que desea Terminar el Planograma y Solicitar su Aprobación?",
                InsufficientPermitsToApprove: "Permisos Insuficientes para Aprobar",
                YouWantToApproveThePlanogram: "Está seguro que desea Aprobar el Planograma?",
                InsufficientPermissionsToReport: "Permisos Insuficientes para Informar",
                CommunicateThePlanogram: "Está seguro que desea Comunicar el Planograma a las Tiendas?",
                NotInformed: "NO INFORMADO",
                ValidationToSaveAplanogram: "Debe indicar un codigo, un nombre y al menos 1 subcategoria, 1 tienda y 1 modulo",
                ErrorWhenSaveThePlanogram: "Ocurrió un error al querer guardar el planograma",
                WidthNotAllowed: "ancho no permitido",
                WidthNotAllowedSubtitle: "ancho menor al espacio ocupado por los productos, ancho minimo permitido: ",
                ActionNotAllowed: "Acción no permitida",
                ActionNotAllowedSubtitle: "No se pueden eliminar Categorías que ya fueron asignadas al planograma",
                CanNotDeleteStores: "No se pueden eliminar Tiendas que ya fueron asignadas al planograma.",
                Shelf: "Estante",
                HeightNotAllowed: "altura no permitida",
                HeightNotAllowedSubtitle: "altura del nivel no puede ser menor que la altura del productos mas alto del nivel, altura minimo permitido: ",
                LevelWithItems: "Nivel con Items",
                LevelWithItemsSubtitle: "no se puede eliminar un nivel que contiene items",
                RoundingStandard: "Redondeo Estándar",
                Include: "Incluir",
                Exclude: "Excluir",
                GroupOfArticles: "Grupo de Artículos",
                PricesAndCosts: "Precios y costos",
                CurrentCost: "Costo Actual",
                SalesUn: "Venta Un",
                CompetitiveF: "Competitivas",
                RelationshipItems: "Parentesco Items",
                KinshipZones: "Parentesco Zonas",
                StepOneOp1Importers: " Se sube el archivo a importar desde la PC del usuario. <br/>El archivo debe ser tipo xlsx y constar de las columnas 'Zona|Articulo | UMV | Sensibilidad' en la primera linea. <br/>No pasara la validación si encuentra un registro repetido por articulo-umv-zona, o si no existe algún dato ingresado. <br/>La columna 'Sensibilidad' debe llevar el código y si se encuentra vacia <b>se eliminara el registro de sensibilidad</b> si es que existe.",
                StepOneOp2Importers: " Se sube el archivo a importar desde la PC del usuario",
                IntroductionToTheStepsImporters: "A través de este asistente de <b>4 pasos</b> se puede incorporar nueva información a Prisma.<br /><b>Paso 1:</b>",
                RemainingStepsImporters: "<br /><b>Paso 2:</b> Se verifica el formato y la extensión del archivo subido al servidor.<br /><b>Paso 3:</b> Se validan los datos del archivo en busqueda de inconsistencias.<br/><b>Paso 4:</b> Por último se incorpora definitivamente la información procesada y depurada al sistema.",
                NoCategoriesFoundForAllSKUs: "No se encontraron categorias para todos los SKUs",
                YouWantToAddThem: "Desea agregarlos ?",
                ValidationWillRunAgain: "Se volverá a correr la validación",
                Severity: "Severidad",
                Phase: "Fase",
                File: "Fila",
                WarningM: "ADVERTENCIA",
                AllSensitivityRecordsWillBeDeleted: "Se eliminará todo registro de sensibilidad!",
                Success: "Éxito",
                NoArticleInAnyAreaHasSensitivity: "Ningún artículo en ninguna zona tiene sensibilidad",
                ItHasPendingFiles: "Posee archivos pendientes, presione aceptar para volver al paso numero 3.",
                TheImporterTypeParameterHasNotBeenReceived: "No se ha recibido el parametro de tipo de importador. Se redireccionará a la home.",
                UnitOfMeasureOfSale: "Unidad de Medida de Venta",
                TypeOfPriceForce: "Tipo de Precio Vigente",
                CompetitivePricesSurvey: "Encuesta de Precios Competidores",
                MassiveLoadOfCosts: "Carga Masiva de Costos",
                BulkLoadingOfLabels: "Carga masiva de etiquetas",
                ManualLiquidations: "Liquidaciones Manuales",
                LiquidationsByMaturity: "Liquidaciones por Vencimiento",
                CancelLiquidations: "Cancelar Liquidaciones",
                UpdateFieldsOfMeasurementUnits: "Actualizar campos de unidades de medidas",
                Liquidation: "Liquidación",
                SelectReason: "Seleccione Motivo/s",
                LowerPrice: "Bajar Precio",
                IncreasePrice: "Subir Precio",
                LowM: "bajo",
                Rise: "subo",
                RiseA: "aumente",
                StaysTheSame: "se mantenga igual",
                Drops: "disminuya",
                IfHyphotesis: "Si ",
                ThePriceOfHyphotesis: " el precio de ",
                InHyphotesis: " en ",
                TheDemandIsExpectedHyphotesis: ", se espera que la demanda ",
                AHyphotesis: " un ",
                ValueIn: "Valor en ",
                UpdateDates: "Actualización de Fechas",
                UpdateDatesSubtitleOp1: "Indique la nueva fecha de inicio de los nuevos precios. Formato: AAAA-MM-DD",
                UpdateDatesSubtitleOp2: "Indique la nueva fecha de fin de los nuevos precios",
                Overlaps1: "Solapamientos",
                TypeCP: "Tipo PV",
                Laydown: "Acostado",
                Standup: "Parado",
                NewPriceHasBeenRejected: "Se ha rechazado el nuevo precio de $",
                GoToTheAuditTab: ". Vaya a la solapa de Auditoría para más detalles. ",
                Rejected: "Rechazado",
                PendingPrice: "Precio Pendiente",
                EndorsedPrice: "Precio Endosado",
                ClickHereToLoadTags: "Clic aquí para cargar tags asociados a este producto",
                ThePriceVariationLimitHasBeenExceeded: "Se ha excedido el límite de Variación de Precio. Los limites son: {0} a {1}",
                TheMarginsLimitHasBeenExceeded: "Se ha excedido el limite de Margenes. Los limites son: {0} a {1}",
                ThisPriceHasAnOverlapWithAPromotion: "Este precio tiene un Solapamiento con una Promocion. Clic aqui para ver mas",
                SumErrorOfWeightedIndexes: "La suma de los índices ponderados debe ser igual a 100%",
                PendingChangesWithoutSaving: "Tiene cambios pendientes sin guardar",
                TheChangesYouHaveMadeInRoleWillBeLost: "Si cambia de Marca, se perderan los cambios que haya hecho en Rol, Estrategia y Cobertura para ",
                RoleAndStrategy: "Rol y Estrategia",
                Role: "Rol",
                Strategy: "Estrategia",
                Coverage: "Cobertura",
                WeightedIndex: "índice Ponderado (Pesos)",
                MarketSales: "Ventas Mercado",
                MarketUnits: "Unidades Mercado",
                AnalysisSchedule: "Calendario del Análisis",
                Frequency: "Frecuencia",
                ActionType: "Tipo de Acción",
                LastRevision: "Última Revisión",
                InDays: "(En Días)",
                PriceUpdateConfirmTitle: "Se actualizarán los precios en pantalla",
                PriceUpdateConfirmText: "Recuerde GUARDAR luego para aplicar estos cambios",
                DoYouWishToIgnorePendingSuggestions: "Desea ignorar las sugerencias pendientes?",
                CopyingThisStructureAndItems: "Nuevo Planograma copiando Estructura y Productos de este Planograma",
                ClonePlanogram: "Clonar Planograma",
                YourRequestToGenerateSuggestionsHasBeenPasted: "Su pedido para generar sugerencias ha sido encolado!",
                TheSuggestionsContinueToBeGeneratedAssortment: "Las sugerencias siguen generandose. Recibira un mail cuando haya finalizado. Puede revisar el estado de las mismas dentro de unos minutos desde la pantalla de inicial de analisis de surtido.",
                ConfirmationCreateThisRule: "Está seguro que desea crear esta regla?",
                SavedRule: "Regla Guardada",
                SavedRuleSubtitle: "Se ha guardado la regla exitosamente",
                ErrorWhenSavingTheRule: "Hubo un error al guardar la regla",
                Associations: "Asociaciones",
                Values: "Valores",
                MarketPrice: "Precio Mercado",
                SalesRankingMarket: "Ranking de Ventas Mercado",
                ShareOfSalesMarket: "Share de Ventas Mercado",
                ShareOfUnitsMarket: "Share de Unidades Mercado",
                AccumulatedSalesMarket: "Acumulado Ventas Mercado",
                Margin2: "Margen 2",
                WeightedIndex2: "Índice Ponderado",
                SalesRanking: "Ranking de Ventas",
                UnitsRanking: "Ranking de Unidades",
                MarginRanking: "Ranking de Margen",
                ShareOfUnits: "Share de Unidades",
                ShareOfMargen: "Share de Margen",
                Margin2Percentage: "Margen 2 %",
                ShareOfMargen2: "Share de Margen 2",
                Presence: "Presencia",
                AccumulatedSales: "Acumulado Ventas",
                AccumulatedUnits: "Acumulado Unidades",
                AccumulatedMargin: "Acumulado Margen",
                AccumulatedWeightedIndex: "Acumulado Índice Ponderado",
                NameRole: "Nombre del Rol",
                NameValidation: "El nombre del Template debe tener al menos 2 caracteres",
                SelectAtLeastOneFilter: "Seleccione al menos un filtro",
                SelectAtLeastOneCategory: "Seleccione al menos una categoría",
                TheTemplate: "El Template: ",
                HasBeenCreatedSuccessfully: " se ha creado satisfactoriamente",
                ErrorSavingTemplate: "Ocurrió un error al guardar el Template: ",
                Confirm: "Confirmar",
                ConfirmPeriod: "Al seleccionar un periodo de 12 meses, no es posible utilizar el filtro por etiquetas. Desea Continuar?",
                GenerateTheReport: "Está seguro que desea generar el reporte?",
                GeneratedReport: "Reporte Generado",
                GeneratedReportSubtitle: "Ya puede visualizar el reporte generado.",
                GeneratingReport: "Generando Reporte",
                GeneratingReportSubtitle: "Puede realizar el seguimiento del mismo mediante el listado de tareas pendientes ubicado en la barra superior.",
                SKUsTooltip: "Cantidad de SKUs activos",
                SKUsTooltip1: "Porcentaje de SKUs activos",
                SalesDolar: "Ventas ($)",
                AmountOfSales: "Cantidad de ventas",
                SalesQty: "Ventas (Qty)",
                Dds: "DDS",
                CategoryCluster: "Categoría / Cluster",
                AmountSuggestions: "Cantidad de sugerencias de baja",
                HighF: "Altas",
                NumberHighSuggestions: "Cantidad de sugerencias de alta",
                ImpactInDolar: "Impacto en $ por Sugerencias por Cluster",
                PendindFalls: "Cantidad de bajas pendientes de validación",
                HighSlopes: "Cantidad de altas pendientes de validación",
                ImpactByDecisionsByCluster: "Impacto en $ por Decisiones por Cluster",
                InternalPerformance: "Performance Interna",
                InternalPerformanceTooltip: "Venta en $ (promedio por mes por tienda Same Store)",
                SalePercentage: "% Venta",
                SaleUnTooltip: "Venta en Unidades (promedio por mes por tienda Same Store)",
                UnitsPercentage: "% Unidades",
                ShareOfSalesInUnits: "Share de Ventas en Unidades",
                PercentageMargin: "% Margen $",
                MarginShare: "Share de Margen $",
                PercentageMargin2: "% Margen",
                SaleXsku: "Venta x Sku",
                Rotation: "Rotación",
                PerformanceMarket: "Performance Mercado",
                AssortmentCoverage: "Cobertura de surtido sobre todos los productos de mercado",
                MarketSalePercentage: "% Venta M",
                ShareOfSalesInTheMarket: "Share de Ventas en el Mercado",
                GAPSale: "GAP Venta",
                GAPSaleTooltip: "GAP Share de Venta en $ propia vs Mercado",
                SaleInUnitsTooltip2: "Venta en Unidades",
                MarketUnitsPercentage: "% Unidades M",
                MarketUnitsPercentageTooltip: "Share de Ventas en Unidades Mercado",
                GAPUnits: "GAP Unidades",
                GAPUnitsTooltip: "GAP Share de Unidades propio vs Mercado",
                InventoryInDolar: "Inventario en $",
                ShareOfInventoryValue: "Share de valor del inventario",
                InventoryInUnits: "Inventario en Unidades",
                GMROITooltip: "Margen $ / valor del inventario (cuantos $ de Margen por cada $ invertido en inventario)",
                Mlinear: "M lineales",
                MlinearTooltip: "Metros lineales (frentes x ancho del producto)",
                ProductFronts: "Frentes del Producto",
                GMROSTooltip: "Margen $ / Metros lineales (rentabilidad por espacio)",
                PercentageSpace: "% Espacio",
                PercentageSpaceTooltip: "Share de Espacios",
                Reviews: "Revisiones",
                Revised: "Revisada",
                ClonePlanogramWarning: 'La Estructura y los Productos de este Planograma serán clonados en un Nuevo Planograma',
                ClonePlanogramSubtitle: 'Agregar un nuevo Planograma, copiando la estructura y productos de este Planograma',
                ImportFromSpaceman: 'Importar de Spaceman',
                MonthsForCalculation: "Meses para Calculo",
                ClickHereFilterByPriceList: "Click aquí para filtrar por esta Zona de Precios",
                CloneLayout: "Agrega un nuevo Layout, copiando este Layout",
                CloneLayout: 'Clonar Layout',
                CloneLayoutWarning: 'Este Layout va a ser clonado en un Nuevo Layout',
                Sp_generalKpis_planogram: "Planograma",
                Sp_generalKpis_store: "Tienda",
                Sp_editLayout_editFloorActionButton: "Editar Piso",
                Sp_addLevel_EditTitle: "Editar datos del piso",
                SP_editPlanogram_Autocomplete_AssingFronts: "Asignación de Frentes",
                MapsMargin: "Mapa de Margen",
                Pr_Decisions_PriceUpdate:"Modificar Precios",
                Pr_Decisions_PriceUpdateTooltip: "Permite realizar una actualización masiva de los precios seleccionados",
                Pr_Decisions_PriceUpdate_StrategySelection: "Estrategia",
                Pr_Decisions_PriceUpdate_Value: "Valor",
                Pr_Decisions_PriceUpdate_Variation:"Aplicar variación de precio",
                Pr_Decisions_PriceUpdate_GoToPrice:"Llevar a un precio fijo",
                Pr_Decisions_PriceUpdate_GoToMargin: "Llevar a margen objetivo",
                Pr_Decisions_ExperimentalPriceUpdate: "Simulación de Precios",
                Pr_Decisions_ExperimentalPriceUpdateTooltip: "Permite realizar una prueba experimental de que sucederia si se cambiasen los precios",
                Pr_Decisions_PriceCopy: "Copiar Precios",
                Pr_Decisions_PriceCopyTooltip: "Permite realizar una copia masiva entre distintos tipos de precio",
                Pr_Decisions_PriceCopy_Source: "Precio Origen",
                Pr_Decisions_PriceCopy_Target: "Precio Destino",
                Pr_Decisions_Filters_Markets: "Mercados",
                Pr_Decisions_Filters_MarketsPlaceholder: "Seleccione Mercados",
                Pr_Decisions_Filters_Brands: "Banderas",
                Pr_Decisions_Filters_BrandsPlaceholder: "Seleccione Banderas",
                Pr_Decisions_Filters_Regions: "Regiones",
                Pr_Decisions_Filters_RegionsPlaceholder: "Seleccione Regiones",
                Pr_Decisions_SimulationStop: "Detener Simulación",
                Pr_Decisions_SimulationApply: "Confirmar Simulación",
                Pr_Decisions_SimulationWarningOnSave_Title: "Hay una simulación en curso",
                Pr_Decisions_SimulationWarningOnSave_Desc: "No se pueden guardar los cambios hasta que confirme o detenga la simulación activa",
                Hm_marketBoard_marketBoard: "Tablero de Mercado",
                EnterCodesSeparatedByComma: "Ingrese Codigos Separados por Coma",
                Adm_brands_savedBrand: "Bandera Guardada",
                Adm_brands_subtitle: "La Bandera se guardó correctamente",
                EnterCodesSeparatedBySpaces: "Ingrese Codigos Separados por espacios",
                EnterCodesSeparatedBySpacesDetail: "Ingrese Codigos Separados por espacios, luego presione 'enter'",
                Strip:"Fleje",
                Catalog: "Catálogo",
                OneTime: "Una Vez",
                Biannual: "Semestral",
                Annual: "Anual",
                PriceSurvey: "Encuesta de Precios",
                ExhibitionSurvey: "Encuesta de Exhibición",
                PriceLabelScan: "Escaneo de Precios",
                VoiceCommand: "Comando de Voz",
                ManualEntry: "Entrada Manual",
                Sort: "Ordenar",
                TooltipSortModules: "Ordenar Modulares",
                Ready: "Listo",

            })
            .translations("en", {
                Points: "Points",
                UnitSales: "Unit Sales",
                Price: "Price",
                Cost: "Cost",
                Benchmark: "Benchmark",
                TotalUnits: "Total Units",
                YesterdayPrice: "Yesterday Price",
                Inventory: "Inventory",
                Prices: "Price",
                Range: "Range",
                Language: "Language",
                English: "English",
                Spanish: "Spanish",
                ProductCost: "Product cost",
                DiffInDollars: "Difference in dollars",
                NotificationTitle: "Gross Margin decreased",
                NotificationSubtitle: "Coca Cola 1.5L was the product with the biggest decline in gross margin across the store.",
                TheReasons: "The Reasons",
                Reason1: 'Cost has incresed but sale price was not updated.',
                Iagree: "I Agree!",
                Idesagree: "I Disagree...",
                Discard: "Discard",
                Reason2: "Sales Volume has decreased",
                SubReason1: "Weekly seasonality was detected Sunday outsells Monday.",
                ChartTitle: "Evolution Chart: ",
                ChartTitleStrong: "Coca Cola 1.5L",
                Week: "Week",
                Month: "Month",
                Year: "Year",
                SalesInDollars: "Sales ($)",
                MarginInDollars: "Margin ($)",
                ChartFooter: "Available indicators: UnitSales, Price, Sales, Margin",
                PricesTableTitle: "Competitors Prices",
                PricesTableTitleCol1: "Competitor",
                PricesTableTitleCol2: "Date",
                PricesTableTitleCol3: "Price",
                PricesTableTitleCol4: "In Promotion",
                PricesTableTitleCol5: "Address",
                MapTitle: "Competitor Location: ",
                Back: "Back",
                WeatherTitle: "Yesterday Weather",
                InSales: "In Sales",
                WeatherDesc: "Last day with same weather 54 more units were sold",
                InventoryTitle: "Inventory Indicators",
                InventoryDays: "Inventory Days",
                InventoryComment: "The remaining days of inventory.",
                GMROI: "GMROI",
                InventoryFooter: " of margin per each $ invested in inventory",
                RangeTitle: "Range Indicators",
                RangeLine1: " in beverages category in store 1",
                RangeLine1Sub: " raised 2 positions",
                RangeLine2: " in beverages category in all the network",
                RangeLine2Sub: " raised 5 positions",
                RangeLine3: " in beverages category in the market",
                RangeLine3Sub: " dropped 1 position",
                StoreManager: "Store Manager",
                Profile: "Profile",
                Contacts: "Contacts",
                Mailbox: "Mailbox",
                Logout: "Logout",
                Home: "Home",
                Place: "Place",
                SmartDashboard: "Smart Dashboard",
                Maps: "Maps",
                DeviationAnalysis: "Deviation Analysis",
                WeatherAnalysis: "Weather Analysis",
                BcgMatrix: "BCG Matrix",
                Pivot: "Pivot",
                Notifications: "Notifications",
                NEW: "NEW",
                Decisions: "Decisions",
                Elasticities: "Elasticities",
                PromotionAnalysis: "Promotion Analysis",
                Sensitivity: "Sensitivity",
                RulesAndStrategies: "Rules and Strategies",
                Competitors: "Competitors",
                RangeAnalysis: "Range Analysis",
                Segmentation: "Segmentation",
                TicketAnalysis: "Ticket Analysis",
                BasketAnalysis: "Basket Analysis",
                Spaces: "Spaces",
                Planograms: "Planograms",
                Layouts: "Layouts",
                SpaceAnalysis: "Space Analysis",
                StockAnalysis: "Stock Analysis",
                OrderSuggestions: "Order Suggestions",
                Suppliers: "Suppliers",
                Administration: "Administration",
                GeneralSettings: "General Settings",
                Importers: "Importers",
                Masters: "Masters",
                DiscardConfirmTitle: "¿Are you sure?",
                DiscardConfirmSub: "The reason will be discarded",
                YesDiscard: "Yes, discard",
                DiscardMessageTitle: "Succesfully Discarded!",
                DiscardMessageComment: "You will not see this reason again.",
                ThanksForFeedback: "Thanks for your feedback!",
                ThanksForFeedbackComment: "Your feedback help us to improve reasons and get's you some point ",
                ThanksForFeedbackPoints: " points",
                ThanksForFeedbackTitle2: "Earn points giving Feedback",
                DisagreeTitle: "I disagree",
                DisagreeSub: "Tell us what do you think could be the motive",
                Send: "Send",
                Costs: "Costs",
                CompetitorsPrices: "Competitors Prices",
                CompetitivePosition: "Competitive Position",
                MarketAnalysis: "Market Analysis",
                Clusters: "Clusters",
                StoresIncluded: "Stores Included",
                Stores: "Stores",
                Store: "Store",
                StoresToBeIncludedByCategories: "Select stores to be included by Category",
                TypeOfStore: "Type of store",
                SelectTypeOfStore: "--Select type of store--",
                AddStore: "Add Store",
                StoreMasters: "Store Masters",
                AssignStores: "Assign Stores",
                Close: "Close",
                AssignmentOfStores: " Store Assignment",
                ByStore: "By Store",
                ByStores: "By Stores",
                TopNavbarSearch: "Search Products, Categories, Stores and Clusters",
                BenchmarksStepOne: "Select at least 2 Stores or Categories or Clusters to compare",
                StoresToCompare: "Stores to compare",
                SelectStores: "Select Stores",
                PerformanceByProvinceSubtitle: "The following is a summary of density of stations by province and its performance",
                PerformanceByProvinceSubtitle2: "The following is a summary of density of stations by province and its performance (with drill-down by Department)",
                TotalStores: "Total Stores:",
                BaseStore: "Base Stores",
                SelectStore: "Select Store",
                MirrorStore: "Mirror Store",
                ChooseComparableStores: "Choose a comparable store",
                MaintainThePriceOfMirrorStores: "Do not change the price of the mirror store",
                PriceChangesReportSubtitle: "The following report shows a summary of the price changes reported to the ERP by effective date and store",
                AddPricesArea: "Add price zones",
                Name: "Name",
                National: "National",
                Enabled: "Enabled",
                Yes: "Yes",
                ToDisable: "Disable",
                EnableTooltip: "Enable",
                Save: "Save",
                ItsBase: "It's Base",
                ItsNational: "It's National",
                Excluded: "Excluded",
                ExplicationCompetitors: "Manage the Groups of Competitors and their Points of Sale here to be able to establish competitive positioning rules",
                NewGroup: "New Group",
                NewCompetitor: "New Competitor",
                CompetitorsGroup: "Competitors Group",
                DragCompetitorsHere: "Drag Competitors Here",
                OnlyNew: "Only New",
                WithoutGroups: "Without Groups",
                Logo: "Logo",
                SonOf: "Son of",
                SelectFather: "Select Father",
                SelectFamily: "--SelectFamily--",
                ExternalCode: "External Code",
                Address: "Address",
                Brand: "Brand",
                CorporateName: "Corporate Name",
                Latitude: "Latitude",
                Length: "Length",
                Marker: "Marker",
                Observe: "Observe",
                SelectPriceZones: "Select Price Zones",
                CreateSensitivity: "Create Sensitivity",
                Order: "Order",
                SurveyFrequency: "Survey Frequency",
                Expiration: "Expiration",
                ValidityDays: "Validity Days",
                MondayAbbreviation: "M",
                TuesdayAbbreviation: "T",
                WednesdayAbbreviation: "W",
                ThursdayAbbreviation: "T",
                FridayAbbreviation: "F",
                SaturdayAbbreviation: "S",
                SundayAbbreviation: "S",
                SelectSurveyFrequency: "Select Survey Frequency",
                Monday: "Monday",
                Tuesday: "Tuesday",
                Wednesday: "Wednesday",
                Thursday: "Thursday",
                Friday: "Friday",
                Saturday: "Saturday",
                Sunday: "Sunday",
                CreateRoundingRule: "Create Rounding Rule",
                LoadItemsToExclude: "Load Items To Exclude",
                RoundingRules: "Rounding Rules",
                EditRoundingRuleSubtitle: "In case of having more than one rounding for the same type of price and range, Prism will try to comply with the most specific rule according to its scope of application (first category and then price area)",
                TypeOfPrice: "Price Type",
                RoundingType: "Rounding Type",
                Decimals: "Decimals",
                Tolerance: "Tolerance",
                DirectionRoundingRules: "Dir",
                TestPanel: "Test Panel",
                TestPanelSubtitle: "Does not take into account the rules with areas of application",
                PriceToRound: "Price To Round",
                EnterValue: "Enter Value",
                ResultWithRoundingApplied: "Result with rounding applied",
                TryRounding: "Try rounding",
                SelectPriceType: "Select price type",
                Terminations: "Terminations",
                SubcategoriesToInclude: "Subcategories to Include",
                SubcategoriesToExclude: "Subcategories to Exclude",
                AreasToInclude: "Areas to Include",
                AreasToBeExcluded: "Areas to be Excluded",
                Select: "Select",
                AddNewRank: "Add New Rank",
                SelectDecimals: "Select Decimals",
                Insert: "Insert",
                Add: "Add",
                EditTerminations: "Edit Terminations",
                New1: "New",
                Cancel: "Cancel",
                Accept: "Accept",
                StrategyAndPriceRules: "Strategy and price rules",
                ExplicationStrategyAndPriceRules: "Load the <b>gross margin guidelines</b> for each of the categories / subcategories, indicating the target margin, the minimum and the maximum. \r\n Also indicate the <b> minimum and maximum price variation \r\n Finally enter the <b>main competitor</b> for each of the categories / subcategories.",
                DescriptionRule: "Description Rule",
                Validity: "Validity",
                AreaOfApplication: "Inclusion Scope",
                Acceptance: "Acceptance",
                Description: "Description",
                Subcategories: "Subcategories",
                Items: "Items",
                PlaceholderSearchItem: "Enter description or article code",
                PriceTypes: "Price Types",
                PriceType: "Price Type",
                CompetitionAreas: "Competition Areas",
                Labels: "Labels",
                Properties: "Properties",
                RelationshipRule: "Relationship Rule",
                Areas: "Areas",
                RulesOfKinshipOfZones: "The Kinship rules of zones are evaluated each time a price of a Parent Zone is changed.\r\nFilters marked with * do not affect the selection of items.",
                SelectParentPriceZone: "Select Parent Price Zone",
                SelectKindOfType: "Select Kind of Type",
                DifferenceIn: "Difference in",
                AllSubcategories: "All Subcategories",
                AllArticleGroups: "All Article Groups",
                AllPricesZones: "All Prices Zones",
                ExceptZoneFather: "Except Zone Father",
                AllSuppliers: "All Suppliers",
                ApplySKUs: "Apply to SKUs that contain the following description / brand",
                ScopeOfExclusion: "Exclusion Scope",
                ExcludeSKUs: "Exclude SKUs that contain the following description / brand",
                SelectSubcategories: "Select Subcategories",
                SelectArticleGroup: "Select Article Group",
                SelectSuppliers: "Select Suppliers",
                SelectSensibilitiesToInclude: "Select Sensitivities to Include",
                SelectLabelsToInclude: "Select Labels To Include",
                SelectSubcategoriesToExclude: "Select Subcategories to Exclude",
                SelectItemGroupsToExclude: "Select Item Groups to Exclude",
                SelectPriceAreasToExclude: "Select Price Areas to Exclude",
                SelectSuppliersToExclude: "Select Suppliers to Exclude",
                SelectSensitivityToExclude: "Select Sensitivities to Exclude",
                SelectTagsToExclude: "Select Tags to Exclude",
                Products: "Products",
                RulesOfRelationshipOfProducts: "The Kinship product rules are evaluated each time a price of a Parent product is changed.\r\nFilters marked with * do not affect the selection of items.",
                SelectFather: "--Select Father--",
                Generic: "Generic",
                ParentProductVariant: "This parent product is a variant of the product",
                GenericRuleVariant: "It's a Generic rule - Variant",
                SuggestionRule: "Suggestion Rule",
                Margin: "Margin",
                MarginRules: "The margin rules generate price suggestions based on the change in supplier costs.\r\nFilters marked with * do not affect the selection of items.",
                TargetMargin: "Target Margin",
                KeepMargin: "Keep Margin",
                KeepSameMarginPercentage: "Keep SameMargin %",
                KeepSameMargin$: "Keep Same Margin $",
                Competitive1: "Competitive",
                CompetitiveRules: "Competitive rules generate price suggestions based on market price changes.\r\nFilters marked with * do not affect the selection of items.",
                SelectPointOfSale: "Select Point of Sale",
                SelectTypesOfObservations: "Select Sources of Information",
                ConsiderOnlyCompetitorsSamePriceArea: "Consider only competitors in the same price zone",
                Use: "Use",
                MoreThanOneFashionToUse: "If there is more than one fashion to use",
                LessOfaFashionToUse: "And if there is less of a fashion with the same number of repetitions, use",
                ApplyToProducts: "Apply to Products that contain the following description / brand",
                CancelTheChangesMade: "Cancel the changes made",
                SaveCompetitionRule: "Save Competition Rule",
                SaveMargenRule: "Save Margen Rule",
                SaveParentageRule: "Save Parentage Rule",
                SaveZoneRule: "Save Zone Rule",
                GenerateSuggestions: "Generate Suggestions",
                CategoryReview: "Category Review",
                TooltipGenerateSuggestionsButton: "The process of generation of suggestions is executed periodically according to the frequency set in each category and also when new market information is incorporated. From here it could be executed manually",
                EvaluateMarginRules: "Evaluate Margin Rules",
                EvaluateCompetitionRules: "Evaluate Competition Rules",
                EvaluateRelationshipRules: "Evaluate Relationship Rules",
                ExportToAnExcel: "Export the grid data to an Excel",
                IncludeAllItems: "Include all the items in the grid",
                ExcludeAllItems: "Exclude all items in the grid",
                SaveAllChangesPriceDecisions: "Save all changes made: accepted suggestions, changed prices, ignored suggestions, endorsed prices and rejected endorsements.",
                AcceptSuggestions: "Accept Suggestions",
                AcceptSuggestionsTooltip: "Accept all suggestions according to applied filters",
                EndorseAll: "Endorse All",
                EndorseAllTooltip: "Endorses all pending prices according to applied filters",
                MarkAsRevised: "Mark as reviewed",
                Normal: "Normal",
                MarkAsRevisedAllNormalPricesTooltip: "Mark as reviewd all regular prices",
                MarkAsRevisedAllPricesOfCompetitionTooltip: "Mark as reviewed all competitive prices",
                MarkAsRevisedAllPricesTooltip: "Mark as reviewed ALL prices",
                Status: "Status",
                PreviousPrice: "Previous Price",
                ThePriceVariationLimitHasBeenExceededTooltip: "The Price Variation limit has been exceeded. The limits are:",
                To: "to",
                TheMarginLimitHasBeenExceededTooltip: "The Margin limit has been exceeded. The limits are:",
                GeneralInformation: "General Information",
                Ean: "EAN",
                BaseUm: "Base UM",
                ProductInformation: "Product Information",
                Maker: "Maker",
                NewLabel: "New Label",
                Quality: "Quality",
                Premium: "Premium",
                Apply: "Apply",
                Area: "Area",
                Action: "Action",
                Situation: "Situation",
                ChangePriceManually: "Change Price Manually",
                AcceptSuggestion: "Accept Suggestion",
                Reverse: "Reverse",
                Endorse: "Endorse",
                ToRefuse: "To Refuse",
                MarkAsEndosada: "Mark As Endorse",
                ModifyMargin: "Modify Margin",
                PercentajeMargin: "Margin %",
                UpdateDates: "Update Dates",
                DateFrom: "Date From",
                DateUntil: "Date Until",
                ForEndorsement: "For Endorsement",
                Falls: "Falls",
                Rises: "Rises",
                StrongFalls: "Strong Falls",
                StrongRises: "Strong Rises",
                DepressedMargins: "Depressed Margins",
                HighMargins: "High Margins",
                NegativeMargins: "Negative Margins",
                LittleSignificantChanges: "Little Significant Changes",
                SuggestedNotRespected: "Suggested Not Respected",
                Overlaps: "Overlaps",
                SeeMap: "See Map",
                Page: "Page",
                Position: "Position",
                CurrentPrices: "Current Prices",
                Motive: "Motive",
                Reasons: "Reasons",
                CompetitorsReferenceSameZone: "Competitors Reference in the same Zone",
                ReasonForSuggestion: "Reason for the suggestion",
                LowForts: "Low Forts",
                LastPricesInformedERP: "Last Prices Informed to the ERP",
                GenerationDate: "Generation Date",
                GenerationTime: "Generation Time",
                Archive: "Archive",
                PricesPendingToInform: "Prices Pending To Inform",
                Un_select: "Un(select)",
                PricesToInform: "Prices to Inform",
                AllTypesOfPrice: "All types of price",
                AllStores: "All stores",
                AllCategories: "All categories",
                SelectAllVisiblePricesTooltip: "Select / Deselect all visible prices (considering filters)",
                GenerateTheFileToInformTheERPTooltip: "Generates the file to inform the ERP of the selected prices. These prices will go to Informed status in Prisma.",
                Delete: "Delete",
                NewTest: "New Test",
                RemoveTheSelectedTestsTooltip: "Remove the selected tests",
                CreateAnewTestTooltip: "Create a new test",
                PriceChangesReport: "Price Changes Report",
                PriceMapReport: "Price Map Report",
                ReportInformed: "Report Informed",
                OnlyThoseNotImplemented: "Only those not implemented",
                DailyMarginsReport: "Daily Margins Report",
                ImportantInformation: "Important Information",
                ExplanationNewCost: "Add the new costs with the '+ Add Cost' button.\r\nThese will appear gray in the list, until the 'Save new costs and Generate Suggestions' button has been pressed.\r\nOnce they have been saved correctly, you will see the list again in the normal way.",
                AddCost: "Add Cost",
                SaveNewCosts: "Save New Costs and Generate Suggestions",
                BulkLoad: "Bulk Load",
                Supplier: "Supplier",
                CenterOfDistribution: "Center of Distribution",
                ProductCode: "Product Code",
                NameOfProduct: "Name of Product",
                StartDate: "Start Date",
                NetCost: "Net Cost",
                Taxes: "Taxes",
                SelectDistributionCenter: "Select Distribution Center",
                ItemCode: "Item Code",
                SelectItem: "Select Item",
                Tax: "Tax",
                TaxRate: "Tax Rate",
                Subsidy: "Subsidy",
                FreightCost: "Freight Cost",
                DateEffectiveFrom: "Date Effective From",
                FreightCostText: "Theoretical Freight Cost from its distribution center to the station is",
                CreatePlanogram: "Create Planogram",
                Dimension: "Dimension",
                ModalTitleCreatePlanogram: "New Planogram",
                NewPlanogramLegend1: "Complete the following fields",
                NewPlanogramLegend2: "Drag the desired element to the 'Dropzone' and fill in the fields",
                NewPlanogramLegend3: "Click on show items if you want to drag a new module",
                ModalTitleEditPlanogram: "Edit Planogram",
                Width: 'Width',
                Height: 'Height',
                Depth: 'Depth',
                AddShelfToPlanogram: 'Add forniture to planogram',
                RemoveShelfFromPlanogram: 'Remove forniture from planogram',
                SaveShelf: 'Save forniture',
                LevelOfPlanogram: 'Level of the planogram',
                ExplanationAddLevels: 'Add the number of levels to the Planogram, the height of each of the levels will be the same, unless later specification. You can manually edit the height of each level by clicking on the "+" "-". ',
                Optional: 'Optional',
                ExplanationAddOneLevel: 'You can add a new level with a defined height, at the start or at the end of the planogram',
                NumberOfEqualLevels: 'Number of Equal Levels',
                CompleteAllFieldsToCreateANewPlanogram: 'Complete all the fields to create a new Planogram.',
                AtTheEndPressTheNextButton: 'At the end press the next button.',
                Fornitures: 'Furnitures',
                Print: 'Print',
                PrintPlanogram: 'Print the Planogram',
                ShowDimensions: 'Show Dimensions',
                HideDimensions: 'Hide Dimensions',
                FromThePlanogram: "Show or hide the dimensions of the Planogram",
                Clean: 'Clean',
                CleanPlanogram: 'Delete all the items arranged in the Planogram',
                EditDimensionsTooltip: "Edit the dimensions of the Planogram or add a new Module",
                EditPlanogramDimensions: "Edit Dimensions",
                GoToThePlanogramViewMode: 'Go to the Planogram View Mode',
                GoToThePlanogramEditMode: 'Go to the Planogram Edit Mode',
                GoToTheLayoutViewMode: 'Go to the Layout View Mode',
                GoToTheLayoutEditMode: 'Go to the Layout Edit Mode',
                AssignStoresLayout: "Assign stores to Layout",
                SeeAuditPicture: 'See pictures of the Planogram Audit',
                RequestApproval: 'Request Approval',
                RequestApprovalTooltip: 'Mark the Plan as Completed and Request Approval',
                Approve: "Approve",
                ApproveTooltip: "Approve the Planogram",
                AssingFrontsTo: 'Assing fronts to',
                Maximize: 'Maximize',
                MinimumFronts: 'Minimum Fronts',
                MaximumFronts: 'Maximum Fronts',
                PercentageOfOccupiedSpace: 'percentage of occupied space',
                Percentage: 'Percentage',
                AddRule: 'Add Rule',
                TopToBottom: 'Top to bottom',
                BottomToTop: 'Bottom to top',
                LeftToRight: 'Left to right',
                RightToLeft: 'Right to left',
                Upward: 'Upward',
                Falling: 'Falling',
                Measure: 'Measure',
                AddOrder: 'Add order',
                AddRestriction: 'Add Restriction',
                GENERATE: 'GENERATE',
                WithoutCategoryAssigned: 'Without category assigned',
                Delist: 'Delists',
                SeeTickets: 'See Tickets',
                DeleteAnItem: 'Delete an Item',
                AddItem: 'Add Item',
                Front: 'Front',
                Side: 'Side',
                Top: 'Top',
                Subcategory: 'Subcategory',
                Manufacturer: 'Manufacturer',
                Sales: 'Sales',
                Units: 'Units',
                SalesInStore: 'Sales in Store',
                PriceInStore: 'Price in Store',
                UnitsInStore: 'Units in Store',
                StockInStore: 'Stock in Store',
                SalesInCluster: 'Sales in Cluster',
                AveragePriceInCluster: 'Average Price in Cluster',
                UnitsInCluster: 'Units in Cluster',
                StockInCluster: 'Stock in Cluster',
                ExhibitionDays: 'Exhibition Days',
                ShowProductInformation: 'Show Product Information',
                WithoutRule: 'Without rule',
                RepositionFrequencyInDays: 'Reposition frequency in days',
                Tag: 'Tag',
                OrderBy: 'Order By',
                WithoutOrder: 'Without Order',
                MarginInCluster: 'Margin in Cluster',
                Weight: 'Weight',
                Aream2: 'Area',
                WithoutFilters: 'Without filters',
                Filters: 'Filters',
                SelectTag: "Select Tag",
                SelectBrand: "Select Brand",
                SelectManufacturer: "Select Manufacturer",
                Select: 'Select',
                NewSingular: 'New',
                Edit: 'Edit',
                NewTypeOfTicket: "New Type of Ticket",
                SelectTypeTicket: "--Select Type Ticket--",
                NeedDescription: "Need Description",
                NewPlanogram: "New Planogram",
                Complete: "Complete",
                From1: "From",
                ChangeSenseOrientation: "Change Traffic flow",
                SenseCirculation: "Traffic flow",
                AutoStack: "Stack",
                ShowDoor: "Show Door",
                Tags: "Tags",
                ProductChat: "Product Chat",
                NewMessage: "New Message",
                Write: "Write",
                DelistProduct: "Delist Product",
                LiquidarProduct: "LIQUIDAR PRODUCT",
                AskForWithdrawal: "ASK FOR WITHDRAWAL",
                ChooseCriteria: "Choose Criteria",
                HighPriority: "High Priority",
                MediumPriority: "Medium Priority",
                LowPriority: "Low Priority",
                StopFollowingTicket: "Stop Following Ticket",
                FollowTicket: "Follow Ticket",
                Elements: "Elements",
                EditElement: "Edit Element",
                Container: "Container",
                Columns: "Columns",
                AddElements: "Add Elements",
                AddLevelToThePlanegram: "Add Level to the Planegram",
                AddLevel: "Add the indicated Level to the Planogram",
                Measurements: "Measurements",
                Dropzone: "Dropzone",
                DragItemhere: "Drag an item here",
                NewFurniture: "New Furniture",
                IncreaseHeightPlanogram: "Increase the height of the Planogram Level",
                ReduceHeightPlanogram: "Reduce the height of the Planogram Level",
                RemovePlanogramLevel: "Remove the Planogram Level",
                NewModuleTitle: "New Module",
                NumberLevels: "Number of Levels",
                DivisionsOrDoors: "Divisions or doors",
                PositionIn: "Position in:",
                ShowElements: "Show Elements",
                EditElement: "Edit Element",
                InsertInTheSameColumn: "Insert in the same Column",
                InsertInNewColumnOnLeft: "Insert in new column on the left",
                InsertInNewColumnOnRight: "Insert in new column on the right",
                Refrigerator: "Refrigerator",
                NewTags: "New Tags",
                Quantity: "Quantity",
                UnitOfMeasureAbbreviation: "Unit of Measure",
                BaseUnitOfMeasure: "Base Unit Of Measure",
                ValidationEan1: "exists in the data base (field not valid)",
                ValidationEan2: "Valid",
                UploadPhotosItem: "Upload photos",
                UploadPhotosItemTooltip: "Upload photos for this Unit of Measure",
                RemoveThisUnitOfMeasure: "Remove this Unit of Measure",
                UnitOfMeasureCode: "Unit Of Measure Code",
                AddUnitOfMeasure: "Add Unit Of Measure",
                UploadItemPictures: "Upload Item Pictures",
                ItemInFront: "from the front item",
                ProfileItem: "of the profile item",
                ItemAbove: "of the item above",
                Distributor: "Distributor",
                DischargeDate: "Discharge date",
                Conversion: "Conversion",
                Dimensions: "Dimensions",
                HeightText: "Represents the sum of the levels",
                NoImage: "Without picture",
                NoImageSubtitle: "Add audit photo",
                EditLayout: "Edit Layout",
                EditUnitOfMeasures: "Measurement unit edition",
                ProductHigh: "Product High",
                DeleteItem: "Delete item or group of items",
                ChangeFront: "Change the front of the item or group of items",
                AddItemBehind: "Add item or group of items behind",
                DeleteItemBehind: "Remove item or group of items behind",
                AddItemToRight: "Add item to the right",
                DeleteItemOnRight: "Delete the item on the right",
                AddItemAbove: "Add Item or group of items above",
                DeleteItemAbove: "Delete Item or group of items above",
                TooltipForStoreUnits: "There are not enough units to complete the fronts",
                TooltipForStoreUnits2: "There are not enough units to complete the total load",
                MoreInformation: "More Information",
                TooltipRemoveElement: "Delete the selected item",
                MoreZoom: "Zoom in on the Planogram",
                LessZoom: "Give less zoom to the Planogram",
                AveragePerformance: "Average Performance",
                SelectPriceType: "--Select a price type--",
                InformToERPTooltip: "Generates the file to inform the ERP of the filtered prices. These prices will go to Informed status in Prisma.",
                YouMustSelectACategory: "You must select a category",
                NoResultsFor: "No results for",
                AddFreightCost: "Add Freight / Stimulus Cost",
                CostOfFreightAndStimulus: "Cost of Freight and Stimulus",
                ItemCode1: "Item Code",
                FreightCost: "Freight cost",
                Stimulus: "Stimulus",
                SelectArea: "--Select area--",
                Article: "Article",
                AddRegion: "Add Region",
                Region: "Region",
                SubcategoriesToInclude: "Subcategories to include",
                SubcategoriesToExclude: "Subcategories to exclude",
                PricingZonesToExclude: "Pricing zones to exclude",
                PriceZonesToInclude: "Pricing zones to include",
                AcceptSelectedSuggestions: "Accept the selected suggestions",
                IgnoreSelectedSuggestions1: "Save the changes made and ignore the selected suggestions",
                EndorseSelectedItems: "Endorse the selected items accepting the modifications",
                RejectSelectedChanges: "Reject selected changes (Enter a rejection message)",
                ModifyMarginInStaggeredWay: "Modify margin in a staggered way indicating a Margin $ objective",
                MassiveDatesUpdate: "Massive Update of Validity Dates for New Prices",
                MassiveLoad: "Massive Load",
                IndividualLoad: "Individual Load",
                SaveABTesting: "Save AB Testing",
                PercentageOfTheRiseOrFall: "% of the rise or fall",
                ItMustMatchExactly: "It must coincide exactly with the regions informed by the authority",
                FreightCostTooltip: "Enter here the freight cost in $ per liter",
                StimulusTooltip: "Enter here the fiscal stimulus in $ per liter",
                FilterApplied: "Filter applied",
                LastModification: "Last Modification",
                EditPlanogramStructure: "Edit Planogram Structure",
                CopyingThisStructure: "New Planogram Copying this Structure",
                ClearPlanogram: "Clear Planogram",
                SaveThePlanogram: "Save the Planogram",
                YouDoNotHavePermissionsToSave: "You do not have permissions to Save",
                Active: "Active",
                ClickToFilterBy: "Click to filter by",
                ClickToEdit: "Click to edit",
                Copy: "Copy",
                CopyTooltip: "Copy last module and insert to the right",
                DataImporter: "Data Importer",
                UploadFiles: "Upload Files",
                DragTheFileHere: "Drag the file here",
                DragTheFileHere1: "or click to search on your PC",
                VerifyFormat: "Verify Format",
                ValidateInformation: "Validate Information",
                UpdateTables: "Update Tables",
                UploadFile: "Upload file",
                ValidateData: "Validate Data",
                AddCategories: "Add Categories",
                Finalize: "Finalize",
                SelectSubcategory: "--Select Subcategory--",
                Subcategory2: "Subcategory2",
                GoToStep2Tooltip: "Go to step 2",
                GoToStep3Tooltip: "Go to step 3",
                GoToStep4Tooltip: "Go to step 4",
                RestartTheImporter: "Restart the importer to upload new files",
                ManualSettlementsOverwriteSuggestedSettlements: "Alert: Manual Settlements overwrite Suggested Settlements",
                LiquidationsByExpirationOverwriteSuggestedLiquidations: "Alert: The Settlements by Expiration overwrite the Suggested Liquidations.",
                BleachSensitivities: "Bleach Sensitivities",
                NewHighRule: "New High Rule",
                NewRuleLows: "New Rule Lows",
                SaveProductRegistrationRule: "Save product registration rule",
                SaveProductDropRule: "Save product drop rule",
                ButtonGenerateSuggestionsAssortmentTooltip: "The process of generation of suggestions is executed periodically according to the frequency set in each category and also when new market information is incorporated. From here it could be executed manually",
                NewRuleTitle: "NEW RULE",
                NewHighRuleIntro: "The rules of registration will be used by the system to generate suggestions for the inclusion of new articles that are performing well in the market. To define this criterion, different variables and conditionals can be used in any combination chosen.",
                CoverageInfo: "Take into account the objective coverage of the different brands when creating the coverage rule.",
                ObjectiveFor: " objective for ",
                SelectAnOption: "Select an Option",
                AllClusters: "All Clusters",
                AllTags: "All Tags",
                SelectSubcategoriesToInclude: "Select Subcategories to Include",
                SelectClustersToExclude: "Select Clusters to exclude",
                NewLowRuleIntro: "The deregistration rules will be used by the system to generate suggestions for low of existing articles that are not performing well. To define this criterion, different variables and conditionals can be used in any combination chosen.",
                AssortmentMatrixStep1: "Select: Flag, Format and Client.",
                AssortmentMatrixStep2: "Select Sector, Section, Family, Subfamily and Cluster. ",
                AssortmentMatrixStep3: "Select if opening is desired by Manufacturer or Brand (or both).",
                AssortmentMatrixStep4: "Select 1 or more labels.",
                AssortmentMatrixStep5: "Select period.",
                SaveTemplate: "Save Template",
                OpenTemplate: "Open Template",
                AssortmentReport: "Assortment Report",
                Flag: "Flag",
                AllFlags: "All Flags",
                Format: "Format",
                AllFormats: "All Formats",
                Client: "Customer",
                AllClients: "All Customer", 
                Scope: "Scope",
                Section: "Section",
                Family: "Family",
                Subfamily: "Subfamily",
                AllSubfamily: "All Subfamilies",
                Opening: "Opening",
                UpTo5Labels: "You can only select up to 5 labels.",
                Months3: "3 Months",
                Months6: "6 Months",
                Run: "Run",
                Export: "Export",
                ManagementOfItems: "Management of Items",
                ManagementOfItemsLegend1: "Search for products: Choose category / s, subcategory / s and filter by free text, when finished press the button 'Search'.",
                ManagementOfItemsLegend2: "Modification of attributes: Modify or add the attributes that you want to update in a massive way, when finished press the 'Apply' button.",
                ManagementOfItemsLegend3: "List of Items: In the following table you can see the changes applied.",
                ManageItems: "Manage Items",
                SearchManageItems: "Search by code, name, brand, manufacturer",
                FilterTooltip: "Filter",
                SelectedTags: "Selected Tags",
                AddNewTagTooltip: "Add new Tag",
                AsignForPersist: "Now you must assign the new attribute to at least one item",
                Group: "Group",
                SelectedGroup: "Selected Group",
                YouMustSelectAtLeastOneCategory: "You must select at least one Category",
                ManagementOfItemsTitle1: "Product Search",
                ManagementOfItemsTitle2: "Modification of attributes",
                ManagementOfItemsTitle3: "List of Items",
                NewTagGroup: "New Tag Group",
                NewBrand: "New Brand",
                NewManufacturer: "New Manufacturer",
                WithoutProducts: "Without Products",
                FullView: "Full View",
                UnitsStock: "Stock Units",
                TheItemWasAdded: "The item was added",
                Aggregate: "Aggregate",
                Actives: "Actives",
                EnterFurnitureToPrint: "Enter furniture to print",
                EnterFurnitureToPrintSubtitle: "If it leaves empty, all the furniture will be printed. You can use ranges. Ex: 2-5",
                InvalidRank: "The entered range is invalid",
                InvalidRankSubtitle: "You must write a valid furniture position",
                ConfigurationFinished: "Configuration finished",
                AllReadyToPrintThePlanogram: "All ready to print the planogram",
                Planogram: "Planogram",
                Seconds: " seconds", 
                Since: "Since ",
                YearAgo: " year ago",
                MonthAgo: " month ago",
                DayAgo: " day ago",
                HourAgo: " hour ago",
                MinuteAgo: " minute ago",
                Sp_generalKpis_Kpis: "General KPIs",
                Sp_generalKpis_Filters: "Filters",
                Sp_generalKpis_Category: "Category",
                Sp_generalKpis_rentability: "Rentability",
                Sp_generalKpis_averagePrice: "Average Price",
                Sp_generalKpis_spacing: "Spacing",
                Sp_generalKpis_tags: "Tags",
                Sp_generalKpis_zones: "Zones",
                Sp_generalKpis_applyButton: "Apply",
                Sp_editLayout_AddTicketTooltip: "Add Ticket to the Selected Planogram",
                Sp_editLayout_rotateTooltip: "Rotate selected Planogram",
                Sp_editLayout_deleteTooltip: "Delete selected Planogram",
                Pr_competitors_checkboxMarker: "Relative prices of this competitor will be agreed for the generation of suggestions",
                Pr_competitors_checkboxObserve: "The prices of this competitor will be relieved",
                Sp_editPlanogram_Recommendation: "Recommendation",

                
                CompetitiveIndex: "Competitiveness Index",
                PriceEvolution: "Price Evolution",
                CompetitiveByZone: "Competitiveness By Zone",
                CompetitiveByCompetitor: "Competitiveness by Competitor",
                CompetitiveByCategory: "Competitiveness by Category",
                SelectPricingZone: "-- Select Pricing Zone --",
                Pr_competitiveIndex_subtitle: "Change the grouper to see in different ways the Competitiveness Index",
                Pr_competitiveIndex_GroupBy: "Group by:",
                Pr_competitiveIndex_CompetitivenessFor: "Competitiveness for",
                Pr_competitiveIndex_Ci: "CI",
                Pr_competitiveIndex_Cheaper: "Cheaper",
                Pr_competitiveIndex_TheSame: "The Same",
                Pr_competitiveIndex_MoreExpensive: "More expensive",
                Pr_competitiveIndex_TypeOfSurvey: "Type of Survey",
                Pr_decisions_restore: "Restore",
                Sp_editLayout_validationArea: "The Sales Area can not be greater than the Total Area",
                Pr_decisions_ExperimentalPrice: "Simulated Price",
                Pr_decisions_Close: "Close",
                Pr_decisions_CloseTooltip: "Deselect all items",
                Pr_decisions_Experiment: "Simulation",
                Pr_decisions_ChangePrice: "Change Price",
                Pr_decisions_Selected: "Selected",
                Pr_volumeMeasurement_legend1: "Select a product",
                Pr_volumeMeasurement_legend2: "Select a price zone",
                Pr_volumeMeasurement_legend3: "Fill in the dates 'From' and 'To' to visualize the graph",
                Pr_volumeMeasurement_evolutionOfTheMIX: "Evolution of the MIX",
                Pr_volumeMeasurement_validationDate: "Date Since can not be greater than date Until",
                Sp_planograms_editStructure: "Edit Structure",
                Sp_planograms_editStructureTooltip: "Edit the planogram structure or add a new piece of furniture",
                Sp_planograms_copyStructure: "Copy Structure",
                Sp_planograms_copyStructureAndProducts: "Copy Structure and Products",
                Sp_editPlanogram_editLevelsTooltip: "Edit the dimensions of the Planogram levels",
                Adm_brands_brands: "Brands",
                Adm_brands_addBrand: "Add Brand",
                Adm_brands_brandName: "Brand Name",
                Adm_brands_searchBrand: "Search Brand...",
                Adm_brands_newBrand: "New Brand",
                Adm_brands_editBrand: "Edit Brand",
                Sp_createPlanogram_loadStoresIndividually: "Load stores individually",
                Sp_createPlanogram_loadStoresMassively: "Load stores massively",
                Sp_editPlanogram_space: "Space",
                Sp_editPlanogram_spaced: "Spaced: ",
                Sp_editPlanogram_new: "New",
                Sp_editPlanogram_pendingApproval: "Pending Approval",
                Sp_editPlanogram_approved: "Approved",
                Sp_editPlanogram_informed: "Informed",
                Sp_editLayout_moreZoomTooltip: "Zoom in on Layout",
                Sp_editLayout_lessZoomTooltip: "Give less zoom to the Layout",
                SP_editPlanogram_Autocomplete_Strategy: "Define Strategy",
                SP_editPlanogram_Autocomplete_ExhibitionDays: "Target Exhibition Days",
                SP_editPlanogram_Autocomplete_Results: "Results",
                SP_editPlanogram_Autocomplete_ResultsSummary: "Summary",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part1: "There are ",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part2: " items included in the planogram",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part3: "of a total of ",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part4: " items ",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part5: "and we could not include ",
                SP_editPlanogram_Autocomplete_ResultsSummary_Part6: " items of the current assortment.",
                SP_editPlanogram_Autocomplete_ResultsSummary: "Not included items detail",
                SP_editPlanogram_Autocomplete_ResultsTableCode: "Code",
                SP_editPlanogram_Autocomplete_ResultsTableDesc: "Description",
                Pr_competitors_selectBrand: "--Select Brand--",
                Ad_missions_newMissions: "New Mission",
                Ad_missions_mission: "Mission",
                Ad_missions_missionType: "Mission type",
                Ad_missions_finishDate: "Finish Date",
                Ad_missions_users: "#Users",
                Ad_missions_advance: "Advance",
                Ad_missions_daysToExpire: "Days to Expire",
                Ad_missions_programming: "Programming",
                Ad_missions_surveyMethod: "Survey Method",
                Ad_missions_selectMissionType: "--Select mission type--",
                Ad_missions_selectsMethod: "--Select method--",
                Ad_missions_selectFrecuency: "--Select Frecuency--", 
                Ad_missions_selectActionType: "--Select Action Type--", 
                Ad_missions_timeToCompleteIt: "Time to complete it",
                Ad_missions_inDays: "In days",
                Ad_missions_startsOn: "Start on:",
                Ad_missions_endsThe: "Ends the:",
                Ad_missions_brand: "Brand",
                Ad_missions_selectBrand: "--Select Brands--",
                Ad_missions_region: "Region",
                Ad_missions_selectRegion: "--Select Regions--",
                Ad_missions_location: "Location",
                Ad_missions_selectLocation: "--Select localities--",
                Ad_missions_priceZone: "Price Zone",
                Ad_missions_selectpriceZone: "--Select price zone--",
                Ad_missions_specificStores: "Specific Stores",
                Ad_missions_selectSpecificStores: "--Select specific stores--",
                Ad_missions_products: "Products",
                Ad_missions_group: "Group",
                Ad_missions_selectGroup: "--Select group--",
                Ad_missions_subGroup: "Subgroup",
                Ad_missions_selectGroup: "--Select Subgroup--",
                Ad_missions_Items: "Items",
                Ad_missions_selectItems: "--Select Items--",
                Ad_missions_responsible: "Responsible",
                Ad_missions_selectShopper: "Select Shopper",
                Ad_missions_shopper: "Shopper",
                Ad_missions_addShopper: "Add a new responsable",
                Ad_missions_legendResponsable: "Assign tents to a specific manager to do this survey",
                Pr_competitors_selectPlace: "--Select Place--",

                //JS
                SaveSwalTitle: "Are you sure you want to save the store?",
                StoreSaved: "Saved store",
                ErrorWhenSavingTheStore: "An error occurred while saving the store",
                ValidationNameStore: "You must choose a store name",
                ValidationTypeStore: "You must choose a store type",
                EnableDisableSwalTitle: "the store",
                UpdatedStores: "Updated stores",
                AssignedStoresSwalTitle: "Assigned stores",
                AssignedStoresSwalSubTitle: "The stores were correctly assigned to the user",
                ValidationMirrorStoreAndBaseStore: "The mirror station must be different from the base station",
                ValidationSelectedStoreNotHaveAPrice: "The selected station does not have a price for the chosen item",
                DstPopUpInformativeGraphic: "more sales in $",
                StoreAndCD: "Stores and Distribution Centers",
                StoreDescendant: "Store Desc.",
                CategoryTreeMap: "Category Treemap",
                ByCategory: "By Category",
                Setup: "Setup",
                PriceZones: "Price Zones",
                Rounding: "Rounding",
                Goals: "Goals",
                Reports: "Reports",
                Welcome: "Welcome to Prisma!",
                InformToERP: "Inform to ERP",
                PriceElasticity: "Price Elasticity",
                Reports: "Reports",
                PriceChanges: "Price Changes",
                PriceMap: "Price Map",
                InformedPrices: "Informed Prices",
                DailyMargins: "Daily Margins",
                ReplenishmentCosts: "Replenishment Costs",
                Promotions: "Promotions",
                Campaigns: "Campaigns",
                Workflows: "Workflows",
                Rules: "Rules",
                PromotionalActions: "Promotions",
                Calendar: "Calendar",
                ControlPanel: "Control Panel",
                MyTasks: "My Tasks",
                Communicate: "Communicate",
                BIReports: "BI Reports",
                Markdowns: "Markdowns",
                SelectionRules: "Selection Rules",
                Missions: "Missions",
                MarketData: "Market Data",
                NewPrices: "New Prices",
                Spaces: "Spaces",
                Sensitivities: "Sensitivities",
                Security: "Security",
                Users: "Users",
                PrismaDevelopedBy: "Prisma has been developed by",
                InformationUpdatedUpTo: "Information Updated up to",
                SALES: "SALES",
                SalesYTD: "Sales YTD",
                VsPlan: "vs Plan",
                PlanProgress: "Plan Progress",
                AnnualPlan: "Annual Plan",
                Forecasted: "Forecasted",
                VsPrevYear: "vs Previous Year",
                MARGIN: "MARGIN $",
                COST: "COST",
                UNITS: "UNITS",
                MarginYTD: "Margin YTD",
                UnitsYTD: "Units YTD",
                CostYTD: "Cost YTD",
                MarginDecreased: "Margin has decreased",
                PriceInS: 'Price in $',
                HeatMap: 'Heat Map',
                HeatMapSubtitle: "In this map you can review your performance per Country, State, City and Store",
                PreviousMonth: "Previous Month",
                SameMonthPrevYear: "Same month prev year",
                SelectCategory: "-- Select Category --",
                SalesIn: "Sales in",
                Population: "Population",
                Households: "Households",
                GreaterThan: "greater than",
                Between: "between",
                And: " and ",
                LessThan: "less than",
                SelectSector: "--Select Sector--",
                SelectState: "--Select State--",
                Colors: "Colors",
                Size: "Size",
                Period: "Period",
                START: "START",
                Analysis: "Analysis",
                SelectedPeriod: "Selected period",
                DifferenceSamePeriodPrevYear: "Difference with same period prev. Year",
                State: "State",
                ShowSalesDistributionBetween: "Show sales distribution between",
                Categories: "Categories",
                Stores: "Stores",
                TheSizeOfTheCirclesCorresponds: "The size of the bubbles correspond to",
                Category: "Category",
                Store: "Store",
                TheColorShowsTheVariation: "The color shows the variation between the current period and the previous period",
                DTSLegend1: "Here you can see how each category or store performed vs the previous period. The color of the bubble reflects the magnitude of the variation that can also be seen in the Y Axis",
                PriceDecisionsCategory: "Price Decisions by Category",
                PriceDecisionsCategorySubtitle1: "The following is a summary of the Price Suggestions and Decisions per category",
                PriceDecisionsCategorySubtitle2: "Click over the category or subcategory that you want to review",
                Search: "Search...",
                ClickToEnterCategory: "Click here to review this category",
                RegularSuggestions: "Regular Suggestions",
                Suggestions: "Suggestions",
                SuggestionsQuantity: "Suggestions Quantity",
                NumberCompetitiveSuggestions: "Number of competitive suggestions",
                Impact: "Impact",
                ShowsImpactInMargin: "Shows the impact in margin if suggestions are accepted",
                ShowsImpactInPesos: "Shows the impact in pesos of the suggestion",
                CompetitiveSuggestions: "Competitive Suggestions",
                NewRegular: "New Regular",
                New: "New",
                BigDrop: "Big Drop",
                BigDropToolTip: "New Prices that dropped more than what is allowed for this category",
                BigIncrease: "Big Increase",
                BigIncreaseToolTip: "New Prices that increased more than what is allowed for this category",
                MarginMin: "< Margin min",
                MarginMintTooltip: "New prices that are below the minimum margin for this category",
                MarginMax: "> Margin max",
                MarginMaxTooltip: "New prices that are above the maximum margin for this category.",
                NewCompetitive: "New Competitive",
                OverCategoryTotal: "over category total",
                MarginDifPerMonth: "margin dif. x month",
                Decisions: "Decisions",
                NumberOfChanges: "# Changes",
                OverTotalSuggestedChanges: "over total suggested changes",
                AcceptedSuggestions: "accepted sug.",
                PercentageChange: "% Change",
                VsSuggestedPrices: "vs. suggested prices",
                VsCurrentPrices: "vs. current prices",
                WeightedByUnitSales: "weighted by unit sales",
                AssumingSameUnitSales: "assuming same unit sales",
                Performance: "Performance",
                Suggestion: "Suggestion",
                Product: "Product",
                ValidFromDate: "Valid From Date",
                ValidFrom: "Valid From",
                ValidTo: "Valid To",
                ValidFromUpdateTooltip: "Massively update the date FROM the filtered prices",
                ValidToUpdateTooltip: "Massively update the date TO the filtered prices",
                CompetitorsMap: "Competitors Map",
                CostsAndMargins: "Costs & Margins",
                AuditTrails: "Audit Trails",
                All: "All",
                Current: "Current",
                Exception: "Exception",
                Markdown: "Markdown",
                Promotion: "Promotion",
                Bonus: "Bonus",
                Competitive: "Competitive",
                Regular: "Regular",
                PriceZone: "Price Zone",
                CompetitorType: "Competitor Type",
                Competitor: "Competitor",
                OnlyMarkers: "Only markers",
                MultizoneViews: "Multizone Views",
                Allf: "All",
                Any: "Any",
                SelectProduct: "-- Select product --",
                SelectCompetitorType: "-- Select Competitor Type --",
                SelectCompetitor: "-- Select competitor --",
                Observed: "Observed",
                Type: "Type",
                Basket: "Basket",
                Own: "Own",
                PriceIndex: "Price Index",
                Web: "Web",
                Extra: "Extra",
                Rule: "Rule",
                PricesEvaluatedByCompetitiveRule: "Prices evaluated by Competitive Rule",
                CurrentPrice: "Current Price",
                SuggestedPriceBasedOnTargetMargin: "Suggested Price based on Target Margin",
                Date: "Date",
                AssortmentStrategyAndRules: "Assortment Strategy and Rules",
                AssortmentStrategyAndRulesSubtitle: "Setup the Roles and Strategies for each Product Category, indicating the target coverage.\r\nSetup the weight of each variable to build the weighted index for each Pareto\r\nComplete the review frequency for each Product Category",
                GoalsSelectCategory: "Select a category, pricing zone or store to in the table below to generate the chart",
                BenchmarksStepOne1: "Use the available filters to refine your benchmark",
                BenchmarksStepOne2: "Explore the charts to compare sales, units and margins over the last months",
                BenchmarksStepOne3: "Use the blue buttons to change the chart type",
                Compare: "Compare",
                SectorsToCompare: "Sectors/Categories to compare",
                SelectCategories: "Select Categories",
                ClustersToCompare: "Clusters to compare",
                SelectClusters: "Select Clusters",
                Filter: "Filter",
                SectorsCategories: "Sectors/Categories",
                From: "From",
                To: "To",
                Bars: "Bars",
                Spline: "Spline",
                SalesComparison: "Sales Comparison",
                Accumulated: "accumulated",
                MarginComparison: "Margin Comparison",
                UnitsComparison: "Units Comparison",
                ComparePerformance: "Comparative performance analysis",
                AreYouSureSwalTitle: "Are you sure you want to ",
                EnableSwal: "enable",
                ToDisableSwal: "disable",
                TheZoneSwal: " the zone",
                WarningStoresSwal: "WARNING! the stores ",
                BelongZoneSwall: " belong to the zone",
                CancelSwal: "Cancel",
                ContinueSwal: "Continue",
                UpdatedZonesSwal: "Updated Zones",
                TheProcessContinuesSwal: "The process continues…",
                ErrorTitleSwal: "Error!",
                ErrorWhenDisablingTheZoneSwal: "There was an error disabling the price zone.",
                SavePriceZoneSwalTitle: "Are you sure you want to Save this Price Zone?",
                SaveSwal: "Save",
                SavedZoneSwal: "Saved Zone!",
                SavedZoneSwalSubtitle: "the price zone has been saved successfully",
                ErrorOccurredWhileSavingZoneSwal: "An error has occurred trying to save the zone",
                ValidationSwalTitle: "Incomplete fields",
                ValidationSwalSubtitle: "You must complete all the fields",
                SaveCompetitorValidationSwalSubtitle: "You must complete code and name",
                SaveCompetitorTreeRelationsSwal: "Saved relationships!",
                TheProcessOfContinuousStorageSwal: "The storage process continues. You can check the status in a few minutes.",
                ThereWasAnErrorInSavingRelationshipsSwal: "There was an error in saving relationships",
                DisableCompetitorSwalTitle: "Are you sure you want to disable the competitor?",
                CompetitorDisabledSwalTitle: "Competitor Disabled!",
                CompetitorDisabledSwalSubtitle: "it has been disabled correctly",
                TheProcessOfDisablingContinuesSwal: "The process of disabling continues. You can check the status in a few minutes.",
                ThereWasAnErrorDisablingCompetitorSwal: "There was an error disabling the competitor",
                EditCompetitor: "Edit Competitor",
                TheCompetitorExistsInTheGroupSwalTitle: "The competitor already exists in the group",
                TheCompetitorExistsInTheGroupSwalSubtitle: "The competitor has not been added because it already existed in the group",
                YouAreSureToRemoveTheCompetitorSwal: "You are sure to remove the competitor ",
                FromTheGroupSwal: " from the group ",
                RemoveSwal: "Remove",
                EditSensitivityTitle: "EDIT SENSITIVITY",
                NewSensitivityTitle: "NEW SENSITIVITY",
                SaveSensitivitySwal: "Are you sure you want to save the Sensitivity?",
                SavedSensitivitySwal: "Saved Sensitivity!",
                TheSensibilitySwal: "The sensibility ",
                HasBeenSuccessfullySavedSwal: " has been successfully saved",
                ErrorSavingSensitivitySwal: "There was an error saving this sensitivity",
                EditRoundingRuleTitle: "EDIT ROUNDING RULE",
                NewRoundingRuleTitle: "NEW ROUNDING RULE",
                DeleteTheRuleSwal: "Are you sure you want to delete the rule?",
                ErasedRuleSwal: "Erased Rule!",
                HasBeenErasedCorrectlySwal: "has been erased correctly",
                TheProcessOfContinuousDeletionSwal: "The process of continuous deletion. You can check the status in a few minutes.",
                ErrorWhenDeletingTheRule: "There was an error deleting the rule",
                DeleteSwal: "Delete",
                ValidationNumberToRound: "You must enter a valid number",
                SaveTheRoundingRulesSwal: "Are you sure you want to save the Rounding Rules?",
                SavedRoundingRulesSwal: "Saved Rounding Rules!",
                ErrorWhenSavingTheseRoundingRules: "There was an error saving these Rounding Rules",
                ValidationRangesSwal: "the list of ranges should start at 0 and end at infinity (empty)",
                ValidationRangesSwal1: "verify that the 'up' of a range matches the 'from' of the next",
                AreYouSureSwal: "Are you sure?",
                AreYouSureSwalSubtitle: "Are you sure you want to save the changes?",
                WarningSwal: "Warning",
                YouMustSaveSwal: "You must save before adding a new range.",
                NoChangeWasDetectedSwal: "No change was detected",
                ConfirmedSwal: "Confirmed",
                ConfirmedSwalSubtitle: "The selected changes were saved",
                ErrorSwalSubtitle: "There was an error wanting to save the changes",
                AreYouSureYouWantToReviewTheCategorySwal: "Are you sure you want to review the category?",
                RevisionOfCategorySwal: "Category Review!",
                RevisionOfCategorySwalSubtitle: "The category review process has been successfully completed. They were generated ",
                RevisionOfCategorySwalSubtitle2: " suggestions for this category",
                TheContinuousCategoryReviewProcessSwal: "The revision process of continuous category. You can review the suggestions generated within a few moments from the price decision screen.",
                ErrorWhenReviewingThisCategory: "There was an error reviewing this category",
                GenerateSuggestionsSwal: "You are sure you want to generate the suggestions?",
                GenerationOfSuggestionsSwal: "Generation of Suggestions!",
                TheProcessEndedSuccessfullySwal: "The process ended successfully. They were generated ",
                TheProcessEndedSuccessfullySwal2: " suggestions for this category",
                TheSuggestionsContinueToBeGeneratedSwal: "The suggestions continue to be generated. You can check the status of them within a few moments from the price decision screen.",
                ErrorWhenGeneratingSuggestionsForThisCategorySwal: "There was an error generating the suggestions for this category",
                RemoveRuleSwal: "Are you sure you want to Delete the rule and its associated suggestions?",
                RuleEliminatedSwal: "Rule eliminated!",
                TheRuleHasBeenSuccessfullyRemovedSwal: "The rule has been successfully removed",
                ErrorWhenDeletingTheRule: "There was an error removing the rule",
                TheRuleSwal: "The Rule ",
                AssociatedWithGenericSwal: " is already associated with the Generic ",
                ThereCanOnlyBe1RulePerGenericSwal: " There can only be 1 rule per Generic",
                NoApplicationScopeOrItemsWereAddedSwal: "No Application Scope or Items were added",
                NoApplicationScopeOrItemsWereAddedSwalSubtitle: "For Item Relationship rules, you must select at least one sensitivity, label, attribute or item.",
                AssortmentMatrix: "Assortment Matrix",
                ExportDecisions: "Export Decisions",
                ExportDecisionsPrisma: "Export Decisions Prisma",
                TextTooShort: "Text too short",
                Never: "Never",
                Daily: "Daily",
                Weekly: "Weekly",
                Biweekly: "Biweekly",
                Monthly: "Monthly",
                Bimonthly: "Bimonthly",
                Quarterly: "Quarterly",
                Days1: " Days",
                PricePoints: "Price Points",
                StandardRounding: "Standard Rounding",
                ToNearest: "to nearest",
                ToUp: "to up",
                ToDown: "to down",
                RoundingEndingValidation1: "Ending should be between 0 and 1. ",
                RoundingEndingValidation2: "Ending should be between 0 and 100. ",
                RoundingEndingValidation3: "Ending should be between 0 and 1000. ",
                RoundingEndingValidation4: "Ending should be between 0 and 10000. ",
                RoundingEndingValidation5: "Ending should be between 0 and 100000. ",
                RoundingEndingValidation6: "Ending should have the same or less number of decimals than this rounding",
                RoundingEndingValidation7: "Ending should be smaller than",
                RoundingEndingValidation8: "There is already an ending for this value. ",
                RoundingEndingValidation9: "The value for this ending is invalid. ",
                Direction: "Direction",
                EditEndings: "Edit Endings",
                Rounding3: "thousandths (56.36105 = 56.361)",
                Rounding2: "hundredths (56.36105 = 56.36)",
                Rounding1: "tenths (56.36105 = 56.4)",
                Rounding0: "integers (56.36105 = 56)",
                RoundingM1: "tens (56.36105 = 60)",
                RoundingM2: "hundreds (56.36105 = 100)",
                RoundingM3: "thousands (5.600 = 6.000)",
                RoundingM4: "ten thousands (5.600 = 10.000)",
                RoundingM5: "hundred thousands (56.000 = 100.000)",
                RoundingM6: "millions (560.000 = 1.000.000)",
                NewSuggestionRule: "New Suggestion Rule",
                NewParentRule: "New Parent Rule",
                Minimum: "Minimum",
                Average: "Average",
                Maximum: "Maximum",
                Mode: "Mode",
                LowestModePrice: "Lowest Mode Price",
                AverageModePrice: "Average Mode Price",
                HighestModePrice: "Highest Mode Price",
                HighestRepetitions: "Highest Repetitions",
                PriceVariation: "Price Variation",
                RULE: "RULE: ",
                of: "of",
                CI: "CI",
                SuggestedCI: "Suggested CI",
                NewCI: "New CI",
                PriceHistoryAndEstimatedUnitSales: "Price History and Estimated Unit Sales",
                PriceHistory: "Price History",
                UnitSalesHistory: "Unit Sales History",
                Costs: "Costs",
                CurrentWOTaxes: "Current wo Taxes",
                NewWOTaxes: "New wo Taxes",
                CurrentTaxes: "Current Taxes",
                NewTaxes: "New Taxes",
                CurrentTotalCostWOVat: "Current Total Cost wo VAT",
                NewTotalCostWOVat: "New Total Cost wo VAT",
                TotalCostVariation: "Total Cost % Variation wo VAT",
                Margins: "Margins",
                MarginHasChanged: "The margin has changed",
                CostHasChanged: "The cost has changed",
                TicketTypes: "Ticket Types",
                Planograms: "Planograms",
                CreatePlanogram: "Create Planogram",
                AnErrorHasOccurred: "An error has occurred",
                AnErrorHasOccurredInfo: "An unexpected error has occurred.\r\nIf the problem persists, press the Download Info button to download a text file.Send an email to the Prisma support team with the text file attached.\r\nThanks and sorry for the inconvenience.",
                ViewAdditionalInfo: "View additional information",
                DownloadInfo: "Download Info",
                RequestedActivityProcessing: "The requested activity is still processing",
                SalesLegend: "sales_$",
                UnitsLegend: "units",
                MarginLegend: "margin_$",
                PriceLegend: "price",
                SundayS: "Sun",
                MondayS: "Mon",
                TuesdayS: "Tue",
                WednesdayS: "Wed",
                ThursdayS: "Thu",
                FridayS: "Fri",
                SaturdayS: "Sat",
                JanuaryS: "Jan",
                FebruaryS: "Feb",
                MarchS: "Mar",
                AprilS: "Apr",
                Mays: "May",
                JuneS: "Jun",
                JulyS: "Jul",
                AugustS: "Aug",
                SeptemberS: "Sep",
                OctoberS: "Oct",
                NovemberS: "Nov",
                DecemberS: "Dec",
                Sunday: "Sunday",
                Monday: "Monday",
                Tuesday: "Tuesday",
                Wednesday: "Wednesday",
                Thursday: "Thursday",
                Friday: "Friday",
                Saturday: "Saturday",
                January: "January",
                February: "February",
                March: "March",
                April: "April",
                May: "May",
                June: "June",
                July: "July",
                August: "August",
                September: "September",
                October: "October",
                November: "November",
                December: "December",
                IsThe: " is the ",
                WithGreatestIncrease: " with the greatest increase ",
                WithGreatestDecrease: " with the greatest drop ",
                DTSLegend3: "Here you can see the subcategories grouped by category, the total size of each category and the variation versus the previous period.",
                DSTFinalMessage: "Explore the report",
                DSTFinalMessageInfo: "You can navigate the report by clicking the buttons above. If you hover your mouse over a bubble you will get more info of the ",
                OfThe: " of the ",
                DstPopUpInformativeGraphicLess: "less sales $",
                RequestedActivityProcessingInfo: "The requested activity is still processing.\r\nThe results will be ready when this process ends.\r\nPress OK to close this message.",
                SessionExpired: "The current session has expired",
                SessionExpiredInfo: "The current session has expired\r\n Press OK to close this message and login again with your credentials.",
                AccessDenied: "Access Denied",
                AccessDeniedInfo: "You do not have access to complete the requested action.\r\n You can request additional permissions to the system administrator.",
                Suggested: "Suggested",
                Endorsed: "Endorsed",
                Pending: "Pending",
                NewPriceSuggestion: "There is a new price suggestion",
                ERPPrice: "It's the ERP Price",
                PriceNeedsEndorsement: "This price was changed and needs endorsement",
                ReadyToInformToERP: "This price was changed and endorsed. It's ready to be informed to the ERP",
                Ignored: "Ignored",
                Ignore: "Ignore",
                Days: "days",
                Event: "Event",
                Observations: "Observations",
                Date: "Date",
                User: "User",
                AddNewFloor: "Add new floor",
                ChoosePicture: "Choose Picture",
                NoSelectedFiles: "No selected files",
                Width: "Width",
                Height: "Height",
                HeightF: "High",
                SellingArea: "Selling Area",
                TotalArea: "Total area",
                LayoutEdition: "Layout Edition",
                LayoutEditionSubTitle: "Create new layouts or modify existing ones",
                LayoutEditionLegend1: "Add one or more floors to the Layout",
                LayoutEditionLegend2: "Drag the planograms that you want to place in the Layout",
                LayoutEditionLegend3: "Click on the Planogram you want to click on, or to see your own information",
                LayoutView: "Layout View",
                AnalyzeLayoutPerformance: "Analyze your Layouts Performance",
                ViewMode: "View Mode",
                EditMode: "Edit Mode",
                Rotate: "Rotate",
                ViewPlanogram: "View Planogram",
                View: "View",
                AddTicket: "Add Ticket",
                SelectedStore: "Selected Store",
                Off: "Off",
                StockOut: "Out of Stock",
                Floor: "Floor",
                NewFloor: "New Floor",
                ShowInfo: "Show Information",
                HideInfo: "Hide Information",
                ShowInformationPlanograms: "Show Information of the Planograms",
                HideInformationPlanograms: "Hide Information of the Planograms",
                AddFloorToLayout: "Add a floor to this Layout",
                LayoutWOFloor: "Layout without floor",
                AddFloorToLayoutEditMode: "Add a new floor to the Layout from the Edit Mode",
                NoPlanograms: "No Planograms",
                NoPlanogramsSubtitle: "No Planograms associated with this Cluster",
                InformationPanel: "Information Panel",
                Facings: "Facings",
                SquareMeters: "Square Meters",
                LinearMeters: "Linear Meters",
                Legend: "Legend",
                AdditionalInformation: "Additional Information",
                NewTicket: "New Ticket",
                SelectTicket: "--Select Ticket--",
                SelectPriority: "--Select Priority--",
                Code: "Code",
                CreateLayout: "Create Layout",
                SelectCluster: "--Select Cluster--",
                PressNextWhenDone: "Press the Next Button when you are done",
                UseCreatedFurniture: "Use the created furnitures or click + to create a new one",
                First: "First",
                Last: "Last",
                EditPlanogram: "Edit Planogram",
                PlanogramView: "Planogram View",
                AnalyzePlanogramPerformance: "Analyze your Planogram Performance",
                ExportToExcel: "Export to Excel",
                ExportToExcelInfoPlanogram: "Export the Planogram information to Excel",
                AuditPicture: "Audit Picture",
                CreateFurniture: "Create Furniture",
                ViewDimensions: "View Dimensions",
                ActiveInCluster: "Active in Cluster",
                WithoutExhibition: "Without Exhibition",
                WithExhibition: "With Exhibition",
                Autocomplete: "Autocomplete",
                PriceElasticity: "Price Elasticity",
                Hypothesis: "Hypothesis",
                Action: "Action",
                SelectAction: "--Select Action--",
                Value: "Value",
                SelectValueType: "--Select Value Type--",
                Forecast: "Forecast",
                ExpectVolumeSales: "I expect volume sales",
                ExpectedValue: "Expected Value",
                AvoidChangingOtherThanPrice: "Avoid changing other variables besides price during the test",
                AssumptionsAndObservations: "Assumptions and Observations",
                EditLevels: "Edit Levels",
                Load: "Load",
                DaysInDisplay: "Days in Display",
                DaysInStock: "Days in Stock",
                ClusterPerformance: "Cluster Performance",
                StorePerformance: "Store Performance",
                LoadBehind: "Load behind",
                LoadAbove: "Load above",
                ClearFilters: "Clear Filters",
                SelectedCluster: "Selected Cluster",
                GroupBy: "Group by",
                SavePlanogramSwalTitle: "Are you sure you want to Save the Planogram?",
                SavedPlanogramSwal: "Planogram Saved!",
                SavedPlanogramaSwalSubtitle: "the planogram has been saved successfully",
                ClickHereViewCategory: "click here to view this category",
                MarginGuidelines: "Margin Guidelines",
                GenerateSuggestionsVarTooltipMin: "Generate suggestions with a greater variation than the minimum allowed",
                AllowMin: "Allow Min",
                AllowMax: "Allow Max",
                GenerateSuggestionsVarTooltipMax: "Generate suggestions with a greater variation than the maximum allowed",
                SignVar: "Sign. Var.",
                SignVarTooltip: "Generate suggestions and new prices which variation is greater than the percentage specified in this column (Significance Rule)",
                SignVarMargin: "Sign Var. in Margin",
                SignVarMarginTooltip: "Generate suggestions and new prices which variation in margin is greater than the amount specified in this column (Significance Rule)",
                MainCompetitor: "Main Competitor",
                TargetMarginP: "Target %",
                TargetMargin: "Target $",
                AllowMinTooltip: "Generate suggestions below minimum margin",
                AllowMaxTooltip: "Generate suggestions above maximum margin",
                GoToTarget: "Go Target",
                GoToTargetTooltip: "If you select this option, the suggestion will try to reach the target margin after a change in costs",
                KeepMargin: "Keep",
                KeepMarginTooltip: "If you select this option, the suggestion will try to keep the current margin after a change in costs",
                PriceVariation: "Price Variation",
                LowestPrice: "Lowest Price",
                AveragePrice: "Average Price",
                HighestPrice: "Highest Price",
                SwalReviewCategoryPriceRules: "Are you sure you want to review this Category?",
                RequestQueued: "Your request has been queued",
                RequestQueuedInfo: "You can track your request in the list of pending tasks that you can find in the right header",
                TheProcessContinues: "The process continues...",
                TheProcessContinuesReviewCategoryInfo: "The review category task is stil processing. You can review the generated suggestions in a few moments in the Prices Decisions screen.",
                AnErrorOccurredReviewingCategory: "An error occurred while reviewing this category",
                AreYouSureGeneratePriceSuggestions: "Are you sure you want to generate suggestions?",
                TheProcessContinuesGeneratePriceSuggestionsInfo: "The generate suggestions task is stil processing. You can review the generated suggestions in a few moments in the Prices Decisions screen.",
                AnErrorOccurredGeneratingSuggestions: "An error occurred while generating suggestions for this category",
                AreYouSureDeletePriceRule: "Are you sure you want to delete this rule and the related suggestions?",
                DeletedRule: "Rule Deleted",
                DeletedRuleInfo: "The rule was successfully deleted",
                TheProcessContinuesInfo: "The task is stil processing. You can refresh this screen in a few moments.",
                AnErrorDeletingRule: "There was an error while trying to delete the rule",
                CompetitiveWrittenRule1: "RULE: Price ",
                IfMoreThanOneMode: "If there is more than one mode use: ",
                IfMoreThanOneModeWithSameReps: "and if there is more than one mode with same amount of repetitions use: ",
                OnlyCompetitorsInSamePriceZone: "(only competitors in same price zone)",
                SamePrice: "Same Price",
                DifferenceOf: "Difference of",
                SubsidiesFreights: "Subsidies & Freights",
                InsufficientSpaceSwal: 'Insufficient Space',
                InsufficientHeightSpaceSubtitleSwal: 'The height of the item exceeds the available height',
                InsufficientWidthSpaceSubtitleSwal: 'The width of the item exceeds available place',
                InsufficientDepthSpaceSubtitleSwal: 'The depth of the item exceeds available place',
                IncorrectCategorySwal: 'Incorrect category',
                IncorrectCategorySubtitleSwal: 'You can not add this item at a subcategory level',
                SureDeleteItemSwal: 'Are you sure you want to delete the product',
                AddLayDownItemSwal: 'Do you want to add the product lying position at the end of the level?',
                Fields: 'fields',
                SavedTicketSwal: "ticket Saved!",
                SavedTicketSwalSubtitle: "The ticket has been saved successfully",
                SureCleanPlanogramSwal: 'Are you sure you want to clean the planogram?',
                SureGenerateAutocompletePlanogramSwal: 'Are you sure you want to generate the autocomplete?',
                WithoutLevelsSwal: 'Without Levels',
                WithoutLevelsSubtitleSwal: 'the planogram does not have levels created',
                PlanagramNotFoundSwal: 'Planagram not found',
                PlanagramNotFoundSwalSubtitle1: 'was not found panogram that containing itemID: ',
                PlanagramNotFoundSwalSubtitle2: 'in the cluster with ID: ',
                HideProducts: 'Hide Products',
                ShowProducts: 'Show Products',
                HideInformation: 'Hide Information',
                ShowInformation: 'Show Information',
                HidePlanograms: 'Hide Planograms',
                ShowPlanograms: 'Show Planograms',
                Subspaced: 'Under Spaced',
                Overpaced: 'Over Spaced',
                SalesShare: 'Sales Share',
                SpaceShare: 'Spaces Share',
                SalesRestProducts: 'Sale - Rest of the Products',
                SpaceRestProcucts: 'Space - Rest of the Products',
                SalesRestPlanograms: 'Sale - Rest of the Planograms',
                SpaceRestPlanograms: 'Space - Rest of the Planograms',
                SureDeletePlanogramSwal: 'Are you sure you want to delete the planogram ',
                SureDeletePlanogramSwalDesc: 'Deleting this planogram will cause it to be automatically removed from all layouts.',
                SaveLayoutSwalTitle: "Are you sure you want to Save the Layout?",
                SavedLayoutSwal: "Layout Saved!",
                SavedLayoutSwalSubtitle: "The Layout has been saved successfully",
                ViewMore: "Show More",
                ViewLess: "Show Less",
                PlanogramCreatedIn: "Planogram created in",
                In: "in",
                UploadPicture: "Upload Picture",
                TicketsSubtitle: "You can change the priority and status of existing Tickets or leave comments by clicking on the name of the Ticket",
                SaveEditTicketTitle: "Changes saved",
                SaveEditTicketTitleSubtitle: "The changes were saved correctly",
                ResolveTicket: "RESOLVE TICKET",
                CloseTicket: "CLOSE TICKET",
                CreatedTicketIn: "created the ticket in",
                TicketType: "Ticket Type",
                Priority: "Priority",
                Comments: "Comments",
                Comment: "Comment",
                WriteComment: "Write a comment...",
                Low: "Low",
                LowPercentage: "Low %",
                RisePercentage: "Rise %",
                Medium: "Medium",
                High: "High",
                Resolved: "Resolved",
                Closed: "Closed",
                AlertWillLoseChanges: "If you leave this page you will lose the pending changes to confirm. Are you sure you want to leave the page?",
                SwalEmptyFloors: "Empty Floors",
                SwalEmptyFloorsSubtitle: "The last floor added is still empty",
                ValidationSwalTitleAddLevel: "Incomplete data",
                ValidationSwalSubtitleAddLevel: "You must complete all fields with numeric values",
                FileName: "File name: ",
                FileSize: ", File size: ",
                NotValidFileType: ": Not a valid file type. Update your selection.",
                ValidationNameTicket: "You must enter a name",
                ValidationTypeTicket: "You must select a ticket type",
                WithoutSize: "Without size",
                WithoutSizeSubtitle: "without size height or width or depth",
                BasicInformation: "Basic Information",
                UnitOfMeasure: "Unit of Measure",
                SaveItemSwall: "Save Item",
                SaveItemSwallSubtitle: "Are you sure you want to save the item?",
                ItemSavedProperly: "Item Saved Properly",
                ShowProfileItem: "Change Front: Show the profile item",
                ShowItemInfront: "Change Front: Show item in front",
                ShowItemAbove: "Change Front: Show the item above",
                ValidationSwalUomRepeted: "The Unit of Measure code can not be repeated",
                Regions: "Regions",
                Target: "Target",
                YouMustEnterValidNumber: "You must enter a valid number",
                ActionsAgGrid: "Actions",
                PricesOfThisCategoryAsRevised: "Are you sure you want to Mark all prices in this Category as revised (pending suggestions will be ignored)?",
                CompetitionPricesOfThisCategoryAsRevised: "Are you sure you want to Mark all the Competition prices in this Category as revised (pending suggestions will be ignored)?",
                NormalPricesOfThisCategoryAsRevised: "Are you sure you want to Mark all the Normal prices of this Category as revised (pending suggestions will be ignored)?",
                PricesOfThisCategoryAsRevisedPartial: "Are you sure you want to Mark all prices in this Category as revised (pending suggestions will not be ignored)?",
                CompetitionPricesOfThisCategoryAsRevisedPartial: "Are you sure you want to Mark all the Competition prices in this Category as revised (pending suggestions will not be ignored)?",
                NormalPricesOfThisCategoryAsRevisedPartial: "Are you sure you want to Mark all the Normal prices of this Category as revised (pending suggestions will not be ignored)?",
                WantToNotifyEndorsement: "You are sure you want to notify endorsement",
                TheEndorsementWasNotified: "The endorsement was notified",
                ErrorWhenWantingNotifyEndorsement: "There was an error in wanting to notify the endorsement in this category",
                IgnoreSelectedSuggestions: "Are you sure you want to ignore the selected suggestions and bleach the new prices?",
                ConfirmedIgnoreSelectedSuggestions: "The selected suggestions were ignored and the new prices were bleached",
                ErrorWhenWantingIgnoreSuggestions: "There was an error wanting to ignore the suggestions",
                Attention: "Attention",
                NoChangesWereRecorded: "No changes were recorded",
                EndorseTheSelectedPrices: "Are you sure you want to endorse the selected prices?",
                EndorseTheModifiedPrices: "You are sure you want to endorse the modified prices",
                ConfirmedEndorseTheSelectedPrices: "The selected prices were endorsed",
                ErrorWhenWantingEndorsePrices: "There was an error in wanting to endorse prices",
                RejectionComment: "Rejection comment",
                RejectionCommentSubtitle: "Enter a comment for which the suggested values were rejected:",
                RejectedItems: "Rejected Items",
                RejectedItemsSubtitle: "Selected items have been rejected",
                ModifyMarginIn: "Modify Margin in ",
                ModifyMarginSubtitle: "The current margin of the selected items is ",
                EnterNewMarginValue: " Enter the new margin value:",
                YouMustEnterValue: "You must enter a value",
                YouMustEnterNumericValue: "You must enter a numeric value",
                MarginUpdated: "Margin Updated",
                TheMarginWasUpdatedCorrectly1: "The target margin was updated correctly ",
                TheMarginWasUpdatedCorrectly2: " for the selected items",
                SelectAtLeastOnePriceToContinue: "You must select at least one price to continue",
                EnterNewMarginValueforSelectedProducts: "Enter the new margin value for each of the selected products:",
                NewPrice: "New Price",
                Present: "Current",
                SuggestedS: "Suggested",
                CurrentPrice1: "Current Price",
                Original: "Original",
                OriginalAverage: "Original Average",
                LastCost: "Last Cost",
                CompetitivenessIndex: "Competitiveness Index",
                Sale: "Sale",
                Volume: "Volume",
                NewSale: "New Sale ($)",
                CurrentAverage: "Current Average",
                SuggestedAverage: "Suggested Average",
                PercentajeNew: "New %",
                DolarNew: "New $",
                ValidityDates: "Validity Dates",
                FromN: "From N",
                ToN: "To N",
                OverlapsAbbreviation: "Overlaps",
                ErrorWhenWantingToMarkThisCategoryAsRevised: "There was an error in wanting to mark this category as revised",
                TheFollowingChangesWillBeSaved: "The following changes will be saved: ",
                PriceChangesOp: " price changes, ",
                IgnoredSuggestionsOp: " ignored suggestions, ",
                EndorsedPricesOp: " endorsed prices, ",
                RejectedPricesOp: " rejected prices, ",
                SuggestionTooltip: "SUGGESTION: There is a new suggestion for the price of the system.",
                CurrentTooltip: "CURRENT: It is the price of the ERP.",
                NewTooltip: "NEW: This price was updated and pending endorsement.",
                EndorsedTooltip: "ENDORSED: This price was updated and endorsed. It is ready to be informed to the ERP.",
                ReportFilteredPricesToTheERP: "Are you sure you want to report the filtered prices to the ERP?",
                YouMustSelectAPriceType: "You must select a price type",
                YouMustSelectAPriceTypeSubtitle: " No type of price has been selected",
                YouMustSelectAReason: "You must select a reason for these price types",
                YouMustSelectAReasonSubtitle: " You must select a reason for these price types",
                GeneratedPriceReport: "Generated Price Report!",
                GeneratedPriceReportSubtitle: "The price report was successfully generated to inform changes to the ERP",
                DeleteSelectedTests: "Are you sure you want to delete the selected tests?",
                TestsWereDeleted: "The tests were deleted",
                TestsWereDeletedSuccessfully: "The tests were successfully deleted",
                ErrorInWantingToEraseTests: "There was an error in wanting to erase the tests",
                Data: "Data",
                VariationInPrices: "Variation in Prices",
                Change: "Change",
                Expected: "Expected",
                SelectFieldsToElaborateAnHypothesis: "Select the fields to elaborate an hypothesis",
                IncorrectData: "Incorrect Data",
                InvalidDateRange: "Invalid date range",
                Priceless: "Priceless",
                DateFromTooltip: "New Effective Date From",
                StoreCode: "Store Code",
                ChangesSent: "Changes sent",
                ChangesSentTooltip: "All changes, including products that are not active in the store",
                NAtooltip: "Changes that will not produce a label impression",
                Atooltip: "Only the changes that will produce a label impression",
                Limit: "Limit",
                LimitTooltip: "Limit of changes per day allowed for the Store",
                Detour: "Detour %",
                Discount: "Discount",
                Decrease: "Decrease",
                Increase: "Increase",
                SuggestionsGenerated: "Suggestions Generated!",
                SuggestionsGeneratedSubtitle: "New suggestions have been generated",
                ValidationOfDates: "The start date must be after the current date",
                YouWantToSaveThisNewCost: "You are sure you want to Save this New cost?",
                YouWantToSaveThisNewCostSubtitle: "Are you sure you want to Save this New Cost and Generate Price Suggestions?",
                TheSubsidyCanNotBeGreaterThan100: "The subsidy can not be greater than 100%",
                SavedCost: "Saved Cost",
                SavedCostSubtitle: "the cost has been saved successfully ",
                SavedCostSubtitle1: "and they were generated ",
                SavedCostSubtitle2: "suggestions ",
                TheSubsidy: " the subsidy",
                UpdatedSubsidies: "Updated Subsidies",
                ErrorDisablingSubsidy: "There was an error disabling the subsidy.",
                SaveSubsidy: "You are sure you want to save the subsidy?",
                SavedSubsidy: "Saved Subsidy !",
                SavedSubsidySubtitle: "the subsidy has been saved successfully",
                ErrorToSaveSubsidy: "An error has occurred trying to save the subsidy",
                SaveRegion: "Are you sure you want to save the Region?",
                SavedRegion: "Saved Region",
                SavedRegionSubtitle: "the region has been saved successfully",
                ErrorToSaveTheRegion: "An error has occurred trying to save the region",
                RoundingStandard: "Rounding Standard",
                Include: "Include",
                Exclude: "Exclude",
                GroupOfArticles: "Group of Articles",
                PricesAndCosts: "Prices and costs",
                CurrentCost: "Current Cost",
                SalesUn: "Sales Un",
                CompetitiveF: "Competitive",
                RelationshipItems: "Relationship Items",
                KinshipZones: "Kinship Zones",
                StepOneOp1Importers: " The file to be imported is uploaded from the user's PC. <br/>The file must be type xlsx and consist of the columns' Zone | Article | UMV | Sensibility 'on the first line. <br/>Validation will not happen if it finds a repeated record by article-umv-zone, or if there is no data entered. <br/>The column 'Sensitivity' should carry the code and if it is empty <b> the sensitivity record will be deleted</b> if it exists.",
                StepOneOp2Importers: " The file to be imported is uploaded from the user's PC.",
                IntroductionToTheStepsImporters: "Through this <b> 4 steps </ b> wizard you can add new information to Prisma. <br /> <b> Step 1: </ b>",
                RemainingStepsImporters: "<br /> <b> Step 2: </ b> The format and extension of the file uploaded to the server is verified. <br /> <b> Step 3: </ b> File data is validated in search of inconsistencies. <br /> <b> Step 4: </ b> Finally, the processed and purified information is definitively incorporated into the system.",
                NoCategoriesFoundForAllSKUs: "No categories found for all SKUs",
                YouWantToAddThem: "You want to add them",
                ValidationWillRunAgain: "Validation will run again",
                Severity: "Severity",
                Phase: "Phase",
                File: "File",
                WarningM: "WARNING",
                AllSensitivityRecordsWillBeDeleted: "All sensitivity records will be deleted",
                Success: "Success",
                NoArticleInAnyAreaHasSensitivity: "No article in any area has sensitivity",
                ItHasPendingFiles: "Have pending files, press accept to return to step number 3.",
                TheImporterTypeParameterHasNotBeenReceived: "The importer type parameter has not been received. It will be redirected to the home.",
                UnitOfMeasureOfSale: "Unit of Measure of Sale",
                TypeOfPriceForce: "Type of Price in force",
                CompetitivePricesSurvey: "Competitive Prices Survey",
                MassiveLoadOfCosts: "Massive Load of Costs",
                BulkLoadingOfLabels: "Bulk loading of labels",
                ManualLiquidations: "Manual Liquidations",
                LiquidationsByMaturity: "Cancel Liquidations",
                CancelLiquidations: "Cancelar Liquidaciones",
                UpdateFieldsOfMeasurementUnits: "Update fields of measurement units",
                Liquidation: "Liquidation",
                SelectReason: "Select Reason / s",
                LowerPrice: "Lower Price",
                IncreasePrice: "Increase Price",
                LowM: "low",
                Rise: "rise",
                RiseA: "Rise",
                StaysTheSame: "Stays the same",
                Drops: "drops",
                IfHyphotesis: "If ",
                ThePriceOfHyphotesis: " the price of ",
                InHyphotesis: " in ",
                TheDemandIsExpectedHyphotesis: ", the demand is expected ",
                AHyphotesis: " a ",
                ValueIn: "Value in ",
                UpdateDatesSubtitleOp1: "Indicate the new start date of the new prices. Format: YYYY-MM-DD",
                UpdateDatesSubtitleOp2: "Indicate the new end date of the new prices",
                Overlaps1: "Overlaps",
                TypeCP: "Type CP",
                NoFilesSelectedForUpload: "No files currently selected for upload",
                NewOrModified: "New or Modified",
                RequestForApproval: "Request for approval",
                Approved: "Approved",
                Communicated: "Communicated",
                ErasedPlanogram: "Erased Planogram",
                ErasedPlanogramSubtitle: "The planogram has been erased successfully",
                InsufficientPermissionsToEdit: "Insufficient Permissions to Edit",
                InsufficientPermissionsToEditPlanogram: "Insufficient Permissions to Edit the Planogram and Request Approval",
                FinishThePlanogram: "Are you sure you want to Complete the Planogram and Request Your Approval?",
                InsufficientPermitsToApprove: "Insufficient Permits to Approve",
                YouWantToApproveThePlanogram: "Are you sure you want to approve the Planogram?",
                InsufficientPermissionsToReport: "Insufficient Permissions to Report",
                CommunicateThePlanogram: "Are you sure you want to communicate the planogram to the stores?",
                NotInformed: "NOT INFORMED",
                ValidationToSaveAplanogram: "It must indicate a code, a name and at least 1 subcategory, 1 store and 1 module",
                ErrorWhenSaveThePlanogram: "An error occurred when wanting to save the planogram",
                WidthNotAllowed: "width not allowed",
                WidthNotAllowedSubtitle: "smaller width to the space occupied by the products, minimum width allowed: ",
                ActionNotAllowed: "Action not allowed",
                ActionNotAllowedSubtitle: "You can not delete Categories that were already assigned to the planogram",
                CanNotDeleteStores: "You can not delete Stores that were already assigned to the planogram.",
                Shelf: "Shelf",
                HeightNotAllowed: "height not allowed",
                HeightNotAllowedSubtitle: "Height of the level can not be less than the height of the products higher than the level, minimum height allowed: ",
                LevelWithItems: "Level with Items",
                LevelWithItemsSubtitle: "you can not delete a level that contains items",
                Laydown: "Laydown",
                Standup: "Standup",
                NewPriceHasBeenRejected: "The new price of $ has been rejected",
                GoToTheAuditTab: ". Go to the Audit tab for more details. ",
                Rejected: "Rejected",
                PendingPrice: "Pending Price",
                EndorsedPrice: "Endorsed Price",
                ClickHereToLoadTags: "Click here to load tags associated with this product",
                ThePriceVariationLimitHasBeenExceeded: "The Price Variation limit has been exceeded. The limits are: {0} to {1}",
                TheMarginsLimitHasBeenExceeded: "The Margenes limit has been exceeded. The limits are: {0} to {1}",
                ThisPriceHasAnOverlapWithAPromotion: "This price has an Overlap with a Promotion. Click here to see more",
                SumErrorOfWeightedIndexes: "The sum of the weighted indexes must be equal to 100%",
                PendingChangesWithoutSaving: "You have pending changes without saving",
                TheChangesYouHaveMadeInRoleWillBeLost: "If you change the Brand, you will lose the changes you have made in Role, Strategy and Coverage for ",
                RoleAndStrategy: "Role and Strategy",
                Role: "Role",
                Strategy: "Strategy",
                Coverage: "Coverage",
                WeightedIndex: "Weighted Index (Dolar)",
                MarketSales: "Market Sales",
                MarketUnits: "Market Units",
                AnalysisSchedule: "Analysis Schedule",
                Frequency: "Frequency",
                ActionType: "Action Type",
                LastRevision: "Last Revision",
                InDays: "(In Days)",
                PriceUpdateConfirmTitle: "The prices on the screen will be updated",
                PriceUpdateConfirmText: "Remember to SAVE later to apply these changes",
                DoYouWishToIgnorePendingSuggestions: "Do you wish to ignore pending suggestions?",
                YourRequestToGenerateSuggestionsHasBeenPasted: "Your request to generate suggestions has been pasted!",
                TheSuggestionsContinueToBeGeneratedAssortment: "The suggestions continue to be generated. You will be notified on your email when complete. You can check the status of them within a few moments from the initial assortment analysis screen.",
                ConfirmationCreateThisRule: "Are you sure you want to create this rule?",
                SavedRule: "Saved Rule",
                SavedRuleSubtitle: "The rule has been saved successfully",
                ErrorWhenSavingTheRule: "There was an error saving the rule",
                Associations: "Associations",
                Values: "Values",
                MarketPrice: "Market Price",
                SalesRankingMarket: "Sales Ranking Market",
                ShareOfSalesMarket: "Share of Sales Market",
                ShareOfUnitsMarket: "Share of Units Market",
                AccumulatedSalesMarket: "Accumulated Sales Market",
                Margin2: "Margin 2",
                WeightedIndex2: "Weighted Index",
                SalesRanking: "Sales Ranking",
                UnitsRanking: "Units Ranking",
                MarginRanking: "Margin Ranking",
                ShareOfUnits: "Share of Units",
                ShareOfMargen: "Share of Margin",
                Margin2Percentage: "Margin 2 %",
                ShareOfMargen2: "Share of Margin 2",
                Presence: "Presence",
                AccumulatedSales: "Accumulated Sales",
                AccumulatedUnits: "Accumulated Units",
                AccumulatedMargin: "Accumulated Margin",
                AccumulatedWeightedIndex: "Accumulated Weighted Index",
                NameRole: "Name of the Role",
                NameValidation: "The name of the Template must have at least 2 characters",
                SelectAtLeastOneFilter: "Select at least one filter",
                SelectAtLeastOneCategory: "Select at least one category",
                TheTemplate: "The Template: ",
                HasBeenCreatedSuccessfully: " has been created successfully",
                ErrorSavingTemplate: "An error occurred while saving the Template: ",
                Confirm: "Confirm",
                ConfirmPeriod: "When selecting a period of 12 months, it is not possible to use the filter by labels. Do you wish to continue?",
                GenerateTheReport: "Are you sure you want to generate the report?",
                GeneratedReport: "Generated Report",
                GeneratedReportSubtitle: "You can now view the generated report.",
                GeneratingReport: "Generating Report",
                GeneratingReportSubtitle: "You can track it through the list of pending tasks located in the top bar.",
                SKUsTooltip: "Number of active SKUs",
                SKUsTooltip1: "Percentage of active SKUs",
                SalesDolar: "Sales ($)",
                AmountOfSales: "Amount of sales",
                SalesQty: "Sales (Qty)",
                Dds: "SD",
                CategoryCluster: "Category / Cluster",
                AmountSuggestions: "Number of low suggestions",
                HighF: "High",
                NumberHighSuggestions: "Number of high suggestions",
                ImpactInDolar: "Impact on $ by Cluster Suggestions",
                PendindFalls: "Number of pending cancellations of validation",
                highSlopes: "Number of pending validation pending",
                ImpactByDecisionsByCluster: "Impact on $ by Decisions by Cluster",
                InternalPerformance: "Internal Performance",
                InternalPerformanceTooltip: "Sale in $ (average per month per Same Store)",
                SalePercentage: "% Sale",
                SaleUnTooltip: "Sale in Units (average per month per Same Store)",
                UnitsPercentage: "% Units",
                ShareOfSalesInUnits: "Share of Sales in Units",
                PercentageMargin: "% Margin $",
                MarginShare: "Share of Margin $",
                PercentageMargin2: "% Margin",
                SaleXsku: "Sale x Sku",
                Rotation: "Rotation",
                Place: "Place",
                PerformanceMarket: "Performance Market",
                AssortmentCoverage: "Assortment coverage on all market products",
                MarketSalePercentage: "% Sale M",
                ShareOfSalesInTheMarket: "Share of Sales in the Market",
                GAPSale: "GAP Sale",
                GAPSaleTooltip: "GAP Share of Sale in $ own vs Market",
                SaleInUnitsTooltip2: "Sale in Units",
                MarketUnitsPercentage: "% Units M",
                MarketUnitsPercentageTooltip: "Share of Sales in Units Market",
                GAPUnits: "GAP Units",
                GAPUnitsTooltip: "GAP Share of Own Units vs Market",
                InventoryInDolar: "Inventory in $",
                ShareOfInventoryValue: "Share of inventory value",
                InventoryInUnits: "Inventory in Units",
                GMROITooltip: "Margin $ / inventory value (how many $ of Margin for each $ invested in inventory)",
                Mlinear: "M linear",
                MlinearTooltip: "Linear meters (facings x width of the product)",
                ProductFronts: "Product Fronts",
                GMROSTooltip: "Margin $ / Linear meters (profitability per space)",
                PercentageSpace: "% Space",
                PercentageSpaceTooltip: "Share of Spaces",
                Reviews: "Reviews",
                Revised: "Revised",
                MonthsForCalculation: "Months For Calculation",
                ClickHereFilterByPriceList: "Click here to filter by this PricingZone",
                CloneLayout: "Add a new Layout, copying this Layout",
                CopyingThisStructureAndItems: "New Planogram copying Structure and Products from this Planogram",
                ImportFromSpaceman: 'Import from Spaceman',
                ClonePlanogram: 'Clone Planogram',
                ClonePlanogramSubtitle: 'Add a new Planogram, copying the structure and products of this Planogram',
                ClonePlanogramWarning: 'Structure and Products of this Planogram will be cloned into a New Planogram',
                Sp_generalKpis_planogram: "Planogram",
                Sp_generalKpis_store: "Store",
                Sp_editLayout_editFloorActionButton: "Edit Floor",
                Sp_addLevel_EditTitle: "Edit current floor data",
                Communicate1: "Communicate",
                SP_editPlanogram_Autocomplete_AssingFronts: "Asignación de Frentes",
                MapsMargin: "Margin Map",
                Pr_Decisions_PriceUpdate: "Price Update",
                Pr_Decisions_PriceUpdateTooltip: "Perform a massive price update for the selected prices",
                Pr_Decisions_PriceUpdate_StrategySelection: "Strategy",
                Pr_Decisions_PriceUpdate_Value: "Value",
                Pr_Decisions_PriceUpdate_Variation: "Apply price variation",
                Pr_Decisions_PriceUpdate_GoToPrice: "Go to fixed price",
                Pr_Decisions_PriceUpdate_GoToMargin: "Go to margin",
                Pr_Decisions_ExperimentalPriceUpdate: "Price Simulation",
                Pr_Decisions_ExperimentalPriceUpdateTooltip: "Performs an experimental non permanent price change that allows to get an idea of how the price change would affect margin and impact",
                Pr_Decisions_PriceCopy: "Price Copy",
                Pr_Decisions_PriceCopyTooltip: "Perform a massive price copy from one price type to another",
                Pr_Decisions_PriceCopy_Source: "Source Price",
                Pr_Decisions_PriceCopy_Target: "Target Price",
                Pr_Decisions_Filters_Markets: "Markets",
                Pr_Decisions_Filters_MarketsPlaceholder: "Select Markets",
                Pr_Decisions_Filters_Brands: "Flags",
                Pr_Decisions_Filters_BrandsPlaceholder: "Select Flags",
                Pr_Decisions_Filters_Regions: "Regions",
                Pr_Decisions_Filters_RegionsPlaceholder: "Select Regions",
                Pr_Decisions_SimulationStop: "Stop Simulation",
                Pr_Decisions_SimulationApply: "Apply Simulation",
                Pr_Decisions_SimulationWarningOnSave_Title: "There is an active Simulation",
                Pr_Decisions_SimulationWarningOnSave_Desc: "In order to save pending changes you must first stop or confirm the current active simulation",
                Hm_marketBoard_marketBoard: "Market Board",
                EnterCodesSeparatedByComma: " Enter Codes Separated By Comma",
                Adm_brands_savedBrand: "Saved Brand",
                Adm_brands_subtitle: "The Brand was saved correctly",
                SP_editPlanogram_Autocomplete_AssingFronts: "Facings assignment",
                EnterCodesSeparatedBySpaces: " Enter Codes Separated By Spaces",
                EnterCodesSeparatedBySpacesDetail: "Enter Codes Separated By Spaces, then press 'enter'",
                Strip: "Strip",
                Catalog: "Catalog",
                OneTime: "One Time",
                Biannual: "Biannual",
                Annual: "Annual",
                PriceSurvey: "Price Survey",
                ExhibitionSurvey: "Exhibition Survey",
                PriceLabelScan: "Price Label Scan",
                VoiceCommand: "Voice Command",
                ManualEntry: "Manual Entry",
            })
            .translations("oxxogas", {
                StoresIncluded: "Estaciones Incluidas",
                Stores: "Estaciones",
                Store: "Estación",
                StoresToBeIncludedByCategories: "Seleccione estaciones a incluir por Categoría",
                TypeOfStore: "Tipo de estación",
                SelectTypeOfStore: "--Selecionar tipo de estación--",
                AddStore: "Agregar Estación",
                StoreMasters: "Maestro de Estaciones",
                AssignStores: "Asignar Estaciones",
                AssignmentOfStores: " Asignacion de Estaciones",
                ByStores: "Por estaciones",
                TopNavbarSearch: "Buscar Productos, Categorías, Estaciones y Clusters",
                BenchmarksStepOne: "Seleccione al menos 2 Estaciones o Categorías o Cluster para comparar",
                StoresToCompare: "Estaciones a comparar",
                SelectStores: "Seleccione Estaciones",
                PerformanceByProvinceSubtitle: "El siguiente es un resumen de densidad de Estaciones por provincia y su performance",
                PerformanceByProvinceSubtitle2: "El siguiente es un resumen de densidad de Estaciones por provincia y su performance (con drill-down por Departamento)",
                TotalStores: "Total Estaciones:",
                BaseStore: "Estación base",
                SelectStore: "Seleccione Estación",
                MirrorStore: "Estación espejo",
                ChooseComparableStores: "Elegir estaciones comparables",
                MaintainThePriceOfMirrorStores: "Mantener el precio de las estaciones espejo igual",
                PriceChangesReportSubtitle: "El siguiente reporte muestra un resumen de los cambios de precios informados al ERP por fecha de vigencia y estación",
                SaveSwalTitle: "Esta seguro que desea guardar la estación?",
                StoreSaved: "Estación guardada",
                ErrorWhenSavingTheStore: "Ocurrio un error al guardar la estación",
                ValidationNameStore: "Debe elegir un nombre para la estación",
                ValidationTypeStore: "Debe elegir un tipo de estación",
                EnableDisableSwalTitle: "la estación",
                UpdatedStores: "Estaciones Actualizadas",
                AssignedStoresSwalTitle: "Estaciones Asignadas",
                AssignedStoresSwalSubTitle: "Se asignaron correctamente las estaciones al usuario",
                ValidationMirrorStoreAndBaseStore: "La estación espejo debe ser diferente de la estación base",
                ValidationSelectedStoreNotHaveAPrice: "La estación seleccionada no tiene un precio para el item elegido",
                DstPopUpInformativeGraphic: "mas $ de venta mensual por estación",
                StoreAndCD: "Estación y Centro de Distribución",
                StoreDescendant: "Desc. Estación",                
                CloneLayout: 'Clone Layout',
                CloneLayoutWarning: 'This Layout will be cloned into a New Layout',
                Sort: "Sort",
                TooltipSortModules: "Sort Modules",
                Ready: "Ready",

            })

        $translateProvider.preferredLanguage("es");

    });
