"use strict";

angular.module("prisma")
    .controller("AssortmentReportCtrl", function ($scope, homeService, adminService, $timeout, assortmentReportService, queueService, $rootScope, $q, $stateParams, ngDialog, $window, $http, $translate) {
        var self = this;
        var numberColWidth = 130;
        var columnDefs = null;

        self.allBrands = [];
        self.selectedBrands = [];

        self.allFormats = [];
        self.selectedFormat = [];

        self.allCustomerTypes = [];
        self.selectedCustomerTypes = [];

        self.allClusters = [];
        self.selectedClusters = [];

        self.allCategorySectors = [];
        self.selectedCategorySector = self.selectedCategorySector | {};

        self.allCategoryGroups = [];
        self.allCategoryGroupsFiltered = [];
        self.selectedCategoryGroup = self.selectedCategoryGroup | {};

        self.allCategories = [];
        self.allCategoriesFiltered = [];
        self.selectedCategory = self.selectedCategory | {};

        self.allSubCategories = [];
        self.allSubCategoriesFiltered = [];
        self.selectedSubCategorias = [];

        self.allTags = [];
        self.selectedTag = [];

        self.openByManufacturer = false;
        self.openByBrand = false;

        self.radioPeriod = '3';

        self.allTemplates = []

        self.showLoaderExecute = false;
        self.showLoaderExport = false;

        self.lastExecutedReports = [];

        self.selectedReport = null;
        self.showLastExecutedReports = false;

        self.changeCategorySector = function () {
            self.allCategoryGroupsFiltered = self.allCategoryGroups.filter(function (cat, index, ar) { return cat.parentId === self.selectedCategorySector });
        }

        self.changeCategoryGroup = function () {

            //self.allCategoriesFiltered = self.allCategories.filter(cat => cat.parentId === self.selectedCategoryGroup);
            self.allCategoriesFiltered = self.allCategories.filter(function (cat, index, ar) { return cat.parentId === self.selectedCategoryGroup });
        }

        self.changeCategory = function () {
            //self.allSubCategoriesFiltered = self.allSubCategories.filter(subc => subc.parentId === self.selectedCategory);
            self.allSubCategoriesFiltered = self.allSubCategories.filter(function (subc, index, ar) { return subc.parentId === self.selectedCategory });

            //traigo los clusters
            adminService.clusters.getClustersByCategory(self.selectedCategory)
                .then(function (clusters) {
                    self.selectedClusters = [];
                    self.allClusters = clusters;
                });
        }

        self.allBrandsCheck = false;
        self.allBrandsChange = function () {

            if (!self.allBrands) return;

            self.selectedBrands = [];

            //if (self.allFormatsCheck) {

            //    for (var i = 0; i < self.allFormats.length; i++) {
            //        self.selectedFormat.push(self.allFormats[i].id);
            //    }
            //}
        }

        self.allFormatsCheck = false;
        self.allFormatsChange = function () {

            if (!self.allFormats) return;

            self.selectedFormat = [];

            //if (self.allFormatsCheck) {

            //    for (var i = 0; i < self.allFormats.length; i++) {
            //        self.selectedFormat.push(self.allFormats[i].id);
            //    }
            //}
        }

        self.allCustomerTypesCheck = false;
        self.allCustomerTypesChange = function () {

            if (!self.allCustomerTypes) return;

            self.selectedCustomerTypes = [];

        }

        self.allClustersCheck = false;
        self.allClustersChange = function () {

            if (!self.allClusters) return;

            self.selectedClusters = [];

            //if (self.allClustersCheck) {

            //    for (var i = 0; i < self.allClusters.length; i++) {
            //        self.selectedClusters.push(self.allClusters[i].id);
            //    }
            //}
        }

        self.allSubcategoriesCheck = false;
        self.allSubcategoriesChange = function () {

            if (!self.allSubCategories) return;

            self.selectedSubCategorias = [];


            //self.selectedCategory
            //if (self.allSubcategoriesCheck) {

            //    for (var i = 0; i < self.allSubCategoriesFiltered.length; i++) {
            //        self.selectedSubCategorias.push(self.allSubCategoriesFiltered[i].id);
            //    }
            //}
        }

        self.saveTemplate = function () {

            swal(
                {
                    title: translations.SaveTemplate,
                    text: translations.Name,
                    type: "input",
                    showCancelButton: true,
                    closeOnConfirm: false,
                    animation: "slide-from-top",
                    inputPlaceholder: translations.NameRole
                },
                function (inputValue) {
                    if (inputValue === false) return false;

                    if (inputValue.length < 2) {
                        swal.showInputError(translations.NameValidation);
                        return false
                    }

                    var filterData = getFilterData(inputValue);


                    if (filterData.brands.length == 0 &&
                        filterData.formats.length == 0 &&
                        filterData.clusters.length == 0 &&
                        filterData.sector == 0 &&
                        filterData.categoryGroup == 0 &&
                        filterData.category == 0 &&
                        filterData.subCategories.length == 0 &&
                        !filterData.openByBrand && !filterData.openByManufacturer &&
                        filterData.tags.length == 0) {
                        swal(translations.ErrorTitleSwal, translations.SelectAtLeastOneFilter, "error");
                        return;
                    }

                    if (filterData.category == 0) {
                        swal(translations.ErrorTitleSwal, translations.SelectAtLeastOneCategory, "error");
                        return;
                    }


                    assortmentReportService.saveTemplate(filterData)

                        .then(function () {
                            swal(translations.Save, translations.TheTemplate + inputValue + translations.HasBeenCreatedSuccessfully, "success");
                        }, function (err) {
                            swal(translations.ErrorTitleSwal, translations.ErrorSavingTemplate + err.message, "error");
                        });

                });
        }

        self.openSelectedTemplate = function (id) {
            var templates = self.allTemplates.filter(function (item) { return item.id == id });
            if (templates.length <= 0)
                return;

            var template = templates[0];

            //for (var i = 0; i < template.brands.length; i++) {
            //    self.selectedBrands[template.brands[i]] = true;
            //}

            self.allBrandsCheck = template.allBrandsCheck;
            self.allFormatsCheck = template.allFormatsCheck;
            self.allCustomerTypesCheck = template.allCustomerTypesCheck;
            self.allClustersCheck = template.allClustersCheck;
            self.allSubcategoriesCheck = template.allSubcategoriesCheck;

            self.selectedBrands = self.allBrandsCheck ? [] : template.brands;
            self.selectedFormat = self.allFormatsCheck ? [] : template.formats;
            self.selectedCustomerTypes = self.allCustomerTypesCheck ? [] : template.customerTypes;

            self.selectedClusters = self.allClustersCheck ? [] : template.clusters;
            self.selectedCategorySector = template.sector;
            self.selectedCategoryGroup = template.categoryGroup;
            self.selectedCategory = template.category;
            self.selectedSubCategorias = self.allSubcategoriesCheck ? [] : template.subCategories;

            self.changeCategory();

            self.openByBrand = template.openByBrand;
            self.openByManufacturer = template.openByManufacturer;

            self.selectedTag = template.tags;

            self.radioPeriod = template.periodMonths.toString();

        }

        self.exportToExcel = function () {
            //self.showLastExecutedReports = true;

            //    if (self.selectedReport == null)
            //    return;

            self.showLoaderExport = true;

            assortmentReportService.exportToExcel(self.selectedReport).then(function (url) {
                self.showLoaderExport = false;
                $window.open(url, '_blank', '');
                console.log(url);
            });
        }

        function getFilterData(name) {

            var filterData = {
                name: name,
                //brands: [],
                brands: self.allBrandsCheck ? self.allBrands.map(function (a) { return a.brandId; }) : self.selectedBrands,
                formats: self.allFormatsCheck ? self.allFormats.map(function (a) { return a.id; }) : self.selectedFormat,
                customerTypes: self.allCustomerTypesCheck ? self.allCustomerTypes.map(function (a) { return a.id; }) : self.selectedCustomerTypes,
                clusters: self.allClustersCheck ? self.allClusters.map(function (a) { return a.id; }) : self.selectedClusters,
                sector: self.selectedCategorySector,
                categoryGroup: self.selectedCategoryGroup,
                category: self.selectedCategory,
                subCategories: self.allSubcategoriesCheck ? self.allSubCategoriesFiltered.map(function (a) { return a.id; }) : self.selectedSubCategorias,

                openByBrand: self.openByBrand,
                openByManufacturer: self.openByManufacturer,

                tags: self.selectedTag,

                PeriodMonths: self.radioPeriod,

                allBrandsCheck: self.allBrandsCheck,
                allFormatsCheck: self.allFormatsCheck,
                allCustomerTypesCheck: self.allCustomerTypesCheck,
                allClustersCheck: self.allClustersCheck,
                allSubcategoriesCheck: self.allSubcategoriesCheck,
            }

            //for (var i = 0; i < self.allBrands.length; i++) {
            //    if (self.selectedBrands[self.allBrands[i].brandId]) {
            //        filterData.brands.push(self.allBrands[i].brandId);
            //    }
            //}

            return filterData;
        }

        self.openTemplate = function () {

            assortmentReportService.getTemplates()
                .then(function (templates) {
                    self.allTemplates = templates;

                    ngDialog.open({
                        template: 'openTemplateModal',
                        className: 'ngdialog-theme-default ngdialog-theme-custom',
                        scope: $scope
                    });
                })
        }

        self.deleteTemplate = function (id) {
            assortmentReportService.deleteTemplate(id)
                .then(function (templates) {
                    self.allTemplates = templates;
                })
        }

        self.ExecuteReport = function () {
            //TODO:validar??
            //console.log(getFilterData()); return;
            loadReportData();
        }

        self.disableTags = false;

        self.select3M6M = function () {
            self.disableTags = false;
        }

        self.select12M = function () {
            if (self.selectedTag.length <= 0) {
                return true;
            }

            swal(
                {
                    title: translations.Confirm,
                    type: "warning",
                    text: translations.ConfirmPeriod,
                    showCancelButton: true,
                    closeOnConfirm: true,
                    animation: "slide-from-top"
                },
                function (isConfirm) {
                    if (isConfirm) {
                        self.selectedTag = [];
                        self.disableTags = true;
                    }
                    else {
                        self.radioPeriod = "3";
                        $('#defaultPeriod').trigger('click');
                        $('#defaultPeriod').focus()
                        $(".btn12M").removeClass("active");
                        $(".btn12M").css("background", "white");

                        $('#defaultPeriod').addClass("active");

                        $('#defaultPeriod').css("active");
                        self.disableTags = false;
                    }
                }
            )
        }

        function getBrands() {
            homeService.scorecard.getBrands()
                .then(function (brands) {
                    self.allBrands = brands;
                });
        }

        function getStoreFormats() {
            adminService.stores.getStoreFormats()
                .then(function (formats) {
                    self.allFormats = formats;
                })
        }

        function getCustomerTypes() {
            adminService.stores.getCustomerTypes()
                .then(function (customerTypes) {
                    self.allCustomerTypes = customerTypes;
                })
        }

        function getClusters() {
            adminService.clusters.getClusters()
                .then(function (clusters) {
                    self.allClusters = clusters;
                })
        }

        function getCategorySectors() {
            adminService.categories.getCategorySector()
                .then(function (c) {
                    self.allCategorySectors = c;
                })
        }

        function getCategoryGroups() {
            adminService.categories.getCategoryGroups()
                .then(function (c) {
                    self.allCategoryGroups = c;
                    self.allCategoryGroupsFiltered = c;
                })
        }

        function getCategories() {
            adminService.categories.getCategories()
                .then(function (c) {
                    self.allCategories = c;
                    self.allCategoriesFiltered = c;
                })
        }

        function getSubCategories() {
            adminService.categories.getSubCategories()
                .then(function (c) {
                    self.allSubCategories = c;
                    self.allSubCategoriesFiltered = c;
                })
        }

        function getTags() {
            adminService.tags.getTagGroups()
                .then(function (t) {
                    self.allTags = t;
                })
        }

        self.getLastExecutedReport = function () {
            self.loadingReport = true;
            assortmentReportService.getLastExecutedReport()
                .then(function (report) {
                    self.selectedReport = report;
                    self.loadingReport = false;
                });
        }

        function fieldValidator(array) {
            var rv = false;
            angular.forEach(array, function (value) {
                if (value === 0 || value === null) {
                    rv = true;
                }
            });
            return rv;
        }
        //--------------------------------------------------
        //grid
        function setColumnDefinitions() {
            columnDefs = [
                {
                    headerName: ' ', //agrupacion
                    width: numberColWidth + 200,
                    pinned: 'left',
                    field: 'groupName',
                    suppressMenu: true,
                    suppressSorting: true,
                    filter: false,
                    cellRenderer: {
                        renderer: 'group',
                        checkbox: false,
                        innerRenderer: function (params) {
                            var name = params.value;
                            return name;
                        }
                    }
                },
                {
                    headerName: "SKUs",
                    field: "activeSkus",
                    filter: 'number',
                    headerTooltip: translations.SKUsTooltip,
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell
                },
                {
                    headerName: "&#37; SKUs",
                    field: "activeSkusPercentage",
                    filter: 'number',
                    headerTooltip: translations.SKUsTooltip1,
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.percentageRendererDirect
                },
                {
                    headerName: translations.SalesDolar,
                    field: "sales",
                    filter: 'number',
                    headerTooltip: translations.Sales,
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.bigMoneyRenderer
                },
                {
                    headerName: "Share (&#36;)",
                    field: "share",
                    filter: 'number',
                    headerTooltip: "Share",
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.percentageRendererDirect
                },
                {
                    headerName: translations.SalesQty,
                    field: "salesqty",
                    filter: 'number',
                    headerTooltip: translations.AmountOfSales,
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.bigMoneyRenderer
                },
                {
                    headerName: "Share (qty)",
                    field: "shareQty",
                    filter: 'number',
                    headerTooltip: "Share",
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.percentageRendererDirect
                },
                {
                    headerName: translations.MarginInDollars,
                    field: "margin",
                    filter: 'number',
                    headerTooltip: translations.Margin,
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.bigMoneyRenderer
                },
                {
                    headerName: "Share (Mg)",
                    field: "shareMargin",
                    filter: 'number',
                    headerTooltip: "Share",
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.percentageRendererDirect
                },
                {
                    headerName: translations.Dds,
                    field: "dds",
                    filter: 'number',
                    headerTooltip: translations.DaysInStock,
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell
                },
                {
                    headerName: "Stock (&#36;)",
                    field: "stock",
                    filter: 'number',
                    headerTooltip: "Cantidad de SKUs activos",
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.bigMoneyRenderer
                },
                {
                    headerName: "Stock (&#37;)",
                    field: "stockPercentage",
                    filter: 'number',
                    headerTooltip: "Stock",
                    width: numberColWidth,
                    cellClass: $rootScope.getClassForCell,
                    cellRenderer: $rootScope.percentageRendererDirect
                },
                //{
                //    headerName: "Aging (&#36;)+180",
                //    field: "agin",
                //    filter: 'number',
                //    headerTooltip: "Aging (&#36;) + 180",
                //    width: numberColWidth,
                //    cellClass: $rootScope.getClassForCell
                //},
                //{
                //    headerName: "Aging (&#37;)+180",
                //    field: "aginPercentage",
                //    filter: 'number',
                //    headerTooltip: "Aging (&#37;) + 180",
                //    width: numberColWidth,
                //    cellClass: $rootScope.getClassForCell,
                //    cellRenderer: $rootScope.percentageRendererDirect,
                //    cellRenderer: $rootScope.percentageRendererDirect
                //},
                {
                    headerName: "GMROI",
                    field: "gmroi",
                    enableFilter: true,
                    filter: 'number:2',
                    headerTooltip: "GMROI",
                    width: numberColWidth,
                    //cellClass: $rootScope.getClassForCell
                },
                //{
                //    headerName: "GMROS",
                //    field: "gmros",
                //    filter: 'number',
                //    headerTooltip: "GMROS",
                //    width: numberColWidth,
                //    cellClass: $rootScope.getClassForCell
                //},
            ];

        }
        function setGridOptions() {
            self.gridOptions = {
                columnDefs: columnDefs,
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                groupHeaders: false,
                suppressCellSelection: true,
                rowHeight: 35,
                groupHideGroupColumns: true,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
                getNodeChildDetails: getNodeChildDetails,
                rowGroupOpened: rowGroupOpened
            };
        }


        function getNodeChildDetails(rowItem) {
            if (rowItem.subItems && rowItem.subItems.length > 0) {
                return {
                    group: true,
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: rowItem.subItems,
                    // the key is used by the default group cellRenderer
                    key: rowItem.id
                };
            } else {
                return null;
            }
        }

        function rowGroupOpened(rowItem) {
            console.log(rowItem);
        }

        self.showTagError = false;
        function loadReportData() {

            if (self.gridOptions != null) {
                self.showTagError = false;

                if (self.selectedTag && self.selectedTag.length > 5) {
                    self.showTagError = true;

                    return;
                }

                var filterData = getFilterData();

                //if (filterData.brands.length == 0 &&
                //    filterData.formats.length == 0 &&
                //    filterData.clusters.length == 0 &&
                //    filterData.sector == 0 &&
                //    filterData.categoryGroup == 0 &&
                //    filterData.category == 0 &&
                //    filterData.subCategories.length == 0 &&
                //    !filterData.openByBrand && !filterData.openByManufacturer &&
                //    filterData.tags.length == 0) {
                //    swal("Error", "Seleccione al menos un filtro", "error");
                //    return;
                //}

                if (fieldValidator(filterData)) {
                    swal("Error", "Seleccione Sector, Seccion y Familia", "error");
                    return;
                }

                swal({
                    title: translations.GenerateTheReport,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: translations.ContinueSwal,
                    cancelButtonText: translations.CancelSwal,
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                    function (isConfirm) {

                        if (isConfirm) {

                            assortmentReportService.loadReportData(filterData)
                                .then(function (reportData) {

                                    if (reportData) {
                                        swal(translations.GeneratedReport, translations.GeneratedReportSubtitle, "success");
                                        self.selectedReport = self.getLastExecutedReport();
                                        self.gridOptions.api.setRowData(reportData);
                                        self.gridOptions.api.sizeColumnsToFit();
                                    }
                                    else {
                                        swal(translations.GeneratingReport, translations.GeneratingReportSubtitle, "success");

                                        //actualizo las tareas pendientes.
                                        queueService.queuedTasks.getTop5();
                                    }


                                });
                        }
                    });

                /*
                self.showTagError = false;
      
                self.showLoaderExecute = true;
                
                if (self.selectedTag.length > 5) {
                    self.showTagError = true;
                    self.showLoaderExecute = false;
                    return;
                }
      
                var filterData = getFilterData();
                
                assortmentReportService.loadReportData(filterData).then(function (reportRows) {
      
                    self.gridOptions.api.setRowData(reportRows);
                    self.gridOptions.api.sizeColumnsToFit();
      
                    self.showLoaderExecute = false;
                });*/
            }
        }

        self.openReport = function (dto) {

            self.showLoaderExecute = true;
            self.selectedReport = dto;
            assortmentReportService.openReport(dto)
                .then(function (reportRows) {
                    self.showLoaderExecute = false;
                    self.gridOptions.api.setRowData(reportRows);
                    self.gridOptions.api.sizeColumnsToFit();
                });
        }

        var translations = null;

        function init() {

            setGridOptions();

            $translate(["SaveTemplate", "Name", "NameRole", "NameValidation", "ErrorTitleSwal", "SelectAtLeastOneFilter", "SelectAtLeastOneCategory"
                , "Save", "TheTemplate", "HasBeenCreatedSuccessfully", "ErrorSavingTemplate", "Confirm", "ConfirmPeriod", "GenerateTheReport"
                , "ContinueSwal", "CancelSwal", "GeneratedReport", "GeneratedReportSubtitle", "GeneratingReport", "GeneratingReportSubtitle"
                , "SKUsTooltip", "SKUsTooltip1", "Sales", "SalesDolar", "AmountOfSales", "SalesQty", "MarginInDollars", "Margin", "DaysInStock"
                , "Dds"])
                .then(function (all) {
                    translations = all;

                    setColumnDefinitions();
                    self.gridOptions.api.setColumnDefs(columnDefs);

                    $timeout(function () {
                        self.gridOptions.api.showNoRowsOverlay();
                    }, 500);

                });

            getBrands();
            getStoreFormats();
            getCustomerTypes();

            //getClusters();

            getCategorySectors();
            getCategoryGroups();
            getCategories();
            getSubCategories();

            getTags();

            //getLastExecutedReports();
        }

        //button Collapsed
        $scope.isNavCollapsed = true;
        $scope.isCollapsed = false;
        $scope.isCollapsedHorizontal = false;

        $scope.isGeneralCollapsed = false;
        $scope.isScopeCollapsed = false;
        $scope.isOpeningCollapsed = false;
        $scope.isPeriodCollapsed = false;


        init();
    });
