angular.module("prisma")
    .controller("editMechanicCtrl", function ($scope, $rootScope, $timeout, $state, $stateParams, promotionsService, authService, homeService, ngDialog, $window, serverService, Upload) {
        var self = this;

        self.isEditPanguiCode = false;

        if ($stateParams.position == -2)//interna
            self.loadForInternalPromotion = true;

        self.hasPermissionToEditMechanic = authService.hasPermission('promotion_edit_promotionItem') || (self.loadForInternalPromotion && !authService.hasPermission('promotion_take_internalPromotion'));
        self.promoDetails = '';

        self.categoriesInLookup = [];
        self.announcementTypes = [];
        self.itemsInLookup = [];
        self.overlaps = [];
        self.itemsToMassiveCharge = "";
        self.extraItemsToMassiveCharge = "";
        self.storeCodes = "";
        self.itemCodes = "";
        self.paymentTypes = [];
        self.categoryIds = [];
        self.mechanics = [];
        self.stores = [];
        self.vendors = [];
        self.chat = '';
        self.validationRetailP = true;
        self.validationSupermarketP = true;
        self.totalValidation = true;
        self.negotiationType = 2;
        self.search = "";
        self.searchwithCheckbox = false;
        self.totalItemCounter = 0;
        self.mechanicItemsFiltered = [];

        self.inputVisibility = 2;
        self.itemLookupText = '';
        self.promotionInfo = null;
        self.selectedVendor = null;
        self.selectedItemInModal = null;
        self.selectionItemOrCategory = null;// 1 Category(Jerarquia), 2 Item
        self.selectionItemOrCategoryExtra = null;// 1 Category(Jerarquia), 2 Item
        self.promotionExhibitionType = promotionsService.promotionExhibitionType;
        self.mechanic = newMechanic();

        self.goToLayout = function () {
            var state = 'promotions.addItemToLayout';
            if (self.isReview)
                state = 'promotions.2reviewTradeAgreements';
            else if (self.isPrintLayoutCompare)
                state = 'promotions.printLayoutCompare';
            else if (self.isLayoutImageUpload)
                state = 'promotions.layoutImageUpload';
            else if (self.isFinalValidation)
                state = 'promotions.finalValidation';

            $state.go(state, {
                promotionId: $stateParams.promotionId,
                taskId: $stateParams.taskId,
                promotionMediaPageId: $stateParams.promotionMediaPageId
            });
        }

        self.goToEditPromotion = function () {
            $state.go('promotions.editInternalPromotion', {
                promotionId: $stateParams.promotionId,
                promotionMediaPageId: $stateParams.promotionMediaPageId
            });
        }

        self.mechanicChanged = function () {
            var newType = angular.copy(self.mechanic.mechanicType);
            self.mechanic = newMechanic();
            self.setChargeRate();
            self.mechanic.mechanicType = newType;
            self.itemLookupText = '';
            promotionsService.mechanics.getMechanicCategories()
                .then(function (categories) {
                    self.categoriesInLookup = categories;
                });
        }

        self.showOverlapping = function () {

            var newScope = $scope.$new();
            newScope.overlaps = self.overlaps;

            ngDialog.open({
                template: 'overlapDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope
            });
        };

        function validationNegativeNumbers() {
            updateItemList();
            var message = "success";
            var camposError = "";

            for (var i = 0; i < self.mechanic.items.length; i++) {
                if (self.mechanic.items[i].mechanicPrice < 0) {
                    camposError += " Precio Mecanica, ";
                }
                if (self.mechanic.items[i].manualForecast < 0) {
                    camposError += " Forecast Manual, ";
                }
                if (self.mechanic.items[i].salesForecast < 0) {
                    camposError += " Pronostico de Venta, ";
                }
            }

            for (i = 0; i < self.mechanic.categories.length; i++) {
                if (self.mechanic.categories[i].mechanicPrice < 0) {
                    camposError += " Precio Mecanica, ";
                }
                if (self.mechanic.categories[i].manualForecast < 0) {
                    camposError += "Forecast Manual, ";
                }
                if (self.mechanic.categories[i].salesForecast < 0) {
                    camposError += "Pronostico de Venta, ";
                }
            }
            for (i = 0; i < self.mechanic.categoriesExtra.length; i++) {
                if (self.mechanic.categoriesExtra[i].mechanicPrice < 0) {
                    camposError += " Precio Mecanica, ";
                }
                if (self.mechanic.categoriesExtra[i].manualForecast < 0) {
                    camposError += "Forecast Manual, ";
                }
                if (self.mechanic.categoriesExtra.salesForecast < 0) {
                    camposError += "Pronostico de Venta, ";
                }
            }
            for (i = 0; i < self.mechanic.itemsExtra.length; i++) {
                if (self.mechanic.itemsExtra[i].mechanicPrice < 0) {
                    camposError += " Precio Mecanica, ";
                }
                if (self.mechanic.itemsExtra[i].manualForecast < 0) {
                    camposError += "Forecast Manual, ";
                }
                if (self.mechanic.itemsExtra.salesForecast < 0) {
                    camposError += "Pronostico de Venta, ";
                }
            }

            if (self.mechanic.sellOutNegotiated < 0) {
                camposError += " Monto Negociado, ";
            }

            if (camposError != "") {
                message = "Los campos " + camposError + "no pueden ser negativos";
            }
            return message;

        }

        self.savePanguiCode = function () {
            if ($stateParams.promotionMechanicId != 0) {
                swal({
                    title: 'Esta seguro que desea guardar el codigo pangui?',
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                    function (isConfirm) {
                        if (isConfirm) {
                            promotionsService.mechanics.savePanguiCode(self.mechanic.id, self.mechanic.panguiCode)
                                .then(function (isOk) {
                                    swal('Mecanica codigo guardado!', 'El codigo pangui ' + self.mechanic.panguiCode + ' se ha asociado correctamente', 'success');
                                }, function (status) {
                                    if (status == 502) {
                                        swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                    }
                                    else {
                                        swal("Error", "Hubo un error al guardar", "error");
                                    }
                                });
                        }
                    });
            }
            else swal("Error", "Hubo un error al guardar", "error");
        }


        self.saveComment = function () {
            if (self.chat != '' && $stateParams.promotionMechanicId != 0) {
                var newChat = {
                    id: 0,
                    promotionItemId: $stateParams.promotionItemId,
                    promotionMediaPageId: self.mechanic.promotionMediaPageId,
                    message: self.chat.replace(/(\r\n|\n|\r)/gm, '')
                };
                promotionsService.mechanics.saveChat(newChat)
                    .then(function (isOk) {
                        swal('Comentario guardado!', '', 'success');
                    }, function (status) {
                        if (status == 502) {
                            swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                        }
                        else {
                            swal("Error", "Hubo un error al guardar", "error");
                        }
                    });
            }
        }

        self.isOnlyOneItemInList = function () {
            if (self.itemList.length === 1) {
                self.itemList[0].isItemInPhoto = true;
            }
        }

        self.validateCheckBoxItemInPhoto = function () {
            var retorno;
            if (self.mechanic.items &&  self.mechanic.items.length > 0) {
                retorno = self.mechanic.items.some(function (x) {
                    return x.isItemInPhoto;
                })
            }
            else {
                 retorno = true;
            }
            return retorno;
        }

        self.save = function () {
            if (self.mechanic != newMechanic()) {
                updateItemList();
                if (!self.isReview) {
                    if (self.canSave()) {
                        if (self.hasCategoryOrSubCategory() || self.mechanic.items.length > 0) {
                            if (validationNegativeNumbers() == 'success') { //Validacion de numeros negativos

                                if (self.totalValidation) //Validacion de que retail y supermercado sumen 100
                                {
                                    if (!self.validateCheckBoxItemInPhoto()) {
                                        swal('Error', 'Debe seleccionar almenos un articulo en foto.', 'error');
                                        return;
                                    }
                                    if (self.mechanic.name.length <= 200) {

                                        //Fix Enter en comentarios dentro de un TEXTAREA, para bajar los excels se rompe.
                                        self.mechanic.name = self.mechanic.name.replace(/(\r\n|\n|\r)/gm, '');
                                        self.mechanic.observation ? self.mechanic.observation.replace(/(\r\n|\n|\r)/gm, '') : '';
                                        self.mechanic.ticketDescription ? self.mechanic.ticketDescription.replace(/(\r\n|\n|\r)/gm, '') : '';
                                        self.mechanic.otherDiscounts ? self.mechanic.otherDiscounts.replace(/(\r\n|\n|\r)/gm, '') : '';

                                        //SWAL CONFIRM para guardar
                                        swal({
                                            title: 'Esta seguro que desea asociar esta Mecanica a esta Página?',
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#1AB394",
                                            confirmButtonText: "Continuar",
                                            cancelButtonText: "Cancelar",
                                            showLoaderOnConfirm: true,
                                            closeOnConfirm: false,
                                            closeOnCancel: true
                                        },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    if (self.chat != '') {
                                                        var newChat = {
                                                            promotionItemId: $stateParams.promotionItemId,
                                                            promotionMediaPageId: self.mechanic.promotionMediaPageId,
                                                            message: self.chat
                                                        };
                                                        self.mechanic.chats.push(newChat);

                                                    }
                                                    self.chat = '';
                                                    //dates as string 
                                                    self.mechanic.items.forEach(function (i) {
                                                        if (i.startTime)
                                                            i.startTime = i.startTime.toLocaleString();
                                                        if (i.endTime)
                                                            i.endTime = i.endTime.toLocaleString();
                                                    });
                                                    self.mechanic.categories.forEach(function (c) {
                                                        if (c.startTime)
                                                            c.startTime = c.startTime.toLocaleString();
                                                        if (c.endTime)
                                                            c.endTime = c.endTime.toLocaleString();
                                                    });
                                                    //TODO: arreglar despues
                                                    if (self.mechanic.targetUnits) self.mechanic.targetUnits = parseInt(self.mechanic.targetUnits);
                                                    promotionsService.mechanics.saveMechanic(self.mechanic)
                                                        .then(function (mechanic) {
                                                            if (mechanic.id != 0)
                                                                swal('Mecanica Asociada!', 'La mecanica ' + self.mechanic.name + ' se ha asociado correctamente', 'success');
                                                            else swal('Error', 'La posicion ' + self.mechanic.position + ' se encuentra actualmente ocupada', 'error');

                                                            if (!self.loadForInternalPromotion)
                                                                self.goToLayout();
                                                            else self.goToEditPromotion();

                                                        }, function (status) {
                                                            if (status == 502) {
                                                                swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                                            }
                                                            else {
                                                                swal("Error", "Hubo un error al guardar", "error");
                                                            }
                                                        });
                                                }
                                            });
                                    }
                                    else { swal('Error', 'El tamaño de la descripcion ha excedido los 200 caracteres. Modifiquelo y vuelva a guardar.', 'error'); }

                                }
                                else { swal('Error', 'La suma de Retail y de Supermercado debe ser el 100%', 'error'); }
                            }
                            else { swal('Error', validationNegativeNumbers(), 'error'); }
                        }
                        else { swal('Error', 'Debe elegir al menos una categoria o subcategoria', 'error'); }
                    }
                    else { swal('Error', 'Debe elegir un tipo de mecanica y asignar al menos un articulo o categoria', 'error'); }
                }
                else
                    saveReview();
            }
        }

        function saveReview() {
            swal({
                title: 'Esta seguro que desea editar esta Mecanica ?',
                text: 'Solo se guardarán campos de titulo de la mecanica, prorrateo, restricciones y otros datos',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        if (self.chat != '') {
                            var newChat = {
                                promotionItemId: $stateParams.promotionItemId,
                                promotionMediaPageId: self.mechanic.promotionMediaPageId,
                                message: self.chat
                            };
                            self.mechanic.chats.push(newChat);
                            self.chat = '';
                        }
                        promotionsService.mechanics.saveReview(self.mechanic)
                            .then(function (isOk) {
                                swal('Mecanica modificada!', 'La mecanica ' + self.mechanic.name + ' se ha modificado correctamente', 'success');
                                self.goToLayout();

                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de guardado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al guardar", "error");
                                }
                            });
                    }
                });
        }

        self.canSave = function () {
            if (!self.mechanic.mechanicType || !self.selectionItemOrCategory || (self.mechanic.categories.length == 0 && self.mechanic.items.length == 0))  //!self.mechanic.targetUnits || !self.mechanic.payUnits || !self.mechanic.itemOrCategory || 
                return false;
            else return true;
        }

        self.hasCategoryOrSubCategory = function () {
            rv = false;
            angular.forEach(self.mechanic.categories, function (c) {
                if (c.shortName == "CAT" || c.shortName == "SUBCAT") {
                    rv = true;
                }
            });
            return rv;
        }

        self.itemOrCategoryChanged = function () {
            if (self.selectionItemOrCategory == "1") {
                self.mechanic.items = [];
                self.itemList = [];
            }
            if (self.selectionItemOrCategory == "2") {
                self.mechanic.categories = [];
            }
            //delete from db
            promotionsService.mechanics.deleteAllSelection(self.mechanic.id, false, $stateParams.promotionId);
        }
        self.itemOrCategoryExtraChanged = function () {
            if (self.selectionItemOrCategoryExtra == "1") {
                self.mechanic.itemsExtra = [];
            }
            if (self.selectionItemOrCategoryExtra == "2") {
                self.mechanic.categoriesExtra = [];
            }
            //delete from db
            promotionsService.mechanics.deleteAllSelection(self.mechanic.id, true, $stateParams.promotionId);
        }

        self.setNegotiationType = function (negotiationType) {
            if (negotiationType == 1) {
                self.mechanic.sellIn = !self.mechanic.sellIn;
                self.mechanic.sellInNewCost = self.mechanic.sellIn ? self.mechanic.sellInNewCost : '';
            }
            else {
                self.mechanic.sellOut = !self.mechanic.sellOut;
                self.mechanic.sellOutNegotiated = self.mechanic.sellOut ? self.mechanic.sellOutNegotiated : '';
            }
        };

        self.setAcumPromotion = function (val) {
            self.mechanic.acumOtherPromotions = val;
        }
        self.setApplyOfferPrices = function (val) {
            self.mechanic.applyOfferPrices = val;
        }
        self.setCardRestriction = function (val) {
            self.mechanic.cardRestriction = val;
            if (val == false) {
                self.mechanic.bonusCard = false;
                self.mechanic.wongCard = false;
                self.mechanic.metroCard = false;
                self.mechanic.otherCard = false;
                self.mechanic.participantGoods = null;
            }
        }
        self.setUnitsRestriction = function (val) {
            self.mechanic.unitsRestriction = val;
            self.mechanic.moneyRestriction = !val;
            self.mechanic.moneyRestrictionValue = null;
        }
        self.setMoneyRestriction = function (val) {
            self.mechanic.moneyRestriction = val;
            self.mechanic.unitsRestriction = !val;
            self.mechanic.unitsRestrictionValue = null;
        }
        self.setAffectsClientMargin = function (val) {
            self.mechanic.affectsClientMargin = val;
        }
        self.setCharge = function (val) {
            self.mechanic.charge = val;
            if (val == false)
                self.mechanic.chargeRate = null;
            else self.setChargeRate();
        }
        self.setApportionment = function () {
            if (!self.mechanic.apportionment) {
                self.mechanic.retailP = null;
                self.mechanic.supermarketP = null;
            }
        }
        self.setChargeRate = function () {
            var storeId = self.mechanic.stores[0].storeId;
            promotionsService.stores.getStore(storeId).then(function (s) {
                self.mechanic.chargeRate = promotionsService.calculateTariff(s.brand, self.mechanic.announcementType);
                self.mechanic.negotiatedChargeRate = self.mechanic.chargeRate;
            });
        }

        var previousLookupText = '';

        self.addCategoryToMechanic = function () {
            var newScope = $scope.$new();
            var y = window.scrollY;

            newScope.saveCategory = function () {
                self.mechanic.categories.push({
                    categoryId: self.selectedCategoryInModal.id
                    , categoryCode: self.selectedCategoryInModal.code
                    , categoryName: self.selectedCategoryInModal.name
                    , shortName: self.selectedCategoryInModal.shortName
                    , investmentApportionment: 0
                    , manualForecast: null
                    , salesForecast: null
                    , sellOut: null
                    , isExtra: false
                });

                self.selectedCategoryInModal = null;

                ngDialog.close();
                $timeout(function () {
                    window.scroll(0, y);
                }, 550);
            }
            newScope.cancel = function () {
                ngDialog.close();
                $timeout(function () {
                    window.scroll(0, y);
                }, 550);
            }

            ngDialog.open({
                template: 'selectCategoryDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope,
                overflow: 'initial !important',
                showClose: false,
                closeByEscape: false,
                closeByDocument: false
            });
        }
        self.addCategoryExtraToMechanic = function () {
            var newScope = $scope.$new();
            var y = window.scrollY;

            newScope.saveCategory = function () {
                self.mechanic.categoriesExtra.push({
                    categoryId: self.selectedCategoryInModal.id
                    , categoryCode: self.selectedCategoryInModal.code
                    , categoryName: self.selectedCategoryInModal.name
                    , unitsToTake: null
                    , investmentApportionment: 0
                    , isExtra: true
                });

                self.selectedCategoryInModal = null;

                ngDialog.close();
                $timeout(function () {
                    window.scroll(0, y);
                }, 550);
            }
            newScope.cancel = function () {
                ngDialog.close();
                $timeout(function () {
                    window.scroll(0, y);
                }, 550);
            }

            ngDialog.open({
                template: 'selectCategoryDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope,
                overflow: 'initial !important',
                showClose: false,
                closeByEscape: false,
                closeByDocument: false
            });
        }

        //control o promovsnormal es un campo que se ve en mecanicas 3, 10 y 13. 
        //EN PACK VIRTUAL(10) se ve un campo cantidad a comprar a nivel mechanicitem, en el caso de que estos tengan un valor
        //multimplicaran el precio del producto(solo posible en pack virtual). Entonces promo vs normal es la DIFERENCIA entre:
        //la SUMA de todos los precios normales de productos y extras multiplicados por sus respectivas cantidades a comprar 
        //VS el campo IMPORTE

        //EN COMPRA A LLEVA B CON X DESCUENTO(13) promo vs normal no afecta la descripcion y es la diferencia entre:
        //la SUMA de todos los precios normales de productos y extras
        //VS (SUMA de todos los precios normales de productos y extras POR %Descuento o MENOS $Descuento)

        //EN COMPRA A + $ LLEVA B(3) promo vs normal no afecta la descripcion y es la DIFERENCIA entre:
        //la SUMA de todos los precios normales de productos y extras
        //VS la SUMA de todos los precios normales de productos + el campo IMPORTE
        function calculateControl() {
            updateItemList();
            var itemsSum = 0;
            var extraSum = 0;
            angular.forEach(self.mechanic.items, function (i) {
                itemsSum += i.normalPrice * (i.targetUnits != null ? i.targetUnits : 1);
            });
            angular.forEach(self.mechanic.itemsExtra, function (i) {
                extraSum += i.normalPrice * (i.targetUnits != null ? i.targetUnits : 1);
            });
            if (self.mechanic.mechanicType == 10)
                self.mechanic.promoVsNormal = (itemsSum + extraSum) - self.mechanic.targetItemDiscount;
            else if (self.mechanic.mechanicType == 3)
                self.mechanic.promoVsNormal = (itemsSum + extraSum) - (itemsSum + self.mechanic.targetItemDiscount);
            else if (self.mechanic.mechanicType == 13) {
                if (self.mechanic.discount)
                    self.mechanic.promoVsNormal = (itemsSum + extraSum) - (itemsSum + (extraSum - self.mechanic.discount));
                else
                    self.mechanic.promoVsNormal = (itemsSum + extraSum) - (itemsSum + (extraSum * self.mechanic.discountP / 100));
            }

        }

        self.addItemToMechanic = function () {
            var newScope = $scope.$new();
            var y = window.scrollY;

            newScope.saveItem = function () {
                promotionsService.mechanics.getItemForMechanic(self.selectedItemInModal.id, self.selectedItemInModal.saleUnitOfMeasureCode, $stateParams.promotionId)
                    .then(function (item) {
                        self.mechanic.items.push({
                            itemId: item.itemId
                            , itemCode: item.itemCode
                            , itemEan: item.itemEan
                            , sapDescription: item.sapDescription
                            , umvId: item.umvId
                            , umv: item.umv
                            , subcategoryCode: item.subcategoryCode
                            , subcategoryName: item.subcategoryName
                            , subcategoryId: item.subcategoryId
                            , manufacturer: item.manufacturer
                            , investmentApportionment: item.investmentApportionment
                            , normalPrice: item.normalPrice
                            , minimalPrice: item.minimalPrice
                            , currentPrice: item.currentPrice
                            , currentPriceType: item.currentPriceType
                            , currentPriceReason: item.currentPriceReason
                            , fromPrice: item.fromPrice
                            , toPrice: item.toPrice
                            , internalCost: item.internalCost
                            , marginPriceP: item.marginPriceP
                            , minRange: item.minRange
                            , maxRange: item.maxRange
                            , manualForecast: item.manualForecast
                            , dailyAvgSales: item.dailyAvgSales
                            , dailyAvgUnits: item.dailyAvgUnits
                            , salesForecast: item.salesForecast
                            , unitsForecast: item.unitsForecast
                            , marginForecastP: item.marginForecastP
                            , marginForecast: item.marginForecast
                            , internalPromotion: item.internalPromotion
                            , sellInNewCost: item.sellInNewCost
                            , sellOutNegotiated: item.sellOutNegotiated
                            , estipulatedMarginP: item.estipulatedMarginP
                            , estipulatedMargin: item.estipulatedMargin
                            , returnAgreement: item.returnAgreement
                            , isExtra: false

                        });
                        updateItemList();
                    });

                self.selectedItemInModal = null;
                self.itemLookupText = null;

                ngDialog.close();
                $timeout(function () {
                    window.scroll(0, y);
                }, 550);
            }
            newScope.cancel = function () {
                ngDialog.close();
                $timeout(function () {
                    window.scroll(0, y);
                }, 550);
            }

            ngDialog.open({
                template: 'selectItemDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope,
                overflow: 'initial !important',
                showClose: false,
                closeByEscape: false,
                closeByDocument: false
            });
        }
        self.massiveCharge = function () {
            if (self.itemsToMassiveCharge.split(',').length > 10) {
                swal({
                    title: 'Cargo mas de 10 codigos',
                    text: 'si carga mas de 10 codigos a la vez no se obtendra informacion de ventas',
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonText: "Cancelar",
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Aceptar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        doMassiveLoad();
                    }
                });
            } else {
                doMassiveLoad();
            }
        }

        self.extraMassiveCharge = function () {
            if (self.extraItemsToMassiveCharge.split(',').length > 10) {
                swal({
                    title: 'Cargo mas de 10 codigos',
                    text: 'si carga mas de 10 codigos a la vez no se obtendra informacion de ventas',
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonText: "Cancelar",
                    confirmButtonColor: "#1AB394",
                    confirmButtonText: "Aceptar",
                    showLoaderOnConfirm: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        doExtraMassiveLoad();
                    }
                });
            } else {
                doExtraMassiveLoad();
            }
        }


        self.itemList = [];     //lista visible en la pantalla
        self.itemCounter = 50;//cantidad de items visibles

        function doMassiveLoad() {
            self.isLoadingMassiveCharge = true;
            promotionsService.mechanics.getItemsForMechanic({ itemCodes: self.itemsToMassiveCharge, categoryIds: self.categoryIds, promotionId: $stateParams.promotionId })
                .then(function (items) {
                    if (items.length == 0) {
                        $timeout(function () {
                            swal('Error!', 'Ninguno de los codigos coincide, revisar el formato y solo inculir items de las categorias de esta pagina', 'error');
                        }, 200)
                    }

                    var isAlreadyCharged = false;
                    angular.forEach(items, function (item) {

                        //verifico si ya existe
                        isAlreadyCharged = false;
                        angular.forEach(self.mechanic.items, function (itemCargado) {
                            if (itemCargado.itemId == item.itemId && itemCargado.umvId == item.umvId)
                                isAlreadyCharged = true;
                        });

                        if (!isAlreadyCharged) {
                            self.mechanic.items.push({
                                itemId: item.itemId
                                , itemCode: item.itemCode
                                , itemEan: item.itemEan
                                , sapDescription: item.sapDescription
                                , umvId: item.umvId
                                , umv: item.umv
                                , subcategoryCode: item.subcategoryCode
                                , subcategoryName: item.subcategoryName
                                , subcategoryId: item.subcategoryId
                                , manufacturer: item.manufacturer
                                , investmentApportionment: item.investmentApportionment
                                , normalPrice: item.normalPrice
                                , minimalPrice: item.minimalPrice
                                , currentPrice: item.currentPrice
                                , currentPriceType: item.currentPriceType
                                , currentPriceReason: item.currentPriceReason
                                , fromPrice: item.fromPrice
                                , toPrice: item.toPrice
                                , internalCost: item.internalCost
                                , marginPriceP: item.marginPriceP
                                , minRange: item.minRange
                                , maxRange: item.maxRange
                                , manualForecast: item.manualForecast
                                , dailyAvgSales: item.dailyAvgSales
                                , dailyAvgUnits: item.dailyAvgUnits
                                , salesForecast: item.salesForecast
                                , unitsForecast: item.unitsForecast
                                , marginForecastP: item.marginForecastP
                                , marginForecast: item.marginForecast
                                , internalPromotion: item.internalPromotion
                                , sellInNewCost: item.sellInNewCost
                                , sellOutNegotiated: item.sellOutNegotiated
                                , estipulatedMarginP: item.estipulatedMarginP
                                , estipulatedMargin: item.estipulatedMargin
                                , returnAgreement: item.returnAgreement
                                , isExtra: false

                            });
                        }
                        else {
                            //No lo carga xq ya lo tenes agregado.
                        }


                    });

                    updateItemList();

                    self.itemsToMassiveCharge = "";
                    self.isLoadingMassiveCharge = false;
                });
        }

        function doExtraMassiveLoad() {
            self.isLoadingMassiveCharge = true;
            promotionsService.mechanics.getItemsForMechanic({ itemCodes: self.extraItemsToMassiveCharge, categoryIds: [], promotionId: $stateParams.promotionId })
                .then(function (items) {
                    if (items.length === 0) {
                        $timeout(function () {
                            swal('Error!',
                                'Ninguno de los codigos coincide, revisar el formato.',
                                'error');
                        },
                            200);
                    }

                    var isAlreadyCharged = false;
                    angular.forEach(items, function (item) {

                        //verifico si ya existe
                        isAlreadyCharged = false;
                        angular.forEach(self.mechanic.itemsExtra, function (itemCargado) {
                            if (itemCargado.itemId === item.itemId && itemCargado.umvId === item.umvId) {
                                isAlreadyCharged = true;
                            }
                        });

                        if (!isAlreadyCharged) {
                            self.mechanic.itemsExtra.push({
                                itemId: item.itemId
                                , itemCode: item.itemCode
                                , itemEan: item.itemEan
                                , sapDescription: item.sapDescription
                                , umvId: item.umvId
                                , umv: item.umv
                                , subcategoryCode: item.subcategoryCode
                                , subcategoryName: item.subcategoryName
                                , subcategoryId: item.subcategoryId
                                , manufacturer: item.manufacturer
                                , investmentApportionment: item.investmentApportionment
                                , normalPrice: item.normalPrice
                                , minimalPrice: item.minimalPrice
                                , currentPrice: item.currentPrice
                                , currentPriceType: item.currentPriceType
                                , currentPriceReason: item.currentPriceReason
                                , fromPrice: item.fromPrice
                                , toPrice: item.toPrice
                                , internalCost: item.internalCost
                                , marginPriceP: item.marginPriceP
                                , minRange: item.minRange
                                , maxRange: item.maxRange
                                , manualForecast: item.manualForecast
                                , dailyAvgSales: item.dailyAvgSales
                                , dailyAvgUnits: item.dailyAvgUnits
                                , salesForecast: item.salesForecast
                                , unitsForecast: item.unitsForecast
                                , marginForecastP: item.marginForecastP
                                , marginForecast: item.marginForecast
                                , internalPromotion: item.internalPromotion
                                , sellInNewCost: item.sellInNewCost
                                , sellOutNegotiated: item.sellOutNegotiated
                                , estipulatedMarginP: item.estipulatedMarginP
                                , estipulatedMargin: item.estipulatedMargin
                                , returnAgreement: item.returnAgreement
                                , isExtra: true
                            });
                        }
                        else {
                            //No lo carga xq ya lo tenes agregado.
                        }
                    });

                    updateItemList();

                    self.extraItemsToMassiveCharge = '';
                    self.isLoadingMassiveCharge = false;
                });
        }

        self.viewMore = function () {
            self.itemCounter += 50;
            updateItemList();
        }

        function ExportToExcel(tableHtml, worksheetName) {
            var uri = 'data:application/vnd.ms-excel;base64,',
                template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
                format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

            var ctx = { worksheet: worksheetName, table: tableHtml },
                href = uri + base64(format(template, ctx));
            return href;
        }

        self.batchLoad = function () {
            self.currentStep = 1;
            self.gridOptions = {
                columnDefs: [
                    {
                        headerName: 'Severidad',
                        width: 100,
                        pinned: 'left',
                        field: 'severity',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                        rowGroupIndex: 0,
                        cellRenderer: function (params) {
                            var severityName = '';
                            switch (params.value) {
                                case 'Information': { severityName = '<i style="color: #6aa4ff;" class="fa fa-info-circle" aria-hidden="true"></i> Info'; break; }
                                case 'Warning': { severityName = '<i style="color: #e4e400;" class="fa fa-stop-circle" aria-hidden="true"></i> Warn'; break; }
                                case 'Error': { severityName = '<i style="color: #ea2323;" class="fa fa-exclamation-circle" aria-hidden="true"></i> Error'; break; }
                            }
                            return severityName;
                        }
                    },
                    {
                        headerName: 'Fila',
                        width: 50,
                        pinned: 'left',
                        field: 'row',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                    {
                        headerName: 'Categoria',
                        width: 150,
                        rowGroupIndex: 1,
                        pinned: 'left',
                        field: 'category',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                    {
                        headerName: 'Descripcion',
                        width: 350,
                        pinned: 'left',
                        field: 'description',
                        suppressMenu: true,
                        suppressSorting: true,
                        filter: false,
                    },
                ],
                enableColResize: true,
                enableSorting: true,
                enableFilter: false,
                rowHeight: 35,
                suppressHorizontalScroll: false,
                suppressCellSelection: true,
                groupUseEntireRow: true,
                groupSuppressAutoColumn: false,
                groupDefaultExpanded: 0,
                icons: {
                    columnRemoveFromGroup: '<i class="fa fa-remove"/>',
                    filter: '<i class="fa fa-filter"/>',
                    sortAscending: '<i class="fa fa-long-arrow-down"/>',
                    sortDescending: '<i class="fa fa-long-arrow-up"/>',
                    groupExpanded: '<i class="fa fa-minus-square-o"/>',
                    groupContracted: '<i class="fa fa-plus-square-o"/>',
                    columnGroupOpened: '<i class="fa fa-minus-square-o"/>',
                    columnGroupClosed: '<i class="fa fa-plus-square-o"/>'
                },
                angularCompileRows: true,
            }

            self.showNextStep = function () {
                if (self.currentStep > 0 && self.currentStep < 4) {
                    self.currentStep++;
                    loadResults([]);
                }
            }

            self.downloadExcel = function () {
                updateItemList();
                var html = '<style>.text{mso-number-format:"\@";/*force text*/}</style><table width="100%"><thead><tr>';
                //header
                html += '<th>Articulo</th>';
                html += '<th>Ean</th>';
                html += '<th>Descripcion SAP</th>';
                html += '<th>UMV</th>';
                html += '<th>Precio Mecanica</th>';
                html += '<th>Codigo Jerarquia</th>';
                html += '<th>Descripcion Jerarquia</th>';
                html += '<th>Proveedor</th>';
                html += '<th>Precio Normal</th>';
                html += '<th>Precio Bonus</th>';
                html += '<th>Puntos Bonus</th>';
                html += '<th>Venta $ Promedio Diaria</th>';
                html += '<th>Venta U Promedio Diaria</th>';
                html += '<th>Pronostico Venta Unidades </th>';
                html += '<th>Articulo en Foto</th>';
                html += '</tr></thead><tbody>';

                angular.forEach(self.mechanic.items, function (item) {
                    html += buildRow(item);
                });

                html += '</tbody></table>';

                var exportHref = ExportToExcel(html, 'Pagina1');
                $timeout(function () {
                    location.href = exportHref;
                    self.showNextStep();
                }, 100); // trigger download
            }

            function buildRow(item) {
                var html = '';
                html +=
                    '<tr><td>' + item.itemCode +
                    '</td><td>' + item.itemEan +
                    '</td><td>' + item.sapDescription +
                    '</td><td>' + item.umv +
                    '</td><td>' + item.mechanicPrice +
                    '</td><td>' + item.subcategoryCode +
                    '</td><td>' + item.subcategoryName +
                    '</td><td>' + item.manufacturer +
                    '</td><td>' + item.normalPrice +
                    '</td><td>' + item.bonusPrice +
                    '</td><td>' + item.bonusPoints +
                    '</td><td>' + item.dailyAvgSales +
                    '</td><td>' + item.dailyAvgUnits +
                    '</td><td>' + item.unitsForecast +
                    '</td><td>' + item.unitsForecast +
                    '</td><td>' + 
                    '</td></tr>';

                return html;
            }

            self.uploadFile = function (files) {
                if (files && files.length > 0) {
                    self.isBusy = true;
                    var url = serverService.getApiUrl() + '/promotion/mechanics/uploadItemsBatchFile';
                    Upload.upload({
                        url: url,
                        data: { file: files[0] }
                    }).then(function (response) {
                        self.guid = response.data;
                        self.finishedUpload = true;
                        self.isBusy = false;
                        self.gridOptions.api.refreshView();
                    });
                }
            }

            self.validateUploadedFile = function () {
                self.isBusy3 = true;
                promotionsService.mechanics.validateItemsBatchFile(self.guid, $stateParams.promotionMediaPageId)
                    .then(function (dto) {
                        processImporterResults(dto);
                        if (!anyError(dto)) {
                            self.finishedValidation = true;
                        }
                    });

            }

            self.reUploadFile = function () {
                self.currentStep = 2;
                loadResults([]);
                self.files = null;
                self.finishedUpload = false;
                self.finishedValidation = false;
                self.finishedUpdate = false;
            }

            self.updateItems = function () {
                self.isBusy4 = true;
                promotionsService.mechanics.updateItemsInBatch(self.guid, $stateParams.promotionId)
                    .then(function (dtos) {
                        self.isBusy4 = false;
                        self.mechanic.items = [];
                        self.itemList = [];
                        self.itemCounter = dtos.length > 50 ? 50 : dtos.length;
                        //delete from db
                        promotionsService.mechanics.deleteAllSelection(self.mechanic.id, false, $stateParams.promotionId);
                        angular.forEach(dtos, function (item) {
                            self.mechanic.items.push({
                                itemId: item.itemId
                                , itemCode: item.itemCode
                                , itemEan: item.itemEan
                                , sapDescription: item.sapDescription
                                , umvId: item.umvId
                                , umv: item.umv
                                , subcategoryCode: item.subcategoryCode
                                , subcategoryName: item.subcategoryName
                                , subcategoryId: item.subcategoryId
                                , manufacturer: item.manufacturer
                                , investmentApportionment: null
                                , normalPrice: item.normalPrice
                                , bonusPrice: item.bonusPrice
                                , bonusPoints: item.bonusPoints
                                , mechanicPrice: item.mechanicPrice
                                , minimalPrice: null
                                , currentPrice: null
                                , currentPriceType: null
                                , currentPriceReason: null
                                , fromPrice: null
                                , toPrice: null
                                , internalCost: null
                                , marginPriceP: null
                                , minRange: null
                                , maxRange: null
                                , manualForecast: null
                                , dailyAvgSales: item.dailyAvgSales
                                , dailyAvgUnits: item.dailyAvgUnits
                                , salesForecast: null
                                , unitsForecast: item.unitsForecast
                                , marginForecastP: null
                                , marginForecast: null
                                , internalPromotion: ($stateParams.position == -2 ? true : false)
                                , sellInNewCost: null
                                , sellOutNegotiated: null
                                , estipulatedMarginP: null
                                , estipulatedMargin: null
                                , returnAgreement: null
                                , isExtra: false
                                , isItemInPhoto: item.isItemInPhoto

                            });
                        });
                        updateItemList();

                        self.finish();
                    });

            }

            self.finish = function () {
                self.currentStep = 1;
                loadResults([]);

                self.finishedUpload = false;
                self.finishedValidation = false;
                self.finishedUpdate = false;

                ngDialog.close();
            }

            function processImporterResults(importerResults) {
                self.isBusy = false;
                self.isBusy2 = false;
                self.isBusy3 = false;
                self.isBusy4 = false;

                if (importerResults.length > 0) {
                    loadResults(importerResults);
                }
                if (anyError(importerResults)) {
                    self.error = true;
                }
                else
                    self.error = false;
            }

            function loadResults(results) {
                self.gridOptions.api.setRowData(results);
                self.gridOptions.api.refreshView();

                self.gridVisible = results.length > 0 ? true : false;
            }

            function anyError(importerResults) {
                var error = false;
                for (var i = 0; i < importerResults.length; i++) {
                    if (importerResults[i].severity === 'Error')
                        error = true;
                }

                return error;
            }

            ngDialog.open({
                template: 'batchLoadDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                controller: function () {
                    return self;
                },
                controllerAs: 'ct',
            });
        }

        self.addItemExtraToMechanic = function () {
            var newScope = $scope.$new();
            var y = window.scrollY;

            newScope.saveItem = function () {
                promotionsService.mechanics.getItemForMechanic(self.selectedItemInModal.id, self.selectedItemInModal.saleUnitOfMeasureCode, $stateParams.promotionId)
                    .then(function (item) {
                        self.mechanic.itemsExtra.push({
                            itemId: item.itemId
                            , umvId: item.umvId
                            , umv: item.umv
                            , itemCode: item.itemCode
                            , itemEan: item.itemEan
                            , sapDescription: item.sapDescription
                            , itemType: null
                            , subcategoryCode: item.subcategoryCode
                            , subcategoryName: item.subcategoryName
                            , subcategoryId: item.subcategoryId
                            , unitsToTake: null
                            , investmentApportionment: item.investmentApportionment
                            , normalPrice: item.normalPrice
                            , isExtra: true
                        });
                    });


                self.selectedItemInModal = null;
                self.itemLookupText = null;


                ngDialog.close();
                $timeout(function () {
                    window.scroll(0, y);
                }, 550);
            }
            newScope.cancel = function () {
                ngDialog.close();
                $timeout(function () {
                    window.scroll(0, y);
                }, 550);
            }

            ngDialog.open({
                template: 'selectItemExtraDialog',
                className: 'ngdialog-theme-default ngdialog-theme-custom',
                scope: newScope,
                overflow: 'initial !important',
                showClose: false,
                closeByEscape: false,
                closeByDocument: false
            });
        }

        self.deleteMechanicItem = function (item) {
            swal({
                title: 'Esta seguro que desea eliminar el articulo ?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        updateItemList();
                        var index = self.mechanic.items.indexOf(item);
                        self.mechanic.items.splice(index, 1);
                        promotionsService.mechanics.deleteMechanicItemOrCategory(item.id, false, $stateParams.promotionId)//delete from db
                            .then(function (isOk) {
                                updateItemList();
                                $timeout(function () {
                                    swal('Articulo Borrado!', 'se ha borrado correctamente', 'success');
                                }, 200)
                            });
                    }
                });

        }

        self.deleteMechanicExtraItem = function (item) {
            swal({
                title: 'Esta seguro que desea eliminar el articulo ?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        var index = self.mechanic.itemsExtra.indexOf(item);
                        self.mechanic.itemsExtra.splice(index, 1);
                        promotionsService.mechanics.deleteMechanicItemOrCategory(item.id, false, $stateParams.promotionId)//delete from db
                            .then(function (isOk) {
                                $timeout(function () {
                                    swal('Articulo Borrado!', 'se ha borrado correctamente', 'success');
                                }, 200)
                            });

                    }
                });



        }

        self.deleteMechanicCategory = function (cat) {

            swal({
                title: 'Esta seguro que desea eliminar la jerarquia ?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        var index = self.mechanic.categories.indexOf(cat);
                        self.mechanic.categories.splice(index, 1);
                        promotionsService.mechanics.deleteMechanicItemOrCategory(cat.id, true, $stateParams.promotionId)//delete from db
                            .then(function (isOk) {
                                $timeout(function () {
                                    swal('Jerarquia Borrada!', 'se ha borrado correctamente', 'success');
                                }, 200)
                            });

                    }
                });
        }

        self.deleteMechanicExtraCategory = function (cat) {

            swal({
                title: 'Esta seguro que desea eliminar la jerarquia ?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        var index = self.mechanic.categoriesExtra.indexOf(cat);
                        self.mechanic.categoriesExtra.splice(index, 1);
                        promotionsService.mechanics.deleteMechanicItemOrCategory(cat.id, true, $stateParams.promotionId)//delete from db
                            .then(function (isOk) {
                                $timeout(function () {
                                    swal('Jerarquia Borrada!', 'se ha borrado correctamente', 'success');
                                }, 200)
                            });

                    }
                });


        }

        self.deleteChat = function (chatId) {

            swal({
                title: 'Borrar el comentario ?',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1AB394",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {

                        promotionsService.items.deleteItemChat(chatId)
                            .then(function (isOk) {
                                for (var i = 0; i < self.mechanic.chats.length; i++) {
                                    if (self.mechanic.chats[i].id == chatId)
                                        self.mechanic.chats.splice(self.mechanic.chats.indexOf(chatId), 1);
                                }
                                swal('Comentario Borrado!', 'se ha borrado correctamente', 'success');
                            }, function (status) {
                                if (status == 502) {
                                    swal("El proceso continua...", "El proceso de borrado continua. Puede revisar el estado en unos minutos.")
                                }
                                else {
                                    swal("Error", "Hubo un error al borrar el comentario", "error");
                                }
                            });


                    }

                });
        }


        $scope.$watch('ct.mechanic', function (newVal, oldVal) {
            switch (newVal.mechanicType) {
                case 1: { //AxB
                    if (newVal.targetUnits != null && newVal.payUnits != null && newVal.itemOrCategory && newVal.itemOrCategory.length > 2) {
                        newVal.name = "El cliente al comprar " + newVal.targetUnits + " artículos de " + newVal.itemOrCategory + " pagará " + newVal.payUnits + " y llevará totalmente gratis " + (newVal.targetUnits - newVal.payUnits).toString();
                    }
                    else
                        newVal.name = '';
                    break;
                }
                case 2: { //Coleccionable
                    break;
                }
                case 3: { //Compra A + $ lleva B
                    calculateControl();
                    if (newVal.targetUnits != null && newVal.sourceItem != null && newVal.targetItem && newVal.targetItemDiscount) {
                        var takingExtra = newVal.itemsExtra.length > 0 && newVal.itemsExtra[0].unitsToTake ? newVal.itemsExtra[0].unitsToTake : "";
                        newVal.name = "El cliente al comprar " + newVal.targetUnits + " de " + newVal.sourceItem + " mas $" + newVal.targetItemDiscount + " llevará " + takingExtra + " " + newVal.targetItem;
                    }
                    else
                        newVal.name = '';
                    break;
                }
                case 4: { //Compra A y llevate B Gratis
                    if (newVal.targetUnits != null && newVal.sourceItem != null && newVal.targetItem) {
                        var takingExtra = newVal.itemsExtra.length > 0 && newVal.itemsExtra[0].unitsToTake ? newVal.itemsExtra[0].unitsToTake : "";
                        newVal.name = "El cliente al comprar " + newVal.targetUnits + " de " + newVal.sourceItem + " llevará totalmente gratis " + takingExtra + " " + newVal.targetItem;
                    }
                    break;
                }
                case 5: { //Compra X$ en A y lleva X B Gratis
                    if (newVal.sourceItem != null && newVal.targetItem && newVal.targetItemDiscount) {
                        var takingExtra = newVal.itemsExtra.length > 0 && newVal.itemsExtra[0].unitsToTake ? newVal.itemsExtra[0].unitsToTake : "";
                        newVal.name = "El cliente al comprar $" + newVal.targetItemDiscount + " en " + newVal.sourceItem + " llevará totalmente gratis " + takingExtra + " " + newVal.targetItem;
                    }
                    else
                        newVal.name = '';
                    break;
                }
                case 6: { //Cupon de Descuento
                    self.blockDiscountP = false;
                    self.blockDiscount = false;

                    if (newVal.discountP == 0 || newVal.discountP == undefined || newVal.discountP == null)
                        self.blockDiscount = false;
                    else
                        self.blockDiscount = true;
                    if (newVal.discount == 0 || newVal.discount == undefined || newVal.discount == null)
                        self.blockDiscountP = false;
                    else
                        self.blockDiscountP = true;

                    newVal.name = '';
                    if (newVal.itemOrCategory != null && newVal.itemOrCategory != '' && ((newVal.discount != 0 && newVal.discount != null) || (newVal.discountP != 0 && newVal.discountP != null))) {
                        var discount = newVal.discountP != 0 && newVal.discountP != null ? "un " + newVal.discountP + '%' : "$" + newVal.discount;
                        newVal.name = "El cliente al entregar el cupon obtendrá " + discount + " de descuento en " + newVal.itemOrCategory;
                    }

                    break;
                }
                case 7: { //Cuponera de Descuento
                    self.blockpunctualDiscountP = false;
                    self.blockpunctualDiscount = false;

                    if ((newVal.items.length > 0 && (newVal.items[0].punctualDiscount == 0 || newVal.items[0].punctualDiscount == null || newVal.items[0].punctualDiscount == undefined)) || (newVal.categories.length > 0 && (newVal.categories[0].punctualDiscount == 0 || newVal.categories[0].punctualDiscount == null || newVal.categories[0].punctualDiscount == undefined)))
                        self.blockpunctualDiscountP = false;
                    else
                        self.blockpunctualDiscountP = true;

                    if ((newVal.items.length > 0 && (newVal.items[0].punctualDiscountP == 0 || newVal.items[0].punctualDiscountP == null || newVal.items[0].punctualDiscountP == undefined)) || (newVal.categories.length > 0 && (newVal.categories[0].punctualDiscountP == 0 || newVal.categories[0].punctualDiscountP == null || newVal.categories[0].punctualDiscountP == undefined)))
                        self.blockpunctualDiscount = false;
                    else
                        self.blockpunctualDiscount = true;

                    self.blockDiscountP = false;
                    self.blockDiscount = false;

                    if (newVal.discountP == 0 || newVal.discountP == undefined || newVal.discountP == null)
                        self.blockDiscount = false;
                    else
                        self.blockDiscount = true;
                    if (newVal.discount == 0 || newVal.discount == undefined || newVal.discount == null)
                        self.blockDiscountP = false;
                    else
                        self.blockDiscountP = true;
                    break;
                }
                case 8: { //X de descuento
                    self.blockDiscountP = false;
                    self.blockDiscount = false;

                    if (newVal.discountP == 0 || newVal.discountP == undefined || newVal.discountP == null)
                        self.blockDiscount = false;
                    else
                        self.blockDiscount = true;
                    if (newVal.discount == 0 || newVal.discount == undefined || newVal.discount == null)
                        self.blockDiscountP = false;
                    else
                        self.blockDiscountP = true;

                    if ((newVal.discount && newVal.discount != 0) || (newVal.discountP && newVal.discountP != 0)) {
                        var discount = newVal.discountP != 0 && newVal.discountP != null ? "un " + newVal.discountP + '%' : "$" + newVal.discount;
                        newVal.name = "El cliente al comprar los productos indicados obtendra " + discount + " de descuento";
                    }

                    break;
                }
                case 9: { //x% de Descuento por Dia

                    break;
                }
                case 10: { //Pack Virtual

                    //Solo permito seleccionar items, no jerarquias.
                    self.selectionItemOrCategoryExtra = 2; //Item
                    self.selectionItemOrCategory = 2; //Item
                    calculateControl();

                    if (newVal.itemOrCategory && newVal.itemOrCategory != '' && newVal.targetItemDiscount && newVal.targetItemDiscount != '') {
                        var m3 = newVal.items.length > 0 ? newVal.items[0] : null;
                        if (m3 && m3.normalPrice && m3.currentPrice) {
                            var m3name = m3.sapDescription;
                            var total = m3.normalPrice - m3.currentPrice;
                        }

                        m3Extra = newVal.itemsExtra.length > 0 ? newVal.itemsExtra[0] : null;
                        if (m3Extra && m3Extra.normalPrice && m3Extra.currentPrice) {
                            var totalExtra = m3Extra.normalPrice - m3Extra.currentPrice;
                        }

                        newVal.name = "El cliente al comprar " + newVal.itemOrCategory + " pagará $" + newVal.targetItemDiscount + ". El cliente ahorrará $" + self.mechanic.promoVsNormal.toFixed(2);

                        if (m3 && m3.normalPrice && m3.currentPrice && m3Extra && m3Extra.normalPrice && m3Extra.currentPrice) {
                            var m3SubTotal = total + totalExtra;
                            newVal.name += " (Precio Normal del Pack " + m3name + "=(" + m3SubTotal + ")), el cliente Ahorrará " + (m3SubTotal - newVal.targetItemDiscount); // M4 = M3-M1
                        }
                    }

                    break;
                }
                case 11: { // Mensaje

                    break;
                }
                case 12: { //X interes y Cuotas

                    if (newVal.dues && newVal.interest)
                        newVal.name = "El cliente comprará los articulos que se detallan en " + newVal.dues + " cuotas con un interes del %" + newVal.interest;

                    break;
                }
                case 13: { //Compra A y lleva B con X de descuento
                    calculateControl();
                    self.blockDiscountP = false;
                    self.blockDiscount = false;

                    if (newVal.discountP == 0 || newVal.discountP == undefined || newVal.discountP == null)
                        self.blockDiscount = false;
                    else
                        self.blockDiscount = true;

                    if (newVal.discount == 0 || newVal.discount == undefined || newVal.discount == null)
                        self.blockDiscountP = false;
                    else
                        self.blockDiscountP = true;

                    if (newVal.targetUnits && newVal.targetUnits > 0 && newVal.sourceItem && newVal.targetItem && ((newVal.discount && newVal.discount != 0) || (newVal.discountP && newVal.discountP != 0))) {
                        var discount = newVal.discountP != 0 && newVal.discountP != null ? "un " + newVal.discountP + '%' : "$" + newVal.discount;
                        var c4 = newVal.itemsExtra && newVal.itemsExtra.length > 0 ? (newVal.itemsExtra[0].unitsToTake != null ? newVal.itemsExtra[0].unitsToTake : "") : "";
                        newVal.name = "El cliente al comprar " + newVal.targetUnits + " de " + newVal.sourceItem + " tendra " + discount + " de descuento en " + c4 + ' ' + newVal.targetItem;
                    }

                    break;
                }
                case 14: { //Electro Fans
                    self.blockpunctualDiscountP = false;
                    self.blockpunctualDiscount = false;

                    if ((newVal.items.length > 0 && (newVal.items[0].punctualDiscount == 0 || newVal.items[0].punctualDiscount == null || newVal.items[0].punctualDiscount == undefined)) || (newVal.categories.length > 0 && (newVal.categories[0].punctualDiscount == 0 || newVal.categories[0].punctualDiscount == null || newVal.categories[0].punctualDiscount == undefined)))
                        self.blockpunctualDiscountP = false;
                    else
                        self.blockpunctualDiscountP = true;

                    if ((newVal.items.length > 0 && (newVal.items[0].punctualDiscountP == 0 || newVal.items[0].punctualDiscountP == null || newVal.items[0].punctualDiscountP == undefined)) || (newVal.categories.length > 0 && (newVal.categories[0].punctualDiscountP == 0 || newVal.categories[0].punctualDiscountP == null || newVal.categories[0].punctualDiscountP == undefined)))
                        self.blockpunctualDiscount = false;
                    else
                        self.blockpunctualDiscount = true;

                    break;
                }
                case 15: { //Descuento por Hora
                    self.blockpunctualDiscountP = false;
                    self.blockpunctualDiscount = false;

                    if ((newVal.items.length > 0 && (newVal.items[0].punctualDiscount == 0 || newVal.items[0].punctualDiscount == null || newVal.items[0].punctualDiscount == undefined)) || (newVal.categories.length > 0 && (newVal.categories[0].punctualDiscount == 0 || newVal.categories[0].punctualDiscount == null || newVal.categories[0].punctualDiscount == undefined)))
                        self.blockpunctualDiscountP = false;
                    else
                        self.blockpunctualDiscountP = true;

                    if ((newVal.items.length > 0 && (newVal.items[0].punctualDiscountP == 0 || newVal.items[0].punctualDiscountP == null || newVal.items[0].punctualDiscountP == undefined)) || (newVal.categories.length > 0 && (newVal.categories[0].punctualDiscountP == 0 || newVal.categories[0].punctualDiscountP == null || newVal.categories[0].punctualDiscountP == undefined)))
                        self.blockpunctualDiscount = false;
                    else
                        self.blockpunctualDiscount = true;

                    break;
                }
                case 16: { //Precios Multiples

                    break;
                }
                case 17: { //Mega Promo

                    if (newVal.buyCash != null && newVal.stickers != null && newVal.buyCashSponsors != null && newVal.stickersSponsors != null)
                        newVal.name = "Por cada $ " + newVal.buyCash + " de compra, presentando tu tarjta Bonus, recibiras " + newVal.stickers + " stickers y por cada $ " + newVal.buyCashSponsors + " de compra en productos auspiciadores recibiras " + newVal.stickersSponsors + " stickers mas.";

                    break;
                }
                case 18: { //Desc en Vales de Compra
                    //Solo permito seleccionar items, no jerarquias.
                    self.selectionItemOrCategory = 2; //Item
                    break;
                }
                case 20: { //Precio-Producto Masivo
                    self.selectionItemOrCategory = 2;
                    break;
                }
            }
            //APLICA A TODAS LAS MECANICAS

            if (newVal.apportionment == true) {
                self.validationRetailP = true;
                self.validationSupermarketP = true;
                self.totalValidation = true;
                var total = newVal.retailP + newVal.supermarketP;

                if (newVal.retailP > 100)
                    self.validationRetailP = false;

                if (newVal.supermarketP > 100)
                    self.validationSupermarketP = false;

                if (total != 100)
                    self.totalValidation = false;
            }
            else {
                self.validationRetailP = true;
                self.validationSupermarketP = true;
                self.totalValidation = true;
            }

            //Solapamiento
            if (newVal.items != oldVal.items || newVal.categories != oldVal.categories) {
                var overlaps = {
                    items: newVal.items,
                    categories: newVal.categories,
                    excludedItemCodes: newVal.excludedItemCodes,
                    position: $stateParams.position,
                    promotionMediaPageId: $stateParams.promotionMediaPageId,
                    promotionId: $stateParams.promotionId
                };

                promotionsService.mechanics.getMechanicOverlaps(overlaps)
                    .then(function (overlaps) {
                        self.overlaps = overlaps;
                        self.distinctPromotionsOveralps = []; 
                            _.forOwn(_.groupBy(self.overlaps, "promotionId"), function (value, key) {
                                self.distinctPromotionsOveralps.push(key);
                            });
                    });
            }

            self.itemCodes = "";
            self.mechanic.items.forEach(function (i) { self.itemCodes += (i.itemCode.replace(/^0+/, '')) + ','; });

            self.extraItemCodes = '';
            self.mechanic.itemsExtra.forEach(function (i) {
                self.extraItemCodes += (i.itemCode.replace(/^0+/, '')) + ',';
            });
        }, true);

        $scope.$watch('ct.itemLookupText', function (newVal, oldVal) {
            if (newVal) {

                if (newVal.id) {
                    self.selectedItemInModal = self.itemLookupText;
                }
                else if (newVal && newVal != oldVal && (newVal.length >= 4 || (oldVal.length && oldVal.length > 3)) && newVal != previousLookupText) {
                    previousLookupText = newVal;

                    self.itemsInLookup = [{ id: 0, code: '', name: 'Cargando Items con el texto ' + newVal + '...' }];
                    var lookupParameter = { searchTerm: newVal, categoryIds: self.categoryIds };
                    homeService.lookups.itemsLookup(lookupParameter)
                        .then(function (items) {
                            self.itemsInLookup = items;
                            if (items.length > 0)
                                self.lookupItemsNoResults = false;
                            else
                                self.lookupItemsNoResults = true;
                        });
                }
            }

        }, true);

        $scope.$watch('ct.itemExtraLookupText', function (newVal, oldVal) {
            if (newVal) {

                if (newVal.id) {
                    self.selectedItemInModal = self.itemExtraLookupText;
                }
                else if (newVal && newVal != oldVal && (newVal.length >= 4 || (oldVal.length && oldVal.length > 3)) && newVal != previousLookupText) {
                    previousLookupText = newVal;

                    self.itemsInLookup = [{ id: 0, code: '', name: 'Cargando Items con el texto ' + newVal + '...' }];
                    var lookupParameter = { searchTerm: newVal, categoryIds: [] };
                    homeService.lookups.itemsLookup(lookupParameter)
                        .then(function (items) {
                            self.itemsInLookup = items;
                            if (items.length > 0)
                                self.lookupItemsNoResults = false;
                            else
                                self.lookupItemsNoResults = true;
                        });
                }
            }

        }, true);

        $scope.$watch('ct.selectedVendor', function (newVal, oldVal) {
            if (newVal && newVal.id != null) {
                self.mechanic.vendorId = newVal.id;
            }
            else {
                self.mechanic.vendorId = null;
            }
        }, true);

        function newMechanic() {
            var newMechanic = {
                promotionMediaPageId: parseInt($stateParams.promotionMediaPageId),
                taskId: parseInt($stateParams.taskId),
                position: parseInt($stateParams.position),
                isHighlighted: ($stateParams.isHighlighted == "true"),
                isSubstitute: ($stateParams.isSubstitute == "true"),
                promotionId: parseInt($stateParams.promotionId),
                name: '',
                ticketDescription: '',
                observation: '',
                chats: [],
                itemOrCategory: '',
                message: '',
                items: [],
                categories: [],
                categoriesExtra: [],
                itemsExtra: [],
                stores: self.stores,
                sourceUnits: null,
                buyCash: null,
                stickers: null,
                buyCashSponsors: null,
                stickersSponsors: null,
                targetUnits: null,
                payUnits: null,
                sourceItem: '',
                targetItem: '',
                targetItemDiscount: null,
                targetUnitsAtDiscount: null,
                targetUnitsDiscount: null,
                itemPlusMoney: null,
                payAmount: null,
                mechanicType: null,
                apportionment: false,
                retailP: null,
                supermarketP: null,
                acumOtherPromotions: false,
                applyOfferPrices: false,
                unitsRestriction: false,
                moneyRestriction: false,
                unitsRestrictionValue: null,
                moneyRestrictionValue: null,
                cardRestriction: false,
                bonusCard: false,
                wongCard: false,
                metroCard: false,
                otherCard: false,
                participantGoods: '',
                ministerPermission: '',
                affectsClientMargin: false,
                discountByCategoryP: null,
                otherDiscounts: '',
                sellIn: false,
                sellOut: true,
                vendorId: null,
                charge: true,
                chargeRate: null,
                negotiatedChargeRate: null,
                excludedItemCodes: '',
                announcementType: 1,
                paymentType: 2,
                returnAgreement: false,
                sellInNewCost: null,
                sellOutNegotiated: null,
                estipulatedMarginP: null,
                estipulatedMargin: null,
                promoVsNormal: null
            }

            return newMechanic;
        }

        function getMechanicInfo(promotionId, promotionMechanicId) {
            self.isLoading = true;

            promotionsService.mechanics.getMechanicInfo(promotionId, promotionMechanicId)
                .then(function (mechanic) {
                    self.mechanic = mechanic;
                    self.mechanic.promotionMediaPageId = $stateParams.promotionMediaPageId;
                    self.mechanic.position = $stateParams.position;
                    self.mechanic.isHighlighted = $stateParams.isHighlighted;
                    self.mechanic.isSubstitute = $stateParams.isSubstitute;
                    self.setAcumPromotion(mechanic.acumOtherPromotions);
                    self.setApplyOfferPrices(mechanic.applyOfferPrices);
                    self.setCardRestriction(mechanic.cardRestriction);
                    self.setAffectsClientMargin(mechanic.affectsClientMargin);
                    if ($stateParams.promotionMechanicId == 0) {//isNew
                        self.setMoneyRestriction(mechanic.moneyRestriction);
                        self.setUnitsRestriction(mechanic.unitsRestriction);
                        self.setCharge(mechanic.charge);
                    }
                    else {//is not new
                        self.mechanic.items.forEach(function (i) {
                            if (i.activationDate)
                                i.activationDate = new Date(i.activationDate);
                            if (i.startTime)
                                i.startTime = new Date(i.startTime);
                            if (i.endTime)
                                i.endTime = new Date(i.endTime);
                        });
                        self.mechanic.categories.forEach(function (c) {
                            if (c.activationDate)
                                c.activationDate = new Date(c.activationDate);
                            if (c.startTime)
                                c.startTime = new Date(c.startTime);
                            if (c.endTime)
                                c.endTime = new Date(c.endTime);
                        });
                        updateItemList();
                    }
                    self.isLoading = false;

                    //Load Category Or Item Box
                    self.selectionItemOrCategory = mechanic.categories && mechanic.categories.length > 0 ? "1" : mechanic.items && mechanic.items.length > 0 ? "2" : null;
                    self.selectionItemOrCategoryExtra = mechanic.categoriesExtra && mechanic.categoriesExtra.length > 0 ? "1" : mechanic.itemsExtra && mechanic.itemsExtra.length > 0 ? "2" : null;

                    angular.forEach(self.mechanic.stores, function (s) {
                        self.storeCodes += s.storeCode + ",";
                    });
                });
        }

        //buttons Collapsed Sections
        $scope.isNavCollapsed = true;
        $scope.isCollapsed = false;
        $scope.isCollapsedHorizontal = false;

        $scope.isTitleMechanicsCollapsed = false;
        $scope.isApportionmentCollapsed = false;
        $scope.isSelectionCollapsed = false;
        $scope.isExtraSelectionCollapsed = false;
        $scope.isControlCollapsed = false;
        $scope.isRestrictionsCollapsed = false;
        $scope.isOtherDataCollapsed = false;
        $scope.isNegotiationCollapsed = false;
        $scope.isExtrasCoverageCollapsed = false;


        //actualiza los items de la mecanica segun la lista visible
        function updateItemList() {
            var itemList = [];
            if (self.search != "" || self.searchwithCheckbox) {
                itemList = self.mechanicItemsFiltered;
            }
            else {
                itemList = self.mechanic.items;
            }
            angular.forEach(self.itemList, function (item) {
                angular.forEach(itemList, function (mecIt) {
                    if (item.itemId == mecIt.itemId && item.umvId == mecIt.umvId)
                        mecIt = item;
                });
            });
            self.itemList = itemList.slice(0, self.itemCounter);
            self.totalItemCounter = itemList.length;
            self.isOnlyOneItemInList();
        }

        self.filterItemList = function () {

            self.mechanicItemsFiltered = [];

            if (self.search != "" || self.searchwithCheckbox) {
                angular.forEach(self.mechanic.items, function (i) {
                    if (((i.itemCode && i.itemCode.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                        || (i.itemEan && i.itemEan.toLowerCase().indexOf(self.search.toLowerCase()) > -1)
                        || (i.sapDescription && i.sapDescription.toLowerCase().indexOf(self.search.toLowerCase()) > -1))
                        && (i.isItemInPhoto && self.searchwithCheckbox || !self.searchwithCheckbox)){ 

                        self.mechanicItemsFiltered.push(i);
                    }
                });
            }
            else {
                self.mechanicItemsFiltered  = self.mechanic.items;
            }

            self.itemList = self.mechanicItemsFiltered.slice(0, self.itemCounter);
            self.totalItemCounter = self.mechanicItemsFiltered.length;
        }

        function init() {
            self.isNew = $stateParams.promotionMechanicId == 0 ? true : false;
            self.mechanics = promotionsService.promotionMechanicTypes;
            self.announcementTypes = promotionsService.promotionMechanicAnnouncementTypes;
            self.paymentTypes = promotionsService.promotionMechanicPaymentTypes;

            promotionsService.tasks.getTaskName($stateParams.taskId)
                .then(function (taskName) {
                    switch (taskName) {
                        case "2° Revisión Comercial": self.isReview = true;
                            break;
                        case "1° Input para armado de Pieza": self.isPrintLayoutCompare = true;
                            break;
                        case "2° Input para armado de Pieza": self.isPrintLayoutCompare = true;
                            break;
                        case "3° Input para armado de Pieza": self.isPrintLayoutCompare = true;
                            break;
                        case "1° Subida de Pieza para revisión": self.isLayoutImageUpload = true;
                            break;
                        case "2° Subida de Pieza para revisión": self.isLayoutImageUpload = true;
                            break;
                        case "3° Subida de Pieza para revisión": self.isLayoutImageUpload = true;
                            break;
                        case "Validación final": self.isFinalValidation = true;
                            break;
                    }

                });

            self.isEditPanguiCode = $stateParams.isEditPanguiCode == 'true' ? true : false;

            if ($stateParams.promotionMechanicId != 0) {
                getMechanicInfo($stateParams.promotionId, $stateParams.promotionMechanicId);
            }

            promotionsService.mechanics.getMechanicVendors()
                .then(function (vendors) {
                    self.vendors = vendors;
                    vendors.forEach(function (v) { if (v.id == self.mechanic.vendorId) self.selectedVendor = v; });
                });

            //Cargo los datos de la página con sus categorias asociadas
            if ($stateParams.promotionMediaPageId != 0) {
                promotionsService.promotions.getPromotionMediaPage($stateParams.promotionMediaPageId)
                    .then(function (promotionMediaPage) {
                        self.promotionMediaPage = promotionMediaPage;

                        self.categoryIds.push(promotionMediaPage.mainCategoryId);
                        for (var i = 0; i < promotionMediaPage.secondaryCategoriesIds.length; i++) {
                            self.categoryIds.push(promotionMediaPage.secondaryCategoriesIds[i]);
                        }
                    });
            }

            promotionsService.mechanics.getMechanicCategories()
                .then(function (categories) {
                    self.categoriesInLookup = categories;
                });

            promotionsService.mechanics.getPromotionInfo($stateParams.promotionId)
                .then(function (info) {
                    self.promotionInfo = info;
                });
            if ($stateParams.promotionMechanicId == 0) {
                promotionsService.mechanics.getPromotionStores($stateParams.promotionId)
                    .then(function (stores) {
                        self.stores = stores;
                        self.mechanic.stores = stores;
                        angular.forEach(stores, function (s) {
                            s.toList = true;
                            self.storeCodes += s.storeCode + ",";
                        });
                        self.setChargeRate();
                    });
            }

            promotionsService.promotions.getPromotionData($stateParams.promotionId, $stateParams.promotionMediaPageId, $stateParams.position)
                .then(function (promotionInfo) {
                    self.promoDetails = promotionInfo;
                });

        }


        //TODO: Utilizar directiva, por algun motivo no le pega al controller
        $scope.$watch('ct.inputVisibility', function (newVal, oldVal) {
            if (newVal == 1) {
                $('.view-all').hide();
                $('.view-fill').show();
            } else {
                $('.view-all').show();
            }
        }, true);


        init();
    });