"use strict";

angular.module("prisma")
.directive("layoutPreview", function ($compile, $filter) {
    return {
        link: function ($scope, element, attrs, controller) {
            console.log('Link')

            var showChat = false;

            $scope.recreateLayoutTable = function () {
                console.log('layoutPreview::recreateLayoutTable')
                drawTable(true);
            };

            $scope.$on('recreateLayoutTable', function (event, data) {
                console.log('layoutPreview::recreateLayoutTable')
                $scope.recreateLayoutTable()
            });

            $scope.refreshLayoutTable = function () {
                console.log('layoutPreview::refreshLayoutTable')
                drawTable(false);
            };

            $scope.$on('refreshLayoutTable', function (event, data) {
                console.log('layoutPreview::refreshLayoutTable')

                $scope.refreshLayoutTable()
            });

            $scope.doRowspan = function (n) {
                console.log('layoutPreview::doRowspan')

                $scope.ct.layoutPreviewGrid[n - 1].rowspan++;
                var box = $scope.ct.layoutPreviewGrid[n - 1];
                for (var i = 0; i < $scope.ct.layoutPreviewGrid.length; i++) {
                    if ($scope.ct.layoutPreviewGrid[i].rowNumber == box.rowNumber + (box.rowspan - 1) && $scope.ct.layoutPreviewGrid[i].colNumber == box.colNumber) {
                        $scope.ct.layoutPreviewGrid.splice(i, 1);
                        var anyRowFound = false;
                        for (var j = 0; j < $scope.ct.layoutPreviewGrid.length; j++) {
                            if ($scope.ct.layoutPreviewGrid[j].rowNumber == box.rowNumber + (box.rowspan - 1))
                            {                                
                                anyRowFound = true;
                                break;
                            }
                        }
                        if (!anyRowFound)//descuento el row number y row span de las siguientes
                        {
                            for (var z = 0; z < $scope.ct.layoutPreviewGrid.length; z++) {
                                if ($scope.ct.layoutPreviewGrid[z].rowNumber == box.rowNumber) {
                                    $scope.ct.layoutPreviewGrid[z].rowspan--;
                                }
                            }
                            for (var z = 0; z < $scope.ct.layoutPreviewGrid.length; z++) {                              
                                if ($scope.ct.layoutPreviewGrid[z].rowNumber > box.rowNumber + (box.rowspan - 1)) {
                                    $scope.ct.layoutPreviewGrid[z].rowNumber--;
                                }
                            }
                        }

                    }
                }
                _drawTable();
            };

            $scope.doColspan = function (n) {
                console.log('layoutPreview::doColspan')

                $scope.ct.layoutPreviewGrid[n - 1].colspan++;
                var box = $scope.ct.layoutPreviewGrid[n - 1];
                for (var i = 0; i < $scope.ct.layoutPreviewGrid.length; i++) {
                    if ($scope.ct.layoutPreviewGrid[i].colNumber == box.colNumber + (box.colspan - 1) && $scope.ct.layoutPreviewGrid[i].rowNumber == box.rowNumber) {
                        $scope.ct.layoutPreviewGrid.splice(i, 1);
                        var anyColFound = false;
                        for (var j = 0; j < $scope.ct.layoutPreviewGrid.length; j++) {
                            if ($scope.ct.layoutPreviewGrid[j].colNumber == box.colNumber + (box.colspan - 1)) {
                                anyColFound = true;
                                break;
                            }
                        }
                        if (!anyColFound)//descuento el col number y col span de las siguientes
                        {
                            for (var z = 0; z < $scope.ct.layoutPreviewGrid.length; z++) {
                                if ($scope.ct.layoutPreviewGrid[z].colNumber == box.colNumber) {
                                    $scope.ct.layoutPreviewGrid[z].colspan--;
                                }
                            }
                            for (var z = 0; z < $scope.ct.layoutPreviewGrid.length; z++) {
                                if ($scope.ct.layoutPreviewGrid[z].colNumber > box.colNumber + (box.colspan - 1)) {
                                    $scope.ct.layoutPreviewGrid[z].colNumber--;
                                }
                            }
                        }
                    }
                }
                _drawTable();
            };

            $scope.doLoadItem = function (position, isHighlighted, promotionItemId, promotionMechanicId, salesUnitOfMeasureId, isReserved) {
                console.log('layoutPreview::doLoadItem')
                if ($scope.ct.isItemEditEnabled) {
                    $scope.ct.loadItemToPosition(position, isHighlighted, promotionItemId, promotionMechanicId, salesUnitOfMeasureId, isReserved);
                }

            }

            $scope.doClearPosition = function (n, promotionItemId) {
                console.log('layoutPreview::doClearPosition')

                $scope.ct.removeItem(promotionItemId);
                //$scope.ct.layoutPreviewGrid[n - 1].itemDescription = '';
                //$scope.ct.layoutPreviewGrid[n - 1].itemCode = '';

                _drawTable();
            };

            $scope.doHighlight = function (n) {
                console.log('layoutPreview::doHighlight')
                if ($scope.ct.layoutPreviewGrid[n - 1].isHighlighted)
                    $scope.ct.layoutPreviewGrid[n - 1].isHighlighted = false;
                else
                    $scope.ct.layoutPreviewGrid[n - 1].isHighlighted = true;

                _drawTable();
            };

            $scope.doBlockPosition = function (n) {
                console.log('layoutPreview::doBlockPosition')

                if ($scope.ct.layoutPreviewGrid[n - 1].position != null)
                    $scope.ct.layoutPreviewGrid[n - 1].position = null;
                else
                    $scope.ct.layoutPreviewGrid[n - 1].position = n - 1;

                _drawTable();
            };

            $scope.loadVariant = function (n, promotionItemId) {
                console.log('layoutPreview::loadVariant')

                $scope.ct.loadVariant(n, promotionItemId);

                _drawTable();
            };

            $scope.$watch('ct.files', function (newVal, oldVal) {
                console.log('layoutPreview::$$watch')

                if (newVal && newVal.length > 0) {
                    _drawTable();
                }
            }, true);

            /*
            $scope.$watch('ct.refresh', function (newVal, oldVal) {
                console.log($scope.layout);
                if (newVal && newVal != oldVal) {
                    drawTable();
                }
            }, true);
            */

            self._drawTable = function () {
                element.find('.previewLayoutTable').remove();


                if ($scope.ct.files !== undefined) {
                    if ($scope.ct.files.length > 0) {
                        var backWidth = 110 * $scope.ct.layout.columns;
                        var backHeight = 110 * $scope.ct.layout.rows;
                        var background = '<div style="position:absolute;  width:' + backWidth + 'px; height:' + backHeight + 'px; background:url(../../../assets/images/' + $scope.ct.showImage + '); opacity: 0.1;"></div>';
                        element.append($(background));
                    }
                }
                var table = '<table border="1" class="previewLayoutTable"></table>';
                element.append($(table));
                var position = 0;

                for (var i = 0; i < $scope.ct.layoutPreviewGrid.length; i++) {
                    var box = $scope.ct.layoutPreviewGrid[i];

                    var row = element.find('.row' + box.rowNumber);
                    if (row.length == 0) {
                        row = '<tr class="preview-row row' + box.rowNumber + '"></tr>';
                        $('.previewLayoutTable').append($(row));
                    }

                    row = element.find('.row' + box.rowNumber);

                    var col = '';
                    var highlightedClass = box.isHighlighted && box.itemDescription != '' ? ' highlighted' : '';
                    if ($scope.ct.layoutPreviewGrid[i].position != null) {//Unlocked position
                        if ($scope.ct.isActivationTask && box.promotionMechanicId != null && box.promotionMechanicId != 0) {//Mechanic on activation, activation is defined in ctrlJS
                            col = '<td rowspan="' + box.rowspan + '" colspan="' + box.colspan + '" class=" box' + box.colNumber + highlightedClass + '" ng-click="doLoadItem(' + box.position + ', ' + box.isHighlighted + ', ' + box.promotionItemId + ',' + box.promotionMechanicId + ',' + box.isReserved + ')" ' + ' style="border:4px solid  #A9A9A9;"' + '>';
                        }
                        else {
                            col = '<td rowspan="' + box.rowspan + '" colspan="' + box.colspan + '" class=" box' + box.colNumber + highlightedClass + '" ng-click="doLoadItem(' + box.position + ', ' + box.isHighlighted + ', ' + box.promotionItemId + ',' + box.promotionMechanicId + ',' + box.salesUnitOfMeasureId + ',' + box.isReserved + ')">';
                        }
                        if (box.itemCode != '') {
                            //if (ian.imageExists(box.imageUrl))
                            col += '<img src="' + (box.imageUrl ? box.imageUrl : "https://iantechstorage.blob.core.windows.net/prisma-promotion-item-images/no-image.gif") + '" class="layout-image">';
                        }

                        col += '<div class="info-box">'
                        col += '<span class="description capitalized">' + box.itemDescription.toLowerCase() + '</span>';
                        if (box.itemDescription != '' && box.itemPromotionalPrice != null && box.itemPromotionalPrice != 0)
                            col += '<span class="promotional-price">$' + box.itemPromotionalPrice + '</span>';
                        if (box.itemDescription != '' && box.itemNormalPromotionPrice != null && box.itemNormalPromotionPrice != 0)
                            col += '<span class="normal-promotion-price">$' + box.itemNormalPromotionPrice + '</span>';
                        if (box.itemDescription != '' && box.itemBonusPrice != null && box.itemBonusPrice != 0 && box.itemBonusPoints != null && box.itemBonusPoints != 0) 
                            col += '<span class="bonus">$' + box.itemBonusPrice + '+' + box.itemBonusPoints + 'p' + '</span>';                        
                        col += '</div>';

                    }
                    else//blocked position
                        col = '<td rowspan="' + box.rowspan + '" colspan="' + box.colspan + '" class=" box' + box.colNumber + highlightedClass + '" >';

                    if (box.position != null) {
                        position++;
                        col += '<span class="col-position">' + position + '</span>';
                        //if ($scope.ct.issues != null &&  $scope.ct.issues.length > 0 && $scope.ct.issues.some(x => x.pageNumber == $scope.ct.selectedPage.pageNumber && x.position == box.position))
                        if ($scope.ct.issues != null && $scope.ct.issues.length > 0 && $scope.ct.checkIssueInPosition(box.position))
                            col += '<span class="issue" title= "' + $scope.ct.issueName + '"><i class="fa fa-exclamation" aria-hidden="true"></i></span>';

                    }
                    if (self.showChat)
                        col += '<a href="#"><i class="fa fa-commenting comments" aria-hidden="true"></i></a>';

                    if (box.isHighlighted) {
                        if ($scope.ct.isLayoutEditable) {
                            col += '<a class="favourite checked" href="javascript:void(null);" title="Remover esta posici&oacute;n como destacada" ng-click="doHighlight(' + (i + 1) + ')"><i class="fa fa-star" aria-hidden="true"></i></a>';
                        }
                        else {
                            col += '<a class="favourite checked" title="Esta posici&oacute;n es destacada"><i class="fa fa-star" aria-hidden="true"></i></a>';
                        }
                    }
                    else {
                        if ($scope.ct.isLayoutEditable) {
                            col += '<a class="favourite" href="javascript:void(null);" title="Agregar esta posici&oacute;n como destacada" ng-click="doHighlight(' + (i + 1) + ')"><i class="fa fa-star" aria-hidden="true"></i></a>';
                        }
                    }
                    if (box.position != null) {
                        if ($scope.ct.isLayoutEditable) {
                            col += '<a class="block" href="javascript:void(null);" title="Bloquear esta posici&oacute;n" ng-click="doBlockPosition(' + (i + 1) + ')"><i class="fa fa-times-circle" aria-hidden="true"></i></a>';
                        }
                    }
                    else {
                        if ($scope.ct.isLayoutEditable) {
                            col += '<a class="block checked" href="javascript:void(null);" title="Desbloquear esta posici&oacute;n para que no se puedan agregar Productos o Mec&aacute;nicas" ng-click="doBlockPosition(' + (i + 1) + ')"><i class="fa fa-times-circle" aria-hidden="true"></i></a>';
                        }
                    }

                    if (box.itemDescription != '' && !$scope.ct.isActivationTask && !$scope.ct.isLayoutForReview && !$scope.ct.isLayoutCompare && !$scope.ct.isLayoutImageUpload && !$scope.ct.isFinalValidation) {
                        col += '<a class="trash" href="javascript:void(null);" title="Limpiar esta posici&oacute;n" ng-click="doClearPosition(' + (i + 1) + ', ' + box.promotionItemId + ')"><i class="fa fa-trash-o" aria-hidden="true"></i></a>';
                        if (box.promotionMechanicId == null || box.promotionMechanicId == 0)//mechanics cannot have variant items
                            col += '<a class="addVariant" href="javascript:void(null);" title="Agregar un art&iacute;culo relacionado" ng-click="loadVariant(' + (i + 1) + ', ' + box.promotionItemId + ')"><i class="fa fa-plus-circle gray" aria-hidden="true"></i></a>';
                    }

                    if ($scope.ct.isLayoutEditable) {
                        if ((box.colNumber + box.colspan - 1) < $scope.ct.layout.columns && box.rowspan == 1) {
                            col += '<button class="buttonExpand" ng-click="doColspan(' + (i + 1) + ')">';
                            col += '<i class="fa fa-arrows-h icon" aria-hidden="true"></i>';
                            col += '</button>';
                        }

                        if ((box.rowNumber + box.rowspan - 1) < $scope.ct.layout.rows && box.colspan == 1) {
                            col += '<button class="buttonExpandTop" ng-click="doRowspan(' + (i + 1) + ')">';
                            col += '<i class="fa fa-arrows-v icon" aria-hidden="true"></i>';
                            col += '</button>';
                        }
                    }

                    col += '</td>';

                    $(row).append($compile($(col))($scope));
                }
            }

            self.drawTable = function (recreate, individualPage) {
                console.log('layoutPreview:drawTable')
                //$scope.ct.selectedRow = null;
                //$scope.ct.selectedColumn = null;
                var position = 0;

                $scope.ct.layoutPreviewGrid = [];

                var layout = null;
                var id = null;

                // Obtiene el id dependiendo si es por cada elemento en el repeat o seleccionado individualmente.
                if (angular.isDefined(individualPage)) {
                    console.log('layoutPreview::attrs.selectedPage= %o', individualPage);
                    id = individualPage.promotionMediaPageLayoutId;
                } else {
                    id = $scope.ct.layout.id;
                }

                //if ($scope.ct.layout && $scope.ct.layout.id != 0 && !recreate) {
                if (recreate) {
                    layout = $scope.ct.layout;
                }
                else {
                    for (i = 0; i < $scope.ct.layouts.length; i++) {
                        if ($scope.ct.layouts[i].id == id) {
                            layout = $scope.ct.layouts[i];
                            break;
                        }
                    }
                }
               

                if (layout && layout.id != 0 && !recreate) {

                    angular.forEach($filter('orderBy')(layout.details, ['rowNumber', 'colNumber'], false), function (detail) {
                        var gridCell = {
                            rowNumber: detail.rowNumber,
                            colNumber: detail.columnNumber,
                            rowspan: detail.rowspan,
                            colspan: detail.colspan,
                            position: detail.position,
                            isHighlighted: detail.isHighlighted,
                            //imageUrl: detail.imageUrl,
                            promotionItemId: 0,
                            promotionMechanicId: 0,
                            itemCode: '',
                            itemDescription: '',
                            itemPromotionalPrice: 0,
                            itemBonusPrice: 0,
                            itemBonusPoints: 0,
                            itemNormalPromotionPrice: 0,
                            isReserved: false,
                        };

                        var selectedPage = null;

                        if (angular.isDefined(attrs.selectedPage)) {
                            var page = $scope.$eval(attrs.selectedPage);
                            console.log('layoutPreview::attrs.selectedPage= %o', page);
                            selectedPage = page;
                        } else {
                            selectedPage = $scope.ct.selectedPage;
                        }

                        console.log('layoutPreview::selectedPage= %o', selectedPage);

                        if (selectedPage && selectedPage.items) {
                            angular.forEach(selectedPage.items, function (item) {
                                if (item.position == detail.position && (item.promotionItemType == 1 || item.promotionItemType == 0 || item.promotionItemType == 4)) { //Publicados, Destacados o Reservados
                                    gridCell.salesUnitOfMeasureId = item.salesUnitOfMeasureId;
                                    gridCell.promotionItemId = item.id;
                                    gridCell.itemCode = item.code;
                                    gridCell.promotionMechanicId = item.promotionMechanicId;
                                    gridCell.itemDescription = item.description;
                                    gridCell.itemPromotionalPrice = item.promotionalPrice;
                                    gridCell.itemBonusPrice = item.loyaltyPrice;
                                    gridCell.itemBonusPoints = item.loyaltyPoints;
                                    gridCell.itemNormalPromotionPrice = item.normalPromotionPrice;
                                    gridCell.imageUrl = item.imageUrl;
                                    gridCell.isReserved = item.promotionItemType == 4;
                                }
                            });
                        }

                        $scope.ct.layoutPreviewGrid.push(gridCell);

                    });
                }
                else {
                    for (var i = 1; i <= layout.rows; i++) {
                        for (var j = 1; j <= layout.columns; j++) {
                            position++;
                            var gridCell = {
                                rowNumber: i,
                                colNumber: j,
                                rowspan: 1,
                                colspan: 1,
                                position: position,
                                isHighlighted: false,
                                imageUrl: '',
                                promotionItemId: 0,
                                promotionMechanicId: 0,
                                itemCode: '',
                                itemDescription: '',
                                itemPromotionalPrice: 0,
                                itemBonusPrice: 0,
                                itemBonusPoints: 0,
                                itemNormalPromotionPrice: 0
                            };

                            $scope.ct.layoutPreviewGrid.push(gridCell);
                        }
                    }
                }

                _drawTable();
            }

            // Si posee el atributo selectedPage fuerza a dibujar la tabla por cada elemento.
            if (angular.isDefined(attrs.selectedPage)
                && angular.isDefined($scope.$eval(attrs.selectedPage))) {
                var individualPage = $scope.$eval(attrs.selectedPage);

                if (individualPage.promotionMediaPageLayoutId !== 0) {
                    drawTable(false, individualPage);
                }
            }

        }
    }
});




